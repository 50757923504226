import { Grid, Paper, Typography } from "@material-ui/core";
import TaskIcon from "./TaskIcon";
function GridItems(props) {
    return (
        <Grid container direction="column" className={props.classes.stoplightGrid}>
            {props.gridItems?.map((task, i) => (
                <Grid
                    container
                    columns={16}
                    spacing={0}
                    key={task.task_name + i}
                    className={props.classes.stoplightGridItem}
                >
                    <Grid
                        item
                        xs={11}
                        className={props.classes.stoplightGridTaskIcon}
                    >

                        <p
                            className={props.classes.stoplightP}
                            key={task.task_name}
                        >
                            {task?.task_name
                                ?.toUpperCase()
                                .replace(/[_-]/g, " ")}
                        </p>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                    >
                        <TaskIcon task={task} classes={props.classes} />
                    </Grid>
                </Grid>
            ))}
        </Grid>

    );
};

export default GridItems;