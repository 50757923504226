import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";

import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import Alert from "@material-ui/lab/Alert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import useStyles from "./style";

const UpdatePorts = () => {
	const [allSerialPorts, setAllSerialPorts] = useState();
	const [enablePort, setEnablePort] = useState();
	const [currentPort, setCurrentPort] = useState();
	const [wait, setWait] = useState(false);
	const [status, setStatus] = useState();
	const token = useContext(Context);
	const classes = useStyles();

	let getSerialPorts = "";
	let updateSerialPort = "";
	if (process.env.NODE_ENV === "development") {
		getSerialPorts = "http://localhost:80/api/serial-ports/serial_ports";
		updateSerialPort = "http://localhost:80/api/serial-ports/serial_port";
	} else if (process.env.NODE_ENV === "production") {
		getSerialPorts = "/api/serial-ports/serial_ports";
		updateSerialPort = "/api/serial-ports/serial_port";
	}

	const getAllSerialPorts = async () => {
		await fetch(getSerialPorts, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((serialData) => {
				setAllSerialPorts(serialData.serial_ports);
			})
			.catch((error) => {
				alert("Error Retrieving Ports", error);
			});
	};

	const submitUpdatedPort = async () => {
		await fetch(
			updateSerialPort + `?port=${currentPort}&enabled=${enablePort}`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token.token}`,
					accept: "application/json",
				},
			}
		)
			.then((res) => {
				setStatus(res.status);
				setWait(false);
				window.location.reload(false);
			})

			.catch((error) => {
				console.log(error);
			});
	};

	const deletePort = async (port) => {
		await fetch(updateSerialPort + `?port=${port.port}`, {
			method: "Delete",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	};

	useEffect(() => {
		getAllSerialPorts();
	}, []);

	const Refresh = () => {
		window.location.reload(false);
	};

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));

	return (
		<div
			className={classes.paper}
			style={{ width: "100%", marginTop: "0" }}
			elevation={6}
		>
			<Typography
				component="h1"
				variant="h5"
				style={{ marginBottom: "15px" }}
			>
				Serial Port(s)
			</Typography>
			{allSerialPorts?.length > 0 ? allSerialPorts?.map((port) => (
				<form
					autoComplete="off"
					onSubmit={submitUpdatedPort}
					className={classes.form1}
					key={port.port}
					style={{ display: "block", textAlign: "center" }}
				>
					<div
						className={classes.port}
						style={{
							display: "block",
							textAlign: "center",
							marginBottom: "0",
						}}
					>
						<Grid>
							<div>
								<Typography
									style={{
										color: "black",
									}}
								>
									{port.port}
								</Typography>
							</div>
						</Grid>
					</div>
				</form>
			)): 
				<Typography style={{color: "black",}}>
					None
				</Typography>
			}
		</div>
	);
};

export default UpdatePorts;
