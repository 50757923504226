import {
    Box,
    Button,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import { useSystemSettingsContext } from "../../context/SystemSettings";
import useStyles from "./style";

const CLASSIFICATIONS = [
    { display_name: "None", value: "none", color: "" },
    { display_name: "Unclassified", value: "unclassified", color: "#00c853" },
    { display_name: "Confidential", value: "confidential", color: "#304ffe" },
    { display_name: "Secret", value: "secret", color: "#d50000" },
    { display_name: "Top Secret", value: "top_secret", color: "#ff6d00" },
    { display_name: "Top Secret // SCI", value: "top_secret_sci", color: "#ffd600" }
]

const ClassificationSettings = () => {
    const classes = useStyles();
    const [classification, setClassification] = useState("unclassified");
    const token = useContext(Context);
    const { systemSettings, setSystemSettings } = useSystemSettingsContext();

    let getApplianceSettings = "";
    let updateClassificationURL = ""
    if (process.env.NODE_ENV === "development") {
        getApplianceSettings = "http://localhost:80/api/appliance-settings/appliance_settings";
        updateClassificationURL = "http://localhost:80/api/appliance-settings/classification";
    } else if (process.env.NODE_ENV === "production") {
        getApplianceSettings = "/api/appliance-settings/appliance_settings";
        updateClassificationURL = "/api/appliance-settings/classification";
    }

    const getClassification = async () => {
        await fetch(getApplianceSettings, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.classification) {
                    for (const cls of CLASSIFICATIONS) {
                        if (cls.value === response.classification) {
                            setClassification(cls.value)
                        }
                    }
                } else {
                    setClassification("none")
                }

            })

            .catch((error) => {
                alert("Error Retrieving Appliance Info", error);
            });
    };

    const UpdateClassification = async () => {

        await fetch(updateClassificationURL + `?classification=${classification}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((error) => {
                        throw new Error(error.detail);
                    })
                }
            })
            .then((data) => {
                alert("Classification updated!")
                window.location.reload();
            })
            .catch((error) => {
                alert(`Error Updating Classification:\n${error.message}`);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateClassification();
    };

    // saves/updates the classication to a useContext
    const handleClassificationChange = (newSettingValue) => {
        setSystemSettings(prevState => ({
            ...prevState,
            classification: newSettingValue
        }))
    }

    useEffect(() => {
        getClassification();
    }, []);

    return (
        <Paper className={classes.paper} elevation={6} style={{ marginBottom: "50px", }}>
            <Typography
                component="h1"
                variant="h5"
                style={{ marginBottom: "15px" }}
            >
                Classification
            </Typography>
            <Box
                component="form"
                className={classes.form}
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <InputLabel
                    style={{
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Select Classification:
                </InputLabel>
                <div style={{ margin: "auto" }}>
                    <Select
                        value={classification}
                        onChange={(e) => { 
                            setClassification(e.target.value) 
                            // Setting SystemSettingsContext
                            handleClassificationChange(e.target.value)
                        }}
                    >
                        {CLASSIFICATIONS.map((cls) => (
                            <MenuItem
                                key={cls.value}
                                value={cls.value}
                            >
                                {cls.display_name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div style={{ margin: "auto" }}>
                    <Button type="submit" className={classes.applicanceSettBtn}>
                        Submit
                    </Button>
                </div>

            </Box>
        </Paper>
    );
}

export { CLASSIFICATIONS, ClassificationSettings };

