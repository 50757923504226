import { ThemeProvider } from "@material-ui/core/styles";
import { theme, useStyle } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../../context/Context";
import ReconnectingWebSocket from "reconnecting-websocket";
import taskWeights from "./BuildHelpers/TaskWeights";
import {
    Paper,
    CssBaseline,
    Stepper,
    Step,
    StepLabel,
    StepButton,
    Button,
} from "@material-ui/core";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import Stoplights from "./BuildHelpers/Stoplights";
import BuildID from "./BuildHelpers/BuildID";
import BuildStatus from "./BuildHelpers/BuildStatus";
import BuildDuration from "./BuildHelpers/BuildDuration";
import BuildProgressBar from "./BuildHelpers/BuildProgressBar";
import BuildLogs from "./BuildHelpers/BuildLogs";
import BuildButtonContainer from "./BuildHelpers/BuildButtonContainer";
import CheckActiveBuilds from "./BuildHelpers/CheckActiveBuilds";

const TrackBuild = () => {
    const token = useContext(Context);
    const classes = useStyle();
    const location = useLocation();
    const info = location.state;
    const history = useHistory();
    const [hide, setHide] = useState(true);
    const [res, setRes] = useState();
    const [logs, setLogs] = useState([]);
    const [buildData, setBuildData] = useState("");
    const buildID =
        buildData.build_id ||
        buildData.util_id ||
        info?.build?.build_id ||
        info?.build?.util_id;
    const [activeBuilds, setActiveBuilds] = useState([]);
    const [isrunning, setIsRunning] = useState(info?.inProgress || false);
    const [buildfailed, setBuildFailed] = useState(
        res?.status?.startsWith("build failed") || false
    );
    const [pbValue, setPbValue] = useState(0);
    const runningTasks = res?.tasks || {};
    const workflowTasks = res?.workflow || {};
    const resdataTypeEndpoint = info?.resdata?.type || "build";
    const resdataResult = info?.resdata?.Result;
    const infoBuildType = info?.buildType;
    const prechecks = resdataResult?.pre_checks;
    const bios = resdataResult?.bios;
    const datacenter = resdataResult?.datacenter;
    const postInstall = resdataResult?.post_install;
    const network = resdataResult?.network_devices;
    const virtualNetwork = resdataResult?.virtual_serial_devices;
    const networkUtil = resdataResult?.network_utilities;
    const playbooks = resdataResult?.playbooks;

    const [isFetching, setIsFetching] = useState(true);
    const [steps, setSteps] = useState([]);
    const type_dispatcher = {
        pre_checks: prechecks,
        bios: bios,
        datacenter: datacenter,
        post_install: postInstall,
        network_devices: network,
        virtual_serial_devices: virtualNetwork,
        network_utilities: networkUtil,
        playbooks: playbooks,
    };
    const getSteps = () => {
        let s = [];
        if (prechecks) {
            s.push("pre_checks");
        }
        if (bios) {
            s.push("bios");
        }
        if (datacenter) {
            s.push("datacenter");
        }
        if (network) {
            s.push("network_devices");
        }
        if (virtualNetwork) {
            s.push("virtual_serial_devices");
        }
        if (postInstall) {
            s.push("post_install");
        }
        if (networkUtil) {
            s.push("network_utilities");
        }
        if (playbooks) {
            s.push("playbooks");
        }
        setSteps(s);
    };

    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;

    // web socket
    let socketURL = "";
    let baseURLBase = "";
    let baseURL = "";
    let stopURL = "";
    let activeURL = "";
    let buildType = "";
    let reviewPath = "";
    let socketBase = "";

    if (network != null) {
        buildType = "network-builds";
        reviewPath = "/review/network-builds/";
        baseURLBase = "/api/network-builds/";
    } else if (virtualNetwork != null) {
        buildType = "virtual-network-builds";
        reviewPath = "/review/virtual-network-builds/";
        baseURLBase = "/api/virtual-network-builds/";
    } else if (networkUtil != null) {
        buildType = "network-utils";
        reviewPath = "/review/network-utils/";
        baseURLBase = "/api/network-utils/";
    } else if (infoBuildType === "playbooksBuild") {
        buildType = "playbooks";
        reviewPath = "/review/playbooks/";
        baseURLBase = `/api/playbooks/`;
    } else {
        buildType = "build";
        reviewPath = "/review/build/";
        baseURLBase = "/api/build/";
    }

    if (process.env.NODE_ENV === "development") {
        baseURL = `http://localhost:80${baseURLBase}`;
        stopURL = "http://localhost:80/api/control/cancel-build/";
        activeURL = "http://localhost:80/api/control/active/";
        socketBase = "ws://localhost";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = baseURLBase;
        stopURL = "/api/control/cancel-build/";
        activeURL = "/api/control/active/";
        socketBase = `wss://${window.location.hostname}`;
    }

    const submitForm = async () => {
        let url = `${baseURL}${resdataTypeEndpoint}`;
        if (info.kit_id) {
            url += `?kit_id=${encodeURIComponent(info.kit_id)}`;
        }
        let fetchData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        };
        const data = JSON.stringify(info.data) || JSON.stringify(info.files);
        if (data) {
            fetchData["body"] = data;
        }
        await fetch(url, fetchData)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((error) => {
                        throw new Error(error.detail);
                    });
                }
            })
            .then((data) => {
                setBuildData(data);
                alert("Please power on devices");
                setActiveStep(0);
                history.push({
                    state: {
                        resdata: info?.resdata,
                        build: data,
                        inProgress: true,
                        buildType: info.buildType,
                    },
                });
                setHide(false);
                setTimeout(function () {
                    setIsRunning(true);
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
                alert(`Error Deploying:\n\n${error}.\n\nPlease Try Again.`);
            });
    };
    // Get the status of the current build
    const getStatus = async () => {
        await fetch(baseURL + buildID, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setRes(resdata);
                if (resdata?.status === "success") {
                    setIsRunning(false);
                }
                if (resdata?.status?.startsWith("build failed")) {
                    setIsRunning(false);
                    setBuildFailed(true);
                }
                setIsFetching(false);
            })
            .catch((error) => {
                alert("Error With Providing Status, Please Try Again", error);
            });
    };
    // Cancel the current build
    const cancelContainers = () => {
        fetch(stopURL + buildID + `?type=${buildType}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Build Canceled");
        history.push("/");
    };
    // Check if there are any active builds running
    const getActiveBuilds = () => {
        fetch(activeURL, {
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((active) => {
                setActiveBuilds(active);
                if (buildID != null) {
                    setHide(false);
                }
            })
            .catch((error) => {
                if (buildID != null) {
                    setHide(false);
                }
            });
    };

    const calculateBuildPercentage = () => {
        let tasks = 0;
        let tasksFinished = 0;
        let weightedTasks = 0;
        let finishedWeightedTasks = 0;
        for (const w in workflowTasks) {
            let weight = getWeight(w);
            tasks += workflowTasks?.[w]?.length;
            for (const t of workflowTasks[w]) {
                weightedTasks += taskWeights[t] || weight;
            }
        }
        if (Array.isArray(runningTasks)) {
            for (const t of runningTasks) {
                if (t.status === "green") {
                    tasksFinished += 1;
                    finishedWeightedTasks += taskWeights[t.task_name] || 1;
                }
            }
        } else {
            for (const t in runningTasks) {
                let weight = getWeight(t);
                for (const ft of runningTasks[t]) {
                    if (ft.status === "green") {
                        tasksFinished += 1;
                        finishedWeightedTasks +=
                            taskWeights[ft.task_name] || weight;
                    }
                }
            }
        }
        if (tasksFinished > 0) {
            const newPbValue = (finishedWeightedTasks / weightedTasks) * 100;
            const previousPbValue = pbValue;
            incrementPbValue(previousPbValue, newPbValue);
        }
    };

    const incrementPbValue = async (prev, curr) => {
        let trackedPbValue = prev;
        while (trackedPbValue < curr) {
            await new Promise((r) => setTimeout(r, 50));
            trackedPbValue += 1;
            setPbValue(trackedPbValue);
        }
    };

    const getWeight = (t) => {
        switch (t) {
            case "bios":
                return 8;
            case "network_devices":
                return 1;
            case "virtual_serial_devices":
                return 5;
            default:
                return 1;
        }
    };

    const handleSteps = async () => {
        const currentStep = steps[activeStep];
        const totalTasks = workflowTasks[currentStep];
        const currentTasks = Array.isArray(runningTasks)
            ? runningTasks
            : runningTasks[currentStep];
        let passedTasks = 0;
        if (currentTasks !== undefined && totalTasks !== undefined) {
            for (const step of currentTasks) {
                if (step.status === "green") {
                    passedTasks += 1;
                }
            }
            if (passedTasks === totalTasks.length) {
                await new Promise((r) => setTimeout(r, 1500));
                setActiveStep(activeStep + 1);
            }
        }
    };

    useEffect(() => {
        getSteps();
    }, [resdataResult]);
    useEffect(() => {
        calculateBuildPercentage();
        if (isrunning) {
            handleSteps();
        }
    }, [runningTasks, activeStep]);

    useEffect(() => {
        getActiveBuilds();
        if (isrunning) {
            const id = window.setInterval(() => {
                getStatus();
            }, 1000);
            return () => window.clearInterval(id);
        }
        return undefined;
    }, [isrunning]);

    socketURL = `${socketBase}/api/ws/build_log/${buildID}?token=${token.token}&build_type=${buildType}`;

    const ws = useRef(null);
    useEffect(() => {
        if (!token.token || !buildID) return;

        ws.current = new ReconnectingWebSocket(socketURL);
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, [buildID]);

    useEffect(() => {
        if (!ws.current) return;
        const update_logs = (event) => {
            let data = JSON.parse(event.data);
            setLogs((logs) => [...logs, data]);
        };

        ws.current.onmessage = (event) => {
            update_logs(event);
        };
    }, [buildID]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>
                <div className={classes.root}>
                    {/* <CheckActiveBuilds
                        activeBuilds={activeBuilds}
                    /> */}
                    <BuildButtonContainer
                        classes={classes}
                        buildID={buildID}
                        info={info}
                        hide={hide}
                        submitForm={submitForm}
                        res={res}
                        setIsRunning={setIsRunning}
                        cancelContainers={cancelContainers}
                        reviewPath={reviewPath}
                    />
                    {res?.status === "success" || isrunning || buildfailed ? (
                        isFetching ? (
                            <Paper className={classes.buildInfoPaper}>
                                <CircularProgress
                                    size="3rem"
                                    color="error"
                                    sx={{
                                        [`& .${circularProgressClasses.circle}`]:
                                            {
                                                strokeLinecap: "round",
                                            },
                                    }}
                                />
                            </Paper>
                        ) : (
                            <Paper className={classes.buildInfoPaper}>
                                <BuildProgressBar
                                    buildfailed={buildfailed}
                                    classes={classes}
                                    value={pbValue}
                                />
                                <BuildID
                                    buildID={buildID}
                                    res={res}
                                    classes={classes}
                                />
                                <BuildStatus res={res} classes={classes} />
                                <BuildDuration res={res} classes={classes} />
                            </Paper>
                        )
                    ) : (
                        ""
                    )}
                    <Paper className={classes.stepperPaper}>
                        <Stepper
                            nonLinear={isrunning ? false : true}
                            alternativeLabel
                            activeStep={activeStep}
                            className={classes.stepper}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    {isrunning ? (
                                        <StepLabel>
                                            {label
                                                .toUpperCase()
                                                .replace(/[_-]/g, " ")}
                                        </StepLabel>
                                    ) : (
                                        <StepButton
                                            onClick={() => {
                                                setActiveStep(
                                                    steps.indexOf(label)
                                                );
                                            }}
                                        >
                                            {label
                                                .toUpperCase()
                                                .replace(/[_-]/g, " ")}
                                        </StepButton>
                                    )}
                                </Step>
                            ))}
                        </Stepper>
                        <Stoplights
                            res={res}
                            classes={classes}
                            stoplight_type={steps[activeStep]}
                            stoplight_type_obj={
                                type_dispatcher[steps[activeStep]]
                            }
                        />
                    </Paper>
                </div>
                <BuildLogs res={res} classes={classes} logs={logs} />
            </div>
        </ThemeProvider>
    );
};

export default TrackBuild;
