import React from "react";

import { Controller } from "react-hook-form";
import {
  Paper,
  Grid,
  Typography,
  Container,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@material-ui/core";
import useStyles from "./styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const NetworkSettings = ({ register, control, info, show4 }) => {
  const classes = useStyles();

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={6}>
        <Typography component="h1" variant="h5">
          Network Settings
        </Typography>
        <form autoComplete="off" className={classes.form}>
          <Grid
            style={{
              marginRight: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <Grid style={{ marginTop: "10px" }}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <input
                        style={{ marginLeft: "25px" }}
                        type="checkbox"
                        defaultChecked={"" || info?.taclan === true}
                        {...register("taclan")}
                      />
                    }
                    label="Taclan"
                  />
                </FormGroup>
              </FormControl>
            </Grid> */}

            <Alerttip title="VLAN tagging for the Default Port Group on the Distributed Switch. Enter the VLAN ID number or use 0 if no Tagging is Needed">
              <TextField
                variant="outlined"
                style={{ margin: "40px 0" }}
                defaultValue={info?.vlanid || ""}
                label="VLAN ID"
                {...register("vlanid")}
                type="number"
              />
            </Alerttip>
            <Alerttip title="Enter the Name for the Distributed Switch that will be Deployed (example: NTS-DSwitch)">
              <TextField
                style={{ marginBottom: "40px" }}
                variant="outlined"
                label="DVSwitch Name"
                {...register("dvs_name")}
                defaultValue={info?.dvs_name || ""}
              />
            </Alerttip>
            <Alerttip title="Enter the Name for the Default Port Group on the Distribute Switch (example: MGMT)">
              <TextField
                variant="outlined"
                style={{ marginBottom: "20px" }}
                label="DV Port Name"
                {...register("dv_port_name")}
                defaultValue={info?.dv_port_name || ""}
              />
            </Alerttip>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "30px 0",
              }}
            >
              <Alerttip title="Select the Primary Physical Network Adapter to be Attached to the Distributed Switch">
                <InputLabel
                  style={{ color: "black", display: "flex", margin: "0 10px" }}
                >
                  Primary NIC:
                </InputLabel>
              </Alerttip>
              <Controller
                control={control}
                name="vmnic_distributed_switch"
                defaultValue={info?.vmnic_distributed_switch || ""}
                render={({ field }) => (
                  <Select {...field} onChange={field.onChange}>
                    <MenuItem value={"vmnic0"}>vmnic0</MenuItem>
                    <MenuItem value={"vmnic1"}>vmnic1</MenuItem>
                    <MenuItem value={"vmnic2"}>vmnic2</MenuItem>
                  </Select>
                )}
              />
            </div>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default NetworkSettings;
