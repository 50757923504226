import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import AddIcon from "@material-ui/icons/Add";

import {
  Paper,
  Typography,
  Container,
  Card,
  Fab,
  TextField,
} from "@material-ui/core";
import useStyles from "./styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const VMAdvOptions = ({ register, info, showVMOptions }) => {
  const classes = useStyles();

  const { control } = useForm({
    defaultValues: {
      vm_advanced_options: info?.vm_advanced_options || [
        { key: "", value: "" },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "vm_advanced_options",
  });

  const handleAddFields = () => {
    append({ key: "", value: "" });
  };

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Container component="main" maxWidth="xl">
      <Paper
        style={{ display: "flex", flexDirection: "column" }}
        className={classes.paper}
        elevation={6}
      >
        <Typography component="h1" variant="h5">
          Advanced Options
        </Typography>
        <Fab
          variant="extended"
          style={{ marginTop: "15px" }}
          onClick={handleAddFields}
        >
          <AddIcon />
          Options
        </Fab>
        <form autoComplete="off" className={classes.form}>
          {fields.map((item, index) => (
            <div key={item.id}>
              <Card name="vm_advanced_options" className={classes.card}>
                <div style={{ display: "flex" }}>
                  <Alerttip title="Do you need any Advanced Systems Settings? If so, enter the Advanced System Setting identifier (example: Config.Etc.motd)">
                    <TextField
                      variant="outlined"
                      style={{ margin: "20px" }}
                      label="Advanced Key"
                      {...register(`vm_advanced_options[${index}].key`)}
                      defaultValue={info?.vm_advanced_options[index]?.key || ""}
                      autoComplete="false"
                    />
                  </Alerttip>
                  <Alerttip title="Enter in the value for the Advanced System Settings. For example, for the MOTD, you could enter “Welcome to ESXi, Powered by Mantle!” ">
                    <TextField
                      variant="outlined"
                      style={{ margin: "20px" }}
                      label="Advanced Value"
                      {...register(`vm_advanced_options[${index}].value`)}
                      defaultValue={
                        info?.vm_advanced_options[index]?.value || ""
                      }
                      autoComplete="false"
                    />
                  </Alerttip>
                </div>
              </Card>
            </div>
          ))}
        </form>
      </Paper>
    </Container>
  );
};

export default VMAdvOptions;
