import { makeStyles } from "@material-ui/core/styles";

// Light Theme Colors
const primaryColorLight = "#b71c1c"; // Light version of rich dark red
const secondaryColorLight = "#f5f5f5"; // Light version of medium dark gray
const hightlightColorLight = "#e0e0e0"; // Light version of medium dark gray
const errorColorLight = "#e57373"; // Light version of bright red

const warningColorLight = "#ffb74d"; // Light version of orange for warnings
const infoColorLight = "#64b5f6"; // Light version of light blue for informational messages
const successColorLight = "#81c784"; // Light version of green for success states

// Dark Theme Colors
const primaryColorDark = "#590000"; // Dark version of rich dark red
const secondaryColorDark = "#1b1b1b"; // Dark version of medium dark gray
const errorColorDark = "#880e4f"; // Dark version of bright red

const warningColorDark = "#f57c00"; // Dark version of orange for warnings
const infoColorDark = "#1976d2"; // Dark version of light blue for informational messages
const successColorDark = "#388e3c"; // Dark version of green for success states

// Shared Theme Colors
const backgroundLight = "#f9f9f9"; // Light gray for light theme backgrounds
const backgroundDark = "#212121"; // Very dark for dark theme backgrounds
const textPrimaryLight = "#303030"; // Very dark text for light backgrounds
const textPrimaryDark = "#ffffff"; // White text for dark backgrounds
const textSecondaryLight = "#555"; // Slightly lighter text for secondary content in light theme
const textSecondaryDark = "#bdbdbd"; // Light gray for secondary text in dark theme

// Background and paper colors for light theme
const paperColorLight = "#fff"; // White paper backgrounds in light theme

// Paper color for dark theme (secondary dark color is already defined)
const paperColorDark = "#424242"; // A dark grey for paper backgrounds in dark theme

const useGlobalStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cardIcons: {
        width: "98%",
        height: "100px",
        margin: "0 auto", // centers the card in the grid
        // padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down("sm")]: {
            width: "auto",
        },
    },
    gridContainer: {
        margin: "0 !important",
        justifyContent: "center",
    },
    activeCard: {
        backgroundColor: "#a8d5ba",
    },
    accordion: {
        backgroundColor: "black",
        color: "white",
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
    },
    alert: {
        marginTop: "10px",
    },
    rightLabel: {
        marginLeft: "10px",
    },

    // root: {
    //     width: "auto",
    //     marginLeft: theme.spacing(2),
    //     marginRight: theme.spacing(2),
    //     [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //         width: 700,
    //         marginLeft: "auto",
    //         marginRight: "auto",
    //     },
    //     backgroundColor: theme.palette.background.default,
    //     color: theme.palette.text.primary,
    // },
    mainBuildButton: {
        margin: "30px 0",
        padding: "10px",
        width: "10vw",
        backgroundColor: "#EA2331",
        color: "#333333",

        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },
    buildButtonDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buildButtons: {
        letterSpacing: "1px",
        margin: "5px",
        backgroundColor: "#b71c1c",
        color: "white",
        borderRadius: theme.shape.borderRadius * 1,
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: "#424242",
            color: "white",
        },
    },
    buildInfoPaper: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
    },
    buildInfoDiv: {
        display: "inline-block",
        textAlign: "center",
        margin: "10px",
    },
    buildDuration: {
        width: "6rem",
    },
    buildInfoLabels: {
        textAlign: "left",
        fontFamily: "Montserrat",
        marginLeft: "10px",
        fontSize: "0.75rem",
        padding: "0px",
    },
    buildInfo: {
        textAlign: "left",
        fontFamily: "Montserrat",
        marginLeft: "10px",
        fontSize: "1.25rem",
        padding: "0px",
    },
    stepperPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(10),
        padding: theme.spacing(2),
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    stoplightGrid: {
        borderRadius: theme.shape.borderRadius * 1,
        marginBottom: theme.spacing(3),
    },
    stoplightGridItem: {
        backgroundColor: theme.palette.background.default,
        marginBottom: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius * 1,
        borderStyle: "solid",
        borderWidth: "0.25px",
        borderColor: "#eeeeee",
    },
    stoplightGridTaskName: {
        marginLeft: "50px",
    },
    stoplightP: {
        // fontWeight: "bolder",
        letterSpacing: "1px",
        marginBottom: "16px",
        marginLeft: "10px",
    },
    pbDiv: {
        paddingRight: theme.spacing(2),
    },
    pbLabel: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Montserrat",
    },
    logsDiv: {
        position: "fixed",
        bottom: 0,
    },
    logsAppBar: {
        width: "100%",
        backgroundColor: "white",
        color: "black",
        display: "flex",
        flexDirection: "row",
        padding: "2px 15px 2px 10px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
        },
        position: "fixed",
        bottom: "0",
        marginBottom: "45px",
        zIndex: 1399,
    },
    logsDrawerPaper: {
        marginBottom: "75px",
        zIndex: -1,
    },
    virtualTableBox: {
        marginTop: "20px",
        height: "100%",
        width: "100%",
    },
    dragger: {
        width: "100%",
        height: "20px",
        cursor: "ns-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        backgroundColor: "#f4f7f9",
    },
    logsDivScroll: {
        marginTop: "20px",
        overflowY: "scroll",
        maxHeight: "500px",
    },
    logsCell: {
        padding: "5px",
        paddingLeft: "15px",
        whiteSpace: "nowrap",
    },
    autoscrollLabel: {
        padding: "0px",
        fontFamily: "Montserrat",
        fontSize: "0.75rem",
    },
    autoScrollSwitch: {
        margin: "0px 0px 0px auto",
    },
    tableRowEven: {
        backgroundColor: "#fafafa",
        cursor: "pointer",
        textDecoration: "none",
        color: "inherit",
    },
    tableRowOdd: {
        backgroundColor: "white",
        cursor: "pointer",
        textDecoration: "none",
        color: "inherit",
    },
    /*
        New styles from the uploadPage.jsx's theme overide
    */
    customMuiPaper: {
        backgroundColor: secondaryColorLight, // lighter version of medium dark gray
        borderStyle: "solid",
        borderWidth: "0.25px",
        borderColor: "#e0e0e0",
    },
    customMuiListSubheader: {
        fontSize: "16px",
        // fontWeight: "bold",
        borderBottom: "2px solid #212121", // lighter version of medium dark gray
        color: "white",
        backgroundColor: "#b71c1c",
    },
    customMuiListItemText: {
        marginLeft: "8px",
        marginTop: "0px",
        marginBottom: "0px",
        fontSize: "14px",
        fontWeight: "normal",
    },
    customMuiListItemButton: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
        display: "flex",
        alignSelf: "center",
    },
    customBox: {
        backgroundColor: "#f5f5f5",
        borderRightStyle: "solid",
        borderRightWidth: "0.25px",
        borderRightColor: "#e0e0e0",
    },
    mainContentGrid: {
        marginTop: "20px",
        height: "80vh",
        overflowY: "scroll",
    },
    linkNoUnderline: {
        textDecoration: "none",
    },
    cursorDefault: {
        cursor: "default",
    },
    /*
        New styles from the uploadPage.jsx's theme overide
    */
    customMuiPaper: {
        backgroundColor: secondaryColorLight, // lighter version of medium dark gray
        borderStyle: "solid",
        borderWidth: "0.25px",
        borderColor: "#e0e0e0",
    },
    customMuiListSubheader: {
        fontSize: "16px",
        // fontWeight: "bold",
        borderBottom: "2px solid #212121", // lighter version of medium dark gray
        color: "white",
        backgroundColor: "#b71c1c",
    },
    customMuiListItemText: {
        marginLeft: "8px",
        marginTop: "0px",
        marginBottom: "0px",
        fontSize: "14px",
        fontWeight: "normal",
    },
    customMuiListItemButton: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
        display: "flex",
        alignSelf: "center",
    },
    customBox: {
        backgroundColor: "#f5f5f5",
        borderRightStyle: "solid",
        borderRightWidth: "0.25px",
        borderRightColor: "#e0e0e0",
    },
    mainContentGrid: {
        marginTop: "20px",
        height: "85vh",
        overflowY: "scroll",
    },
    MenuButtons: {
        // letterSpacing: "3px",
        backgroundColor: "#b71c1c",
        color: "white",
        // marginRight: "0px",
        // width: "9rem",
        padding: "2px 10px",
        "&:hover": {
            backgroundColor: "#424242",
            color: "white",
        },
    },
    BuildFormPaper: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        "@media (max-width: 450px)": {
            width: "100%",
        },
    },
    BuildForm: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
        alignItems: "center",
        flexDirection: "column",
    },
    BuildFormCard: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 0 0 0",
        margin: "10px 0 10px 0",
        width: "98%",
        "&:hover": {
            boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
        },
    },
    customInputLabel: {
        color: "black",
        display: "flex",
        marginTop: "20px",
        marginRight: "20px",
        marginBottom: "20px",
    },
    divRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
    },
    divCol: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    paperTitle: {
        marginBottom: "25px",
        textAlign: "center",
        fontWeight: "lighter",
    },
    linkPointer: {
        cursor: "pointer",
    },
    customTextField: {
        width: "25vh",
        marginBottom: "10px",
    },
    customGridContainer: {
        alignItems: "center",
        "@media (max-width: 959px)": {
            borderRadius: theme.shape.borderRadius * 1,
            borderStyle: "solid",
            borderWidth: "0.25px",
            borderColor: "#eeeeee",
            margin: "2px",
        },
    },
    customGridItem1: {
        textAlign: "right",
        "@media (max-width: 959px)": {
            textAlign: "center",
        },
    },
    customGridItem2: {
        "@media (max-width: 959px)": {
            textAlign: "center",
            marginLeft: "30px",
        },
    },
    customCardTitleBold: {
        margin: "16px 0",
        textAlign: "center",
        fontWeight: "bold",
    },
    customCardTitle: {
        margin: "16px 0",
        textAlign: "center",
    },
    customInputLabelForm: {
        marginBottom: "20px",
    },
    mobileNoDisplay: {
        "@media (max-width: 475px)": {
            display: "none",
        },
    },
    mobileFooterText: {
        display: "flex",
        flexDirection: "row",
        "@media (max-width: 726px)": {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
        },
    },
    formControlButton: {
        backgroundColor: "#cccccc",
        height: "50px",
        width: "100px",
        borderRadius: "5%",
        marginTop: "5px",
    },
}));

export default useGlobalStyle;
