import React from "react";
import { useFieldArray } from "react-hook-form";
import { Controller } from "react-hook-form";
import {
  TextField,
  MenuItem,
  Button,
  InputLabel,
  Select,
} from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const NestedPortGroupVlan = ({
  nestedIndex,
  info,
  register,
  controls,
}) => {
  const { fields, append } = useFieldArray({
    control: controls,
    name: `added_vswitches.${nestedIndex}.port_groups`,
  });

  const handleAddFields = (e) => {
    e.preventDefault();
    append({
      name: "",
      vlan: "",
    });
  };

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 25px",
      }}
    >
      <Button
        style={{ backgroundColor: "lightgrey" }}
        onClick={handleAddFields}
      >
        + Port Group/VLAN
      </Button>
      {fields.map((item, k) => (
        <>
          <div
            style={{
              display: "flex",
            }}
          >
            <Alerttip title="Would you like additional Port Groups assigned to the new vSwitch? If so, enter the name here (example: Server-PG)">
              <TextField
                variant="outlined"
                style={{ margin: "20px" }}
                label="vSwitch Port Groups"
                {...register(
                  `added_vswitches.${nestedIndex}.port_groups.${k}.name`
                )}
                defaultValue={
                  info?.added_vswitches?.[nestedIndex]?.port_groups?.[k]?.name || ""
                }
                autoComplete="false"
              />
            </Alerttip>

            <Alerttip title="Would you like VLAN tagging on the Port Group? If so, assign the VLAN ID here (example: 10)">
              <TextField
                variant="outlined"
                style={{ margin: "20px" }}
                label="vSwitch VLAN"
                type="number"
                {...register(
                  `added_vswitches.${nestedIndex}.port_groups.${k}.vlan`
                )}
                defaultValue={
                  info?.added_vswitches?.[nestedIndex]?.port_groups?.[k]?.vlan || ""
                }
                autoComplete="false"
              />
            </Alerttip>
          </div>
        </>
      ))}
    </div>
  );
};

export default NestedPortGroupVlan;
