import axios from "axios";

export const fetchData = async (url, token) => {
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw new Error(`Error Retrieving Data: ${error.message}`);
  }
};

export const uploadDataWPercent = async (
  url,
  data,
  headers,
  onUploadProgress
) => {
  try {
    const res = await axios.post(url, data, {
      headers: headers,
      onUploadProgress: onUploadProgress,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data?.detail || "Error with response");
    } else if (error.request) {
      throw new Error("Error with request. See logs.");
    } else {
      throw new Error("Error. Please contact NTS");
    }
  }
};

export const deleteData = async (url, token) => {
  try {
    console.log(url);
    await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error(`Error Deleting Data: ${error.message}`);
  }
};
