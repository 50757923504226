import {
    Drawer,
    AppBar,
    Typography,
    Box,
    Switch,
    FormControlLabel,
} from "@material-ui/core";
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import React, { useState, useCallback, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import IconButton from "@mui/material/IconButton";
import { useStyle } from "../styles";

function BuildLogs(props) {
    const minDrawerHeight = 100;
    const maxDrawerHeight = 500;
    const [drawerHeight, setDrawerHeight] = useState(200);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const logs = props.logs;
    const [rows, setRows] = useState([]);
    const [userScrolled, setUserScrolled] = useState(false);
    const classes = useStyle();

    const handleUserScrolled = () => {
        setUserScrolled(!userScrolled);
    };

    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
        if (!drawerOpen) {
            setUserScrolled(false);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };
    const handleMouseMove = useCallback((e) => {
        const newHeight =
            document.documentElement.clientHeight - e.clientY - 100;
        if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
            setDrawerHeight(newHeight);
        }
    }, []);

    const handleCellOverflow = (e) => {
        return <div className={classes.overflowScrollCell}>{e.cellData}</div>;
    };

    useEffect(() => {
        setRows(
            logs.map((log) => ({
                container: log.data[0],
                timestamp: new Date(log.data[1]).toLocaleTimeString(),
                message: log.data[2],
            }))
        );
    }, [logs]);

    return (
        <div className={props.classes.logsDiv}>
            <Drawer
                classes={{ paper: props.classes.logsDrawerPaper }}
                variant="persistent"
                anchor="bottom"
                open={drawerOpen}
                PaperProps={{ style: { height: drawerHeight } }}
            >
                <div className={props.classes.logsBar}>
                    <div
                        onMouseDown={(e) => handleMouseDown(e)}
                        className={props.classes.dragger}
                    />
                </div>
                <Box className={props.classes.virtualTableBox}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                height={height}
                                width={width}
                                rowHeight={30}
                                headerHeight={40}
                                headerStyle={{
                                    fontFamily: "Montserrat",
                                    fontWeight: "normal",
                                }}
                                rowStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderBottom: "1px solid #e0e0e0",
                                    fontFamily: "Montserrat",
                                }}
                                scrollToIndex={
                                    userScrolled ? -1 : rows?.length - 1
                                }
                                rowCount={rows?.length || 0}
                                rowGetter={({ index }) => rows[index]}
                            >
                                <Column
                                    flexGrow={0}
                                    label="Service"
                                    dataKey="container"
                                    width={120}
                                    minWidth={100}
                                />
                                <Column
                                    flexGrow={0}
                                    label="Timestamp"
                                    dataKey="timestamp"
                                    width={95}
                                    minWidth={90}
                                />
                                <Column
                                    label="Message"
                                    dataKey="message"
                                    cellRenderer={handleCellOverflow}
                                    width={1200}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </Box>
            </Drawer>
            <AppBar position="static" className={props.classes.logsAppBar}>
                <IconButton
                    onClick={handleToggleDrawer}
                    style={{ padding: "0px" }}
                >
                    {drawerOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </IconButton>
                <Typography
                    component="h1"
                    variant="h5"
                    className={props.classes.buildInfoLabels}
                    style={{
                        margin: "5px",
                        marginBottom: "7px",
                    }}
                >
                    Logs
                </Typography>
                {drawerOpen ? (
                    <FormControlLabel
                        className={props.classes.autoScrollSwitch}
                        control={
                            <Switch
                                defaultChecked
                                color="primary"
                                size="small"
                                onChange={handleUserScrolled}
                            />
                        }
                        label={
                            <Typography
                                component="h6"
                                variant="caption"
                                className={props.classes.autoscrollLabel}
                            >
                                Auto Scroll
                            </Typography>
                        }
                        labelPlacement="start"
                    />
                ) : (
                    ""
                )}
            </AppBar>
        </div>
    );
}

export default BuildLogs;
