import { useQuery } from "react-query";
import { serialDevicesURL } from "./utilties/QueryURLs";
import { fetchData } from "./utilties/QueryManager";

export const GetSerialDevices = (token) => {
    return useQuery(
        ["serialDevices", serialDevicesURL, token],
        async () => {
            const data = await fetchData(serialDevicesURL, token);
            return data?.devices;
        },
        {
            refetchOnWindowFocus: true,
        }
    );
};
