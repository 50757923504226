// import useStyles from "../create/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

function BuildButtonContainer(props) {
    const classes = props.classes;
    return (
        <div className={classes.buildButtonDiv}>
            {props.hide === true ? (
                <Button
                    onClick={props.submitForm}
                    disable={props.res?.status ? "in_progress" : undefined}
                    style={{
                        display:
                            props.buildID === undefined
                                ? "inline-flex"
                                : "none",
                    }}
                    className={classes.buildButtons}
                >
                    Deploy
                </Button>
            ) : (
                ""
            )}

            <Button
                style={{
                    display:
                        props.res?.status === "in_progress"
                            ? "inline-flex"
                            : "none",
                }}
                onClick={(e) => {
                    if (
                        window.confirm(
                            "Are you sure you wish to cancel this build?"
                        )
                    )
                        props.cancelContainers(e);
                }}
                className={classes.buildButtons}
            >
                Cancel
            </Button>
            <Link
                style={{ textDecoration: "none", alignSelf: "center" }}
                to={{
                    pathname: `${props.reviewPath}${
                        props.buildID || props?.info?.thisID
                    }`,
                    state: {
                        buildID: props.buildID,
                    },
                }}
            >
                <Button
                    style={{
                        display:
                            props?.res?.status === "success" ||
                            props?.res?.status?.startsWith("build failed")
                                ? "flex"
                                : "none",
                    }}
                    className={classes.buildButtons}
                >
                    Review Build
                </Button>
            </Link>
        </div>
    );
}

export default BuildButtonContainer;
