import {
    createTheme,
    responsiveFontSizes,
    makeStyles,
} from '@material-ui/core/styles';

let theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: "#424242"
        },
        secondary: {
            main: '#b71c1c',
        }
    }
});

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
    root: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    innerDivs: {
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    button: {
        backgroundColor: "#EA2331",
        width: "100px",
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },

}));

export { theme, useStyle };