import { useQuery, useQueryClient, useMutation } from "react-query";
import { licenseURL } from "./utilties/QueryURLs";
import {
  fetchData,
  uploadDataWPercent,
  deleteData,
} from "./utilties/QueryManager";

export const GetLicenses = (token) => {
  return useQuery(
    ["licenses", licenseURL, token],
    async () => {
      const data = await fetchData(licenseURL, token);
      return data?.licenses;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useUploadLicense = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ url, headers, data, onUploadProgress, token }) => {
      return await uploadDataWPercent(
        url,
        data,
        headers,
        onUploadProgress,
        token
      );
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(["licenses", licenseURL, token]);
      },
      onError: (error) => {
        console.error(error.message);
        alert(error.message);
      },
    }
  );
};

export const useDeleteLicense = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ url, token }) => {
      return await deleteData(url, token);
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(["licenses", licenseURL, token]);
      },
      onError: (error) => {
        console.error(error.message);
        alert(error.message);
      },
    }
  );
};
