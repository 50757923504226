import React from "react";
import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import Alerttip from "../../alertTip/Alerttip";
import useStyles from "./styles";
import useGlobalStyle from "../../../themes/globalStyle";

const VirtualNetworkVariables = ({
    currentDevice,
    register,
    unregister,
    setValue,
    deviceIndex,
    getValues,
    info,
    watch,
}) => {
    const classes = useStyles();
    const globalStyle = useGlobalStyle();

    const handleConfigVariableChange = (item) => {
        unregister(`network_devices[${deviceIndex}].config_variables`);
        for (let [index, rf] of currentDevice.required_fields[item].entries()) {
            setValue(
                `network_devices[${deviceIndex}].config_variables.${index}.name`,
                rf
            );
            setValue(
                `network_devices[${deviceIndex}].config_variables.${index}.value`,
                ""
            );
        }
    };

    return (
        <>
            {getValues(`network_devices[${deviceIndex}]`)?.variations && (
                <div className={classes.centerContent}>
                    <Typography
                        component="h1"
                        variant="h6"
                        className={globalStyle.customCardTitle}
                    >
                        Device Variation
                    </Typography>
                    <TextField
                        select
                        className={classes.customTextField}
                        variant="outlined"
                        label="Device Variation"
                        {...register(
                            `network_devices[${deviceIndex}].variation`
                        )}
                        value={
                            watch(
                                `network_devices[${deviceIndex}].variation`
                            ) || ""
                        }
                        defaultValue={
                            getValues(
                                `network_devices[${deviceIndex}].variation`
                            ) ||
                            info?.virtual_serial_devices[deviceIndex]
                                ?.variation ||
                            ""
                        }
                    >
                        {currentDevice?.variations.map((item) => (
                            <MenuItem
                                onClick={(e) =>
                                    handleConfigVariableChange(item)
                                }
                                value={item}
                                key={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            )}

            <Box style={{ textAlign: "center" }}>
                {(
                    watch(`network_devices[${deviceIndex}].config_variables`) ||
                    []
                ).length > 0 && (
                    <Typography
                        component="h1"
                        variant="h6"
                        className={globalStyle.customCardTitle}
                    >
                        Variables
                    </Typography>
                )}
                {watch(`network_devices[${deviceIndex}].config_variables`)?.map(
                    (item, index) => (
                        <React.Fragment key={index}>
                            <Box style={{ display: "inline-flex" }}>
                                {item?.name?.length > 17 ? (
                                    <Alerttip title={item?.name?.toUpperCase()}>
                                        <TextField
                                            variant="outlined"
                                            className={classes.customTextField}
                                            label={
                                                item?.name
                                                    ?.toUpperCase()
                                                    .slice(0, 16) + "..."
                                            }
                                            {...register(
                                                `network_devices[${deviceIndex}].config_variables[${index}].value`
                                            )}
                                            defaultValue={
                                                getValues(
                                                    `network_devices[${deviceIndex}].config_variables[${index}].value`
                                                ) ||
                                                info?.virtual_serial_devices[
                                                    deviceIndex
                                                ]?.config_variables[index]
                                                    .value ||
                                                ""
                                            }
                                        />
                                    </Alerttip>
                                ) : (
                                    <Alerttip title={item?.name?.toUpperCase()}>
                                        <TextField
                                            variant="outlined"
                                            label={item?.name?.toUpperCase()}
                                            className={classes.customTextField}
                                            {...register(
                                                `network_devices[${deviceIndex}].config_variables[${index}].value`
                                            )}
                                            defaultValue={
                                                getValues(
                                                    `network_devices[${deviceIndex}].config_variables[${index}].value`
                                                ) ||
                                                info?.virtual_serial_devices[
                                                    deviceIndex
                                                ]?.config_variables[index]
                                                    .value ||
                                                ""
                                            }
                                        />
                                    </Alerttip>
                                )}
                            </Box>
                        </React.Fragment>
                    )
                )}
            </Box>
        </>
    );
};

export default VirtualNetworkVariables;
