import {
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";

const ReviewVMs = (props) => {
    const complete = props.complete
    const classes = props.classes
    return (
        <>
            {complete?.post_build_scan?.vms && (
                <Card
                    className={classes.card}
                    class="card"
                >
                    <Typography
                        component="h5"
                        variant="h5"
                    >
                        VM's
                    </Typography>

                    <Typography
                        style={{
                            marginBottom: "5px",
                            width: "100%",
                        }}
                        component="div"
                    >
                        <div
                            style={{
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display:
                                        "inline-block",
                                }}
                                class="p-b-15"
                            >
                                <div
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <TableContainer
                                        style={{
                                            backgroundColor:
                                                "#EAEAEA",
                                            borderRadius:
                                                "0",
                                            marginTop:
                                                "30px",
                                            paddingBottom:
                                                "20px",
                                        }}
                                        component={
                                            Paper
                                        }
                                    >
                                        <Table
                                            style={{
                                                padding:
                                                    "15px 30px",
                                            }}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            fontWeight:
                                                                "bold",
                                                            fontSize:
                                                                ".9rem",
                                                            padding:
                                                                "15px 30px 5px",
                                                        }}
                                                    >
                                                        Name
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontWeight:
                                                                "bold",
                                                            fontSize:
                                                                ".9rem",
                                                            padding:
                                                                "15px 30px 5px",
                                                        }}
                                                    >
                                                        Memory
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontWeight:
                                                                "bold",
                                                            fontSize:
                                                                ".9rem",
                                                            padding:
                                                                "15px 15px 5px",
                                                        }}
                                                    >
                                                        CPU
                                                        Cores
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontWeight:
                                                                "bold",
                                                            fontSize:
                                                                ".9rem",
                                                            padding:
                                                                "15px 15px 5px",
                                                        }}
                                                    >
                                                        Disks
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontWeight:
                                                                "bold",
                                                            fontSize:
                                                                ".9rem",
                                                            padding:
                                                                "15px 15px 5px",
                                                        }}
                                                    >
                                                        Networks
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                style={{
                                                    textDecoration:
                                                        "none",
                                                    padding:
                                                        "0 20px",
                                                }}
                                            >
                                                {complete?.post_build_scan?.vms?.map(
                                                    (
                                                        vm,
                                                        index
                                                    ) => (
                                                        <TableRow
                                                            key={
                                                                index
                                                            }
                                                        >
                                                            <TableCell
                                                                style={{
                                                                    fontSize:
                                                                        ".8rem",
                                                                    padding:
                                                                        "15px 30px 5px",
                                                                }}
                                                            >
                                                                {
                                                                    vm.name
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    fontSize:
                                                                        ".8rem",
                                                                    padding:
                                                                        "15px 30px 5px",
                                                                }}
                                                            >
                                                                {
                                                                    vm.memory
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize:
                                                                        ".8rem",
                                                                    paddingTop:
                                                                        "5px",
                                                                    paddingBottom:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {
                                                                    vm.cpu_cores
                                                                }
                                                            </TableCell>

                                                            <TableCell
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize:
                                                                        ".8rem",
                                                                    paddingTop:
                                                                        "5px",
                                                                    paddingBottom:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {vm?.disks?.map(
                                                                    (
                                                                        disk,
                                                                        index
                                                                    ) => (
                                                                        <span>
                                                                            {
                                                                                disk
                                                                            }

                                                                            ,
                                                                        </span>
                                                                    )
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize:
                                                                        ".8rem",
                                                                    paddingTop:
                                                                        "5px",
                                                                    paddingBottom:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {vm?.networks?.map(
                                                                    (
                                                                        network,
                                                                        index
                                                                    ) => (
                                                                        <span>
                                                                            {
                                                                                network
                                                                            }
                                                                        </span>
                                                                    )
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Card>
            )}
        </>

    );
}

export default ReviewVMs