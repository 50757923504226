import { Button, TextField } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { useFieldArray } from "react-hook-form";

const NestedHdds = ({ nestedIndex, info, register, controls, getValues }) => {
  const { fields, append } = useFieldArray({
    control: controls,
    name: `vms.${nestedIndex}.hdds`,
  });
  // console.log(getValues(`vms`))

  const handleAddFields = (e) => {
    e.preventDefault();
    append(
      { hdds: "" }
    );
  };

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "25px" }}>
      <Button
        style={{ backgroundColor: "lightgrey" }}
        onClick={handleAddFields}
      >
        + HDDs
      </Button>
      {fields.map((item, k) => (
        <Alerttip
          title="Enter the Hard Drive Size for the Virtual Machine Template in Gigabytes (example: 120)"
        >
          <TextField
            variant="outlined"
            style={{ margin: "20px" }}
            label="HDDS"
            {...register(`vms.${nestedIndex}.hdds.${k}`)}
            defaultValue={info?.vms?.[nestedIndex]?.hdds?.[k] || ""}
            autoComplete="false"
            type="number" // this is what prevents object, Object from rendering here like it will on nestedNetworks
            InputProps={{
              endAdornment: <InputAdornment position="end">GB</InputAdornment>,
            }}
          />
        </Alerttip>
      ))}
    </div>
  );
};

export default NestedHdds;
