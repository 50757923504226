let isosURL = "";
let ovasURL = "";
let playbooksURL = "";
let serialDevicesURL = "";
let templatesURL = "";
let biosURL = "";
let serialPortsURL = "";
let networkBuildsURL = "";
let licenseURL = "";
let collectionURL = "";
let offlinePackagesURL = "";

let uploadISOStreamURL = "";
let uploadLicenseURL = "";
let uploadOVAStreamURL = "";
let getDevicesURL = "";
let uploadTemplateURL = "";
let uploadCollectionURL = "";
let uploadPlaybookURL = "";
let uploadOfflinePackageURL = "";

if (process.env.NODE_ENV === "development") {
  isosURL = "http://localhost:80/api/isos/isos";
  ovasURL = "http://localhost:80/api/ovas/ovas";
  playbooksURL = "http://localhost:80/api/playbooks/playbooks";
  serialDevicesURL = "http://localhost:80/api/serial-devices/serial-devices";
  templatesURL = "http://localhost:80/api/serial-devices/templates";
  biosURL = "http://localhost:80/api/bios/bios-devices";
  serialPortsURL = "http://localhost:80/api/serial-ports/serial_ports";
  networkBuildsURL = "http://localhost:80/api/network-builds";
  licenseURL = "http://localhost:80/api/licenses/";
  collectionURL = "http://localhost:80/api/collections/collections";
  offlinePackagesURL =
    "http://localhost:80/api/offline-packages/offline-packages";

  uploadISOStreamURL = "http://localhost:80/api/isos/upload-iso-stream";
  uploadLicenseURL = "http://localhost:80/api/licenses/create-license";
  uploadOVAStreamURL = "http://localhost:80/api/ovas/upload-ova-stream";
  uploadTemplateURL = "http://localhost:80/api/serial-devices/upload-template";
  uploadCollectionURL = "http://localhost:80/api/collections/upload-collection";
  uploadPlaybookURL = "http://localhost:80/api/playbooks/upload-playbook";
  uploadOfflinePackageURL =
    "http://localhost:80/api/offline-packages/upload-offline-package";
} else if (process.env.NODE_ENV === "production") {
  isosURL = "/api/isos/isos";
  ovasURL = "/api/ovas/ovas";
  playbooksURL = "/api/playbooks/playbooks";
  serialDevicesURL = "/api/serial-devices/serial-devices";
  templatesURL = "/api/serial-devices/templates";
  biosURL = "/api/bios/bios-devices";
  serialPortsURL = "/api/serial-ports/serial_ports";
  networkBuildsURL = "/api/network-builds";
  licenseURL = "/api/licenses/";
  collectionURL = "/api/collections/collections";
  offlinePackagesURL = "/api/offline-packages/offline-packages";

  uploadISOStreamURL = "/api/isos/upload-iso-stream";
  uploadLicenseURL = "/api/licenses/create-license";
  uploadOVAStreamURL = "/api/ovas/upload-ova-stream";
  uploadTemplateURL = "/api/serial-devices/upload-template";
  uploadCollectionURL = "/api/collections/upload-collection";
  uploadPlaybookURL = "/api/playbooks/upload-playbook";
  uploadOfflinePackageURL = "/api/offline-packages/upload-offline-package";
}

export {
  isosURL,
  ovasURL,
  playbooksURL,
  serialDevicesURL,
  templatesURL,
  biosURL,
  serialPortsURL,
  networkBuildsURL,
  licenseURL,
  collectionURL,
  offlinePackagesURL,
  uploadISOStreamURL,
  uploadLicenseURL,
  uploadOVAStreamURL,
  uploadTemplateURL,
  uploadCollectionURL,
  uploadPlaybookURL,
  uploadOfflinePackageURL,
};
