import {
	Button,
	Container,
	Fab,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Context } from "../../context/Context";
import ProgressBar from "../create/ProgressBar";
import useStyles from "./styles";

// Dialog component
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowUpwordIcon from "@material-ui/icons/ArrowUpward";
import BuildIcon from "@material-ui/icons/Build";
import CloseIcon from "@material-ui/icons/Close";
import NetworkCheckIcon from "@material-ui/icons/NetworkCheck";
import ReplayIcon from "@material-ui/icons/Replay";
import RouterIcon from "@material-ui/icons/Router";
import StorageIcon from "@material-ui/icons/Storage";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const Alerttip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));

const ClearDatabase = () => {
	const [clearDatabase, setClearDatabase] = useState(null);
	const [wait, setWait] = useState(false);
	const [status, setStatus] = useState();

	const classes = useStyles();
	const token = useContext(Context);

	let dropAllBuilds = "";
	let dropDataCenterBuilds = "";
	let dropNetworkBuilds = "";
	let dropVirtualNetworkBuilds = "";
	if (process.env.NODE_ENV === "development") {
		dropAllBuilds =
			"http://localhost:80/api/database/drop_database_collection";
		dropDataCenterBuilds =
			"http://localhost:80/api/database/drop_database_collection?collection=builds";
		dropNetworkBuilds =
			"http://localhost:80/api/database/drop_database_collection?collection=networkBuilds";
		dropVirtualNetworkBuilds =
			"http://localhost:80/api/database/drop_database_collection?collection=virtualNetworkBuilds";
	} else if (process.env.NODE_ENV === "production") {
		dropAllBuilds = "/api/database/drop_database_collection";
		dropDataCenterBuilds =
			"/api/database/drop_database_collection?collection=builds";
		dropNetworkBuilds =
			"/api/database/drop_database_collection?collection=networkBuilds";
		dropVirtualNetworkBuilds =
			"/api/database/drop_database_collection?collection=virtualNetworkBuilds";
	}
	const clearAllBuilds = async (e) => {
		await fetch(dropAllBuilds, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((res) => {
				alert("All Builds Removed", res);
				handleClose();
			})
			.catch((error) => {
				alert("error", error);
			});
	};
	const clearDataCenterBuilds = async (e) => {
		await fetch(dropDataCenterBuilds, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((res) => {
				alert("All Data Center Builds Removed", res);
				handleClose1();
			})
			.catch((error) => {
				alert("error", error);
			});
	};
	const clearNetworkBuilds = async (e) => {
		await fetch(dropNetworkBuilds, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((res) => {
				alert("All Network Builds Removed", res);
				handleClose2();
			})
			.catch((error) => {
				alert("error", error);
			});
	};
	const clearVirtualNetworkBuilds = async (e) => {
		await fetch(dropVirtualNetworkBuilds, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((res) => {
				alert("All Virtual Network Builds Removed", res);
				handleClose3();
			})
			.catch((error) => {
				alert("error", error);
			});
	};
	const [open, setOpen] = React.useState(false);
	const [open1, setOpen1] = React.useState(false);
	const [open2, setOpen2] = React.useState(false);
	const [open3, setOpen3] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleClickOpen1 = () => {
		setOpen1(true);
	};

	const handleClose1 = () => {
		setOpen1(false);
	};

	const handleClickOpen2 = () => {
		setOpen2(true);
	};

	const handleClose2 = () => {
		setOpen2(false);
	};
	const handleClickOpen3 = () => {
		setOpen3(true);
	};

	const handleClose3 = () => {
		setOpen3(false);
	};

	return (
		<Paper
			className={classes.paper}
			elevation={6}
		>
			<Typography
				component="h1"
				variant="h5"
				style={{ marginBottom: "15px" }}
			>
				Data Management
			</Typography>
			{/* Clear metric buttons */}
			<div style={{ display: "flex" }}>
				{/* Clear All */}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Alerttip title="Delete All Builds">
						<Fab
							variant="extended"
							style={{
								marginRight: "15px",
								marginTop: "0",
								borderRadius: "30px",
								marginBottom: "30px",
								padding: "0",
								width: "auto",
							}}
							className={classes.button}
							onClick={handleClickOpen}
						>
							<HighlightOffIcon />
						</Fab>
					</Alerttip>
					<Dialog
						open={open}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose}
						aria-describedby="alert-dialog-slide-description"
						style={{ textAlign: "center" }}
					>
						<DialogTitle style={{ paddingTop: "25px" }}>
							{"Delete ALL Build Data?"}
						</DialogTitle>
						<DialogContent style={{ marginTop: "-5px" }}>
							<DialogContentText id="alert-dialog-slide-description">
								By doing this, you are DESTROYING
								<br />
								ALL DataCenter, Network, and Virtual Network
								Build data.
							</DialogContentText>
						</DialogContent>
						<DialogActions
							style={{
								justifyContent: "space-around",
								marginTop: "-5px",
								marginBottom: "15px",
							}}
						>
							<Button
								onClick={handleClose}
								style={{
									float: "left",
									width: "100px",
									background: "black",
									fontWeight: "bold",
									color: "white",
								}}
							>
								Close
							</Button>
							<Button
								onClick={clearAllBuilds}
								style={{
									float: "left",
									width: "100px",
									background: "#ea2331",
									fontWeight: "bold",
									color: "black",
								}}
							>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				</div>

				{/* Clear Datacenter */}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Alerttip title="Delete Data Center Builds">
						<Fab
							variant="extended"
							style={{
								marginRight: "15px",
								marginTop: "0",
								borderRadius: "30px",
								marginBottom: "30px",
								padding: "0",
								width: "auto",
							}}
							className={classes.button}
							onClick={handleClickOpen1}
						>
							<StorageIcon />
						</Fab>
					</Alerttip>
					<Dialog
						open={open1}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose1}
						aria-describedby="alert-dialog-slide-description"
						style={{ textAlign: "center" }}
					>
						<DialogTitle style={{ paddingTop: "25px" }}>
							{"Delete ALL Build Data?"}
						</DialogTitle>
						<DialogContent style={{ marginTop: "-5px" }}>
							<DialogContentText id="alert-dialog-slide-description">
								By doing this, you are DESTROYING
								<br />
								ALL current DataCenter Build data.
							</DialogContentText>
						</DialogContent>
						<DialogActions
							style={{
								justifyContent: "space-around",
								marginTop: "-5px",
								marginBottom: "15px",
							}}
						>
							<Button
								onClick={handleClose1}
								style={{
									float: "left",
									width: "100px",
									background: "black",
									fontWeight: "bold",
									color: "white",
								}}
							>
								Close
							</Button>
							<Button
								onClick={clearDataCenterBuilds}
								style={{
									float: "left",
									width: "100px",
									background: "#ea2331",
									fontWeight: "bold",
									color: "black",
								}}
							>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				</div>

				{/* Clear Network */}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Alerttip title="Delete Network Builds">
						<Fab
							variant="extended"
							style={{
								marginRight: "15px",
								marginTop: "0",
								borderRadius: "30px",
								marginBottom: "30px",
								padding: "0",
								width: "auto",
							}}
							className={classes.button}
							onClick={handleClickOpen2}
						>
							<RouterIcon />
						</Fab>
					</Alerttip>
					<Dialog
						open={open2}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose2}
						aria-describedby="alert-dialog-slide-description"
						style={{ textAlign: "center" }}
					>
						<DialogTitle style={{ paddingTop: "25px" }}>
							{"Delete ALL Network Data?"}
						</DialogTitle>
						<DialogContent style={{ marginTop: "-5px" }}>
							<DialogContentText id="alert-dialog-slide-description">
								By doing this, you are DESTROYING
								<br />
								ALL current Network Build data.
							</DialogContentText>
						</DialogContent>
						<DialogActions
							style={{
								justifyContent: "space-around",
								marginTop: "-5px",
								marginBottom: "15px",
							}}
						>
							<Button
								onClick={handleClose2}
								style={{
									float: "left",
									width: "100px",
									background: "black",
									fontWeight: "bold",
									color: "white",
								}}
							>
								Close
							</Button>
							<Button
								onClick={clearNetworkBuilds}
								style={{
									float: "left",
									width: "100px",
									background: "#ea2331",
									fontWeight: "bold",
									color: "black",
								}}
							>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				</div>

				{/* Clear Virtual Network */}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Alerttip title="Delete Virtual Network Builds">
						<Fab
							variant="extended"
							style={{
								marginTop: "0",
								borderRadius: "30px",
								marginBottom: "30px",
								padding: "0",
								width: "auto",
							}}
							className={classes.button}
							onClick={handleClickOpen3}
						>
							<NetworkCheckIcon />
						</Fab>
					</Alerttip>
					<Dialog
						open={open3}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose3}
						aria-describedby="alert-dialog-slide-description"
						style={{ textAlign: "center" }}
					>
						<DialogTitle style={{ paddingTop: "25px" }}>
							{"Delete ALL Network Data?"}
						</DialogTitle>
						<DialogContent style={{ marginTop: "-5px" }}>
							<DialogContentText id="alert-dialog-slide-description">
								By doing this, you are DESTROYING
								<br />
								ALL current Virtual Network Build data.
							</DialogContentText>
						</DialogContent>
						<DialogActions
							style={{
								justifyContent: "space-around",
								marginTop: "-5px",
								marginBottom: "15px",
							}}
						>
							<Button
								onClick={handleClose3}
								style={{
									float: "left",
									width: "100px",
									background: "black",
									fontWeight: "bold",
									color: "white",
								}}
							>
								Close
							</Button>
							<Button
								onClick={clearVirtualNetworkBuilds}
								style={{
									float: "left",
									width: "100px",
									background: "#ea2331",
									fontWeight: "bold",
									color: "black",
								}}
							>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
			{wait ? (
				<Alert style={{ marginBottom: "10px" }} severity="warning">
					Please wait
				</Alert>
			) : status === 200 ? (
				<Alert style={{ marginBottom: "10px" }} severity="success">
					Uploaded Successfully
				</Alert>
			) : (
				""
			)}
			{wait ? (
				<ProgressBar
					style={{
						display: "flex",
						margin: "20px",
					}}
				/>
			) : status === 200 ? (
				""
			) : (
				""
			)}
		</Paper>
	);
};

export default ClearDatabase;
