import { useContext } from "react";
import { Redirect, useLocation } from 'react-router-dom'
import { Context } from "../../context/Context";


const PrivateRoute = ({ children }) => {
    const token = useContext(Context);
    let location = useLocation();
    if (token.token === 'null' || token.token === null) {
        // Redirect them to the /signin page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Redirect to="/auth" state={{ from: location }} />;
    }
    return children
}
export default PrivateRoute