import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Paper,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import TablePagination from "@material-ui/core/TablePagination";
import { Context } from "../../context/Context";

const PrevBuild = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [orderDirection, setOrderDirection] = useState("desc");
	const [valueToOrderBy, setValueToOrderBy] = useState("date");
	const [builds, setBuilds] = useState([]);
	const location = useLocation();
	const token = useContext(Context);

	let baseURL = "";
	if (process.env.NODE_ENV === "development") {
		baseURL = "http://localhost:80/api/build/builds";
	} else if (process.env.NODE_ENV === "production") {
		baseURL = "/api/build/builds";
	}

	const getAllBuilds = async () => {
		await fetch(baseURL, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				setBuilds(resdata.builds);
			})
			.catch((error) => {
				alert("Error Retrieving Builds", error);
			});
	};

	const Refresh = () => {
		getAllBuilds();
	};
	useEffect(() => {
		getAllBuilds();
	}, []);

	let allBuilds = [];

	builds?.map((b) => (b.status != "in_progress" ? allBuilds.push(b) : null));

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e) => {
		setRowsPerPage(parseInt(e.target.value), 10);
		setPage(0);
	};

	const handleRequestSort = (e, property) => {
		const isAscending =
			valueToOrderBy === property && orderDirection === "asc";
		setValueToOrderBy(property);
		setOrderDirection(isAscending ? "desc" : "asc");
	};

	const createSortHandler = (property) => (e) => {
		handleRequestSort(e, property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function sortedRowInformation(array, comparator) {
		const stabilizedThis = array?.map((el, index) => [el, index]);
		stabilizedThis?.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis?.map((el) => el[0]);
	}

	return (
		<div style={{ backgroundColor: "white" }}>
			<div
				style={{
					display: "flex",
					margin: "0 auto 0",
					width: "85%",
					paddingTop: "15px",
				}}
			>
				<RefreshIcon
					onClick={Refresh}
					style={{ alignSelf: "center", cursor: "pointer" }}
				/>
				<p>Refresh Table</p>
			</div>
			<TableContainer
				component={Paper}
				style={{ margin: "0 auto 10px", width: "85%" }}
			>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Kit ID</TableCell>
							<TableCell key="date">
								<TableSortLabel
									active={valueToOrderBy === "date"}
									direction={
										valueToOrderBy === "date"
											? orderDirection
											: "asc"
									}
									onClick={createSortHandler("date")}
								>
									Build Date/Time
								</TableSortLabel>
							</TableCell>
							<TableCell>Host Count</TableCell>
							<TableCell>Build Duration(m)</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
					{sortedRowInformation(
						allBuilds,
						getComparator(orderDirection, valueToOrderBy)
					)
						?.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)
						?.map((row, i) => (
							<TableBody
								component={Link}
								style={{ textDecoration: "none" }}
								to={{
									pathname: `/newbuild/${row.build_id}`,
									state: { files: row },
								}}
								key={row.build_id}
							>
								<TableRow key={row.id}>
									<TableCell
										style={{ cursor: "pointer" }}
										scope="row"
									>
										{row.build_id
											?.slice(0, 12)
											.toUpperCase()}
									</TableCell>
									<TableCell
										style={{ cursor: "pointer" }}
										scope="row"
									>
										{row.kit_id}
									</TableCell>
									<TableCell style={{ cursor: "pointer" }}>
										{row.date?.slice(0, 10)}
										<br />
										{row.date?.slice(11, 19)}
									</TableCell>

									<TableCell style={{ cursor: "pointer" }}>
										{row.config.hosts.length}
									</TableCell>

									<TableCell style={{ cursor: "pointer" }}>
										{row.build_duration}
									</TableCell>
									<TableCell style={{ cursor: "pointer" }}>
										{row.status
											.toUpperCase()
											.replace(/[_-]/g, " ")}
									</TableCell>
								</TableRow>
							</TableBody>
						))}
				</Table>
			</TableContainer>
			<TablePagination
				style={{
					margin: "0 auto",
					width: "85%",
					paddingBottom: "60px",
				}}
				rowsPerPageOptions={[5, 10, 20]}
				component="div"
				count={allBuilds?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={<span>Rows Per Page:</span>}
			/>
		</div>
	);
};

export default PrevBuild;

///sorting by newest doest account for the data that gets passed to new build
