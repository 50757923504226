import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";

export const NetworkUtilReview = (props) => {
    const classes = props.classes;
    const networkUtilBuild = props.complete;

    return (
        <Grid container>
            {networkUtilBuild?.serial_devices?.map((serial_device, i) => (
                <Grid key={serial_device + i} item md={12} sm={12} xs={12}>
                    <Box className={classes.accordionBox}>
                        <Card className={classes.card_alt}>
                            <Typography
                                className={classes.deviceTitle}
                                variant="h4"
                            >
                                {`Device ${i + 1} - ${
                                    networkUtilBuild?.operation
                                }`}
                            </Typography>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h6">
                                    Port: {serial_device.port}
                                </Typography>
                                {serial_device.baudrate && (
                                    <Typography variant="body1">
                                        Baudrate: {serial_device.baudrate}
                                    </Typography>
                                )}
                                <Typography variant="body1">
                                    Username: {serial_device.username}
                                </Typography>
                                <Typography variant="body1">
                                    Password: ********
                                </Typography>
                                <Typography variant="body1">
                                    Enable Password: ********
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default NetworkUtilReview;
