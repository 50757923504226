import { Button, Grid, Paper, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Alert from "@material-ui/lab/Alert";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";
import ProgressBar from "./ProgressBar";
import useStyles from "./styles";
import "./timer.css";

const Build = () => {
	const [buildData, setBuildData] = useState("");
	const [res, setRes] = useState();
	const [isRunning, setIsRunning] = useState(false);
	const classes = useStyles();
	const location = useLocation();
	const token = useContext(Context);
	const history = useHistory();
	const [activeBuilds, setActiveBuilds] = useState([]);
	const [currentBuild, setCurrentBuild] = useState({});

	const info = location.state;
	const prechecks = info?.resdata?.Result?.pre_checks;
	const network = info?.resdata?.Result?.network;
	const datacenter = info?.resdata?.Result?.datacenter;
	const postInstall = info?.resdata?.Result?.post_install;
	const biosData = info?.resdata?.Result?.bios;
	let baseURL = "";
	let stopURL = "";
	let activeURL = "";
	if (process.env.NODE_ENV === "development") {
		baseURL = "http://localhost:80/api/build";
		stopURL = "http://localhost:80/api/control/cancel-build";
		activeURL = "http://localhost:80/api/control/active";
	} else if (process.env.NODE_ENV === "production") {
		baseURL = "/api/build";
		stopURL = "/api/control/cancel-build";
		activeURL = "/api/control/active";
	}

	const submitForm = async () => {
		await fetch(baseURL + "/build", {
			method: "POST",
			body: JSON.stringify(info.data) || JSON.stringify(info.files),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setBuildData(data);
			})
			.catch((error) => {
				alert("Error Deploying. Please Try Again.", error);
			});
	};

	const buildID = buildData.build_id || info.buildID;
	const [hide, setHide] = useState(true);

	const getCurrentBuild = () => {
		fetch(baseURL + "/" + buildID, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((bld) => {
				setCurrentBuild(bld);
			})
			.catch((error) => {
				alert("Error Retrieving Builds", error);
			});
	};

	const restartContainers = () => {
		fetch(stopURL + "/" + buildID + "?command=restart&type=build", {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		window.location.reload(false);
		alert("Build Restarting");
	};

	const getStatus = async () => {
		await fetch(baseURL + "/" + buildID, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				setRes(resdata);
			})
			.catch((error) => {
				alert("Error With Providing Status, Please Try Again", error);
			});
	};

	const getActiveBuilds = () => {
		fetch(activeURL, {
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((active) => {
				setActiveBuilds(active);
				if (buildID != null) {
					setHide(false);
				}
			})
			.catch((error) => {
				if (buildID != null) {
					setHide(false);
					document.getElementById("getStatus").click();
				}
			});
	};

	useEffect(() => {
		getActiveBuilds();
		if (isRunning) {
			const id = window.setInterval(() => {
				getStatus();
			}, 1000);
			return () => window.clearInterval(id);
		}
		return undefined;
	}, [isRunning]);

	const cancelContainers = () => {
		fetch(stopURL + "/" + buildID + "?type=build", {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		alert("Build Canceled");
		history.push("/");
	};
	const onClick = (e) => {
		submitForm();
		alert("Please power on devices");
		setHide(false);
		setTimeout(function () {
			document.getElementById("getStatus").click();
		}, 2000);
	};

	var diff = new Date() - new Date(currentBuild.date);
	var hours = Math.floor(diff / 3.6e6)
		.toString()
		.padStart(2, "0");
	var minutes = Math.floor((diff % 3.6e6) / 6e4)
		.toString()
		.padStart(2, "0");
	var seconds = Math.floor((diff % 6e4) / 1000)
		.toString()
		.padStart(2, "0");
	var duration = hours + ":" + minutes + ":" + seconds;

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: "1px solid #dadde9",
		},
	}));

	const [time, setTime] = useState();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				overflowX: "hidden",
			}}
		>
			<style>{`
				.MuiLinearProgress-colorPrimary {
					background-color: lightgrey;
					width: 50%; display: block!important; margin-left: auto!important; margin-right: auto!important;
				}
				.MuiLinearProgress-barColorPrimary {
					background-color: #EA2331;
				}
				svg {
					max-height: 20px;
					display: block;
					vertical-align: middle;
					margin-bottom: 8px!important;
				}
				p {
					margin-bottom: 10px!important;
				}
      		`}</style>
			{activeBuilds?.Result !== null ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						width: "100vw",
					}}
				>
					<Alerttip
						title={`Another build is active on this appliance ${activeBuilds?.Result}.`}
					>
						<Alert
							style={{
								marginBottom: "10px",
								width: "30%",
							}}
							severity="warning"
						>
							Active Build Detected
						</Alert>
					</Alerttip>
				</div>
			) : (
				""
			)}
			<Grid
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "60vw",

					backgroundColor: "#EAEAEA",
					padding: "20px 0",
				}}
			>
				<div
					style={{
						backgroundColor: "#EAEAEA",
						width: "60vw",
						display: "flex",
						flexDirection: "column",

						justifyContent: "center",
					}}
				>
					<p
						style={{
							fontSize: "1rem",
							alignSelf: "center",
							marginTop: "20px",
						}}
					>
						Build ID: {buildID || info.thisID}
					</p>
					<div className={classes.btnContainer}>
						{hide === true ? (
							<Button
								onClick={onClick}
								disable={
									res?.status ? "in_progress" : undefined
								}
								style={{
									fontWeight: "bolder",
									letterSpacing: "3px",
									display:
										res?.status === "success" ||
											res?.status === "in_progress"
											? "none"
											: "flex",
								}}
								className={classes.button}
							>
								Deploy
							</Button>
						) : (
							""
						)}

						{hide === false ? (
							<Button
								style={{
									fontWeight: "bolder",
									letterSpacing: "3px",
									display: "none",
								}}
								id="getStatus"
								onClick={() => {
									setIsRunning(true);
									getCurrentBuild();
								}}
								className={classes.button}
							>
								Get Status
							</Button>
						) : (
							""
						)}
						{/* {hide === false ? (
						<Button
							onClick={restartContainers}
							style={{
							fontWeight: "bolder",
							letterSpacing: "3px",
							}}
							className={classes.button}
						>
							Restart
						</Button>
						) : (
							""
						)} */}

						<Button
							style={{
								display:
									res?.status === "success" ||
										res?.status === undefined
										? "none"
										: "flex",

								fontWeight: "bolder",
								letterSpacing: "3px",
							}}
							onClick={(e) => {
								if (
									window.confirm(
										"Are you sure you wish to cancel this build?"
									)
								)
									cancelContainers(e);
							}}
							className={classes.button}
						>
							Cancel
						</Button>
					</div>

					<Link
						style={{ textDecoration: "none", alignSelf: "center" }}
						to={{
							pathname: `/review/build/${buildID || info.thisID}`,
							state: {
								buildID: buildID,
							},
						}}
					>
						<Button
							style={{
								fontWeight: "bolder",
								letterSpacing: "3px",
								display:
									res?.status === "success" ? "flex" : "none",
							}}
							className={classes.button}
						>
							Review Build
						</Button>
					</Link>
				</div>
				{res?.status === "success" ? (
					""
				) : duration === "NaN:NaN:NaN" ? (
					""
				) : res?.status === "in_progress" ? (
					<div className="time">Build Time: {duration}</div>
				) : (
					""
				)}
				{res?.status === "in_progress" ? (
					<ProgressBar
						style={{
							display:
								res?.status === "success" ||
									res?.status === undefined
									? "none"
									: "flex",
							margin: "20px",
						}}
					/>
				) : (
					""
				)}

				<Paper
					className={classes.paper}
					style={{ width: "50vw" }}
					elevation={6}
				>
					<Typography
						component="h1"
						variant="h5"
						style={{ marginBottom: "15px" }}
					>
						Prechecks
					</Typography>
					<Grid style={{ backgroundColor: "lightgrey" }} sx={6}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								width: "400px",
								padding: "30px 30px 20px",
							}}
							component="fieldset"
						>
							<div>
								{prechecks?.map((precheck) => (
									<p style={{ margin: "0px" }} key={precheck}>
										{precheck
											?.toUpperCase()
											.replace(/[_-]/g, " ")}
									</p>
								)) ||
									res?.workflow.pre_checks.map((precheck) => (
										<p
											style={{ margin: "0px" }}
											key={precheck}
										>
											{precheck
												?.toUpperCase()
												.replace(/[_-]/g, " ")}
										</p>
									))}
							</div>
							<div>
								{res?.status === "in_progress" ||
									res?.status === "success"
									? res?.tasks?.pre_checks?.map((task) => (
										<div
											key={task.task_name}
											aria-label="prechecks"
										>
											<div
												style={{
													color: ` ${task.status}`,
												}}
											>
												{task.status === "green" ? (
													<CheckCircleIcon
														style={{
															fontSize:
																"20px",
															textAlign:
																"center",
														}}
													/>
												) : task.status ===
													"red" ? (
													<ErrorIcon />
												) : (
													<HourglassEmptyIcon />
												)}
											</div>
										</div>
									))
									: ""}
							</div>
						</div>
					</Grid>
				</Paper>
				{network || res?.workflow?.network ? (
					<Paper
						className={classes.paper}
						style={{ width: "50vw" }}
						elevation={6}
					>
						<Typography component="h1" variant="h5">
							Network
						</Typography>
						<Grid style={{ backgroundColor: "lightgrey" }} sx={6}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									width: "400px",
									padding: "30px 30px 20px",
								}}
								component="fieldset"
							>
								<div>
									{network?.map((net) => (
										<p
											style={{ marginBottom: "10px" }}
											key={net}
										>
											{net
												.toUpperCase()
												.replace(/[_-]/g, " ")}
										</p>
									)) ||
										res?.workflow?.network.map((net) => (
											<p
												style={{ marginBottom: "10px" }}
												key={net}
											>
												{net
													.toUpperCase()
													.replace(/[_-]/g, " ")}
											</p>
										))}
								</div>
								<div>
									{res?.status === "in_progress" ||
										res?.status === "success"
										? res?.tasks?.network?.map((task) => (
											<div aria-label="network">
												<div
													style={{
														color: ` ${task.status}`,
													}}
													key={task.task_name}
												>
													{task.status ===
														"green" ? (
														<CheckCircleIcon
															style={{
																fontSize:
																	"20px",
																textAlign:
																	"center",
															}}
														/>
													) : task.status ===
														"red" ? (
														<ErrorIcon />
													) : (
														<HourglassEmptyIcon />
													)}
												</div>
											</div>
										))
										: ""}
								</div>
							</div>
						</Grid>
					</Paper>
				) : (
					""
				)}

				{biosData || res?.workflow?.bios ? (
					<Paper
						className={classes.paper}
						style={{ width: "50vw" }}
						elevation={6}
					>
						<Typography
							style={{ marginBottom: "15px" }}
							component="h1"
							variant="h5"
						>
							BIOS
						</Typography>
						<Grid style={{ backgroundColor: "lightgrey" }} sx={6}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									width: "400px",
									padding: "30px 30px 20px",
								}}
								component="fieldset"
							>
								<div>
									{biosData?.map((b) => (
										<p
											style={{ marginBottom: "10px" }}
											key={b}
										>
											{b
												?.toUpperCase()
												.replace(/[_-]/g, " ")}
										</p>
									)) ||
										res?.workflow?.bios?.map((b) => (
											<p
												style={{ marginBottom: "10px" }}
												key={b}
											>
												{b
													?.toUpperCase()
													.replace(/[_-]/g, " ")}
											</p>
										))}
								</div>
								<div>
									{res?.status === "in_progress" ||
										res?.status === "success"
										? res?.tasks?.bios?.map((task) => (
											<div
												key={task?.task_name}
												aria-label="bios"
											>
												<div
													style={{
														color: ` ${task.status}`,
													}}
													key={task}
												>
													{task.status ===
														"green" ? (
														<CheckCircleIcon
															style={{
																fontSize:
																	"20px",
																textAlign:
																	"center",
															}}
														/>
													) : task.status ===
														"red" ? (
														<ErrorIcon />
													) : (
														<HourglassEmptyIcon />
													)}
												</div>
											</div>
										))
										: ""}
								</div>
							</div>
						</Grid>
					</Paper>
				) : (
					""
				)}
				<Paper
					className={classes.paper}
					style={{ width: "50vw" }}
					elevation={6}
				>
					<Typography
						style={{ marginBottom: "15px" }}
						component="h1"
						variant="h5"
					>
						Data Center
					</Typography>
					<Grid style={{ backgroundColor: "lightgrey" }} sx={6}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								width: "400px",
								padding: "30px 30px 20px",
							}}
							component="fieldset"
						>
							<div>
								{datacenter?.map((dcenter) => (
									<p style={{ margin: "0px" }} key={dcenter}>
										{dcenter
											?.toUpperCase()
											.replace(/[_-]/g, " ")}
									</p>
								)) ||
									res?.workflow.datacenter.map((dcenter) => (
										<p
											style={{
												margin: "0px",
											}}
											key={dcenter}
										>
											{dcenter
												?.toUpperCase()
												.replace(/[_-]/g, " ")}
										</p>
									))}
							</div>
							<div>
								{res?.status === "in_progress" ||
									res?.status === "success"
									? res?.tasks?.datacenter?.map((task) => (
										<div
											key={task?.task_name}
											aria-label="datacenter"
										>
											<div
												style={{
													color: ` ${task.status}`,
												}}
												key={task}
											>
												{task.status === "green" ? (
													<CheckCircleIcon
														style={{
															fontSize:
																"20px",
															textAlign:
																"center",
														}}
													/>
												) : task.status ===
													"red" ? (
													<ErrorIcon />
												) : (
													<HourglassEmptyIcon />
												)}
											</div>
										</div>
									))
									: ""}
							</div>
						</div>
					</Grid>
				</Paper>
				{postInstall || res?.workflow?.post_install ? (
					<Paper
						className={classes.paper}
						style={{ width: "50vw" }}
						elevation={6}
					>
						<Typography
							style={{ marginBottom: "15px" }}
							component="h1"
							variant="h5"
						>
							Post Install
						</Typography>
						<Grid style={{ backgroundColor: "lightgrey" }} sx={6}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									width: "400px",
									padding: "30px 30px 20px",
								}}
								component="fieldset"
							>
								<div>
									{postInstall?.map((post) => (
										<p style={{ margin: "0px" }} key={post}>
											{post
												?.toUpperCase()
												.replace(/[_-]/g, " ")}
										</p>
									)) ||
										res?.workflow.post_install.map(
											(post) => (
												<p
													style={{ margin: "0px" }}
													key={post}
												>
													{post
														?.toUpperCase()
														.replace(/[_-]/g, " ")}
												</p>
											)
										)}
								</div>
								<div>
									{res?.status === "in_progress" ||
										res?.status === "success"
										? res?.tasks?.post_install?.map(
											(task) => (
												<div
													key={task?.task_name}
													aria-label="postInstall"
												>
													<div
														style={{
															color: ` ${task.status}`,
															textAlign:
																"center",
														}}
														key={task}
													>
														{task.status ===
															"green" ? (
															<CheckCircleIcon
																style={{
																	fontSize:
																		"20px",
																	textAlign:
																		"center",
																}}
															/>
														) : task.status ===
															"red" ? (
															<ErrorIcon />
														) : (
															<HourglassEmptyIcon />
														)}
													</div>
												</div>
											)
										)
										: ""}
								</div>
							</div>
						</Grid>
					</Paper>
				) : (
					""
				)}
			</Grid>
		</div>
	);
};
export default Build;
