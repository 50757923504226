import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
        },
    },
    paper: {
        // marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        width: "98%",
        alignContent: "center",
        backgroundColor: theme.palette.primary,
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        "@media (max-width: 450px)": {
            width: "100%",
        },
    },
    paperFull: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        width: "97%",
        alignContent: "center",
        backgroundColor: "#EAEAEA",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        "@media (max-width: 450px)": {
            width: "100%",
        },
    },

    button: {
        backgroundColor: "#EA2331",
        color: "#333333",
        marginLeft: "10px",
        marginTop: "10px",
        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },
    buttonGrid: {
        marginBottom: "15px",
    },

    copybtn: {
        color: "black",
        display: "flex",
        alignSelf: "end",
        margin: "30px 20px 20px 0",
        fontWeight: "bolder",
        letterSpacing: "3px",
        cursor: "pointer",
        "&:hover": {
            color: "#EA2331",
        },
    },

    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px",
        padding: "20px",
        width: "50%",
        "&:hover": {
            boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
        },
    },

    typographyHeader: {
        paddingTop: "15px",
        paddingBottom: "15px",
        textIndent: "15px",
    },

    tableHeader: {
        fontWeight: "bold",
        fontSize: "1.5rem",
        padding: "10px",
    },

    tableCellHeader: {
        fontWeight: "bold",
        fontSize: ".9rem",
    },
    accordionBox: {
        backgroundColor: "#EAEAEA",
        marginBottom: "10px",
    },
    table: {
        backgroundColor: "#EAEAEA",
        margin: "15px",
        width: "97%",
    },
    accordion: {
        backgroundColor: "#EAEAEA",
    },

    // networkUtilReview.jsx styles
    card_alt: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    deviceTitle: {
        backgroundColor: "#EAEAEA",
        padding: theme.spacing(1),
        borderRadius: "2px",
        marginTop: "8px",
        width: "97%",
    },
}));
