import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
root: {
    '& .MuiTextField-root': {
    margin: theme.spacing(2),
    },
    
},
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  
  form: {
    width: '100%', 
    display: 'flex',
    justifyContent:'space-between',
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: '#EA2331',
    margin: theme.spacing(3, 0, 2),
    "&:hover":{
      backgroundColor: '#333333',
      color: '#EA2331',
    }
  },
  
  
}));
