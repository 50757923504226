import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PriorTable from "../stats/prior/PriorTable";
import CounterMetrics from "./CounterMetrics";
import CounterMetricsNetwork from "./CounterMetricsNetwork";
import CounterMetricsVirtualNetwork from "./CounterMetricsVirtualNetwork";

import {
    Box,
    Button,
    Fab,
    Grid,
    Modal,
    Tab,
    Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowUpwordIcon from "@material-ui/icons/ArrowUpward";
import BuildIcon from "@material-ui/icons/Build";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";
import RouterIcon from "@material-ui/icons/Router";
import StorageIcon from "@material-ui/icons/Storage";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Context } from "../../context/Context";
import NetworkBuildTable from "../stats/prior/NetworkBuildTable";
import useStyles from "./style";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Home = () => {
    const [openDatacenter, setOpenDatacenter] = useState(false);
    const [openNetwork, setOpenNetwork] = useState(false);
    const [allBuilds, setAllBuilds] = useState([]);
    const [activeBuilds, setActiveBuilds] = useState("");
    const [value, setValue] = useState("0");
    const token = useContext(Context);
    const classes = useStyles();
    const [virtualNetBuilds, setVirtualNetBuilds] = useState([]);
    const [netBuilds, setNetBuilds] = useState([]);
    const [netUtils, setNetUtils] = useState([]);
    const [taclan, setTaclan] = useState(false);
    const history = useHistory();

    const handleOpenDatacenter = () => setOpenDatacenter(true);
    const handleCloseDatacenter = () => setOpenDatacenter(false);

    const handleOpenNetwork = () => setOpenNetwork(true);
    const handleCloseNetwork = () => setOpenNetwork(false);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    let baseURL = "";
    let netBuildsURL = "";
    let virtualNetBuildsURL = "";
    let netUtilsURL = "";
    let activeURL = "";
    let getKeysURL = "";
    let applianceSettingsURL = "";

    if (process.env.NODE_ENV === "development") {
        baseURL = "http://localhost:80/api/build/builds";
        netBuildsURL = "http://localhost:80/api/network-builds/builds";
        virtualNetBuildsURL =
            "http://localhost:80/api/virtual-network-builds/builds";
        netUtilsURL = "http://localhost:80/api/network-utils/network_utils";
        activeURL = "http://localhost:80/api/control/active";
        getKeysURL = "http://localhost:80/api/keygen/user_keys";
        applianceSettingsURL =
            "http://localhost:80/api/appliance-settings/appliance_settings";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "/api/build/builds";
        netBuildsURL = "api/network-builds/builds";
        virtualNetBuildsURL = "api/virtual-network-builds/builds";
        netUtilsURL = "/api/network-utils/network_utils";
        activeURL = "/api/control/active";
        getKeysURL = "/api/keygen/user_keys";
        applianceSettingsURL = "/api/appliance-settings/appliance_settings";
    }
    const getBuilds = async () => {
        let urls = [baseURL, netBuildsURL, virtualNetBuildsURL, netUtilsURL];
        for (let url of urls) {
            await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            })
                .then((response) => response.json())
                .then((resdata) => {
                    switch (url) {
                        case baseURL:
                            setAllBuilds(resdata);
                            break;
                        case netBuildsURL:
                            setNetBuilds(resdata);
                            break;
                        case virtualNetBuildsURL:
                            setVirtualNetBuilds(resdata);
                            break;
                        case netUtilsURL:
                            setNetUtils(resdata);
                            break;
                        default:
                            console.log(resdata);
                            break;
                    }
                })
                .catch((error) => {
                    alert("Error Retrieving Builds", error);
                });
        }
    };
    const inProgress = [];
    const buildTypes = [allBuilds, netBuilds, virtualNetBuilds, netUtils];
    for (let buildType of buildTypes) {
        let builds = [];
        buildType.networkUtils
            ? (builds = buildType.networkUtils)
            : (builds = buildType.builds);

        builds?.map((build) => {
            if (build.status === "in_progress") {
                inProgress.push(build);
            }
        });
    }

    const getActiveBuilds = () => {
        fetch(activeURL, {
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((active) => {
                setActiveBuilds(active);
            })
            .catch((error) => {
                //pass
            });
    };

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));

    const getApplianceSettings = async () => {
        try {
            const response = await fetch(applianceSettingsURL, {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if (!data.build_interface) {
                setOpen(true);
            }
        } catch (error) {
            console.error("Error fetching appliance settings: ", error.message);
        }
    };

    const getActiveKeys = () => {
        fetch(getKeysURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                let userKeys = resdata;
                let taclanKey = userKeys.find(
                    (key) =>
                        key.feature_name === "taclan" && key.status != "expired"
                );
                if (taclanKey) {
                    setTaclan(true);
                }
            });
        // .catch((error) => {
        // 	alert("Error Retrieving Keys", error);
        // });
    };

    useEffect(() => {
        getApplianceSettings();
        getBuilds();
        getActiveBuilds();
        getActiveKeys();
    }, []);

    let alertsClosed = false;
    const closeAlerts = () => {
        document.getElementById("buildsAlerts").style.cssText =
            "opacity: 0; visibility: none; align-items: center; position: absolute; transition: all .2s; display: flex; align-items: center; cursor: none; pointer-events: none; position: absolute;";
        alertsClosed = true;
        document.getElementById("showAlerts").style.cssText =
            "display: flex; margin-top: 45px; align-items: center; position: absolute; transition: all .2s, margin-bottom: 10px; opacity: 1; transition: all .2s; cursor: pointer;";
    };
    const showAlerts = () => {
        alertsClosed = false;
        document.getElementById("showAlerts").style.cssText =
            "cursor: none; pointer-events: none; opacity: 0; visibility: none; display: flex; margin-top: 45px; align-items: center; position: absolute; transition: all .2s, margin-bottom: 10px; transition: all .2s; cursor: none;";
        document.getElementById("buildsAlerts").style.cssText =
            "opacity: 1; align-items: center; position: absolute; transition: all .2s; display: flex; align-items: center; position: absolute;";
    };

    return (
        <div className={classes.container} id="homeMetrics">
            <style>{`
				#homeMetrics {
				background-image: url(/images/bg.jpg);
				background-size: cover;
				background-position: center;
				background-attachment: fixed;
				}
				[role="presentation"] .MuiBox-root {
				min-width: 450px;
				}
			`}</style>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                style={{ textAlign: "center" }}
            >
                <DialogTitle style={{ paddingTop: "25px" }}>
                    {"Warning: Appliance Setting Missing"}
                </DialogTitle>
                <DialogContent style={{ marginTop: "-5px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        In order to create a build
                        <br />
                        you must define a build interface.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: "space-around",
                        marginTop: "-5px",
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        onClick={() =>
                            history.push({
                                pathname: "/appliancesettings",
                                hash: "#buildInterface",
                            })
                        }
                        style={{
                            float: "left",
                            width: "100px",
                            background: "#ea2331",
                            fontWeight: "bold",
                            color: "black",
                        }}
                    >
                        Settings
                    </Button>
                </DialogActions>
            </Dialog>
            {activeBuilds?.Result !== null || inProgress !== null ? (
                <div
                    id="buildsAlerts"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        transition: "all .2s",
                    }}
                >
                    {inProgress?.map((build) => (
                        <Link
                            key={build.build_id || build.util_id}
                            style={{
                                textDecoration: "none",
                                color: "black",
                                border: "15px solid transparent",
                                marginTop: "30px",
                            }}
                            to={{
                                pathname: `/track-build`,
                                state: {
                                    resdata: { ["Result"]: build.workflow },
                                    build: build,
                                    inProgress: true,
                                },
                            }}
                        >
                            <Alert
                                key={build.build_id || build.util_id}
                                style={{ marginBottom: "10px" }}
                                severity="warning"
                            >
                                {build.util_id
                                    ? `Utility ${build.util_id
                                          ?.slice(0, 12)
                                          .toUpperCase()}  In Progress!`
                                    : `Build ${build.build_id
                                          ?.slice(0, 12)
                                          .toUpperCase()} In Progress!`}
                            </Alert>
                        </Link>
                    ))}
                    {activeBuilds?.Result !== null ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                border: "15px solid transparent",
                                marginTop: "30px",
                            }}
                        >
                            <Alerttip
                                title={`Another build is active on this appliance ${activeBuilds?.Result}.`}
                            >
                                <Alert
                                    style={{
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                    severity="warning"
                                >
                                    Active Build Detected
                                </Alert>
                            </Alerttip>
                        </div>
                    ) : (
                        ""
                    )}
                    {activeBuilds?.Result !== null ? (
                        <CloseIcon
                            onClick={closeAlerts}
                            style={{
                                marginTop: "20px",
                                padding: "5px",
                                marginRight: "10px",
                                color: "#EA2331",
                                backgroundColor: "#000",
                                fontSize: "16px",
                                borderRadius: "30px",
                                cursor: "pointer",
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
            ) : null}
            {activeBuilds?.Result !== null || inProgress !== null ? (
                <Alert
                    severity="warning"
                    id="showAlerts"
                    style={{ display: "none" }}
                    onClick={showAlerts}
                >
                    See Alerts
                </Alert>
            ) : null}

            <Grid
                container
                spacing={2}
                style={{
                    marginTop: "50px",
                    maxWidth: "1300px",
                    marginBottom: "0px",
                }}
            >
                <Grid item md={4}>
                    <div
                        style={{
                            borderRadius: "15px",
                            margin: "30px auto 15px",
                        }}
                    >
                        <Typography
                            component="h4"
                            variant="h4"
                            style={{
                                paddingTop: "45px",
                                color: "white",
                                textAlign: "center",
                                marginBottom: "-65px",
                                textShadow: "rgb(0 0 0 / 75%) 2px 2px 2px",
                            }}
                        >
                            Network Builds
                        </Typography>
                        <CounterMetricsNetwork netBuilds={netBuilds} />
                    </div>
                </Grid>
                <Grid item md={4}>
                    <div
                        style={{
                            borderRadius: "15px",
                            margin: "30px auto 15px",
                        }}
                    >
                        <Typography
                            component="h4"
                            variant="h4"
                            style={{
                                paddingTop: "45px",
                                color: "white",
                                textAlign: "center",
                                marginBottom: "-65px",
                                textShadow: "rgb(0 0 0 / 75%) 2px 2px 2px",
                            }}
                        >
                            Virtual Network Builds
                        </Typography>
                        <CounterMetricsVirtualNetwork
                            virtualNetBuilds={virtualNetBuilds}
                        />
                    </div>
                </Grid>

                <Grid item md={4}>
                    <div
                        style={{
                            borderRadius: "15px",
                            margin: "30px auto 15px",
                        }}
                    >
                        <Typography
                            component="h4"
                            variant="h4"
                            style={{
                                paddingTop: "45px",
                                color: "white",
                                textAlign: "center",
                                marginBottom: "-65px",
                                textShadow: "rgb(0 0 0 / 75%) 2px 2px 2px",
                            }}
                        >
                            Data Center Builds
                        </Typography>
                        <CounterMetrics allBuilds={allBuilds} />
                    </div>
                </Grid>
            </Grid>

            <div className={classes.btnContainer}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Fab
                        variant="extended"
                        style={{
                            marginRight: "15px",
                            marginTop: "0",
                            borderRadius: "30px",
                            minWidth: "300px",
                            marginBottom: "30px",
                        }}
                        className={classes.button}
                        onClick={handleOpenNetwork}
                    >
                        <RouterIcon style={{ marginRight: "10px" }} />
                        Build Network
                    </Fab>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Fab
                        variant="extended"
                        style={{
                            marginTop: "0",
                            marginLeft: "15px",
                            borderRadius: "30px",
                            minWidth: "300px",
                            marginBottom: "30px",
                        }}
                        className={classes.button}
                        onClick={handleOpenDatacenter}
                    >
                        <StorageIcon style={{ marginRight: "10px" }} />
                        Build Datacenter
                    </Fab>
                </div>
            </div>

            <Modal open={openDatacenter} onClose={handleCloseDatacenter}>
                <Box
                    className={classes.modalContainer}
                    sx={style}
                    style={{ textAlign: "center" }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            fontFamily: "Montserrat",
                            textAlign: "center",
                            borderBottom: "2px solid rgb(31, 30, 32)",
                            paddingBottom: "10px",
                            marginBottom: "-20px",
                        }}
                        component="h2"
                    >
                        Select Build Type:
                    </Typography>
                    <br />
                    <Link
                        to="/newbuild"
                        style={{
                            textDecoration: "none",
                            width: "7.5rem",
                            fontSize: "12px",
                        }}
                    >
                        <Fab
                            style={{ fontFamily: "Montserrat" }}
                            className={classes.modalbtn}
                            variant="extended"
                        >
                            <AddCircleIcon style={{ marginRight: "5px" }} />
                            New
                        </Fab>
                    </Link>

                    <Link
                        to={{
                            pathname: "/prev",
                        }}
                        style={{
                            textDecoration: "none",
                            width: "7.5rem",
                            fontSize: "12px",
                        }}
                    >
                        <Fab
                            style={{ fontFamily: "Montserrat" }}
                            className={classes.modalbtn}
                            variant="extended"
                        >
                            <ReplayIcon style={{ marginRight: "5px" }} />
                            Previous
                        </Fab>
                    </Link>
                    <Link
                        to="/upload"
                        style={{
                            textDecoration: "none",
                            width: "7.5rem",
                            fontSize: "12px",
                        }}
                    >
                        <Fab
                            style={{ fontFamily: "Montserrat" }}
                            className={classes.modalbtn}
                            variant="extended"
                        >
                            <ArrowUpwordIcon style={{ marginRight: "5px" }} />
                            Import JSON
                        </Fab>
                    </Link>
                    {taclan ? (
                        <Link
                            to="/cutsheet"
                            style={{
                                textDecoration: "none",
                                width: "7.5rem",
                                fontSize: "12px",
                            }}
                        >
                            <Fab
                                style={{ fontFamily: "Montserrat" }}
                                className={classes.modalbtn}
                                variant="extended"
                            >
                                <ArrowUpwordIcon
                                    style={{ marginRight: "5px" }}
                                />
                                Import Cutsheet
                            </Fab>
                        </Link>
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>

            <Modal open={openNetwork} onClose={handleCloseNetwork}>
                <Box sx={style} style={{ textAlign: "center" }} width="30%">
                    <Grid container spacing={2} style={{}}>
                        <Grid item md={12}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontFamily: "Montserrat",
                                    textAlign: "center",
                                    borderBottom: "2px solid #1f1e20",
                                    marginTop: "15px",
                                    paddingBottom: "10px",
                                }}
                                component="h2"
                            >
                                Select Physical Network Type:
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Link
                                to="/newnetwork"
                                style={{
                                    textDecoration: "none",
                                    width: "auto",
                                    display: "inline",
                                }}
                            >
                                <Fab
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        width: "auto",
                                        margin: "5px",
                                    }}
                                    className={classes.modalbtn}
                                    variant="extended"
                                >
                                    <AddCircleIcon
                                        style={{ marginRight: "5px" }}
                                    />
                                    New
                                </Fab>
                            </Link>
                            <Link
                                to={{
                                    pathname: "/prevnetwork",
                                }}
                                style={{
                                    textDecoration: "none",
                                    width: "auto",
                                    display: "inline",
                                }}
                            >
                                <Fab
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        width: "auto",
                                        margin: "5px",
                                    }}
                                    className={classes.modalbtn}
                                    variant="extended"
                                >
                                    <ReplayIcon
                                        style={{ marginRight: "5px" }}
                                    />
                                    Previous
                                </Fab>
                            </Link>
                            {taclan ? (
                                <Link
                                    to="/cutsheet-network"
                                    style={{
                                        textDecoration: "none",
                                        width: "7.5rem",
                                        display: "inline",
                                    }}
                                >
                                    <Fab
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "12px",
                                            margin: "5px",
                                        }}
                                        className={classes.modalbtn}
                                        variant="extended"
                                    >
                                        <ArrowUpwordIcon
                                            style={{ marginRight: "5px" }}
                                        />
                                        Import Cutsheet
                                    </Fab>
                                </Link>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item md={12}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontFamily: "Montserrat",
                                    textAlign: "center",
                                    borderBottom: "2px solid #1f1e20",
                                    marginTop: "15px",
                                    paddingBottom: "10px",
                                }}
                                component="h2"
                            >
                                Select Virtual Network Type:
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Link
                                to="/virtualnetwork"
                                style={{
                                    textDecoration: "none",
                                    width: "auto",
                                    display: "inline",
                                }}
                            >
                                <Fab
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        width: "auto",
                                        margin: "5px",
                                    }}
                                    className={classes.modalbtn}
                                    variant="extended"
                                >
                                    <AddCircleIcon
                                        style={{ marginRight: "5px" }}
                                    />
                                    New
                                </Fab>
                            </Link>
                            <Link
                                to={{
                                    pathname: "/prevvirtualnetwork",
                                }}
                                style={{
                                    textDecoration: "none",
                                    width: "auto",
                                    display: "inline",
                                }}
                            >
                                <Fab
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        width: "auto",
                                        margin: "5px",
                                    }}
                                    className={classes.modalbtn}
                                    variant="extended"
                                >
                                    <ReplayIcon
                                        style={{ marginRight: "5px" }}
                                    />
                                    Previous
                                </Fab>
                            </Link>
                            <Link
                                to="/upload-virtual-network"
                                style={{
                                    textDecoration: "none",
                                    width: "7.5rem",
                                    fontSize: "12px",
                                }}
                            >
                                <Fab
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        margin: "5px",
                                    }}
                                    className={classes.modalbtn}
                                    variant="extended"
                                >
                                    <ArrowUpwordIcon
                                        style={{ marginRight: "5px" }}
                                    />
                                    Import JSON
                                </Fab>
                            </Link>
                        </Grid>

                        <Grid item md={12}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontFamily: "Montserrat",
                                    textAlign: "center",
                                    borderBottom: "2px solid #1f1e20",
                                    marginTop: "15px",
                                    paddingBottom: "10px",
                                }}
                                component="h2"
                            >
                                Network Utilities:
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Link
                                to="/writeerase"
                                style={{
                                    textDecoration: "none",
                                    width: "auto",
                                    display: "inline",
                                }}
                            >
                                <Fab
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        width: "auto",
                                        margin: "5px",
                                    }}
                                    className={classes.modalbtn}
                                    variant="extended"
                                >
                                    <BuildIcon style={{ marginRight: "5px" }} />
                                    Write/Erase
                                </Fab>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <div className={classes.tableStyle}>
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                        }}
                    >
                        <TabList
                            TabIndicatorProps={{
                                style: { backgroundColor: "#800002" },
                            }}
                            onChange={handleChange}
                            variant="fullWidth"
                        >
                            <Tab
                                className={classes.text}
                                label="Network Builds"
                                value="0"
                                style={{
                                    padding: "20px 15px 15px",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    fontFamily: "'Montserrat', sans-serif",
                                    textTransform: "uppercase",
                                }}
                            />
                            <Tab
                                className={classes.text}
                                style={{
                                    padding: "20px 15px 15px",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    fontFamily: "'Montserrat', sans-serif",
                                    textTransform: "uppercase",
                                }}
                                label="DATACENTER BUILDS"
                                value="1"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="0">
                        <Box padding={2}>
                            <NetworkBuildTable allBuilds={allBuilds} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="1">
                        <Box padding={2}>
                            <PriorTable allBuilds={allBuilds} />
                        </Box>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
};

export default Home;
