import {
    Accordion,
    AccordionSummary,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const VirtualNetworkBuildReview = (props) => {
    const netBuild = props.complete;
    let virtual_serial_devices = netBuild?.config?.virtual_serial_devices;

    let templateURL = "";
    if (process.env.NODE_ENV === "development") {
        templateURL = "http://localhost:3000/uploadtemplate/";
    } else if (process.env.NODE_ENV === "production") {
        templateURL = "/uploadtemplate/";
    }

    return (
        <>
            {virtual_serial_devices?.map((id, index) => (
                <div key={index}>
                    <Typography
                        component="h5"
                        variant="h5"
                        style={{
                            marginBottom: "10px",
                            paddingTop: "5px",
                            borderBottom: "2px solid rgb(234, 234, 234)",
                            paddingBottom: "15px",
                            textIndent: "5px",
                        }}
                    >
                        Virtual Serial Device: {index + 1}
                    </Typography>
                    <div
                        style={{
                            width: "calc(100% - 20px)",
                            margin: "0 auto 10px",
                        }}
                    >
                        <TableContainer
                            style={{
                                backgroundColor: "#EAEAEA",
                                borderRadius: "0",
                                marginTop: "15px",
                                paddingBottom: "20px",
                            }}
                            component={Paper}
                        >
                            <h2
                                style={{
                                    padding: "0 20px 10px",
                                    marginBottom: "-10px",
                                }}
                            >
                                {virtual_serial_devices[`${index}`].name}
                            </h2>

                            <Table
                                style={{
                                    padding: "15px 30px",
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: ".9rem",
                                                padding: "15px 30px 5px",
                                            }}
                                        >
                                            Name
                                        </TableCell>
                                        {virtual_serial_devices[index]
                                            .template_name ? (
                                            <TableCell
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: ".9rem",
                                                    padding: "15px 15px 5px",
                                                }}
                                            >
                                                Template Name
                                            </TableCell>
                                        ) : virtual_serial_devices[index]
                                              .variation ? (
                                            <TableCell
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: ".9rem",
                                                    padding: "15px 15px 5px",
                                                }}
                                            >
                                                Variation
                                            </TableCell>
                                        ) : null}
                                        <TableCell
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: ".9rem",
                                                padding: "15px 15px 5px",
                                            }}
                                        >
                                            OVA Filename
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    style={{
                                        textDecoration: "none",
                                        padding: "0 20px",
                                    }}
                                    key={
                                        virtual_serial_devices[`${index}`].port
                                    }
                                >
                                    <TableRow
                                        key={
                                            virtual_serial_devices[`${index}`]
                                                .port
                                        }
                                    >
                                        <TableCell
                                            style={{
                                                cursor: "pointer",
                                                paddingLeft: "30px",
                                                fontSize: ".8rem",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                            scope="row"
                                        >
                                            {virtual_serial_devices[index]
                                                .name ? (
                                                <p>
                                                    {
                                                        virtual_serial_devices[
                                                            index
                                                        ].name
                                                    }
                                                </p>
                                            ) : virtual_serial_devices[index]
                                                  .device_name ? (
                                                <p>
                                                    {
                                                        virtual_serial_devices[
                                                            index
                                                        ].device_name
                                                    }
                                                </p>
                                            ) : null}
                                        </TableCell>
                                        {virtual_serial_devices[0]
                                            .template_name ||
                                        virtual_serial_devices[0].variation ? (
                                            <TableCell
                                                style={{
                                                    cursor: "pointer",
                                                    fontSize: ".8rem",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                }}
                                            >
                                                {virtual_serial_devices[index]
                                                    .template_name ? (
                                                    <a
                                                        href={
                                                            templateURL +
                                                            virtual_serial_devices[
                                                                index
                                                            ].template_name
                                                        }
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                            color: "inherit",
                                                        }}
                                                    >
                                                        {
                                                            virtual_serial_devices[
                                                                index
                                                            ].template_name
                                                        }
                                                    </a>
                                                ) : virtual_serial_devices[
                                                      index
                                                  ].variation ? (
                                                    <p>
                                                        {
                                                            virtual_serial_devices[
                                                                index
                                                            ].variation
                                                        }
                                                    </p>
                                                ) : null}
                                            </TableCell>
                                        ) : null}
                                        <TableCell
                                            style={{
                                                cursor: "pointer",
                                                fontSize: ".8rem",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            {
                                                virtual_serial_devices[
                                                    `${index}`
                                                ].ova_filename
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "15px",
                            }}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Config Variables</Typography>
                                </AccordionSummary>
                                <TableContainer
                                    style={{
                                        backgroundColor: "#EAEAEA",
                                        borderRadius: "0",
                                        marginTop: "15px",
                                        paddingBottom: "20px",
                                    }}
                                    component={Paper}
                                >
                                    <Table>
                                        <TableRow key="name-header">
                                            <TableCell
                                                variant="head"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Name
                                            </TableCell>
                                            <TableCell
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Value
                                            </TableCell>
                                        </TableRow>
                                        {virtual_serial_devices?.[
                                            `${index}`
                                        ]?.config_variables?.map((cv) => (
                                            <TableRow key={cv.name}>
                                                <TableCell
                                                    variant="head"
                                                    style={{
                                                        paddingTop: "5px",
                                                        paddingBottom: "5px",
                                                    }}
                                                >
                                                    {cv.name}
                                                </TableCell>
                                                <TableCell
                                                    variant="head"
                                                    style={{
                                                        paddingTop: "5px",
                                                        paddingBottom: "5px",
                                                    }}
                                                >
                                                    {cv.value}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </TableContainer>
                            </Accordion>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "15px",
                            }}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Port Mapping</Typography>
                                </AccordionSummary>
                                <TableContainer
                                    style={{
                                        backgroundColor: "#EAEAEA",
                                        borderRadius: "0",
                                        marginTop: "15px",
                                        paddingBottom: "20px",
                                    }}
                                    component={Paper}
                                >
                                    <Table>
                                        <TableRow key="name-header">
                                            <TableCell
                                                variant="head"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Interface
                                            </TableCell>
                                            <TableCell
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Portgroup
                                            </TableCell>
                                        </TableRow>
                                        {virtual_serial_devices?.[
                                            `${index}`
                                        ]?.port_mapping?.map((cv) => (
                                            <TableRow key={cv.interface}>
                                                <TableCell
                                                    variant="head"
                                                    style={{
                                                        paddingTop: "5px",
                                                        paddingBottom: "5px",
                                                    }}
                                                >
                                                    {cv.interface}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        paddingTop: "5px",
                                                        paddingBottom: "5px",
                                                    }}
                                                >
                                                    {cv.port_group}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </TableContainer>
                            </Accordion>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default VirtualNetworkBuildReview;
