import { Box, Checkbox, FormControlLabel, Modal, Typography } from "@material-ui/core";
import { useState } from "react";
import useStyles from "../auth/styles";

const banner = `You are accessing a U.S. Government (USG) Information System (IS) that is provided for USG-authorized use only.
By using this IS (which includes any device attached to this IS), you consent to the following conditions:

Bullet The USG routinely intercepts and monitors communications on this IS for purposes including, but not limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE), and counterintelligence (CI) investigations.

Bullet At any time, the USG may inspect and seize data stored on this IS.
      
Bullet Communications using, or data stored on, this IS are not private, are subject to routine monitoring, interception, and search, and may be disclosed or used for any USG-authorized purpose.
      
Bullet This IS includes security measures (e.g., authentication and access controls) to protect USG interests--not for your personal benefit or privacy.
      
Bullet Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative searching or monitoring of the content of privileged communications, or work product, related to personal representation or services by attorneys, psychotherapists, or clergy, and their assistants. Such communications and work product are private and confidential. See User Agreement for details."

Use the following verbiage for operating systems that have severe limitations on the number of characters that can be displayed in the banner:`

const DodBanner = () => {
	const [openModal, setOpenModal] = useState(true);
    const classes = useStyles();

    const lines = banner.split('\n').map((line, index) => {
        if (line.startsWith("Bullet")) {
            return <div key={index} style={{ margin: '10px 0'}}>&bull; {line.replace('Bullet', '').trim()}</div>
        }
        return <div key={index} style={{ margin: '10px 0'}}>{line}</div>
    })

    
	const handleModal = () => {
		setOpenModal(false)
	}

    return (
        <>
            <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modal}>
                    <Typography style={{ fontSize: '12px' }} gutterBottom>
                        <div>{lines}</div>
                    </Typography>
                    <FormControlLabel 
                        required 
                        control={<Checkbox style={{color: "white"}} onChange={handleModal} />}
                        label="I've read & consent to terms in IS user agreem't."
                    />
			    </Box>
		    </Modal>
        </>
    )
}

export default DodBanner;