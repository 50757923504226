import { useQuery, useMutation, useQueryClient } from "react-query";
import { isosURL } from "./utilties/QueryURLs";
import {
  fetchData,
  uploadDataWPercent,
  deleteData,
} from "./utilties/QueryManager";

export const GetISOs = (token) => {
  return useQuery(
    ["isos", isosURL, token],
    async () => {
      const data = await fetchData(isosURL, token);
      return data?.isos;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useUploadISO = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ url, headers, data, onUploadProgress, token }) => {
      return await uploadDataWPercent(
        url,
        data,
        headers,
        onUploadProgress,
        token
      );
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(["isos", isosURL, token]);
      },
      onError: (error) => {
        console.error(error.message);
        alert(error.message);
      },
    }
  );
};

export const useDeleteISO = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ url, token }) => {
      return await deleteData(url, token);
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(["isos", isosURL, token]);
      },
      onError: (error) => {
        console.error(error.message);
        alert(error.message);
      },
    }
  );
};
