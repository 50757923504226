import {
  Button,
  Card,
  Container,
  Fab,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import NestedPortGroupVlan from "./NestedPortGroupVlan";
import NestedUplinks from "./NestedUplinks";
import useStyles from "./styles";

const AddedVSwitch = ({ info, register, controls, getValues }) => {
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control: controls,
    name: "added_vswitches",
  });

  const handleAddFields = () => {
    append({ name: "", port_groups: "", uplinks: [], vlan: "" });
  };

  const handleDelete = (index) => {
    remove(index)
  }

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Container component="main" maxWidth="xl">
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className={classes.paper}
        elevation={6}
      >
        <Typography variant="h5">Added vSwitches</Typography>
        <Fab
          variant="extended"
          style={{ marginTop: "15px" }}
          onClick={handleAddFields}
        >
          <AddIcon />
          vSwitch
        </Fab>
        <form autoComplete="off" className={classes.form}>
          {fields.map((item, index) => (
            <div key={item.id}>
              <Card name="added_vswitches" className={classes.card} spacing={2}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Alerttip title="Would you like an additional vSwitch? If so, enter the name of the vSwitch here (example: MGMT-vSwitch)">
                    <TextField
                      variant="outlined"
                      style={{ margin: "20px" }}
                      label="vSwitch Name"
                      {...register(`added_vswitches.${index}.name`)}
                      defaultValue={info?.added_vswitches[index]?.name || ""}
                      autoComplete="false"
                    />
                  </Alerttip>

                  <NestedPortGroupVlan
                    nestedIndex={index}
                    info={info}
                    register={register}
                    controls={controls}
                  />
                </div>
                <NestedUplinks
                  nestedIndex={index}
                  info={info}
                  register={register}
                  controls={controls}
                />
                <Alerttip title="Remove vSwitch">
                  <IconButton size="small" style={{ display: "flex", marginLeft: "40px", marginTop: "10px" }}>
                    <DeleteIcon
                      sx={{ color: "#D3D3D3" }}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      Delete
                    </DeleteIcon>
                  </IconButton>
                </Alerttip>
              </Card>
            </div>
          ))}
        </form>
      </Paper>
    </Container>
  );
};

export default AddedVSwitch;
