import { useQuery } from "react-query";
import { biosURL } from "./utilties/QueryURLs";
import { fetchData } from "./utilties/QueryManager";

export const GetBios = (token) => {
    return useQuery(
        ["bios", biosURL, token],
        async () => {
            const data = await fetchData(biosURL, token)
            return data;
        },
        {
            refetchOnWindowFocus: true,
        }
    );
};
