import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  metricsContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "60px 0",
    padding: "20px",
    "@media (max-width: 650px)": {
      flexDirection: "column",
    },
  },

  card: {
    display: "flex",
    justifyContent: "center",
    marginRight: "20px",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -3" +
        "0px",
    },

    "@media (max-width: 650px)": {
      marginBottom: "20px",
    },
  },

  btnContainer: {
    display: "flex",
    "@media (max-width: 1240px)": {
      flexDirection: "row",
    },
  },

  button: {
    backgroundColor: "#333333",
    width: "100%",
    color: "#EA2331",
    fontFamily: "Montserrat",
    padding: "30px",
    fontWeight: "bolder",
    margin: "50px 0",
    letterSpacing: "3px",
    "@media (max-width: 650px)": {
      width: "85%",
      fontSize: "12px",
      margin: "10px 10px 70px 10px",
    },
  },

  modalbtn: {
    width: "9rem",
    margin: "20px",
  },

  tableStyle: {
    width: "100vw",
    marginTop: "40px",
    color: "black",
    backgroundColor: "white",
  },
  modalContainer: {
    width: "25%",
    "@media (max-width: 1240px)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));
