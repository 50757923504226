import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	appBar: {
		width: "100vw",
		backgroundColor: "#020304",
		color: "white",

		display: "block",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "10px 50px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		marginTop: "60px",
		position: "fixed",
		bottom: "0",
	},

	profile: {
		display: "flex",
		justifyContent: "space-around",
		width: "400px",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			marginTop: 20,
			justifyContent: "space-between",
		},
	},

	navbtn: {
		cursor: "pointer",
		fontSize: "2rem",
		color: "#9d9d9d",
		"&:hover": {
			color: "#B73239",
		},
	},
	modalbtn: {
		width: "9rem",
		margin: "20px",
	},
}));
