import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        "@media (max-width: 450px)": {
            width: "100%",
        },
    },

    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: theme.spacing(2),
    },
    form2: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buildbtn: {
        margin: "30px 0",
        padding: "10px",
        width: "10vw",
        backgroundColor: "#EA2331",
        color: "#333333",

        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },
    kitID: {
        alignSelf: "center",
        width: "20%",
        padding: "1%",
        margin: "5px 0",
    },
    containerOVA: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    customTextField: {
        width: "27vh",
        margin: "4px",
    },
    customTextFieldFullWidth: {
        width: "100%",
        margin: "4px",
    },
    textFieldContainer: {
        justifyContent: "center",
    },
    spacer: {
        flex: 1,
    },
    deviceBox: {
        backgroundColor: "#FFFFFE",
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
        padding: "8px",
        marginTop: "8px",
        width: "90%",
        "&:hover": {
            boxShadow: "0px 4px 8px rgba(0,0,0,0.15)",
        },
    },
    deviceRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    deviceRowCenter: {
        display: "flex",
    },
    buttonContainer: {
        textAlign: "right",
    },
    removeDeviceButton: {
        minWidth: "auto",
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: "#FF0000",
        borderColor: "#FF0000",
        "&:hover": {
            backgroundColor: "#ffebee",
        },
        "& .MuiButton-label": {
            fontSize: "0.75rem",
        },
    },
    addDeviceButton: {
        minWidth: "auto",
        padding: theme.spacing(1),
        marginTop: theme.spacing(3),
        color: "#4caf50",
        borderColor: "#4caf50",
        "&:hover": {
            backgroundColor: "#e8f5e9",
        },
        "& .MuiButton-label": {
            fontSize: "0.75rem",
        },
    },
    card: {
        width: "100%",
        boxShadow: "none",
    },
    gridWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    gridRow: {
        margin: "5px 0px",
    },
    endRowIcon: {
        textAlign: "left",
        marginLeft: "0px",
    },
    customGridItem1: {
        textAlign: "right",
        "@media (max-width: 959px)": {
            textAlign: "center",
        },
    },
    customGridItem2: {
        textAlign: "left",
        "@media (max-width: 959px)": {
            textAlign: "center",
            // marginLeft: "30px",
        },
    },
    parent: {
        display: "flex",
    },
    beforeAndAfter: {
        content: '""',
        flexBasis: "100%",
    },
    centerContent: {
        textAlign: "center",
        flexBasis: "100%",
    },
    rightAligned: {
        textAlign: "right",
        flexBasis: "100%",
        alignContent: "center",
    },
    buttonStyle: {
        backgroundColor: "green",
        color: "white",
        width: 70,
    },
}));
