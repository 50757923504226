import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import {
	Paper,
	Grid,
	Typography,
	Container,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import useStyles from "./styles";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import ProgressBar from "../create/ProgressBar";
import "./styles.scss";
import DeleteIcon from "@material-ui/icons/DeleteForever";

const UploadKeygen = () => {
	const [keygenFile, setKeygenFile] = useState(null);
	const [wait, setWait] = useState(false);
	const [status, setStatus] = useState();
	const [keys, setKeys] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [orderDirection, setOrderDirection] = useState("desc");
	const [valueToOrderBy, setValueToOrderBy] = useState("date");
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [selectedBuild, setSelectedBuild] = useState("");
	const [page, setPage] = useState(0);

	const classes = useStyles();
	const token = useContext(Context);

	const [deviceLicenseFile, setDeviceLicenseFile] = useState(null);
	const [waitLicense, setWaitLicense] = useState(false);
	const [mantleKey, setMantleKey] = useState(false);

	let baseURL = "";
	let getKeysURL = "";
	let deleteKeyURL = "";
	let licenseURL = "";

	if (process.env.NODE_ENV === "development") {
		baseURL = "http://localhost:80/api/keygen/key-verification";
		getKeysURL = "http://localhost:80/api/keygen/user_keys";
		deleteKeyURL = "http://localhost:80/api/keygen/user_keys";
		licenseURL = "http://localhost:80/api/licenses/license";
	} else if (process.env.NODE_ENV === "production") {
		baseURL = "/api/keygen/key-verification";
		getKeysURL = "/api/keygen/user_keys";
		deleteKeyURL = "/api/keygen/user_keys";
		licenseURL = "/api/licenses/license";
	}

	const changeHandler = (e) => {
		setKeygenFile(e.target.files[0]);
	};

	const submitKeygen = (e) => {
		e.preventDefault();
		const sendData = new FormData();

		sendData.append("key", keygenFile, keygenFile?.name);

		axios
			.post(baseURL, sendData, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token.token}`,
					"Content-Type": "multipart/form-data",
				},
				body: `key=@${keygenFile.name}`,
			})
			.then((res) => {
				setStatus(res.status);
				setWait(false);
				window.location.reload();
			})

			.catch((error) => {
				setWait(false);
				alert("error", error);
			});
	};

	const Waiting = () => {
		if (keygenFile !== null) {
			setWait(true);
		}
		if (deviceLicenseFile !== null) {
			setWaitLicense(true);
		}
	};

	const getActiveKeys = () => {
		fetch(getKeysURL, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				setKeys(response);
				if (response.Result != "No License Information") {
					const listKeyNames = response?.map((name) => {
						if (name.feature_name === "mantle") {
							setMantleKey(true);
						}
					});
				}
			})
			.catch((error) => {
				alert("Error Retrieving Keys", error);
			});
	};

	const deleteKey = async (key) => {
		await fetch(deleteKeyURL + "/" + key.serial_number, {
			method: "Delete",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				setStatus(res.status);
				setWait(false);
				window.location.reload();
			})

			.catch((error) => {
				setWait(false);
				alert("error", error);
			});
	};

	// Pagination stuff for table...
	const handleDialogOpen = (row) => {
		setDialogOpen(true);
		setSelectedBuild(row);
		console.log(selectedBuild);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function sortedRowInformation(array, comparator) {
		if (array.Result != "No License Information") {
			const stabilizedThis = array?.map((el, index) => [el, index]);
			stabilizedThis?.sort((a, b) => {
				const order = comparator(a[0], b[0]);
				if (order !== 0) {
					return order;
				}
				return a[1] - b[1];
			});
			return stabilizedThis?.map((el) => el[0]);
		}
	}

	useEffect(() => {
		getActiveKeys();
	}, []);

	return (
		<Paper className={classes.paper} elevation={6}>
			<div id="settingsLicense">
				{/* Mantle Software Licenses */}
				<Typography component="h1" variant="h5">
					Upload Mantle Software License
				</Typography>
				<form
					autoComplete="off"
					onSubmit={submitKeygen}
					className={classes.form}
				>
					<Grid>
						<div className={classes.fileInput}>
							<input
								type="file"
								required
								accept=".yml"
								onChange={changeHandler}
							/>
						</div>
					</Grid>
					<Button
						onClick={Waiting}
						type="submit"
						className={classes.button}
					>
						Submit
					</Button>
				</form>
				{wait ? (
					<Alert
						style={{ marginBottom: "10px" }}
						severity="warning"
					>
						Please wait
					</Alert>
				) : status === 200 ? (
					<Alert
						style={{ marginBottom: "10px" }}
						severity="success"
					>
						License(s) Successfully Updated
					</Alert>
				) : (
					""
				)}
				{wait ? (
					<ProgressBar
						style={{
							display: "flex",
							margin: "20px",
						}}
					/>
				) : status === 200 ? (
					""
				) : (
					""
				)}

				<br />

				{keys ? (
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }}>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Serial #</TableCell>
									<TableCell>Expiration</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Delete</TableCell>
								</TableRow>
							</TableHead>
							{sortedRowInformation(
								keys,
								getComparator(
									orderDirection,
									valueToOrderBy
								)
							)
								?.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)

								?.map((key, i) => (
									<TableBody
										style={{
											textDecoration: "none",
											backgroundColor:
												key.status ===
													"grace_period"
													? "yellow"
													: "" ||
														key.status ===
														"expired"
														? "red"
														: "",
										}}
										onClick={() =>
											handleDialogOpen(key)
										}
										key={key.serial_number}
									>
										<TableRow key={key.serial_number}>
											<TableCell
												style={{
													cursor: "pointer",
												}}
												scope="row"
											>
												{key.feature_name
													?.slice(0, 12)
													.toUpperCase()}
											</TableCell>
											<TableCell
												style={{
													cursor: "pointer",
												}}
												scope="row"
											>
												{key.serial_number}
											</TableCell>
											<TableCell
												style={{
													cursor: "pointer",
												}}
												scope="row"
											>
												{key.expiration_date}
											</TableCell>
											<TableCell
												style={{
													cursor: "pointer",
												}}
												scope="row"
											>
												{key.status}
											</TableCell>

											<TableCell>
												<DeleteIcon
													style={{
														marginBottomL:
															"20px",
													}}
													value={
														key.serial_number
													}
													className={
														classes.isobutton
													}
													onClick={() => {
														if (
															window.confirm(
																"Are you sure you wish to delete this license?"
															)
														)
															deleteKey(key);
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								))}
						</Table>
					</TableContainer>
				) : (
					""
				)}
			</div>
		</Paper>
	);
};

export default UploadKeygen;
