import { Box, Button, Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";
import NetworkConfig from "../buildForms/Network/NetworkConfig";
import NetworkConfigPrevious from "../buildForms/Network/NetworkConfigPrevious";
import useStyles from "./styles";

const NewNetwork = ({ files }) => {
	const [valueState, setValueState] = useState("0");
	const token = useContext(Context);
	const location = useLocation();
	const info = location?.state?.files?.config || location.state?.files;
	const history = useHistory();
	const classes = useStyles();

	console.log(info);

	const { register, unregister, control, handleSubmit, getValues, setValue, watch } = useForm({
		defaultValues: {
			network_devices: info || [] || [{
				template_name: "",
				port: "",
			}]
		},
		mode: "onBlur",
		reValidateMode: "onChange",
		shouldUnregister: false,
	});

	let postNetworkBuildURL = "";
	let getBuildById = "";
	let cancelURL = "";
	if (process.env.NODE_ENV === "development") {
		postNetworkBuildURL =
			"http://localhost:80/api/network-builds/validate-workflow";
		getBuildById = "http://localhost:80/api/network-builds";
		cancelURL = "http://localhost:80/api/control/cancel-build";
	} else if (process.env.NODE_ENV === "production") {
		postNetworkBuildURL = "/api/network-builds/validate-workflow";
		getBuildById = "/api/network-builds";
		cancelURL = "/api/control/cancel-build";
	}

	const saveData = async (data) => {
		await fetch(postNetworkBuildURL, {
			method: "POST",
			body:
				JSON.stringify(data.network_devices) ||
				JSON.stringify(data.network_devices.files),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				history.push({
					pathname: "/track-build",
					state: {
						data: data.network_devices,
						resdata: resdata,
						kit_id: data.kit_id,
					},
				});
			})
			.catch((error) => {
				alert("Error Creating Build. Please Try Again", error);
				console.log(error);
			});
	};

	const buildID = location?.state?.files.build_id;

	return (
		<Box
			sx={{
				width: "100%",
				typography: "body1",
				backgroundColor: "#EAEAEA",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>

				<Button
					style={{ fontWeight: "bolder", letterSpacing: "3px" }}
					className={classes.buildbtn}
					onClick={handleSubmit(saveData)}
				>
					Build
				</Button>
				<input
					className={classes.kitID}
					placeholder="Kit ID"
					{...register("kit_id")}
					defaultValue=""
					autoComplete="false"
				/>
			</div>

			<TabContext valueState={valueState}>
				<TabPanel valueState="0">
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						{buildID === undefined ? (
							<NetworkConfig
								info={info}
								controls={control}
								register={register}
								unregister={unregister}
								getValues={getValues}
								setValue={setValue}
								watch={watch}
							/>
						) : (
							<NetworkConfigPrevious
								control={control}
								register={register}
							/>
						)}
					</div>
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default NewNetwork;
