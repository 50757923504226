import {
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import useStyles from "./styles";
const Settings = ({ register, control, info, hide }) => {
	const classes = useStyles();

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));
	return (
		<Container component="main" maxWidth="xl">
			<Paper className={classes.paper} elevation={6}>
				<Typography component="h1" variant="h5">
					Network Settings
				</Typography>
				<form autoComplete="off" className={classes.form}>
					<Grid style={{ marginRight: "20px" }}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="Subnet Mask for ESXi host (example: 255.255.255.0)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="Subnet Mask"
									{...register("netmask")}
									defaultValue={info?.netmask || ""}
								/>
							</Alerttip>
							<Alerttip title="Default Gateway for ESXi host (example: 192.168.1.1)">
								<TextField
									variant="outlined"
									style={{ width: "40%" }}
									label="Default Gateway"
									{...register("vcsa_gateway")}
									defaultValue={info?.vcsa_gateway || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="Primary DNS Server for ESXi host (example: 8.8.8.8)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="DNS Server 0"
									{...register("vcsa_dns_0")}
									defaultValue={info?.vcsa_dns_0 || ""}
								/>
							</Alerttip>
							<Alerttip title="Secondary DNS Server for ESXi host (example: 8.8.4.4)">
								<TextField
									style={{
										width: "40%",
										display: hide ? "none" : "",
									}}
									variant="outlined"
									label="DNS Server 1"
									{...register("vcsa_dns_1")}
									defaultValue={info?.vcsa_dns_1 || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="Primary NTP Server for ESXi host (example: 132.163.96.5)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="NTP Server 0"
									{...register("vcsa_ntp_server_0")}
									defaultValue={info?.vcsa_ntp_server_0 || ""}
								/>
							</Alerttip>
							<Alerttip title="Secondary NTP Server for ESXi host (example: 132.163.97.5)">
								<TextField
									style={{
										width: "40%",
										display: hide ? "none" : "",
									}}
									variant="outlined"
									label="NTP Server 1"
									{...register("vcsa_ntp_server_1")}
									defaultValue={info?.vcsa_ntp_server_1 || ""}
								/>
							</Alerttip>
						</div>
						<div>
							<Alerttip title="VLAN Tagging for Management Port. Leave blank for a flat network">
								<TextField
									style={{ width: "40%", marginLeft: "30%" }}
									variant="outlined"
									label="VLAN"
									{...register("vlanid")}
									type="number"
									defaultValue={info?.vlanid || ""}
									placeholder="leave VLAN blank for flat network"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												VLAN
											</InputAdornment>
										),
									}}
								/>
							</Alerttip>
						</div>
					</Grid>
				</form>
				<Grid style={{ marginTop: "30px" }}>
					<FormControl component="fieldset">
						<FormGroup>
							<Alerttip title="Would you like SSH enabled? If so, check this box">
								<FormControlLabel
									control={
										<input
											type="checkbox"
											defaultChecked={
												"" ||
												info?.esxi_ssh_enable === true
											}
											{...register("esxi_ssh_enable")}
										/>
									}
									label="ESXI SSH Enable"
								/>
							</Alerttip>
						</FormGroup>
					</FormControl>
				</Grid>
			</Paper>
		</Container>
	);
};

export default Settings;
