import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      backgroundColor: "green",
    },
  },
  paper: {
    width: 450,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  hlabel: {
    fontFamily: "Montserrat",
  },
  input: {
    width: "100%",
    // padding: "5px",
    marginBottom: "20px",
  },
  iconVis: {
    marginLeft: "7px",
    position: "relative",
    // right: 0
  },
  form: {
    alignContent: "center",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  submit: {
    fontFamily: "Montserrat",
    backgroundColor: "#EA2331",
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: "#333333",
      color: "#EA2331",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    backgroundColor: "black",
    color: "white",
    border: "2px solid #333333",
    boxShadow: 24,
    padding: "20px",
    borderRadius: "10px",
    textAlign: "justify",
  },
}));
