import { DevTool } from "@hookform/devtools";
import { Box, Button, Typography, Paper, Container } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Context } from "../../../context/Context";
import useGlobalStyle from "../../../themes/globalStyle";
import Playbooks from "./Playbooks";

const PlaybooksBuild = ({}) => {
    const location = useLocation();
    const info = location?.state?.files?.config || location.state?.files;
    const token = useContext(Context);
    const history = useHistory();
    let { id } = useParams();
    const globalClasses = useGlobalStyle();

    const playbooks = info?.playbooks;

    const { register, unregister, control, handleSubmit, setValue, watch } =
        useForm({
            defaultValues: {
                build_id: id || "",
                playbooks: playbooks || [
                    {
                        name: "",
                    },
                ],
            },
            mode: "onBlur",
            reValidateMode: "onChange",
            shouldUnregister: false,
        });

    let baseURL = "";
    let buildsURL = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://localhost:80/api/playbooks/validate-workflow";
        buildsURL = "http://localhost:80/api/build/builds";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "/api/playbooks/validate-workflow";
        buildsURL = "/api/build/builds";
    }

    const saveData = async (data) => {
        await fetch(baseURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                history.push({
                    pathname: "/track-build",
                    state: {
                        data: data,
                        resdata: resdata,
                        kit_id: data.kit_id,
                        buildType: "playbooksBuild",
                    },
                });
            })
            .catch((error) => {
                alert("Error Creating Build. Please Try Again", error);
            });
    };
    return (
        <Box
            sx={{
                width: "100%",
                typography: "body1",
                backgroundColor: "#EAEAEA",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={handleSubmit(saveData)}
                    style={{ fontWeight: "bolder", letterSpacing: "3px" }}
                    className={globalClasses.mainBuildButton}
                >
                    Build
                </Button>
            </div>
            <Container maxWidth="xl">
                <Paper className={globalClasses.BuildFormPaper} elevation={6}>
                    <Typography
                        component="h4"
                        variant="h5"
                        style={{ padding: "15px" }}
                    >
                        Build: {id}
                    </Typography>
                </Paper>
            </Container>
            <Playbooks
                info={info}
                controls={control}
                register={register}
                unregister={unregister}
                setValue={setValue}
                watch={watch}
            />
            <DevTool control={control} />
        </Box>
    );
};

export default PlaybooksBuild;
