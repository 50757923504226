import { useQuery } from "react-query";
import { serialPortsURL } from "./utilties/QueryURLs";
import { fetchData } from "./utilties/QueryManager";

export const GetSerialPorts = (token) => {

    return useQuery(
        ["serialPorts", serialPortsURL, token],
        async () => {
            const data = await fetchData(serialPortsURL, token)
            return data?.serial_ports;
        },
        {
            refetchOnWindowFocus: true,
        }
    );
};
