import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
        },
    },

    button: {
        backgroundColor: "#EA2331",
        color: "#333333",
        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        "@media (max-width: 450px)": {
            width: "100%",
        },
    },

    buildbtn: {
        margin: "30px 0",
        padding: "10px",
        width: "10vw",
        backgroundColor: "#EA2331",
        color: "#333333",

        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },

    text: {
        "@media (max-width: 450px)": {
            fontSize: ".75rem",
        },
    },
    form: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
        alignItems: "center",
        flexDirection: "column",
    },

    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        width: "98%",
    },

    kitID: {
        alignSelf: "center",
        width: "20%",
        padding: "1%",
        margin: "5px 0",
    },

    label: {
        width: "200px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    customInputLabel: {
        color: "black",
        display: "flex",
        marginTop: "20px",
        marginRight: "20px",
        marginLeft: "-45px",
        marginBottom: "20px",
    },
    displayFlex: {
        display: "flex",
        verticalAlign: "middle",
    },
    verticalAlignDivParent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    customTextField: {
        width: "100%",
        margin: "5px",
    },
    divGrid: {
        display: "flex",
        flexDirection: "column",
    },
}));
