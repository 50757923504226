import {
	Box,
	useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/Context";
import BuildsDisplay from "./BuildsDisplay";

const NetworkBuildTable = () => {
	const [netBuilds, setNetBuilds] = useState();
	const [netUtils, setNetUtils] = useState();
	const [virtualNetBuilds, setVirtualNetBuilds] = useState();

	const token = useContext(Context);
	const isMobile = useMediaQuery('(max-width:620px)')

	let GetAllNetBuilds = "";
	let GetAllNetUtils = "";
	let GetAllVirtualNetBuilds = "";
	if (process.env.NODE_ENV === "development") {
		GetAllNetBuilds = "http://localhost:80/api/network-builds/builds";
		GetAllNetUtils = "http://localhost:80/api/network-utils/network_utils";
		GetAllVirtualNetBuilds =
			"http://localhost:80/api/virtual-network-builds/builds";
	} else if (process.env.NODE_ENV === "production") {
		GetAllNetBuilds = "/api/network-builds/builds";
		GetAllNetUtils = "/api/network-utils/network_utils";
		GetAllVirtualNetBuilds = "/api/virtual-network-builds/builds";
	}

	const getNetBuilds = async () => {
		await fetch(GetAllNetBuilds, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				setNetBuilds(resdata.builds);
			})
			.catch((error) => {
				alert("Error Retrieving Network Builds", error);
			});
	};

	const getNetUtils = async () => {
		await fetch(GetAllNetUtils, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				setNetUtils(resdata.networkUtils);
			})
			.catch((error) => {
				alert("Error Retrieving Network Utils", error);
			});
	};

	const getVirtualNetBuilds = async () => {
		await fetch(GetAllVirtualNetBuilds, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				setVirtualNetBuilds(resdata.builds);
			})
			.catch((error) => {
				alert("Error Retrieving Virtual Network Builds", error);
			});
	};

	useEffect(() => {
		getNetBuilds();
		getNetUtils();
		getVirtualNetBuilds();
	}, []);

		return (
		<div>
			<Box mb={2}>
				<BuildsDisplay type="network" title="Network Builds" data={netBuilds} isMobile={isMobile} />
			</Box>
			<Box mb={2}>
				<BuildsDisplay type="vnetwork" title="Virtual Network Builds" data={virtualNetBuilds} isMobile={isMobile} />
			</Box>
			<Box mb={2}>
				<BuildsDisplay type="network-utils" title="Network Utilities" data={netUtils} isMobile={isMobile} />
			</Box>
		</div >
	);
};

export default NetworkBuildTable;
