import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    Button,
    Typography,
    Paper,
    InputLabel,
    MenuItem,
    Select,
    Grid,
} from "@material-ui/core";
import useStyles from "./style";
import { Context } from "../../context/Context";
import axios from "axios";
import ProgressBar from "../create/ProgressBar";

const UploadCSVNetwork = () => {
    const [file, setFile] = useState("");
    const [cutsheetInfo, setCutsheetInfo] = useState("");
    const [kitType, setKitType] = useState("");

    const [networkRes, setNetworkRes] = useState();
    const [status, setStatus] = useState();
    const [buildStatus, setBuildStatus] = useState(false);
    const [isRunning, setIsRunning] = useState(false);

    const [devices, setDevices] = useState();
    const [ports, setPorts] = useState();
    const [templates, setTemplates] = useState();

    const [portType, setPortType] = useState("/dev/ttyUSB0");
    const [templateType, setTemplateType] = useState("");
    const [deviceType, setDeviceType] = useState("Dtech M3-SE-3560-P");

    const [portType2, setPortType2] = useState("/dev/ttyUSB2");
    const [templateType2, setTemplateType2] = useState("");
    const [deviceType2, setDeviceType2] = useState("Dtech M3-SE-3560-P");

    const [portType3, setPortType3] = useState("/dev/ttyUSB5");
    const [templateType3, setTemplateType3] = useState("");
    const [deviceType3, setDeviceType3] = useState("Dtech M3-SE-3560-P");

    const [portType4, setPortType4] = useState("/dev/ttyUSB1");
    const [templateType4, setTemplateType4] = useState("");
    const [deviceType4, setDeviceType4] = useState("Pacstar 448");

    const [portType5, setPortType5] = useState("/dev/ttyUSB0");
    const [templateType5, setTemplateType5] = useState("");
    const [deviceType5, setDeviceType5] = useState("Klas SW10GG");

    const [portType7, setPortType7] = useState("/dev/ttyUSB0");
    const [templateType7, setTemplateType7] = useState("");
    const [deviceType7, setDeviceType7] = useState("Cisco Switch");

    const classes = useStyles();
    const token = useContext(Context);

    const [res, setRes] = useState();
    const history = useHistory();

    let postCutsheet = "";
    let postNetworkVW = "";
    let cancelURL = "";
    let getBuildById = "";
    let portUrl = "";
    let templateUrl = "";
    let deviceUrl = "";
    if (process.env.NODE_ENV === "development") {
        postCutsheet =
            "http://localhost:80/api/taclan-cutsheet-to-config/upload-cutsheet-network";
        postNetworkVW =
            "http://localhost:80/api/network-builds/validate-workflow";
        cancelURL = "http://localhost:80/api/control/cancel-build";
        getBuildById = "http://localhost:80/api/network-builds";
        portUrl = "http://localhost:80/api/serial-ports/serial_ports";
        templateUrl = "http://localhost:80/api/serial-devices/templates";
        deviceUrl = "http://localhost:80/api/serial-devices/serial-devices";
    } else if (process.env.NODE_ENV === "production") {
        postCutsheet = "/api/taclan-cutsheet-to-config/upload-cutsheet-network";
        postNetworkVW = "/api/network-builds/validate-workflow";
        cancelURL = "/api/control/cancel-build";
        getBuildById = "/api/network-builds";
        portUrl = "/api/serial-ports/serial_ports";
        templateUrl = "/api/serial-devices/templates";
        deviceUrl = "/api/serial-devices/serial-devices";
    }

    const getDevices = async () => {
        await fetch(deviceUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setDevices(resdata.devices);
            })
            .catch((error) => {
                alert("Error Retrieving Devices", error);
            });
    };

    const getTemplates = async () => {
        await fetch(templateUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setTemplates(resdata.templates);
            })
            .catch((error) => {
                alert("Error Retrieving Templates", error);
            });
    };

    const getPorts = async () => {
        await fetch(portUrl, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setPorts(resdata.serial_ports);
            })
            .catch((error) => {
                alert("Error Retrieving Ports", error);
            });
    };

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const { register, control, handleSubmit } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
    });

    const cancelContainers = () => {
        fetch(cancelURL + "/" + networkRes.build_id + "?type=network", {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Build Canceled");
        history.push("/");
    };

    const getStatus = async () => {
        await fetch(getBuildById + "/" + networkRes.build_id, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setStatus(resdata);
                // if (resdata.status === "success") {
                // 	history.push("/networkreview/" + networkRes.build_id);
                // }
            })
            .catch((error) => {
                alert("Error With Providing Status, Please Try Again", error);
            });
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const sendData = new FormData();

        sendData.append("cutsheet", file, file?.name);

        if (kitType === "klas_lite") {
            let getAll = JSON.stringify({
                devices: [
                    {
                        device: deviceType5,
                        template_name: templateType5,
                        port: portType5,
                    },
                ],
            });

            sendData.append("devices", getAll);
        }

        if (kitType === "lite") {
            let getAll = JSON.stringify({
                devices: [
                    {
                        device: deviceType,
                        template_name: templateType,
                        port: portType,
                    },
                ],
            });

            sendData.append("devices", getAll);
        }
        if (kitType === "medium") {
            let getAll = JSON.stringify({
                devices: [
                    {
                        device: deviceType,
                        template_name: templateType,
                        port: portType,
                    },
                    {
                        device: deviceType2,
                        template_name: templateType2,
                        port: portType2,
                    },
                ],
            });

            sendData.append("devices", getAll);
        }
        if (kitType === "heavy") {
            let getAll = JSON.stringify({
                devices: [
                    {
                        device: deviceType,
                        template_name: templateType,
                        port: portType,
                    },
                    {
                        device: deviceType2,
                        template_name: templateType2,
                        port: portType2,
                    },
                    {
                        device: deviceType3,
                        template_name: templateType3,
                        port: portType3,
                    },
                ],
            });

            sendData.append("devices", getAll);
        }
        if (kitType === "el8000") {
            let getAll = JSON.stringify({
                devices: [
                    {
                        device: deviceType4,
                        template_name: templateType4,
                        port: portType4,
                    },
                ],
            });
            sendData.append("devices", getAll);
        }
        if (kitType === "client_switch") {
            let getAll = JSON.stringify({
                devices: [
                    {
                        device: deviceType7,
                        template_name: templateType7,
                        port: portType7,
                    },
                ],
            });
            sendData.append("devices", getAll);
        }

        axios
            .post(postCutsheet + `?kit_type=${kitType}`, sendData, {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                    accept: "application/json",
                },
                body: `cutsheet=@${file.name};type=${file.type}`,
            })
            .then((res) => {
                setCutsheetInfo(res.data.Result);
                alert("Cutsheet uploaded successfully");
            })
            .catch((error) => {
                alert("Error.  Please check all data requested.", error);
            });
    };

    const saveData = async () => {
        await fetch(postNetworkVW, {
            method: "POST",
            body: JSON.stringify(cutsheetInfo.serial_devices),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                history.push({
                    pathname: "/track-build",
                    state: {
                        data: cutsheetInfo.serial_devices,
                        resdata: resdata,
                    },
                });
            })
            .catch((error) => {
                alert("Error Creating Build. Please Try Again", error);
            });
    };

    useEffect(() => {
        getPorts();
        getTemplates();
        getDevices();
        if (isRunning) {
            const id = window.setInterval(() => {
                getStatus();
            }, 1000);
            return () => window.clearInterval(id);
        }
        return undefined;
    }, [isRunning]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <style>{`
        .MuiInputLabel-root {
          margin-right: 10px;
        }
      `}</style>

            <div
                style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    paddingTop: "30px",
                }}
            >
                <Paper
                    className={classes.paper}
                    elevation={6}
                    style={{
                        width: "100%",
                        height: "inherit",
                        marginTop: "60px",
                        marginBottom: "80px",
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            fontFamily: "Montserrat",
                            marginTop: "15px",
                            borderBottom: "1px solid",
                        }}
                    >
                        Import Network Cutsheet
                    </Typography>

                    <div className={classes.fileInput}>
                        <Grid
                            container
                            spacing={2}
                            style={{
                                marginTop: "15px",
                                minWidth: "500px",
                                maxWidth: "880px",
                                textAlign: "center",
                            }}
                        >
                            <Grid
                                item
                                md={6}
                                style={{
                                    marginBottom: "10px",
                                    textAlign: "right",
                                }}
                            >
                                <input
                                    id={"csvFileInput"}
                                    type={"file"}
                                    required
                                    onChange={handleOnChange}
                                />
                            </Grid>
                            <Grid item md={6} style={{ textAlign: "left" }}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        alignSelf: "center",
                                        display: "inline",
                                    }}
                                >
                                    Type:
                                </InputLabel>
                                <Select
                                    required
                                    style={{
                                        marginLeft: "20px",
                                    }}
                                    onChange={(e) => setKitType(e.target.value)}
                                >
                                    <MenuItem value="lite">
                                        DTECH Lite
                                    </MenuItem>
                                    <MenuItem value="medium">
                                        DTECH Medium
                                    </MenuItem>
                                    <MenuItem value="heavy">
                                        DTECH Heavy
                                    </MenuItem>
                                    <MenuItem value="el8000">
                                        HPE EL8000
                                    </MenuItem>
                                    <MenuItem value="klas_lite">
                                        KLAS Lite
                                    </MenuItem>
                                    <MenuItem value="client_switch">
                                        Client Switch
                                    </MenuItem>
                                </Select>
                            </Grid>

                            {kitType === "klas_lite" ? (
                                <>
                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType5(e.target.value)
                                            }
                                            defaultValue="Klas SW10GG"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType5(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType5(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB0"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </>
                            ) : null}

                            {kitType === "client_switch" ? (
                                <>
                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType7(e.target.value)
                                            }
                                            defaultValue="Cisco Switch"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType7(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType7(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB0"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </>
                            ) : null}

                            {kitType === "lite" ? (
                                <>
                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType(e.target.value)
                                            }
                                            defaultValue="Dtech M3-SE-3560-P"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB0"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </>
                            ) : null}

                            {kitType === "medium" ? (
                                <>
                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType(e.target.value)
                                            }
                                            defaultValue="Dtech M3-SE-3560-P"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB0"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device 2:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType2(e.target.value)
                                            }
                                            defaultValue="Dtech M3-SE-3560-P"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template 2:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType2(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port 2:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType2(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB2"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </>
                            ) : null}

                            {kitType === "heavy" ? (
                                <>
                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType(e.target.value)
                                            }
                                            defaultValue="Dtech M3-SE-3560-P"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType(e.target.value)
                                            }
                                            required
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB0"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device 2:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType2(e.target.value)
                                            }
                                            defaultValue="Dtech M3-SE-3560-P"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template 2:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType2(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port 2:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType2(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB2"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Device 3:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setDeviceType3(e.target.value)
                                            }
                                            defaultValue="Dtech M3-SE-3560-P"
                                        >
                                            {devices?.map((index) => (
                                                <MenuItem
                                                    key={index.name}
                                                    value={index.name}
                                                >
                                                    {index.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Template 3:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setTemplateType3(e.target.value)
                                            }
                                        >
                                            {templates?.map((index) => (
                                                <MenuItem
                                                    key={index.template_name}
                                                    value={index.template_name}
                                                >
                                                    {index.template_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item md={4}>
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                alignSelf: "center",
                                                display: "inline",
                                            }}
                                        >
                                            Port 3:
                                        </InputLabel>

                                        <Select
                                            onChange={(e) =>
                                                setPortType3(e.target.value)
                                            }
                                            defaultValue="/dev/ttyUSB5"
                                        >
                                            {ports?.map((index) => (
                                                <MenuItem
                                                    key={index.port}
                                                    value={index.port}
                                                >
                                                    {index.port}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </>
                            ) : null}
                        </Grid>
                    </div>

                    {kitType === "el8000" ? (
                        <Grid
                            container
                            spacing={2}
                            style={{
                                marginTop: "15px",
                                minWidth: "500px",
                                maxWidth: "880px",
                            }}
                        >
                            <Grid item md={4}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        alignSelf: "center",
                                        display: "inline",
                                    }}
                                >
                                    Device:
                                </InputLabel>

                                <Select
                                    onChange={(e) =>
                                        setDeviceType4(e.target.value)
                                    }
                                    defaultValue="Pacstar 448"
                                >
                                    {devices?.map((index) => (
                                        <MenuItem
                                            key={index.name}
                                            value={index.name}
                                        >
                                            {index.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item md={4}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        alignSelf: "center",
                                        display: "inline",
                                    }}
                                >
                                    Template:
                                </InputLabel>

                                <Select
                                    onChange={(e) =>
                                        setTemplateType4(e.target.value)
                                    }
                                    required
                                >
                                    {templates?.map((index) => (
                                        <MenuItem
                                            key={index.template_name}
                                            value={index.template_name}
                                        >
                                            {index.template_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item md={4}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        alignSelf: "center",
                                        display: "inline",
                                    }}
                                >
                                    Port:
                                </InputLabel>
                                <Select
                                    onChange={(e) =>
                                        setPortType4(e.target.value)
                                    }
                                    defaultValue="/dev/ttyUSB1"
                                >
                                    {ports?.map((index) => (
                                        <MenuItem
                                            key={index.port}
                                            value={index.port}
                                        >
                                            {index.port}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {cutsheetInfo !== "" ? (
                        <>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    marginTop: "15px",
                                    minWidth: "500px",
                                    maxWidth: "880px",
                                }}
                            >
                                <Grid
                                    item
                                    md={12}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <h3
                                        style={{
                                            fontWeight: "normal",
                                            paddingBottom: "5px",
                                            marginBottom: "15px",
                                            marginTop: "60px",
                                        }}
                                    >
                                        Device Information
                                        <hr style={{ width: "240px" }} />
                                    </h3>
                                    <div>
                                        {kitType !== "" ? (
                                            <>
                                                <Button
                                                    onClick={(e) => {
                                                        handleOnSubmit(e);
                                                    }}
                                                    className={classes.button}
                                                    style={{
                                                        fontWeight: "bolder",
                                                        fontFamily:
                                                            "Montserrat",
                                                        letterSpacing: "3px",
                                                        display:
                                                            buildStatus === true
                                                                ? "none"
                                                                : "inline-block",
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    type="submit"
                                                    fullWidth
                                                >
                                                    Upload
                                                </Button>
                                            </>
                                        ) : null}

                                        {cutsheetInfo !== "" ? (
                                            <>
                                                <Button
                                                    className={classes.button}
                                                    style={{
                                                        fontWeight: "bolder",
                                                        fontFamily:
                                                            "Montserrat",
                                                        letterSpacing: "3px",
                                                        display:
                                                            buildStatus === true
                                                                ? "none"
                                                                : "inline-block",
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    type="submit"
                                                    fullWidth
                                                    onClick={handleSubmit(
                                                        saveData
                                                    )}
                                                >
                                                    Continue
                                                </Button>

                                                <style>{`
									.MuiLinearProgress-colorPrimary {
										background-color: lightgrey;
										width: 50%; margin-left: auto!important; margin-right: auto!important;
									}
									.MuiLinearProgress-barColorPrimary {
										background-color: #EA2331;
									}
								`}</style>

                                                <Button
                                                    onClick={(e) => {
                                                        if (
                                                            window.confirm(
                                                                "Are you sure you wish to cancel this build?"
                                                            )
                                                        )
                                                            cancelContainers(e);
                                                    }}
                                                    className={classes.button}
                                                    style={{
                                                        fontWeight: "bolder",
                                                        letterSpacing: "3px",
                                                        display:
                                                            status?.status ===
                                                                "success" ||
                                                            status?.status ===
                                                                undefined
                                                                ? "none"
                                                                : "flex",
                                                        margin: "-30px auto -10px",
                                                    }}
                                                >
                                                    Cancel
                                                </Button>

                                                {/* <Button
									style={{
										fontWeight: "bolder",
										letterSpacing: "3px",
										margin: "15px auto 0",
										display:
											networkRes?.build_id === undefined
												? "inline-flex"
												: "none",
									}}
									onClick={handleSubmit(saveData)}
									className={classes.button}
								>
									Build
								</Button> */}

                                                <Button
                                                    id="getStatus"
                                                    style={{
                                                        fontWeight: "bolder",
                                                        letterSpacing: "3px",
                                                        display: "none",
                                                        margin: "15px auto 0",
                                                    }}
                                                    onClick={() =>
                                                        setIsRunning(true)
                                                    }
                                                    className={classes.button}
                                                >
                                                    Get Status
                                                </Button>
                                                {networkRes ? (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            flexDirection:
                                                                "column",
                                                            margin: "0px 0",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="h1"
                                                            variant="h5"
                                                            style={{
                                                                fontFamily:
                                                                    "Montserrat",
                                                                display:
                                                                    "inline-block",
                                                                fontSize:
                                                                    "1.25rem",
                                                            }}
                                                        >
                                                            Build ID:{" "}
                                                            {
                                                                networkRes?.build_id
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            component="h1"
                                                            variant="h5"
                                                            style={{
                                                                display:
                                                                    status?.status ===
                                                                    undefined
                                                                        ? "none"
                                                                        : "inline-block",
                                                                fontFamily:
                                                                    "Montserrat",
                                                                margin: "20px",
                                                                marginLeft:
                                                                    "10px",
                                                                fontSize:
                                                                    "1.25rem",
                                                            }}
                                                        >
                                                            | Status:{" "}
                                                            {status?.status
                                                                .replace(
                                                                    /[_-]/g,
                                                                    " "
                                                                )
                                                                .toUpperCase()}
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                <ProgressBar
                                                    style={{
                                                        display:
                                                            status?.status ===
                                                                "success" ||
                                                            status?.status ===
                                                                undefined
                                                                ? "none"
                                                                : "flex",

                                                        margin: "0px",
                                                    }}
                                                />

                                                <Button
                                                    className={classes.button}
                                                    style={{
                                                        fontWeight: "bolder",
                                                        letterSpacing: "3px",
                                                        display:
                                                            status?.status ===
                                                                "in_progress" ||
                                                            status?.status ===
                                                                undefined
                                                                ? "none"
                                                                : "flex",
                                                        margin: "15px auto -10px",
                                                    }}
                                                >
                                                    Review
                                                </Button>
                                            </>
                                        ) : null}
                                    </div>
                                    <br />
                                </Grid>
                                {cutsheetInfo?.serial_devices?.map(
                                    (device, i) => (
                                        <Grid
                                            item
                                            md={12}
                                            style={{ textAlign: "center" }}
                                            key={i}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid
                                                    md={4}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <p>
                                                        Device:{" "}
                                                        {device.device_name}:
                                                    </p>
                                                </Grid>
                                                <Grid md={8}>
                                                    <InputLabel
                                                        style={{
                                                            color: "black",
                                                            alignSelf: "center",
                                                            display: "inline",
                                                        }}
                                                    >
                                                        {device?.config_variables?.map(
                                                            (config, c) => (
                                                                <Grid
                                                                    item
                                                                    md={6}
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                        display:
                                                                            "inline-grid",
                                                                    }}
                                                                    key={c}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                "11px",
                                                                        }}
                                                                    >
                                                                        {config?.name.toUpperCase()}
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        value={
                                                                            config.value
                                                                        }
                                                                        style={{
                                                                            marginLeft:
                                                                                "5px",
                                                                            marginTop:
                                                                                "5px",
                                                                            marginBottom:
                                                                                "10px",
                                                                        }}
                                                                        autoCapitalize="none"
                                                                    />
                                                                </Grid>
                                                            )
                                                        )}
                                                    </InputLabel>
                                                </Grid>
                                                <Grid
                                                    md={12}
                                                    style={{
                                                        marginBottom: "30px",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <hr />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </>
                    ) : null}

                    <br />
                    <br />

                    <div>
                        {kitType !== "" ? (
                            <>
                                <Button
                                    onClick={(e) => {
                                        handleOnSubmit(e);
                                    }}
                                    className={classes.button}
                                    style={{
                                        fontWeight: "bolder",
                                        fontFamily: "Montserrat",
                                        letterSpacing: "3px",
                                        display:
                                            buildStatus === true
                                                ? "none"
                                                : "inline-block",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    fullWidth
                                >
                                    Upload
                                </Button>
                            </>
                        ) : null}

                        {cutsheetInfo !== "" ? (
                            <>
                                <Button
                                    className={classes.button}
                                    style={{
                                        fontWeight: "bolder",
                                        fontFamily: "Montserrat",
                                        letterSpacing: "3px",
                                        display:
                                            buildStatus === true
                                                ? "none"
                                                : "inline-block",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    fullWidth
                                    onClick={handleSubmit(saveData)}
                                >
                                    Continue
                                </Button>

                                <style>{`
									.MuiLinearProgress-colorPrimary {
										background-color: lightgrey;
										width: 50%; margin-left: auto!important; margin-right: auto!important;
									}
									.MuiLinearProgress-barColorPrimary {
										background-color: #EA2331;
									}
								`}</style>

                                <Button
                                    onClick={(e) => {
                                        if (
                                            window.confirm(
                                                "Are you sure you wish to cancel this build?"
                                            )
                                        )
                                            cancelContainers(e);
                                    }}
                                    className={classes.button}
                                    style={{
                                        fontWeight: "bolder",
                                        letterSpacing: "3px",
                                        display:
                                            status?.status === "success" ||
                                            status?.status === undefined
                                                ? "none"
                                                : "flex",
                                        margin: "-30px auto -10px",
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    id="getStatus"
                                    style={{
                                        fontWeight: "bolder",
                                        letterSpacing: "3px",
                                        display: "none",
                                        margin: "15px auto 0",
                                    }}
                                    onClick={() => setIsRunning(true)}
                                    className={classes.button}
                                >
                                    Get Status
                                </Button>
                                {networkRes ? (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            flexDirection: "column",
                                            margin: "0px 0",
                                        }}
                                    >
                                        <Typography
                                            component="h1"
                                            variant="h5"
                                            style={{
                                                fontFamily: "Montserrat",
                                                display: "inline-block",
                                                fontSize: "1.25rem",
                                            }}
                                        >
                                            Build ID: {networkRes?.build_id}
                                        </Typography>
                                        <Typography
                                            component="h1"
                                            variant="h5"
                                            style={{
                                                display:
                                                    status?.status === undefined
                                                        ? "none"
                                                        : "inline-block",
                                                fontFamily: "Montserrat",
                                                margin: "20px",
                                                marginLeft: "10px",
                                                fontSize: "1.25rem",
                                            }}
                                        >
                                            | Status:{" "}
                                            {status?.status
                                                .replace(/[_-]/g, " ")
                                                .toUpperCase()}
                                        </Typography>
                                    </div>
                                ) : (
                                    ""
                                )}

                                <ProgressBar
                                    style={{
                                        display:
                                            status?.status === "success" ||
                                            status?.status === undefined
                                                ? "none"
                                                : "flex",

                                        margin: "0px",
                                    }}
                                />
                                <Button
                                    className={classes.button}
                                    style={{
                                        fontWeight: "bolder",
                                        letterSpacing: "3px",
                                        display:
                                            status?.status === "in_progress" ||
                                            status?.status === undefined
                                                ? "none"
                                                : "flex",
                                        margin: "15px auto -10px",
                                    }}
                                >
                                    Review
                                </Button>
                            </>
                        ) : null}
                    </div>
                </Paper>
            </div>
        </div>
    );
};

export default UploadCSVNetwork;
