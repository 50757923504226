/*
    BuildDisplay.jsx is a component that takes in network (soon too datacenter) build information
    and displays it in a MUI Grid/Table for large screens and as Cards for small screens
*/
import {
    Box,
    Card,
    CardContent,
    MenuItem,
    Link as MuiLink,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Alert, Grid } from "@mui/material";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./BuildsDisplay.css";

function BuildsDisplay({ type, title, data, isMobile }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    // Checks to ensure data exists
    const hasData = data && Array.isArray(data) && data.length > 0;

    // Sorts data by date, newest first. Array.isArray() required
    const sortedData = Array.isArray(data)
        ? [...data].sort((a, b) =>
              b.date ? new Date(b.date) - new Date(a.date) : -1
          )
        : [];

    // filters data base on whether the build_id is included in the search term
    const filteredData = (sortedData || []).filter(
        (item) =>
            String(item.build_id).includes(searchTerm) ||
            String(item.util_id).includes(searchTerm)
    );
    // determine the start and end of the data on the current page, base on the page number and items per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    // slices filtered date to display on current page
    const currentData = filteredData.slice(startIndex, endIndex);
    // calculates the max number of pages based on length of filtered data and items per page
    const maxPage = Math.ceil(filteredData.length / itemsPerPage);

    const history = useHistory();

    const handleChangePage = (_event, value) => {
        setCurrentPage(value);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    return (
        <Paper elevation={3}>
            <Box padding={3} sx={{ backgroundColor: "#ededed" }}>
                <Grid container alignItems="center" spacing={3}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ textAlign: isMobile ? "center" : "left" }}
                    >
                        <Typography variant="h5" fontWeight="bold">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ textAlign: isMobile ? "center" : "right" }}
                    >
                        <TextField
                            label="Search by ID"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1);
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                {isMobile ? (
                    currentData.map((entry, index) => (
                        <Card key={index} sx={{ marginBottom: 2 }}>
                            <MuiLink
                                component={Link}
                                underline="none"
                                to={{
                                    pathname:
                                        type === "network"
                                            ? `/review/network-builds/${entry.build_id}`
                                            : type === "vnetwork"
                                            ? `/review/virtual-network-builds/${entry.build_id}`
                                            : type === "network-utils"
                                            ? `/review/network-utils/${entry.util_id}`
                                            : `/review/build/${entry.build_id}`,
                                    state: {
                                        data: data,
                                    },
                                }}
                            >
                                <CardContent>
                                    {type === "network-utils" ? (
                                        <>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                            >
                                                ID:{" "}
                                                {entry?.["util_id"]
                                                    .slice(0, 12)
                                                    .toUpperCase() || "-"}
                                            </Typography>
                                            <Typography>
                                                Operation:{" "}
                                                {entry?.operation || "-"}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography
                                            variant="h6"
                                            component="div"
                                        >
                                            ID:{" "}
                                            {entry?.["build_id"]
                                                .slice(0, 12)
                                                .toUpperCase() || "-"}
                                        </Typography>
                                    )}
                                    <Typography color="textSecondary">
                                        Timestamp:{" "}
                                        {entry?.["date"]?.slice(0, 10)} <br />{" "}
                                        {entry?.["date"]?.slice(11, 19)}
                                    </Typography>
                                    <Typography>
                                        Devices:{" "}
                                        {type === "network"
                                            ? entry.serial_devices.length
                                            : type === "vnetwork"
                                            ? entry.config
                                                  .virtual_serial_devices.length
                                            : type === "network-utils"
                                            ? entry.serial_devices.length
                                            : type === "datacenter"
                                            ? entry.config.hosts.length
                                            : "-"}
                                    </Typography>
                                    <Typography>
                                        Duration: {entry?.["build_duration"]}
                                    </Typography>
                                    <Typography>
                                        Status:{" "}
                                        {entry?.status
                                            .replace(/_/g, " ")
                                            .toUpperCase()}
                                    </Typography>
                                </CardContent>
                            </MuiLink>
                        </Card>
                    ))
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                {type !== "network-utils" ? (
                                    <>
                                        <TableCell>Kit ID</TableCell>
                                    </>
                                ) : (
                                    <TableCell>Operation</TableCell>
                                )}
                                <TableCell>Timestamp</TableCell>
                                <TableCell align="center">
                                    {type === "datacenter"
                                        ? "Hosts"
                                        : "Devices"}
                                </TableCell>
                                <TableCell align="center">Duration</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {currentData.map((item, index) => (
                                <TableRow
                                    key={index}
                                    className={
                                        index % 2 === 0
                                            ? "tableRowEven"
                                            : "tableRowOdd"
                                    }
                                    onClick={() => {
                                        let path;
                                        if (type === "network") {
                                            path = `/review/network-builds/${item.build_id}`;
                                        } else if (type === "vnetwork") {
                                            path = `/review/virtual-network-builds/${item.build_id}`;
                                        } else if (type === "network-utils") {
                                            path = `/review/network-utils/${item.util_id}`;
                                        } else if (type === "datacenter") {
                                            path = `/review/build/${item.build_id}`;
                                        }
                                        history.push(path, { data: data });
                                    }}
                                >
                                    {type === "network-utils" ? (
                                        <>
                                            <TableCell>
                                                {item?.["util_id"]
                                                    .slice(0, 12)
                                                    .toUpperCase()}
                                            </TableCell>
                                            <TableCell>
                                                {item?.operation || "-"}
                                            </TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>
                                                {item?.["build_id"]
                                                    .slice(0, 12)
                                                    .toUpperCase()}
                                            </TableCell>
                                            <TableCell>
                                                {item?.kit_id || "-"}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell>
                                        {item?.["date"]?.slice(0, 10)} <br />{" "}
                                        {item?.["date"]?.slice(11, 19)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {type === "network"
                                            ? item.serial_devices.length
                                            : type === "vnetwork"
                                            ? item.config.virtual_serial_devices
                                                  .length
                                            : type === "network-utils"
                                            ? item.serial_devices.length
                                            : type === "datacenter"
                                            ? item.config.hosts.length
                                            : "-"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item?.["build_duration"]}
                                    </TableCell>
                                    <TableCell>
                                        {item?.status
                                            .replace(/_/g, " ")
                                            .toUpperCase()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}

                {!hasData && (
                    <Alert severity="warning">No data available.</Alert>
                )}
                <Box my={1}>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={6}
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            <Select
                                value={itemsPerPage}
                                onChange={handleChangeItemsPerPage}
                                size="small"
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Pagination
                                count={maxPage}
                                page={currentPage}
                                onChange={handleChangePage}
                                size="large"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    );
}

export default BuildsDisplay;
