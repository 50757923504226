import React, { useState, useEffect, useRef } from 'react';
import { Dialog, Box, Grid, Button, DialogContent, DialogTitle } from '@mui/material';

const LOGOUT_INTERVAL = 900000; // ms
const WARNING_INTERVAL = 780000;


export const LogoutManager = ({ token, isUploading }) => {
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const countdownRef = useRef((LOGOUT_INTERVAL - WARNING_INTERVAL) / 1000);
    const logoutTimer = useRef(null);
    const warningTimer = useRef(null);


    function resetTimer() {
        if (token.token === "null") {
            return;
        }
        if (warningTimer.current) {
            clearTimeout(warningTimer.current);
        }
        if (logoutTimer.current) {
            clearTimeout(logoutTimer.current);
        }

        // start warning timer
        warningTimer.current = setTimeout(() => {
            setIsWarningVisible(true);

            // start the logout timer when the warning is shown
            logoutTimer.current = setTimeout(() => {
                setIsWarningVisible(false);
                logout();
            }, LOGOUT_INTERVAL - WARNING_INTERVAL);
        }, WARNING_INTERVAL);
    }

    function logout() {
        if (token.token === "null") {
            return;
        }
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("passwordExpires")
        sessionStorage.removeItem("licenses")
        sessionStorage.removeItem("lastLogin")
        sessionStorage.removeItem("lastLoginDisplayed")
        window.location.href = "/auth";
    }


    // detect user activity
    useEffect(() => {
        if (token.token) {
            document.addEventListener("mousemove", resetTimer);
            document.addEventListener("click", resetTimer);
            document.addEventListener("keypress", resetTimer);
            resetTimer();
        }
        return () => {
            // clean up when component unmounts
            if (logoutTimer.current) {
                clearTimeout(logoutTimer.current);
            }
            if (warningTimer.current) {
                clearTimeout(warningTimer.current);
            }
            document.removeEventListener("mousemove", resetTimer);
            document.removeEventListener("click", resetTimer);
            document.removeEventListener("keypress", resetTimer);
        };
    }, []);

    // reset timer if user is uploading file
    useEffect(() => {
        if (isUploading) {
            const id = window.setInterval(() => {
                resetTimer();
            }, 1000);
            return () => window.clearInterval(id);
        }
    }, [isUploading]);

    function Countdown({ intialCount, onFinished }) {
        const [count, setCount] = useState(intialCount);

        useEffect(() => {
            if (count > 0) {
                const timerId = setTimeout(() => {
                    setCount(count - 1);
                }, 1000);
                return () => clearTimeout(timerId);
            } else {
                onFinished();
            }
        }, [count, onFinished]);

        const minutes = Math.floor(count / 60);
        const seconds = count % 60;
        const formatedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;
        return <span>{formatedTime}</span>;
    }

    function WarningModal() {
        return (
            token.token != "null" && (
                <Dialog open={isWarningVisible} onClose={resetTimer}>
                    <Box
                        component={DialogTitle}
                        sx={{
                            textAlign: "center",
                            backgroundColor: "#800002",
                            color: "white",
                        }}
                    >
                        Your session is about to expire
                    </Box>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{ minHeight: "100%" }}
                            >
                                <Grid item sx={{}}>
                                    <Box sx={{ mb: 2 }}>
                                        Your organization's policy enforces automatic signout after
                                        a period of inactivity on web applications.
                                    </Box>
                                    <Box>Do you want to stay signed in?</Box>
                                    <Box sx={{ my: 2, fontSize: "24px", textAlign: "center" }}>
                                        <Countdown
                                            intialCount={countdownRef.current}
                                            onFinished={logout}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Button
                                        style={{
                                            borderColor: "darkgreen",
                                            color: "darkgreen",
                                        }}
                                        variant="outlined"
                                        onClick={() => {
                                            setIsWarningVisible(false);
                                            resetTimer();
                                        }}
                                    >
                                        Stay Logged In
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        style={{
                                            backgroundColor: "#800002",
                                            color: "white",
                                        }}
                                        variant="contained"
                                        onClick={logout}
                                    >
                                        Logout
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            )
        );
    }


    return (
        <>
            {isWarningVisible && <WarningModal />}
        </>
    )
}



export default LogoutManager