import {
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import useStyles from "./styles";
import useGlobalStyle from "../../themes/globalStyle";

const CollectionReview = () => {
    const [collection, setCollection] = useState();
    const token = useContext(Context);
    let { firstReview } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const globalClasses = useGlobalStyle()
    let getCollectionByName = "";
    if (process.env.NODE_ENV === "development") {
        getCollectionByName = "http://localhost:80/api/collections/collection";
    } else if (process.env.NODE_ENV === "production") {
        getCollectionByName = "/api/collections/collection";
    }

    const deleteCollection = () => {
        fetch(getCollectionByName + "/" + firstReview, {
            method: "Delete",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Collection Deleted");
        history.push("/uploadpage/collection");
    };

    useEffect(() => {
        const getCollection = () => {
            fetch(getCollectionByName + "/" + firstReview, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            })
                .then((response) => response.json())
                .then((done) => {
                    setCollection(done);
                })
                .catch((error) => {
                    alert("Error Retrieving Collection", error);
                });
        };
        getCollection();
    }, [getCollectionByName, firstReview, token]);

    const CollectionPlugins = () => {
        return (
            <div>
                {collection?.plugins ? (
                    <Paper className={classes.paper}>
                        <Typography
                            className={globalClasses.paperTitle}
                            variant="h5"
                        >
                            Plugins
                        </Typography>
                        <TableContainer
                            style={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "0",
                                padding: "5px",
                            }}
                            component={Paper}
                        >
                            <Table sx={{ minWidth: 400 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold", fontSize: "1.05rem" }}>
                                            Plugin Name
                                        </TableCell>
                                        <TableCell style={{ fontWeight: "bold", fontSize: "1.05rem" }}>
                                            Description
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {Object.entries(collection.plugins)
                                    .map(([key, value], index) => (
                                        <TableBody key={key}>
                                            <TableRow style={{ padding: "20px" }} key={key} className={index % 2 === 0 ? globalClasses.tableRowEven : globalClasses.tableRowOdd}>
                                                <TableCell
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration: "none",
                                                    }}
                                                    scope="row"
                                                    component={Link}
                                                    to={{
                                                        pathname: `/uploadpage/collection/${collection?.name}/${key.split('.').pop()}`,
                                                    }}
                                                >
                                                    {key}
                                                </TableCell>
                                                <TableCell scope="row">
                                                    {value}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                            </Table>
                        </TableContainer>
                    </Paper>
                ) : (<Typography>None</Typography>)}
            </div>
        )
    }

    return (
        <Container maxWidth={false}>
            <Grid container>
                <Paper className={classes.paper} style={{ backgroundColor: "#f5f5f5" }}>
                    <Typography
                        className={globalClasses.paperTitle}
                        variant="h5"
                    >
                        Collection Review
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} align="center" className={classes.buttonGrid}>
                            <Button
                                onClick={(e) => {
                                    if (
                                        window.confirm(
                                            "Are you sure you wish to delete this collection?"
                                        )
                                    )
                                        deleteCollection(e);
                                }}
                                className={globalClasses.buildButtons}>
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6">
                                    Collection Info
                                </Typography>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>Name: {collection?.name}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Version: {collection?.version}</ListItemText>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <CollectionPlugins />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
};

export default CollectionReview;
