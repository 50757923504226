import { Button, TextField, Box, Paper, Typography, InputAdornment, FormControlLabel, Switch, Checkbox } from "@material-ui/core";
import React, { useState, useContext } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import useStyles from "./styles";
import { Context } from "../../context/Context";

const UpdatePasswordForm = (props) => {
    const displayTitle = props.displayTitle
    const token = useContext(Context);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const classes = useStyles();


    const toggleOldPasswordVisible = () => {
        setOldPasswordVisible(!oldPasswordVisible);
    };
    const toggleNewPasswordVisible = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };
    const toggleConfirmPasswordVisible = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    let baseURL = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://localhost:80/api/users/user/update-password";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "/api/users/user/update-password";
    }

    const changePassword = async () => {
        const data = {
            old_password: oldPassword,
            new_password: newPassword,
        };

        await fetch(baseURL + `/admin`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((error) => {
                        throw new Error(error.detail);
                    })
                }
            })
            .then((data) => {
                alert("Password updated!")
                sessionStorage.clear();
                localStorage.clear();
                window.location.replace("/auth");
            })
            .catch((error) => {
                alert(`Error Updating Password:\n${error.message}`);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        changePassword();
    };

    return (
        <div style={{ alignSelf: "center" }}>
            <Paper className={classes.paper} elevation={6}>
                <Typography
                    component="h1"
                    variant="h5"
                    className={classes.hlabel}
                    style={{ display: displayTitle ? "" : "none" }}>
                    Update Password
                </Typography>
                <Box
                    component="form"
                    className={classes.form}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            label="Current Password"
                            type={oldPasswordVisible ? 'text' : 'password'}
                            onChange={(e) => setOldPassword(e.target.value)}
                            value={oldPassword}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleOldPasswordVisible}
                                        >
                                            {oldPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </div>
                    <div className={classes.inputDivs}>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            label="New Password"
                            type={newPasswordVisible ? 'text' : 'password'}
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleNewPasswordVisible}
                                        >
                                            {newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            label="Confirm Password"
                            type={confirmPasswordVisible ? 'text' : 'password'}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleConfirmPasswordVisible}
                                        >
                                            {confirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </div>
                    <p>
                        Must Contain:
                        <br />
                        Min of 15 characters, 2 capital letter, 2 lowercase, 2 number and 2
                        special character
                    </p>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        disabled={newPassword !== confirmPassword || newPassword === ""}
                    >
                        Update
                    </Button>
                </Box>
            </Paper>
        </div>
    );
};
export default UpdatePasswordForm