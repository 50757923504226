import {
    Container,
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    CircularProgress,
    TablePagination,
} from "@material-ui/core";
import { Context } from "../../context/Context";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import useGlobalStyle from "../../themes/globalStyle";
import { useGetCollections, useDeleteCollection } from "../../queries/CollectionQuery";
import { GetISOs, useDeleteISO } from "../../queries/IsosQuery";
import { GetLicenses, useDeleteLicense } from "../../queries/LicenseQuery";
import { GetOVAs, useDeleteOVA } from "../../queries/OvasQuery";
import { GetTemplates, useDeleteTemplate } from "../../queries/TemplatesQuery";
import { GetPlaybooks, useDeletePlaybook } from "../../queries/PlaybooksQuery";
import { useGetOfflinePackages, useDeleteOfflinePackage } from "../../queries/OfflinePackagesQuery";


const UploadTable = (props) => {
    const token = useContext(Context);
    const globalStyle = useGlobalStyle();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const {data: isoData, isLoading: isoIsLoading} = GetISOs(token)
    const {data: licenseData, isLoading: licenseIsLoading} = GetLicenses(token)
    const {data: ovaData, isLoading: ovaIsLoading} = GetOVAs(token)
    const {data: templateData, isLoading: templateIsLoading} = GetTemplates(token)
    const {data: collectionData, isLoading: collectionIsLoading} = useGetCollections(token)
    const {data: playbookData, isLoading: playbookIsLoading} = GetPlaybooks(token)
    const {data: offlinePackagesData, isLoading: offlinePackageIsLoading} = useGetOfflinePackages(token)

    const { mutate: deleteISOMutate } = useDeleteISO();
    const { mutate: deleteLicenseMutate } = useDeleteLicense();
    const { mutate: deleteOVAMutate } = useDeleteOVA();
    const { mutate: deleteTemplateMutate } = useDeleteTemplate();
    const { mutate: deleteCollectionMutate } = useDeleteCollection();
    const { mutate: deletePlaybookMutate } = useDeletePlaybook();
    const { mutate: deleteOfflinePackageMutate } = useDeleteOfflinePackage();

    const typeToKeys = {
        iso: ["iso_type", "iso_filename", "version"],
        license: ["license_name", "license_type", "license_key"],
        ova: ["name", "filename"],
        template: ["template_name", "file_name", "device_name"],
        collection: ["name", "version"],
        playbook: ["name", "filename"],
        offlinepackages: ["filename"],
    };
    const tLables = {
        iso: ["Type", "Filename", "Version"],
        license: ["Name", "Type", "Key"],
        ova: ["Name", "Filename"],
        template: ["Template Name", "Filename", "Device Name"],
        collection: ["Name", "Version"],
        playbook: ["Name", "Filename"],
        offlinepackages: ["Filename"],
    };

    let deleteIsoUrl = "";
    let deleteOVAURL = "";
    let deleteLicenseURL = "";
    let deleteTemplateURL = "";
    let deleteCollectionURL = "";
    let deletePlaybookURL = "";
    let deleteOfflinePackageURL = "";
    if (process.env.NODE_ENV === "development") {
        deleteIsoUrl = "http://localhost:80/api/isos/iso";
        deleteOVAURL = "http://localhost:80/api/ovas";
        deleteLicenseURL = "http://localhost:80/api/licenses/license";
        deleteTemplateURL = "http://localhost:80/api/serial-devices/template";
        deleteCollectionURL = "http://localhost:80/api/collections/collection";
        deletePlaybookURL = "http://localhost:80/api/playbooks/playbook";
        deleteOfflinePackageURL =
            "http://localhost:80/api/offline-packages/offline-package";
    } else if (process.env.NODE_ENV === "production") {
        deleteIsoUrl = "/api/isos/iso";
        deleteOVAURL = "/api/ovas";
        deleteLicenseURL = "/api/licenses/license";
        deleteTemplateURL = "/api/serial-devices/template";
        deleteCollectionURL = "/api/collections/collection";
        deletePlaybookURL = "/api/playbooks/playbook";
        deleteOfflinePackageURL = "/api/offline-packages/offline-package";
    }


    const handleDelete = (item) => {
        switch (props.type) {
          case "iso":
            deleteISOMutate({
              url: `${deleteIsoUrl}/${item.version}?iso_type=${item.iso_type}`,
              token,
            });
            break;
          case "license":
            deleteLicenseMutate({
              url: `${deleteLicenseURL}/${item.license_name}?license_name=${item.license_name}`,
              token,
            });
            break;
          case "ova":
            deleteOVAMutate({
              url: `${deleteOVAURL}/${item.filename}`,
              token,
            });
            break;
          case "template":
            deleteTemplateMutate({
              url: `${deleteTemplateURL}/${item.template_name}`,
              token,
            });
            break;
          case "collection":
            deleteCollectionMutate({
              url: `${deleteCollectionURL}/${item.name}`,
              token,
            });
            break;
          case "playbook":
            deletePlaybookMutate({
              url: `${deletePlaybookURL}/${item.name}`,
              token,
            });
            break;
          case "offlinepackages":
            deleteOfflinePackageMutate({
              url: `${deleteOfflinePackageURL}/${item.filename}`,
              token,
            });
            break;
          default:
            console.log("props.type not matched");
        }
      };
      
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };

    

    if (isoIsLoading || templateIsLoading || licenseIsLoading || ovaIsLoading || collectionIsLoading || playbookIsLoading || offlinePackageIsLoading) {
        return (
            <div>Loading...</div>
        );
    }

    const typeToData = {
        iso: isoData || [],
        license: licenseData || [],
        ova: ovaData || [],
        template: templateData || [],
        collection: collectionData?.collections || [],
        playbook: playbookData || [], 
        offlinepackages: offlinePackagesData?.offlinePackages || [], 
    };
    
    const keys = typeToKeys[props.type] || [];
    const typeLables = tLables[props.type] || [];
    const data = typeToData[props.type] || [];
    
    const dataItems = Array.isArray(data) ? data : [];
    console.log(dataItems)

    const slicedData = dataItems.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    
    console.log('collection data', collectionData)
    // console.log(offlinePackagesData)
    // console.log('ovaData', ovaData)
    console.log('data', data)

    return (
        <Container maxWidth="false">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="my table" size="small">
                            <TableHead>
                                <TableRow>
                                    {typeLables.map((t) => (
                                        <TableCell key={t}>{t}</TableCell>
                                    ))}
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataItems && dataItems.length > 0 ? (
                                    slicedData.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            className={
                                                index % 2 === 0
                                                    ? globalStyle.tableRowEven
                                                    : globalStyle.tableRowOdd
                                            }
                                        >
                                            {keys.map((key) => (
                                                ["template", "playbook", "collection", "ova", "offlinepackages", "iso"].includes(props.type) ? (
                                                    <TableCell
                                                        className={globalStyle.linkNoUnderline}
                                                        key={`${index}_${key}`}
                                                        scope="row"
                                                        component={Link}
                                                        to={{
                                                            pathname:
                                                                props.type === "template"
                                                                    ? `/uploadpage/template/${item.template_name}`
                                                                    : props.type === "collection"
                                                                    ? `/uploadpage/collection/${item?.name}`
                                                                    : props.type === "playbook"
                                                                    ? `/uploadpage/playbook/${item?.name}`
                                                                    : props.type === "ova"
                                                                    ? `/uploadpage/ova/${item?.name}`
                                                                    : props.type === "iso"
                                                                    ? `/uploadpage/iso/${item?.iso_filename}`
                                                                    : `/uploadpage/offlinepackages/${item?.filename}`,
                                                        }}
                                                    >
                                                        {item[key]}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        key={`${index}_${key}`}
                                                        className={globalStyle.cursorDefault}
                                                    >
                                                        {item[key]}
                                                    </TableCell>
                                                )
                                            ))}
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        if (window.confirm(`Are you sure you wish to delete this ${props.type}?`))
                                                            handleDelete(item);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={keys.length + 1}
                                            style={{ textAlign: "center" }}
                                        >
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={dataItems.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UploadTable;
