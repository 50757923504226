import React, { useEffect, useState } from 'react';

const LastLogin = () => {
    const [show, setShow] = useState(true);
    const lastLoginTime = sessionStorage.getItem("lastLogin")
    const lastLoginDisplayed = sessionStorage.getItem("lastLoginDisplayed");


    const formatDateTime = (isoString) => {
        const utcString = isoString.endsWith('Z') ? isoString : isoString + 'Z';
        const date = new Date(utcString);
        return date.toLocaleString();
    }


  useEffect(() => {
    if (lastLoginDisplayed === 'false') {
        const timer = setTimeout(() => {
          setShow(false);
          sessionStorage.setItem("lastLoginDisplayed", 'true')
        }, 30000); // 30 seconds
        return () => clearTimeout(timer);
    } else {
      setShow(false); // Don't show if already displayed
    }
  }, [lastLoginDisplayed]);



  if (!show) {
    return null;
  }

  return (
    lastLoginTime && (
        <div style={{ 
            color: "grey", 
            textAlign: "center", 
            padding: "5px",
            paddingLeft: "32px",
            fontWeight: "normal",
            fontSize: "small", 
        }}>
        {`Last Login: ${formatDateTime(lastLoginTime)}`}
        </div>
    )
  );
};

export default LastLogin;
