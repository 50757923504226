import { Typography, CssBaseline, Button } from "@material-ui/core";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { theme, useStyle } from './shutdownStyle'
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";

const ApplianceShutdown = (props) => {
    const classes = useStyle();
    const [timeoutComplete, setTimeoutComplete] = useState(false)
    const [retry, setRetry] = useState(false)
    const [stopSpinning, setStopSpinning] = useState(false)
    const queryParameters = new URLSearchParams(window.location.search)
    const method = queryParameters.get("method")

    const loadImg = () => {
        let imgsrc = `/images/rd.png?dummy=${Date.now()}`
        return (
            <img
                style={{ display: 'none' }}
                src={imgsrc}
                alt="dummy to test loading"
                onLoad={() => {
                    window.location.replace("/auth");
                }}
                onError={() => {
                    if (method === "Shutting_Down") {
                        setStopSpinning(true);
                    } else {
                        setTimeoutComplete(false);
                        setRetry(!retry);
                    }

                }}
            />
        )
    }
    useEffect(() => {
        setTimeout(() => {
            setTimeoutComplete(true)
        }, 20000);
    }, [retry]);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{
                marginLeft: '20px',
                display: 'block',
            }}>
                {timeoutComplete ? (
                    loadImg()
                ) : ""}
                <div className={classes.innerDivs}>
                    <Typography
                        component="h1"
                        variant="h5"
                    >
                        {stopSpinning ? "MANTLE is Shut Down" : `MANTLE is ${method.replace(/_/g, " ")}`}
                    </Typography>
                </div>
                <div className={classes.innerDivs}>
                    <CircularProgress
                        size="3rem"
                        sx={{
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        style={{ display: stopSpinning ? "none" : "" }}
                    />
                </div>
            </div>
        </ThemeProvider >
    );
};

export default ApplianceShutdown;
