import {
	Card,
	Container,
	Fab,
	IconButton,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import useStyles from "./styles";

const Host = ({ register, info, controls, showEXSiAddButton, showStandaloneHost }) => {
	const classes = useStyles();

	const { fields, append, remove } = useFieldArray({
		control: controls,
		name: "hosts",

	});

	const handleAddFields = () => {
		append({ ip: "", hostname: "", pw: "", user: "" });
		fields.length = fields.length + 1;
	};

	const handleDelete = (index) => {
		remove(index)
	}

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));

	useEffect(() => {
		if (showStandaloneHost) {
			for (let i = fields.length - 1; i > 0; i--) {
				console.log(i)
				remove(i)
			}
		}
	}, [showStandaloneHost]);

	return (
		<Container component="main" maxWidth="xl">
			<Paper
				style={{ display: "flex", flexDirection: "column" }}
				className={classes.paper}
				elevation={6}
			>
				<Typography component="h1" variant="h5">
					ESXi Host
				</Typography>

				{!showEXSiAddButton ? (
					<Fab
						variant="extended"
						onClick={handleAddFields}
						id="addEXSiHost"
						style={
							window.location.pathname != "/virtualnetwork"
								? { marginTop: 15 }
								: { display: "none" }
						}
					>
						<AddIcon />
						ESXI Host
					</Fab>
				) : (
					<Fab
						variant="extended"
						onClick={handleAddFields}
						id="addEXSiHost"
						style={{
							display: "none",
						}}
					>
						<AddIcon />
						ESXI Host
					</Fab>
				)}

				{/* <Fab
					variant="extended"
					onClick={handleAddFields}
					id="addEXSiHost"
					style={
						window.location.pathname != "/virtualnetwork"
							? { marginTop: 15 }
							: { display: "none" }
					}
				>
					<AddIcon />
					ESXI Host
				</Fab> */}

				<form autoComplete="off" className={classes.form}>
					{fields?.map((item, index) => (
						<div key={item.id}>
							<Card className={classes.card} spacing={2}>
								<Alerttip title="ESXi Management IP Address for host (192.168.1.10)">
									<TextField
										variant="outlined"
										style={{ margin: "20px" }}
										label="Host IP"
										{...register(`hosts.${index}.ip`)}
										defaultValue={
											info?.hosts[index]?.ip || ""
										}
										autoComplete="false"
									/>
								</Alerttip>

								<Alerttip title="Hostname or FQDN for ESXi host (example: esxi1.nextechsol.com)">
									<TextField
										variant="outlined"
										style={{ margin: "20px" }}
										label="Host Name"
										{...register(`hosts.${index}.hostname`)}
										defaultValue={
											info?.hosts[index]?.hostname || ""
										}
										autoComplete="false"
									/>
								</Alerttip>
								<Alerttip title="Username for ESXi host (example: root)">
									<TextField
										variant="outlined"
										style={{ margin: "20px" }}
										name={`hosts.${index}.user`}
										label="User"
										{...register(`hosts.${index}.user`)}
										defaultValue={
											info?.hosts[index]?.user || "root"
										}
										autoComplete="false"
									/>
								</Alerttip>
								<Alerttip title="Password for ESXi host (example: P@55w0rd)">
									<TextField
										variant="outlined"
										style={{ margin: "20px" }}
										label="Password"
										type="password"
										{...register(`hosts.${index}.pw`)}
										defaultValue={
											info?.hosts[index]?.pw || ""
										}
										autoComplete="false"
									/>
								</Alerttip>
								{fields.length > 1 ?
								<Alerttip title="Remove Host">
									<IconButton size="small" style={{ display: "flex", marginLeft: "40px", marginTop: "10px" }}>
											<DeleteIcon
												sx={{ color: "#D3D3D3" }}
												onClick={() => {
													handleDelete(index);
												}}
											>
												Delete
											</DeleteIcon>
									</IconButton>
								</Alerttip>
								: ""}
							</Card>
						</div>
					))}
				</form>
			</Paper>
		</Container>
	);
};

export default Host;
