import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    
    button: {
      backgroundColor: '#EA2331',
      color: '#333333',
        "&:hover":{
          backgroundColor: '#333333',
          color: '#EA2331',
        }
    },

    buildbtn: {
      margin:'30px 0',
      padding:"10px",
      width: '10vw',
      backgroundColor: '#EA2331',
      color: '#333333',
      
        "&:hover":{
          backgroundColor: '#333333',
          color: '#EA2331',
        }
    },

    text:{
      '@media (max-width: 450px)' : {
        fontSize:".75rem"
      }
    },

    card: {
      display: 'flex',
      justifyContent: 'left'
    },

    kitID:{
      alignSelf:"center",
      width: "20%",
      padding:"1%",
      margin: "5px 0"
    }

   
   
  }));