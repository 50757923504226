import {
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import useStyles from "./styles";

const VSwitchVmks = ({ control, info, register, setValue }) => {
  const [uplink, setUplink] = useState([]);
  const classes = useStyles();
  const vmnic = [
    "vmnic0",
    "vmnic1",
    "vmnic2",
    "vmnic3",
    "vmnic4",
    "vmnic5",
    "vmnic6",
    "vmnic7",
    "vmnic8",
    "vusb0",
    "vusb1"
  ];

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={6}>
        <Typography
          style={{ marginBottom: "20px" }}
          component="h1"
          variant="h5"
        >
          vSwitch0 Settings
        </Typography>

        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginLeft: "65px",
            alignContent: "center",
          }}
        >
          <Alerttip title="Physical Network Adapter for ESXi Primary Network Connectivity">
            <InputLabel
              style={{
                position: "relative",
                top: "10px",
                color: "black",
                display: "flex",
                margin: "10px 20px",
              }}
            >
              Standard Uplink
            </InputLabel>
          </Alerttip>

          <Controller
            style={{ marginLeft: "45px" }}
            control={control}
            name="vswitch0_standard_uplink"
            defaultValue={info?.vswitch0_standard_uplink || ""}
            render={({ field }) => (
              <Select {...field}>
                {vmnic?.map((v) => (
                  <MenuItem onChange={field.onChange} key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginLeft: "65px",
            alignContent: "center",
          }}
        >
          <Alerttip title="Additional Network Adapters to be added to the ESXi vSwitch">
            <InputLabel
              style={{
                position: "relative",
                top: "10px",
                color: "black",
                display: "flex",
                margin: "20px 20px",
              }}
              id="uplink"
            >
              Additional vSwitch Uplinks
            </InputLabel>
          </Alerttip>
            <Controller
              style={{ marginLeft: "45px" }}
              control={control}
              name="additional_vswitch0_uplinks"
              defaultValue={info?.additional_vswitch0_uplinks}
              render={({ field }) => (
                <Select
                  multiple 
                  value = {info?.additional_vswitch0_uplinks}
                  {...field}
                >
                  {vmnic?.map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
        </Grid>
        <div style={{ marginLeft: "50px", marginTop: "25px" }}>
          <Alerttip title="Would you like a custom default Port Group name in vSphere? Enter the name here, if so">
            <TextField
              style={{ marginRight: "10px" }}
              variant="outlined"
              label="VM Port Group"
              {...register("vm_portgroup")}
              defaultValue={info?.vm_portgroup || "VM Network"}
              autoComplete="false"
            />
          </Alerttip>
          <Alerttip title="VLAN Tagging for VM Portgroup. Leave blank for no VLAN tagging.">
            <TextField
              variant="outlined"
              label="VLAN ID"
              {...register("vm_portgroup_vlan")}
              defaultValue={info?.vm_portgroup_vlan || ""}
              autoComplete="false"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">VLAN</InputAdornment>
                ),
              }}
            />
          </Alerttip>
        </div>
      </Paper>
    </Container>
  );
};

export default VSwitchVmks;
