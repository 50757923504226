import React from "react";
import { Controller } from "react-hook-form";
import {
	Paper,
	Grid,
	Typography,
	Container,
	InputLabel,
	TextField,
	Select,
	MenuItem,
} from "@material-ui/core";
import useStyles from "./styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const VSANSettings = ({
	register,
	info,
	vsanLicense,
	control,
	hostsLength,
}) => {
	const classes = useStyles();

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));

	const license = [];
	vsanLicense?.map((l) => license.push(l.license_key));
	license.push(info?.vsan_license);

	return (
		<Container component="main" maxWidth="xl">
			<Paper className={classes.paper} elevation={6}>
				<Typography component="h1" variant="h5">
					VSAN Settings {hostsLength}
				</Typography>

				<Grid
					style={{
						display: "flex",
						alignItems: "center",
						margin: "30px 0",
					}}
				>
					<Alerttip title="Enter the VMKernal Adapter Number that vSAN service will be running on (example: vmk0)">
						<TextField
							variant="outlined"
							label="vmk"
							{...register("vsan_vmknic")}
							defaultValue={info?.vsan_vmknic || ""}
						/>
					</Alerttip>
				</Grid>
				<Grid
					style={{
						display: "flex",
						alignItems: "center",
					}}
				></Grid>
				<div>
					<Alerttip title="Enter the Name of the Desired vSAN Datastore (example: nts-vsanDatastore)">
						<TextField
							variant="outlined"
							label="Datastore Name"
							{...register("vcsa_datastore_name")}
							defaultValue={info?.vcsa_datastore_name || ""}
						/>
					</Alerttip>
				</div>
				<Grid style={{ marginTop: "20px", display: "flex" }}>
					<Alerttip title="Select the Correct vSAN License to be Installed During the Build. If you do not see a License, Please Upload one in the “Uploads” page">
						<InputLabel
							style={{
								color: "black",
								display: "flex",
								margin: "20px 20px",
							}}
						>
							Select License
						</InputLabel>
					</Alerttip>
					<Controller
						control={control}
						name="vsan_license"
						defaultValue={info?.vsan_license || ""}
						render={({ field }) => (
							<Select {...field}>
								{vsanLicense?.map((l) => (
									<MenuItem
										onChange={field.onChange}
										key={l.license_key}
										value={l.license_key}
									>
										{l.license_name} - ({l.license_key})
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</Grid>
			</Paper>
		</Container>
	);
};

export default VSANSettings;
