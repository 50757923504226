import { useEffect } from "react"
import React from "react";
import {
	Paper,
	Typography
} from "@material-ui/core";
import ClearDatabase from "../clearDatabase/ClearDatabase";
import UploadKeygen from "../uploadKeygen/UploadKeygen";
import BuildInterface from "./BuildInterface";
import CreatePorts from "./CreatePorts";
import { ClassificationSettings } from "./ClassificationSettings";
import UpdatePasswordForm from "../auth/UpdatePasswordForm";
import UserSettingsForm from "../auth/UserSettingsForm";
import useStyles from "./style";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ApplianceSettings = () => {
	const classes = useStyles();
	const location = useLocation()

	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.replace("#", ""));
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [location])

	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				margin: "30px 0",
				overflowX: "hidden",
			}}
		>
			<Paper className={classes.paper} elevation={6}>
				<Typography
					component="h1"
					variant="h5"
					style={{ marginBottom: "15px" }}
				>
					Update Password
				</Typography>
				<UpdatePasswordForm displayTitle={false} />
			</Paper>
			<Paper className={classes.paper} elevation={6}>
				<Typography
					component="h1"
					variant="h5"
					style={{ marginBottom: "15px" }}
				>
					User Settings
				</Typography>
				<UserSettingsForm />
			</Paper>
			<div id='createPorts'>
				<CreatePorts />
			</div>
			<div id='buildInterface'>
				<BuildInterface />
			</div>
			<div id='uploadKeygen'>
				<UploadKeygen />
			</div>
			<div id='clearDatabase'>
				<ClearDatabase />
			</div>
			<div id='classificationSettings'>
				<ClassificationSettings />
			</div>
		</div>
	);
};

export default ApplianceSettings;
