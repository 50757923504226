import {
  Button,
  Card,
  Container,
  Fab,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Context } from "../../../context/Context";
import NestedHdds from "./NestedHdds";
import NestedNetworks from "./NestedNetworks";
import Ovas from "./Ovas";
import useStyles from "./styles";
import { GetOVAs } from "../../../queries/OvasQuery";
import { GetISOs } from "../../../queries/IsosQuery";

const PostInstall = ({ info, register, controls, getValues, unregister }) => {
  const token = useContext(Context);
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control: controls,
    name: "vms",
  });

  const handleAddFields = () => {
    append({
      name: "",
      ip: "",
      ram_in_gb: "",
      cpu_cores: "",
      datastore_iso_path: "",
      ovas: "",
      hdds: [],
      networks: [],
    });
  };

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  const {
    data: isoVersion,
    error: isoError,
    isLoading: isoLoading,
} = GetISOs(token);
const {
    data: allOvas,
    error: ovaError,
    isLoading: ovaLoading,
} = GetOVAs(token);

if (isoLoading || ovaLoading) return <div>Loading...</div>;
if (isoError)
    return <div>An error occurred retrieving ISOs: {isoError.message}</div>;
if (ovaError)
    return <div>An error occurred retrieving OVAs: {ovaError.message}</div>;

  const handleDelete = (index) => {
    remove(index)
  }


  const uniqesxi = [];

  isoVersion?.map((iso) => uniqesxi.push(iso.iso_filename));

  info?.vms?.map((i) => uniqesxi.push(i.datastore_iso_path));

  const isoPath = [...new Set(uniqesxi)];

  const uniqueova = [];

  allOvas?.map((ova) => {
    const port_maps = []
    ova.network_interfaces.map((int) => {
      port_maps.push({ "interface": int, "port_group": "" })
    })
    uniqueova.push(
      { "filename": ova.filename, "port_mapping": port_maps }
    )
  }) // creates list of relevant data to map through for ova menu

  const ovaFile = [...new Set(uniqueova)];

  return (
    <div>
      <Container component="main" maxWidth="xl">
        <Paper
          style={{ display: "flex", flexDirection: "column" }}
          className={classes.paper}
          elevation={6}
        >
          <Typography component="h1" variant="h5">
            VM
          </Typography>
          <Fab
            variant="extended"
            style={{ marginTop: "15px" }}
            onClick={handleAddFields}
          >
            <AddIcon />
            VM
          </Fab>
          <form autoComplete="off" className={classes.form}>
            {fields.map((item, index) => (
              <div key={item.id}>
                <Card
                  name="vms"
                  className={classes.card}
                  style={{ marginBottom: "20px" }}
                  spacing={2}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Alerttip title="Enter the Name of the Virtual Machine to be Created (example: NTS-DNS-01)">
                      <TextField
                        variant="outlined"
                        style={{ margin: "20px" }}
                        label="Name"
                        {...register(`vms[${index}].name`)}
                        defaultValue={info?.vms && info?.vms[index]?.name ? info?.vms[index]?.name : ""}
                        autoComplete="false"
                      />
                    </Alerttip>
                    <Alerttip title="Enter the IP Address for the Virtual Machine (example: 192.168.1.100)">
                      <TextField
                        variant="outlined"
                        style={{ margin: "20px" }}
                        label="IP"
                        {...register(`vms[${index}].ip`)}
                        defaultValue={info?.vms && info?.vms[index]?.ip ? info?.vms[index]?.ip : ""}
                        autoComplete="false"
                      />
                    </Alerttip>

                    <Alerttip title="Enter the Amount of Memory for the Virtual Machine Template in Gigabytes (example: 16)">
                      <TextField
                        variant="outlined"
                        style={{ margin: "20px" }}
                        label="RAM"
                        type="number"
                        {...register(`vms[${index}].ram_in_gb`)}
                        defaultValue={info?.vms && info?.vms[index]?.ram_in_gb ? info?.vms[index]?.ram_in_gb : ""}
                        autoComplete="false"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">GB</InputAdornment>
                          ),
                        }}
                      />
                    </Alerttip>
                    <Alerttip title="Enter the Number of CPU cores for the Virtual Machine Template (example: 4)">
                      <TextField
                        variant="outlined"
                        style={{ margin: "20px" }}
                        label="CPU Cores"
                        type="number"
                        {...register(`vms[${index}].cpu_cores`)}
                        defaultValue={info?.vms && info?.vms[index]?.cpu_cores ? info?.vms[index]?.cpu_cores : ""}
                        autoComplete="false"
                      />
                    </Alerttip>
                    <div style={{ display: "flex" }}>
                      <Alerttip title="Enter the Path to the ISO that will be assigned to the Virtual Machine Template.">
                        <InputLabel
                          style={{
                            color: "black",
                            display: "flex",
                            marginTop: "40px",
                            marginRight: "25px",
                          }}
                        >
                          ISO Path:
                        </InputLabel>
                      </Alerttip>
                      <Controller
                        style={{ marginLeft: "45px" }}
                        control={controls}
                        name={`vms[${index}].datastore_iso_path`}
                        defaultValue={info?.vms && info?.vms[index]?.datastore_iso_path ? info?.vms[index]?.datastore_iso_path : ""}
                        render={({ field }) => (
                          <Select
                            className={classes.select}
                            {...field}
                          >
                            {isoPath?.map((iso) => (
                              <MenuItem
                                onChange={field.onChange}
                                key={iso}
                                value={iso}
                              >
                                {iso}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                  <NestedHdds
                    nestedIndex={index}
                    info={info}
                    register={register}
                    controls={controls}
                    getValues={getValues}

                  />
                  <NestedNetworks
                    nestedIndex={index}
                    info={info}
                    register={register}
                    controls={controls}
                    getValues={getValues}
                  />
                  <Alerttip title="Remove VM">
                    <IconButton size="small" style={{ display: "flex", marginLeft: "40px", marginTop: "10px" }}>
                      <DeleteIcon
                        sx={{ color: "#D3D3D3" }}
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        Delete
                      </DeleteIcon>
                    </IconButton>
                  </Alerttip>
                </Card>
              </div>
            ))}
          </form>
        </Paper>
      </Container>
      <Ovas
        info={info}
        register={register}
        ovaFile={ovaFile}
        classes={classes}
        controls={controls}
        getValues={getValues}
        unregister={unregister}
      />

    </div>
  );
};

export default PostInstall;
