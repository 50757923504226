import { Box, Button, InputAdornment, Paper, TextField, Typography } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import React, { useState } from "react";
import { useSystemSettingsContext } from "../../context/SystemSettings";
import bg from "../../images/mantleBG.jpg";
import DodBanner from "../dodBanner/DodBanner";
import AuthFooter from "./AuthFooter";
import useStyles from "./styles";

const Auth = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const classes = useStyles();
	const { systemSettings } = useSystemSettingsContext();

	const togglePasswordVisible = () => {
		setPasswordVisible(!passwordVisible);
	};
	let baseURL = "";
	if (process.env.NODE_ENV === "development") {
		baseURL = "http://localhost:80/api/auth/token";
	} else if (process.env.NODE_ENV === "production") {
		baseURL = "/api/auth/token";
	}

	const submitLogin = async () => {
		const loginReq = {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=NTS`,
		};

		const loginRes = await fetch(baseURL, loginReq);
		const data = await loginRes.json();

		if (!loginRes.ok) {
			alert(data.detail);
		} else {
			sessionStorage.setItem("token", data.access_token);
			sessionStorage.setItem("lastLogin", data.last_login);
			sessionStorage.setItem("lastLoginDisplayed", 'false');
			if (data.change_default_password) {
				alert("Please change your default password now");
				window.location.replace("/update-password")
			} else if (!data.password_expires) {
				window.location.replace("/")
			} else if (data.password_expired) {
				alert("Your password has expired. Please change your password..")
				window.location.replace("/update-password")
			} else {
				window.location.replace("/");
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		submitLogin();
	};

	const formControlLabelStyles = {
		fontSize: '10px'
	}

	return (
		<div
			style={{
				backgroundImage: `url(${bg})`,
				width: "100vw",
				backgroundRepeat: "no-repeat",
				height: "100vh",
				backgroundSize: "cover",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<div style={{ alignSelf: "center" }}>
				{systemSettings.classification !== 'none' && <DodBanner />}

				<Paper className={classes.paper} elevation={6}>
					<Typography component="h1" variant="h5" className={classes.hlabel}>
						Login
					</Typography>
					<Box
						component="form"
						className={classes.form}
						// autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div>
							<TextField
								variant="outlined"
								className={classes.input}
								type="text"
								label="Username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
						</div>
						<div>
							<TextField
								variant="outlined"
								className={classes.input}
								label="Password"
								type={passwordVisible ? 'text' : 'password'}
								onChange={(e) => setPassword(e.target.value)}
								value={password}
								InputProps={{
									endAdornment:
										<InputAdornment position="end">
											<IconButton
												onClick={togglePasswordVisible}
											>
												{passwordVisible ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
								}}
							// endAdornment={

							// }
							/>
						</div>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							className={classes.submit}
							disabled={!username || !password}
						>
							Login
						</Button>
					</Box>
				</Paper>

			</div>
			<AuthFooter />
		</div>
	);
};

export default Auth;
