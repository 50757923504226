import {
    Button,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Context } from "../../context/Context";
import { useSystemSettingsContext } from "../../context/SystemSettings";
import useStyles from "./style";
import { GetLicenses } from "../../queries/LicenseQuery";
import { GetISOs } from "../../queries/IsosQuery";

const UploadCSV = () => {
    const classes = useStyles();
    const token = useContext(Context);
    const history = useHistory();
    const [file, setFile] = useState("");
    const [cutsheetInfo, setCutsheetInfo] = useState("");
    const [kitType, setKitType] = useState("");
    const [esxiVer, setEsxiVer] = useState("");
    const [vcsaVer, setVcsaVer] = useState("");
    const [isoName, setIsoName] = useState("");

    const [selEsxiLicense, setSelEsxiLicense] = useState("");
    const [selVcsaLicense, setSelVcsaLicense] = useState("");
    const [selVsanLicense, setSelVsanLicense] = useState("");

    const [ELUsername, setELUsername] = useState("");
    const [ELPassword, setELPassword] = useState("");

    const [passwordVisible, setPasswordVisible] = useState("");

    const { systemState, systemFunctions } = useSystemSettingsContext();

    const togglePasswordVisible = () => {
        setPasswordVisible(!passwordVisible);
    };

    let baseISOURL = "";
    let baseURL = "";
    let postCutsheet = "";
    if (process.env.NODE_ENV === "development") {
        baseISOURL = "http://localhost:80/api/isos/isos";
        postCutsheet =
            "http://localhost:80/api/taclan-cutsheet-to-config/upload-cutsheet";
        baseURL = "http://localhost:80/api/build/validate-workflow";
    } else if (process.env.NODE_ENV === "production") {
        baseISOURL = "/api/isos/isos";
        postCutsheet = "/api/taclan-cutsheet-to-config/upload-cutsheet";
        baseURL = "/api/build/validate-workflow";
    }

    const esxi_isos = [];
    const vcsa_isos = [];
    const vm_isos = [];
    const { data: isoVersion } = GetISOs(token);
    isoVersion?.map((i) =>
        i.iso_type === "esxi"
            ? esxi_isos.push(i)
            : i.iso_type === "vcsa"
            ? vcsa_isos.push(i)
            : vm_isos.push(i)
    );
    // setEsxiIsoVersion(esxi_isos);
    // setVcsaIsoVersion(vcsa_isos);
    // setVmIsoVersion(vm_isos);

    const esxi_licenses = [];
    const vcsa_licenses = [];
    const vsan_licenses = [];
    const { data: licenses } = GetLicenses(token);

    licenses?.map((l) =>
        l.license_type === "esxi"
            ? esxi_licenses.push(l)
            : l.license_type === "vsan"
            ? vsan_licenses.push(l)
            : vcsa_licenses.push(l)
    );
    // setEsxiLicenses(esxi_licenses);
    // setVcsaLicenses(vcsa_licenses);
    // setVsanLicenses(vsan_licenses);

    const uniqesxi = [...new Set(esxi_isos)];
    const uniqvcsa = [...new Set(vcsa_isos)];
    const uniqvm = [...new Set(vm_isos)];

    const uniqEsxiLicenses = [...new Set(esxi_licenses)];
    const uniqVcsaLicenses = [...new Set(vcsa_licenses)];
    const uniqVsanLicenses = [...new Set(vsan_licenses)];

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const sendData = new FormData();
        sendData.append("cutsheet", file, file?.name);
        if (kitType === "el8000") {
            let deviceConfig = JSON.stringify({
                username: ELUsername,
                password: ELPassword,
            });
            sendData.append("device_config", deviceConfig);
        } else {
            sendData.append("device_config", JSON.stringify({}));
        }
        axios
            .post(
                postCutsheet +
                    `?esxi_license=${selEsxiLicense}` +
                    `&vcsa_license=${selVcsaLicense}` +
                    `&vsan_license=${selVsanLicense}` +
                    `&esxi_ver=${esxiVer}` +
                    `&vcsa_ver=${vcsaVer}` +
                    `&iso_name=${isoName}` +
                    `&kit_type=${kitType || "medium"}`,
                sendData,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${token.token}`,
                    },

                    body: `cutsheet=@${file.name};type=${file.type}`,
                }
            )
            .then((res) => {
                setCutsheetInfo(res.data.Result);
                // console.log(res.data.Result);
                systemFunctions.getLicense();
            })
            .catch((error) => {
                console.error("error uploading cutsheet: ", error);
                alert("Error uploading cutsheet\n" + error);
            });
    };

    const saveData = async (data) => {
        await fetch(baseURL, {
            method: "POST",
            body: JSON.stringify(cutsheetInfo),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                history.push({
                    pathname: "/track-build",
                    state: {
                        data: cutsheetInfo,
                        resdata: resdata.Result,
                        buildType: "build",
                    },
                });
            })
            .catch((error) => {
                alert("Error Deploying Build", error);
            });
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Paper
                className={classes.paper}
                elevation={6}
                style={{ width: "60%", height: "inherit", marginTop: "60px" }}
            >
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: "Montserrat",
                        marginTop: "15px",
                        borderBottom: "1px solid",
                    }}
                >
                    Import Cutsheet
                </Typography>
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: "15px",
                        minWidth: "500px",
                        maxWidth: "775px",
                    }}
                >
                    <Grid item md={6} style={{ textAlign: "right" }}>
                        <div className={classes.fileInput}>
                            <input
                                id={"csvFileInput"}
                                type={"file"}
                                required
                                onChange={handleOnChange}
                                style={{
                                    marginLeft: "-120px",
                                    marginTop: "-5px",
                                    marginRight: "0",
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid
                        item
                        md={6}
                        style={{ marginBottom: "10px", textAlign: "left" }}
                    >
                        <div
                            className={classes.typeSelect}
                            style={{ marginTop: "5px" }}
                        >
                            <InputLabel
                                style={{
                                    color: "black",
                                    alignSelf: "center",
                                    display: "inline",
                                }}
                            >
                                Kit Type:
                            </InputLabel>
                            <Select
                                className={classes.typeSelect}
                                defaultValue="medium"
                                required
                                style={{
                                    marginLeft: "5px",
                                }}
                                onChange={(e) => setKitType(e.target.value)}
                            >
                                <MenuItem value="lite">DTECH Lite</MenuItem>
                                <MenuItem value="medium">DTECH Medium</MenuItem>
                                <MenuItem value="heavy">DTECH Heavy</MenuItem>
                                <MenuItem value="el8000">HPE EL8000</MenuItem>
                                <MenuItem value="klas_lite">KLAS Lite</MenuItem>
                            </Select>
                        </div>
                        {kitType === "el8000" && (
                            <div style={{ marginTop: "5px", width: "200px" }}>
                                <TextField
                                    style={{ width: "100%" }}
                                    className={classes.textField}
                                    label="Username"
                                    onChange={(e) =>
                                        setELUsername(e.target.value)
                                    }
                                ></TextField>
                                <TextField
                                    style={{ width: "100%" }}
                                    type={passwordVisible ? "text" : "password"}
                                    className={classes.textField}
                                    label="Password"
                                    onChange={(e) =>
                                        setELPassword(e.target.value)
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        togglePasswordVisible
                                                    }
                                                >
                                                    {passwordVisible ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                ></TextField>
                            </div>
                        )}
                    </Grid>
                    <Grid item md={4} style={{ textAlign: "center" }}>
                        <InputLabel
                            style={{
                                color: "black",
                                display: "inline",
                                margin: "20px 20px",
                            }}
                        >
                            ESXI Version
                        </InputLabel>
                        <Select
                            onChange={(e) => setEsxiVer(e.target.value)}
                            className={classes.select}
                            defaultValue={""}
                        >
                            {uniqesxi?.map((iso) => (
                                <MenuItem key={iso.version} value={iso.version}>
                                    {iso.version}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {kitType !== "lite" && kitType !== "klas_lite" ? (
                        <>
                            <Grid item md={4} style={{ textAlign: "center" }}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        display: "inline",
                                        margin: "20px 20px",
                                    }}
                                >
                                    VCSA Version
                                </InputLabel>
                                <Select
                                    onChange={(e) => setVcsaVer(e.target.value)}
                                    className={classes.select}
                                    defaultValue={""}
                                >
                                    {uniqvcsa?.map((iso) => (
                                        <MenuItem
                                            key={iso.version}
                                            value={iso.version}
                                        >
                                            {iso.version}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item md={4} style={{ textAlign: "center" }}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        display: "inline",
                                        margin: "20px 20px",
                                    }}
                                >
                                    VCSA License
                                </InputLabel>
                                <Select
                                    onChange={(e) =>
                                        setSelVcsaLicense(e.target.value)
                                    }
                                    className={classes.select}
                                    defaultValue={""}
                                >
                                    {uniqVcsaLicenses?.map((lic) => (
                                        <MenuItem
                                            key={lic.license_name}
                                            value={lic.license_key}
                                        >
                                            {lic.license_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item md={4} style={{ textAlign: "center" }}>
                                <InputLabel
                                    style={{
                                        color: "black",
                                        display: "inline",
                                        margin: "20px 20px",
                                    }}
                                >
                                    VSAN License
                                </InputLabel>
                                <Select
                                    onChange={(e) =>
                                        setSelVsanLicense(e.target.value)
                                    }
                                    className={classes.select}
                                    defaultValue={""}
                                >
                                    {uniqVsanLicenses?.map((lic) => (
                                        <MenuItem
                                            key={lic.license_name}
                                            value={lic.license_key}
                                        >
                                            {lic.license_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item md={4} style={{ textAlign: "center" }}>
                        <InputLabel
                            style={{
                                color: "black",
                                display: "inline",
                                margin: "20px 20px",
                            }}
                        >
                            VM Version
                        </InputLabel>
                        <Select
                            onChange={(e) => setIsoName(e.target.value)}
                            className={classes.select}
                            defaultValue={""}
                        >
                            {uniqvm?.map((iso) => (
                                <MenuItem
                                    key={iso.version}
                                    value={iso.iso_filename}
                                >
                                    {iso.version}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={4} style={{ textAlign: "center" }}>
                        <InputLabel
                            style={{
                                color: "black",
                                display: "inline",
                                margin: "20px 20px",
                            }}
                        >
                            ESXI License
                        </InputLabel>
                        <Select
                            onChange={(e) => setSelEsxiLicense(e.target.value)}
                            className={classes.select}
                            defaultValue={""}
                        >
                            {uniqEsxiLicenses?.map((lic) => (
                                <MenuItem
                                    key={lic.license_name}
                                    value={lic.license_key}
                                >
                                    {lic.license_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                {file !== "" ? (
                    <Button
                        onClick={(e) => {
                            handleOnSubmit(e);
                        }}
                        className={classes.button}
                        style={{
                            marginTop: "30px",
                            display: "inline",
                            fontWeight: "bolder",
                            fontFamily: "Montserrat",
                            letterSpacing: "3px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        fullWidth
                    >
                        Upload
                    </Button>
                ) : null}

                {cutsheetInfo !== "" ? (
                    <Button
                        onClick={saveData}
                        className={classes.button}
                        style={{
                            display: "inline",
                            fontWeight: "bolder",
                            fontFamily: "Montserrat",
                            letterSpacing: "3px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        fullWidth
                    >
                        Continue
                    </Button>
                ) : null}
            </Paper>
        </div>
    );
};

export default UploadCSV;
