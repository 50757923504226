const Reducer = (state, action)=>{
    switch(action.type){
        case "LOGIN_START":
            return{
                token:"",
                isFetching:true,
                error:false
            };
        case "LOGIN_SUCCESS":
            return{
                token:action.payload,
                isFetching:false,
                error:false
            };
        case "LOGIN_FAILURE":
            return{
                token:"",
                isFetching:false,
                error:true,
            };
    
        case "LOGOUT":
            return{
                token:"",
                isFetching:false,
                error:false,
            };
        case "UPDATE_START":
            return {
                ...state,
                isFetching:true
            };
            case "UPDATE_SUCCESS":
            return {
                user: action.payload,
                isFetching: false,
                error: false,
            };
            case "UPDATE_FAILURE":
            return {
                user: state.user,
                isFetching: false,
                error: true,
            };
        default:
            return state;

    }
};

export default Reducer