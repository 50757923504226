import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

// Light Theme Colors
const primaryColorLight = "#b71c1c"; // Light version of rich dark red
const secondaryColorLight = "#f5f5f5"; // Light version of medium dark gray
const hightlightColorLight = "#e0e0e0"; // Light version of medium dark gray
const errorColorLight = "#e57373"; // Light version of bright red

const warningColorLight = "#ffb74d"; // Light version of orange for warnings
const infoColorLight = "#64b5f6"; // Light version of light blue for informational messages
const successColorLight = "#81c784"; // Light version of green for success states

// Dark Theme Colors
const primaryColorDark = "#590000"; // Dark version of rich dark red
const secondaryColorDark = "#1b1b1b"; // Dark version of medium dark gray
const errorColorDark = "#880e4f"; // Dark version of bright red

const warningColorDark = "#f57c00"; // Dark version of orange for warnings
const infoColorDark = "#1976d2"; // Dark version of light blue for informational messages
const successColorDark = "#388e3c"; // Dark version of green for success states

// Shared Theme Colors
const backgroundLight = "#f9f9f9"; // Light gray for light theme backgrounds
const backgroundDark = "#212121"; // Very dark for dark theme backgrounds
const textPrimaryLight = "#303030"; // Very dark text for light backgrounds
const textPrimaryDark = "#ffffff"; // White text for dark backgrounds
const textSecondaryLight = "#555"; // Slightly lighter text for secondary content in light theme
const textSecondaryDark = "#bdbdbd"; // Light gray for secondary text in dark theme

// Background and paper colors for light theme
const paperColorLight = "#fff"; // White paper backgrounds in light theme

// Paper color for dark theme (secondary dark color is already defined)
const paperColorDark = "#424242"; // A dark grey for paper backgrounds in dark theme

export const createGlobalTheme = () => {
    return createTheme({
        palette: {
            type: "light",
            primary: {
                main: primaryColorLight,
                contrastText: textPrimaryLight,
            },
            secondary: {
                main: secondaryColorLight,
                contrastText: textSecondaryLight,
            },
            error: {
                main: errorColorLight,
                contrastText: textPrimaryLight,
            },
            warning: {
                main: warningColorLight,
                contrastText: textPrimaryLight,
            },
            info: {
                main: infoColorLight,
                contrastText: textPrimaryLight,
            },
            success: {
                main: successColorLight,
                contrastText: textPrimaryLight,
            },
            background: {
                default: backgroundLight,
                paper: paperColorLight,
            },
            text: {
                primary: textPrimaryLight,
                secondary: textSecondaryLight,
            },
        },
        typography: {
            fontFamily: `"Montserrat"`,
        },
    });
};

// createGlobalTheme = responsiveFontSizes(createGlobalTheme);
