import {
    Button,
    Card,
    Container,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import Alerttip from "./Alerttip";
import Item from "./Item";

const ReviewBuildInfo = (props) => {
    const complete = props.complete;
    const classes = props.classes;

    return (
        <Item>
            <Typography component="h5" variant="h5">
                <div>
                    <div className="MuiGrid-container">
                        <div className="MuiGrid-grid-md-6 MuiGrid-grid-sm-12">
                            <span class="buildID">
                                <span>
                                    <DescriptionIcon />
                                    ID:&nbsp;
                                    <span>
                                        {complete?.build_id ||
                                            complete?.util_id}
                                    </span>
                                    <Alerttip title="Copy to clipboard">
                                        <ContentCopyIcon
                                            className={classes.copybtn}
                                            style={{
                                                display: "inline-block",
                                                width: "15px",
                                                height: "15px",
                                                // padding: "8px",
                                                marginTop: "-5px",
                                                marginBottom: "0px",
                                                marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    complete?.build_id ||
                                                        complete?.util_id
                                                );
                                            }}
                                        />
                                    </Alerttip>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MuiGrid-grid-md-6 MuiGrid-grid-sm-12"
                            align="right"
                        >
                            <span
                                class="buildStats"
                                style={{ position: "relative" }}
                            >
                                {complete?.date && (
                                    <>
                                        <span class="minutes" />
                                        <CalendarMonthIcon />
                                        {`${complete?.date}`}
                                    </>
                                )}
                                {complete?.status === "success" &&
                                    complete?.build_duration && (
                                        <>
                                            <span class="spacer"> | </span>
                                            <QueryBuilderIcon />
                                            {`${complete?.build_duration} minutes`}
                                        </>
                                    )}
                                <div>
                                    <>
                                        {/* <span class="spacer"> | </span> */}
                                        <NetworkCheckIcon />
                                        <span
                                            style={{
                                                color:
                                                    complete?.status ===
                                                    "success"
                                                        ? "#43a047"
                                                        : complete?.status ===
                                                          "in_progress"
                                                        ? "#ffa000"
                                                        : complete?.status ===
                                                          "canceled"
                                                        ? "#bdbdbd"
                                                        : "#b71c1c",
                                            }}
                                        >
                                            {complete?.status
                                                ?.toUpperCase()
                                                .replace(/[_-]/g, " ")}
                                        </span>
                                    </>

                                    {complete?.vm_tasks_status && (
                                        <>
                                            <span class="spacer"> | </span>
                                            {`VM Status: ${complete?.vm_tasks_status}`.toUpperCase()}
                                        </>
                                    )}
                                    {complete?.kit_id && (
                                        <>
                                            <span class="spacer"> | </span>
                                            {`Kit ID: ${complete?.kit_id}`.toUpperCase()}
                                        </>
                                    )}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </Typography>
        </Item>
    );
};

export default ReviewBuildInfo;
