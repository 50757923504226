import { makeStyles } from "@material-ui/core/styles";
import { BorderBottom } from "@material-ui/icons";

export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    backgroundColor: "#EAEAEA",
    flexDirection: "column",
    width: "50vw",
    alignItems: "center",
    padding: theme.spacing(2),
    "@media (max-width: 650px)": {
      width: "90%",
    },
  },

  textField: {
    "& label.Mui-focused": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },

  select: {
    '&:before': {
      borderColor: "black",
    },
    '&:after': {
      borderColor: "black",
    }
  },

  form: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    flexDirection: "column",
  },

  form1: {
    width: "50%",
    display: "flex",
    alignItems: "center",
  },

  port: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "@media (max-width: 650px)": {
      flexDirection: "column",
      borderBottom: "2px solid gray",
    },
  },

  button: {
    backgroundColor: "#EA2331",
    width: "100px",
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: "#333333",
      color: "#EA2331",
    },
  },

  applicanceSettBtn: {
    backgroundColor: "#EA2331",
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: "#333333",
      color: "#EA2331",
    },
    "@media (max-width: 650px)": {
      marginTop: "15%",
    },
  },

  update: {
    cursor: "pointer",
    display: "none",
    width: "20%",
    color: "black",
    fontSize: "22rem",
    "&:hover": {
      color: "#B73239",
    },
  },

  card: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0 0 0",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    },
  },
  card2: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0 0 0",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    },
  }
}));
