import {
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
    CircularProgress,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CodeBlock from "./reviewHelpers/CodeBlock";

const NetworkBuildReview = ({ complete, classes, completeIsLoading }) => {
    return (
        <Grid container>
            {complete?.serial_devices?.map((serial_device, i) => (
                <Grid key={serial_device + i} item md={12} sm={12} xs={12}>
                    <Box className={classes.accordionBox}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                id={serial_device.port}
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography component="h6" variant="h6">
                                    {serial_device?.device?.name +
                                        " (" +
                                        serial_device?.port +
                                        ")"}
                                </Typography>
                            </AccordionSummary>
                            <Card>
                                {completeIsLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Box>
                                        <TableContainer
                                            component={Paper}
                                            className={classes.table}
                                        >
                                            {/* <Typography className={classes.tableHeader}>{serial_device.device.name}</Typography> */}
                                            <Table>
                                                <TableHead>
                                                    <TableCell
                                                        className={
                                                            classes.tableCellHeader
                                                        }
                                                    >
                                                        Name
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCellHeader
                                                        }
                                                    >
                                                        Port
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCellHeader
                                                        }
                                                    >
                                                        Template Name
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCellHeader
                                                        }
                                                    >
                                                        OS Type
                                                    </TableCell>
                                                </TableHead>

                                                <TableBody>
                                                    <TableRow key={i}>
                                                        <TableCell>
                                                            {
                                                                serial_device
                                                                    ?.device
                                                                    ?.name
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                serial_device?.port
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                serial_device?.template_name
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                serial_device
                                                                    ?.device
                                                                    ?.os_type
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box className={classes.table}>
                                            <Accordion>
                                                <AccordionSummary
                                                    id="config_variables"
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                >
                                                    <Typography>
                                                        Config Variables
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Table>
                                                        <TableHead>
                                                            <TableCell>
                                                                Name
                                                            </TableCell>
                                                            <TableCell>
                                                                Value
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableBody>
                                                            {serial_device?.config_variables?.map(
                                                                (
                                                                    config_var,
                                                                    j
                                                                ) => (
                                                                    <TableRow
                                                                        key={j}
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                config_var.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                config_var.value
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box className={classes.table}>
                                            {complete?.running_configs?.[i]
                                                ?.running_config && (
                                                <Accordion>
                                                    <AccordionSummary
                                                        id="running_config"
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                    >
                                                        <Typography>
                                                            Running Config
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <CodeBlock
                                                            codestring={
                                                                complete
                                                                    .running_configs[
                                                                    i
                                                                ].running_config
                                                            }
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Box>
                                        <Box className={classes.table}>
                                            {complete?.version_infos?.[i]
                                                ?.version_info && (
                                                <Accordion>
                                                    <AccordionSummary
                                                        id="version_info"
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                    >
                                                        <Typography>
                                                            Version Info
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <CodeBlock
                                                            codestring={
                                                                complete
                                                                    .version_infos[
                                                                    i
                                                                ].version_info
                                                            }
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Box>
                                        <Box className={classes.table}>
                                            {complete?.inventory_infos?.[i]
                                                ?.inventory_info && (
                                                <Accordion>
                                                    <AccordionSummary
                                                        id="inventory_info"
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                    >
                                                        <Typography>
                                                            Inventory Info
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <CodeBlock
                                                            codestring={
                                                                complete
                                                                    .inventory_infos[
                                                                    i
                                                                ].inventory_info
                                                            }
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Card>
                        </Accordion>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default NetworkBuildReview;
