import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    appBar: {
        width: "100vw",
        backgroundColor: "#00c853",
        color: "white",
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
}));

export default useStyles;
