import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: "black",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
    },
}));

const BuildID = (props) => {
    return (
        <div className={props.classes.buildInfoDiv}>
            <Typography
                component="h1"
                variant="h5"
                className={props.classes.buildInfoLabels}>
                Build ID:
            </Typography>
            <Alerttip title={props.buildID}>
                <Typography
                    component="h1"
                    variant="h5"
                    className={props.classes.buildInfo}
                >
                    {props?.buildID ? props?.buildID?.slice(13, 21) + "..." : ""}
                </Typography>
            </Alerttip>

        </div >
    );
}

export default BuildID;