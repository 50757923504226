import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import {
	Paper,
	Grid,
	Typography,
	InputLabel,
	MenuItem,
	Select,
	Container,
	FormControl,
	FormGroup,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import useStyles from "./styles";
import VCSAVersion from "./VCSAVersion";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { GetLicenses } from "../../../queries/LicenseQuery";
import { Context } from "../../../context/Context";

const VSettings = ({ register, control, info }) => {
	const token = useContext(Context);
	const classes = useStyles();

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));

    const { data: licenseData, error: licenseError, isLoading: licenseIsLoading} = GetLicenses(token)
    if (licenseIsLoading) return <div>Loading...</div>;
    if (licenseError) return <div>Error retrieving data...</div>;

    // Filter ESXi licenses from licenseData
    const vcsaLicense = licenseData?.filter(license => license.license_type === 'vcsa');

	const license = [];
	vcsaLicense?.map((l) => license.push(l.license_key));
	license.push(info?.vcsa_license);

	return (
		<Container component="main" maxWidth="xl">
			<Paper className={classes.paper} elevation={6}>
				<Typography component="h1" variant="h5">
					VCSA Settings
				</Typography>
				<form autoComplete="off" className={classes.form}>
					<Grid style={{ marginRight: "20px", width: "100%" }}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="vCenter FQDN or IP Address (example: vcsa.nextechsol.com or 192.168.1.55)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="System Name"
									{...register("vcsa_system_name")}
									defaultValue={info?.vcsa_system_name || ""}
								/>
							</Alerttip>
							<Alerttip title="vCenter Virtual Machine Name (example: VCSA)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="Appliance Name"
									{...register("vcsa_appliance_name")}
									defaultValue={
										info?.vcsa_appliance_name || ""
									}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="vCenter Datacenter Name (example: NTS-Datacenter)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="Datacenter Name"
									{...register("vcsa_datacenter_name")}
									defaultValue={
										info?.vcsa_datacenter_name || ""
									}
								/>
							</Alerttip>
							<Alerttip title="vSAN Cluster name or Host Cluster Name (example: vSAN-Cluster)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="Cluster Name"
									{...register("vcsa_cluster_name")}
									defaultValue={info?.vcsa_cluster_name || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="vCenter IP Address (example: 192.168.1.55)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="IP Address"
									{...register("vcsa_ip")}
									defaultValue={info?.vcsa_ip || ""}
								/>
							</Alerttip>
							<Alerttip title="vCenter Default Gateway (example: 192.168.1.1)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="Default Gateway"
									{...register("vcsa_gateway")}
									defaultValue={info?.vcsa_gateway || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="vCenter Administrator Account (example: administrator)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="SSO User"
									{...register("vcsa_sso_user")}
									defaultValue={info?.vcsa_sso_user || ""}
								/>
							</Alerttip>
							<Alerttip title="vCenter Domain Name (example: vsphere.local)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="SSO Domain"
									{...register("vcsa_sso_domain")}
									defaultValue={info?.vcsa_sso_domain || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="vCenter Administrator Password (example: P@55w0rd!!)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									type="password"
									label="SSO Password"
									{...register("vcsa_sso_pass")}
									defaultValue={info?.vcsa_sso_pass || ""}
									autoComplete="false"
								/>
							</Alerttip>
							<Alerttip title="vCenter Root Password (example: P@55w0rd!!)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									type="password"
									label="OS Password"
									{...register("vcsa_os_password")}
									defaultValue={info?.vcsa_os_password || ""}
									autoComplete="false"
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="Reachable DNS Server; Must Contain vCenter FQDN, if specified in System Name (example: 8.8.8.8)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="DNS 0"
									{...register("vcsa_dns_0")}
									defaultValue={info?.vcsa_dns_0 || ""}
								/>
							</Alerttip>
							<Alerttip title="Reachable DNS Server; Must Contain vCenter FQDN, if specified in System Name (example: 8.8.4.4)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="DNS 1"
									{...register("vcsa_dns_1")}
									defaultValue={info?.vcsa_dns_1 || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="Primary NTP Server for vCenter (example: 132.163.96.5)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="NTP 0"
									{...register("vcsa_ntp_server_0")}
									defaultValue={info?.vcsa_ntp_server_0 || ""}
								/>
							</Alerttip>
							<Alerttip title="Secondary NTP Server for vCenter (example: 132.163.97.5)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									label="NTP 1"
									{...register("vcsa_ntp_server_1")}
									defaultValue={info?.vcsa_ntp_server_1 || ""}
								/>
							</Alerttip>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-around",
								marginBottom: "20px",
							}}
						>
							<Alerttip title="Network Prefix or Subnet for vCenter (example: 24)">
								<TextField
									style={{ width: "40%" }}
									variant="outlined"
									type="number"
									label="Network Prefix"
									{...register("vcsa_network_prefix")}
									defaultValue={
										info?.vcsa_network_prefix || ""
									}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												/
											</InputAdornment>
										),
									}}
								/>
							</Alerttip>
						</div>
						<FormControl component="fieldset">
							<FormGroup>
								<Alerttip title="Would you like SSH enabled for the vCenter Server? If so, check this box">
									<FormControlLabel
										control={
											<input
												style={{ marginLeft: "25px" }}
												type="checkbox"
												defaultChecked={
													"" ||
													info?.vcsa_ssh_enable ===
														true
												}
												{...register("vcsa_ssh_enable")}
											/>
										}
										label="VCSA SSH Enable"
									/>
								</Alerttip>
							</FormGroup>
						</FormControl>

						<Grid style={{ marginTop: "10px" }}>
							<Alerttip title="Would you like the vCenter Server Deployed Using Thin-Provisioning? If so, check this box">
								<FormControl component="fieldset">
									<FormGroup>
										<FormControlLabel
											control={
												<input
													style={{
														marginLeft: "25px",
													}}
													type="checkbox"
													defaultChecked={
														"" ||
														info?.vcsa_appliance_thin_disk_mode ===
															true
													}
													{...register(
														"vcsa_appliance_thin_disk_mode"
													)}
												/>
											}
											label="Thin Disk Mode"
										/>
									</FormGroup>
								</FormControl>
							</Alerttip>
						</Grid>
						<Grid
							style={{
								marginTop: "20px",

								display: "flex",
							}}
						>
							<Alerttip title="Select the Correct License File for vCenter. Pleas Upload a License if you do not see any listed">
								<InputLabel
									style={{
										color: "black",
										display: "flex",
										margin: "20px 10px",
									}}
								>
									Select License
								</InputLabel>
							</Alerttip>
							<Controller
								style={{ marginLeft: "45px" }}
								control={control}
								name="vcsa_license"
								defaultValue={info?.vcsa_license || ""}
								render={({ field }) => (
									<Select {...field}>
										{vcsaLicense?.map((l) => (
											<MenuItem
												onChange={field.onChange}
												key={l.license_key}
												value={l.license_key}
											>
												{l.license_name} - (
												{l.license_key})
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</Grid>

						<div
							style={{
								display: "flex",
								alignItems: "center",
								margin: "30px 0",
							}}
						>
							<Alerttip title="Select the Correct Deployment Size for the vCenter Server">
								<InputLabel
									style={{
										color: "black",
										display: "flex",
										margin: "10px 10px",
									}}
								>
									Deployment Options
								</InputLabel>
							</Alerttip>

							<Controller
								style={{ marginLeft: "10px" }}
								control={control}
								name="vcsa_appliance_deployment_option"
								defaultValue={
									info?.vcsa_appliance_deployment_option || ""
								}
								render={({ field }) => (
									<Select
										{...field}
										onChange={field.onChange}
									>
										<MenuItem value={"tiny"}>tiny</MenuItem>
										<MenuItem value={"small"}>
											small
										</MenuItem>
										<MenuItem value={"medium"}>
											medium
										</MenuItem>
										<MenuItem value={"large"}>
											large
										</MenuItem>
										<MenuItem value={"xlarge"}>
											xlarge
										</MenuItem>
									</Select>
								)}
							/>
						</div>
						<VCSAVersion
							control={control}
							info={info}
							register={register}
						/>
					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default VSettings;
