import { Paper, FormControlLabel, Grid, Button, Switch, Box } from '@material-ui/core'
import useStyles from "./styles"
import { Context } from "../../context/Context"
import React, { useContext, useState } from 'react'

export const UserSettingsForm = () => {
    const classes = useStyles()
    const token = useContext(Context)

    // Password Expires States for setting the button activity.
    const [buttonPressed, setButtonPressed] = useState(false)
    const [switchSetting, setSwitchSetting] = useState(sessionStorage.getItem("passwordExpires") === 'true')

    let passwordExpiresURL = "";
    if (process.env.NODE_ENV === "development") {
        passwordExpiresURL = 'http://localhost:80/api/users/user/password-expires'
    } else if (process.env.NODE_ENV === "production") {
        passwordExpiresURL = '/api/users/user/password-expires'
    }

    //update context
    const handleCheckboxChange = (event) => {
        setSwitchSetting(event.target.checked)
    }

    const handlePasswordExpires = async (event) => {
        setButtonPressed(true)
        try {
            const response = await fetch(`${passwordExpiresURL}/admin`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.token}`,
                },
                body: switchSetting
            })
            if (!response.ok) {
                const errorDetails = await response.json()
                console.error('error details:', errorDetails)
                setButtonPressed(false)
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            alert(`Password expires: ${switchSetting}`)
            sessionStorage.setItem("passwordExpires", switchSetting);
            setButtonPressed(false)
            return await response.json()
        } catch (error) {
            console.error('Error updating password expires:', error)
        }
    }

    // Check if the switch's state matches the value in sessionStorage
    const isButtonDisabled = buttonPressed || (sessionStorage.getItem("passwordExpires") === switchSetting.toString());

    return (
        <div style={{ alignSelf: "center" }}>
            <Paper className={classes.paper} elevation={6}>
                <Box style={{ marginBottom: '16px' }}>
                    {
                        (() => {
                            const isoString = sessionStorage.getItem('lastLogin')
                            if (!isoString) return 'Last Login information not available';
                            const utcString = isoString.endsWith('Z') ? isoString : isoString + 'Z';
                            const date = new Date(utcString);
                            return `Last Login ${date.toLocaleString()}`;
                        })()
                    }

                </Box>
                <Grid container spacing={1} alignItems="center" justify="space-between">
                    <Grid item xs={8}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={switchSetting}
                                    onChange={handleCheckboxChange}
                                    name='passwordExpires'
                                />
                            }
                            label={switchSetting ? 'Password Expires' : 'Password Does Not Expire'}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePasswordExpires}
                            disabled={isButtonDisabled}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default UserSettingsForm