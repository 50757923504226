import {
  Button,
  Card,
  Grid, InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../context/Context";
import useStyles from "./style";

const BuildInterface = () => {
  const [changeBuildInterface, setChangeBuildInterface] = useState();
  const [currentBuildInterface, setCurrentBuildInterface] = useState();
  const [currentApplianceInfo, setCurrentApplianceInfo] = useState();
  const [wait, setWait] = useState(false);
  const [status, setStatus] = useState();
  const token = useContext(Context);
  const classes = useStyles();
  const history = useHistory();
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);

  const handleOpenList = (clickedIndex) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  let editBuildInterface = "";
  let getCurrentBuildInterface = "";
  let applianceInfo = ""
  let applianceShutdown = "";
  let applianceReboot = "";
  if (process.env.NODE_ENV === "development") {
    editBuildInterface = "http://localhost:80/api/appliance-settings/build_interface";
    getCurrentBuildInterface = "http://localhost:80/api/appliance-settings/appliance_settings";
    applianceInfo = "http://localhost:80/api/appliance-settings/appliance_info";
    applianceShutdown = "http://localhost:80/api/appliance-settings/appliance_shutdown";
    applianceReboot = "http://localhost:80/api/appliance-settings/appliance_reboot";
  } else if (process.env.NODE_ENV === "production") {
    editBuildInterface = "/api/appliance-settings/build_interface";
    getCurrentBuildInterface = "/api/appliance-settings/appliance_settings";
    applianceInfo = "/api/appliance-settings/appliance_info";
    applianceShutdown = "/api/appliance-settings/appliance_shutdown";
    applianceReboot = "/api/appliance-settings/appliance_reboot";
  }

  const submitInterface = (e) => {
    e.preventDefault();
    fetch(editBuildInterface + `?build_interface=${changeBuildInterface}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token.token}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        setStatus(res.status);
        setChangeBuildInterface("");
        alert("Build Interface updated!")
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleShutDownAppliance = (e) => {
    const temp_token = token.token
    localStorage.clear();
    sessionStorage.clear();
    fetch(applianceShutdown, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${temp_token}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        window.location.replace("/appliance-shutdown?method=Shutting_Down");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleRebootAppliance = (e) => {
    const temp_token = token.token
    localStorage.clear();
    sessionStorage.clear();
    fetch(applianceReboot, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${temp_token}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        window.location.replace("/appliance-shutdown?method=Restarting");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getBuildInterface = async () => {
    await fetch(getCurrentBuildInterface, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((response) => response.json())
      .then((settings) => {
        setCurrentBuildInterface(settings.build_interface);
      })

      .catch((error) => {
        alert("Error Retrieving Ports", error);
      });
  };

  const getApplianceInfo = async () => {
    await fetch(applianceInfo, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setCurrentApplianceInfo(response);
      })

      .catch((error) => {
        alert("Error Retrieving Appliance Info", error);
      });
  };

  useEffect(() => {
    getBuildInterface();
    getApplianceInfo();
  }, []);

  return (
    <Paper className={classes.paper} elevation={6}>
      <style>{`
        input::placeholder {
            opacity: 1 !important;
        }
        `}</style>
      <Typography component="h1" variant="h5">
        Appliance Settings
      </Typography>
      <form
        autoComplete="off"
        onSubmit={submitInterface}
        className={classes.form}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {status === 200 &&
              <Alert severity="success" style={{ marginBottom: "10px" }}>
                Build Interface Saved!
              </Alert>
            }
            <InputLabel
              style={{
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Select Build Interface:
            </InputLabel>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Select
                native={false}
                displayEmpty={true}
                className={classes.select}
                onChange={(e) => { setChangeBuildInterface(e.target.value); setCurrentBuildInterface(e.target.value) }}
                helperText="Interface number for PXE server (example: ens192)"
                renderValue={() =>
                  currentApplianceInfo?.network?.map((net) => (
                    net.name === currentBuildInterface ? net.name : ""))}
              >
                {currentApplianceInfo?.network?.map((net) => (
                  <MenuItem
                    disabled={net.ip[0] != undefined ? true : false}
                    key={net.name}
                    value={net.name}
                  >
                    {net.name}
                  </MenuItem>
                ))}
              </Select>
              <Button type="submit" className={classes.applicanceSettBtn}>
                Submit
              </Button>

            </Grid>

            {currentApplianceInfo?.network?.map((info, index) => {

              return (
                <div
                >
                  {info.ip[0] != undefined ?

                    <Card style={{ marginTop: "10px", width: "40vw" }} className={classes.card2}>
                      <Typography style={{ marginBottom: "20px" }} gutterBottom variant="h6" component="div">
                        {info?.name} Network Settings:
                      </Typography>

                      <Grid style={{ display: "flex", flexDirection: "column" }}>
                        {currentApplianceInfo?.hostname != undefined ?
                          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                            <div >
                              Hostname:
                            </div>
                            <TextField disabled value={currentApplianceInfo?.hostname} style={{ marginLeft: "50px", marginBottom: "5px" }} >
                            </TextField>
                          </Grid>
                          : ""}
                        <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                          <div>
                            IP Address:
                          </div>
                          <TextField disabled value={info?.ip} style={{ marginLeft: "50px", marginBottom: "5px" }} >
                          </TextField>
                        </Grid>
                        <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                          <div>
                            Subnet Mask:
                          </div>
                          <TextField disabled value={info?.netmask} style={{ marginLeft: "35px", marginBottom: "5px" }} >
                          </TextField>
                        </Grid>
                        <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                          <div>
                            Default Gateway:
                          </div>
                          <TextField disabled value={info?.gateway} style={{ marginLeft: "5px", marginBottom: "5px" }} >
                          </TextField>
                        </Grid>
                        {currentApplianceInfo?.nameservers != undefined ?
                          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                            <div>
                              DNS:
                            </div>
                            <TextField disabled value={currentApplianceInfo?.nameservers} style={{ marginLeft: "100px", marginBottom: "5px" }}  >

                            </TextField>
                          </Grid>
                          : ""}
                      </Grid>

                    </Card>
                    : ""}
                </div>
              )
            })}

          </div>
        </Grid>
      </form>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          style={{ margin: "10px", whiteSpace: "nowrap" }}
          className={classes.applicanceSettBtn}
          onClick={() => {
            if (window.confirm(
              "Are you sure you want to shutdown the appliance?"
            )
            )
              handleShutDownAppliance()
          }}
        >
          Shutdown Appliance
        </Button>
        <Button
          style={{ margin: "10px" }}
          className={classes.applicanceSettBtn}
          onClick={() => {
            if (window.confirm(
              "Are you sure you want to restart the appliance?"
            )
            )
              handleRebootAppliance()
          }}
        >
          Restart Appliance
        </Button>
      </Grid>
    </Paper >

  );
};

export default BuildInterface;
