import { useContext, useEffect, useState } from "react";
import { Context } from "./context/Context";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Auth from "./components/auth/Auth";
import NewBuild from "./components/buildForms/NewBuild";
import Build from "./components/create/Build";
import PlaybooksBuild from "./components/buildForms/Playbooks/PlaybooksBuild";
import PrevBuild from "./components/create/PrevBuild";
import PrevNetworkBuild from "./components/create/PrevNetworkBuild";
import PrevVirtualNetworkBuild from "./components/buildForms/VirtualNetwork/PrevVirtualNetworkBuild";
import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Review from "./components/review/Review";
import UploadFile from "./components/uploadFile/UploadFile";
import UploadVirtualNetworkJSON from "./components/uploadFile/UploadVirtualNetworkJSON";
import ApplianceSettings from "./components/applianceSettings/ApplianceSettings";
import NewNetwork from "./components/create/NewNetwork";
import VirtualNetwork from "./components/buildForms/VirtualNetwork/VirtualNetwork";
import ExistingVirtualNetwork from "./components/buildForms/VirtualNetwork/ExistingVirtualNetwork";
import UploadNetwork from "./components/uploadFile/UploadNetwork";
import NetworkBuildReview from "./components/review/NetworkBuildReview";
import UploadCSV from "./components/uploadCSV/UploadCSV";
import UploadCSVNetwork from "./components/uploadCSV/UploadCSVNetwork";
import UploadKeygen from "./components/uploadKeygen/UploadKeygen";
import WriteErase from "./components/buildForms/Network/NetworkUtil";
import TrackBuild from "./components/TrackBuild/TrackBuild";
import ApplianceShutdown from "./components/applianceSettings/ApplianceShutdown";
import UpdatePassword from "./components/auth/UpdatePassword";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./styles.scss";
import ClassifiedBanner from "./components/classifiedBanner/ClassifiedBanner";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import UploadPage from "./pages/uploadPage/uploadPage";
import NetworkUtilReview from "./components/review/NetworkUtilReview";

function App() {
    const token = useContext(Context);
    const [keys, setKeys] = useState(null);
    const [hasKey, setHasKey] = useState(false);
    const [mantleKey, setMantleKey] = useState(false);
    const [taclanKey, setTaclanKey] = useState(false);
    const [expired, setExpired] = useState(false);
    const [gracePeriod, setGracePeriod] = useState(false);
    const [taclanGracePeriod, setTaclanGracePeriod] = useState(false);
    const [hideGracePeriod, setHideGracePeriod] = useState(false);
    const [hideTaclanGracePeriod, setHideTaclanGracePeriod] = useState(false);
    const [taclanExpired, setTaclanExpired] = useState(false);
    const [mantleExpired, setMantleExpired] = useState(false);
    const [tacChecks, setTacChecks] = useState(false);
    let getKeysURL = "";
    if (process.env.NODE_ENV === "development") {
        getKeysURL = "http://localhost:80/api/keygen/user_keys";
    } else if (process.env.NODE_ENV === "production") {
        getKeysURL = "/api/keygen/user_keys";
    }

    const hideLicenseAlert = () => {
        setHideGracePeriod(true);
    };
    const hideTaclanAlert = () => {
        setHideTaclanGracePeriod(true);
    };

    const getActiveKeys = () => {
        fetch(getKeysURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setKeys(response);
                if (response.Result != "No License Information") {
                    let listMantleKeys = response.find(
                        (key) => key.feature_name === "mantle"
                    );
                    let listTaclanKeys = response.find(
                        (key) => key.feature_name === "taclan"
                    );
                    let listDevKeys = response.find(
                        (key) => key.feature_name === "developer"
                    );
                    let gp = response.find(
                        (key) => key.status === "grace_period"
                    );
                    if (gp) {
                        setGracePeriod(true);
                    }
                    let tgp = response.find(
                        (key) =>
                            key.feature_name === "taclan" &&
                            key.status === "grace_period"
                    );
                    if (tgp) {
                        setTaclanGracePeriod(true);
                    }
                    let exp = response.find((key) => key.status === "expired");
                    let expTaclan = response.find(
                        (key) =>
                            key.feature_name === "mantle" &&
                            key.status === "expired"
                    );
                    let expMantle = response.find(
                        (key) =>
                            key.feature_name === "taclan" &&
                            key.status === "expired"
                    );

                    if (listMantleKeys) {
                        setMantleKey(true);
                        setHasKey(true);
                        if (expTaclan) {
                            setMantleKey(false);
                            setExpired(true);
                        }
                    }
                    if (listTaclanKeys) {
                        let frigginTaclan = response.find(
                            (key) =>
                                key.feature_name === "taclan" &&
                                key.status === "expired"
                        );
                        if (frigginTaclan) {
                            setTaclanExpired(true);
                        }
                    }
                    if (listTaclanKeys) {
                        setTaclanKey(true);
                        setTacChecks(true);
                    }
                    if (listTaclanKeys && !listMantleKeys) {
                        setMantleKey(false);
                        setHasKey(false);
                        if (expTaclan) {
                            setMantleKey(false);
                        }
                    }
                    if (listTaclanKeys && listMantleKeys) {
                        setMantleKey(true);
                        setHasKey(true);
                        if (expTaclan) {
                            setMantleKey(false);
                        }
                    }
                    if (listDevKeys && !listMantleKeys) {
                        setMantleKey(false);
                        setHasKey(false);
                        if (taclanExpired) {
                            setMantleKey(false);
                        }
                    }
                    if (!listMantleKeys && taclanExpired) {
                        setMantleKey(true);
                        setHasKey(true);
                        if (taclanExpired) {
                            setMantleKey(true);
                        }
                    }
                    if (listMantleKeys && taclanExpired) {
                        setMantleKey(true);
                        setHasKey(true);
                        setMantleExpired(false);
                        if (taclanExpired) {
                            setMantleKey(true);
                            setTaclanExpired(true);
                        }
                        if (expMantle) {
                            setMantleKey(true);
                            setTaclanGracePeriod(true);
                            setTaclanExpired(true);
                        }
                    }
                }
            });
    };

    const checkKeys = () => {
        getActiveKeys();
    };

    useEffect(() => {
        checkKeys();
        setTimeout(function () {
            setIsFetching(false);
        }, 1000);
    }, [hasKey]);

    const [isFetching, setIsFetching] = useState(true);
    if (isFetching && token.token) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                    width: "100vw",
                    alignItems: "center",
                }}
                class="dumbSpinner"
            >
                <div>
                    <img src="/images/MantleLogo.png" />
                    <CircularProgress id="dumbSpinner" />
                </div>
            </Box>
        );
    }
    return (
        <BrowserRouter>
            <div>
                <ClassifiedBanner />
                <Navbar />
                {gracePeriod &&
                token.token != null &&
                hideGracePeriod !== true ? (
                    <div id="ctaBanner">
                        <p>
                            A license is expired. Please{" "}
                            <a href="/appliancesettings">upload</a> a new Mantle
                            Software License now in Appliance Settings.{" "}
                            <a
                                style={{
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                }}
                                onClick={hideLicenseAlert}
                            >
                                <HighlightOffIcon />
                            </a>
                        </p>
                    </div>
                ) : (
                    ""
                )}
                {taclanExpired === true && !hideTaclanGracePeriod ? (
                    <div id="ctaBanner">
                        <p>
                            Your Taclan license is expired. Please{" "}
                            <a href="/appliancesettings">upload</a> a new Taclan
                            Software License now in Appliance Settings.{" "}
                            <a
                                style={{
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                }}
                                onClick={hideTaclanAlert}
                            >
                                <HighlightOffIcon />
                            </a>
                        </p>
                    </div>
                ) : (
                    ""
                )}
                {hasKey === true && mantleKey === true ? (
                    <Switch>
                        <Route exact path="/auth">
                            <Auth />
                        </Route>
                        <Route exact path="/appliance-shutdown">
                            <ApplianceShutdown />
                        </Route>
                        <Route exact path="/update-password">
                            <PrivateRoute>
                                <UpdatePassword />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/">
                            <PrivateRoute>
                                <Home tacChecks={tacChecks} />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/upload">
                            <PrivateRoute>
                                <UploadFile />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/uploadnetwork">
                            <PrivateRoute>
                                <UploadNetwork />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/upload-virtual-network">
                            <PrivateRoute>
                                <UploadVirtualNetworkJSON />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/prev">
                            <PrivateRoute>
                                <PrevBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/prevnetwork">
                            <PrivateRoute>
                                <PrevNetworkBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/prevvirtualnetwork">
                            <PrivateRoute>
                                <PrevVirtualNetworkBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/review/:build_type?/:id?">
                            <PrivateRoute>
                                <Review />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/networkreview/:id">
                            <PrivateRoute>
                                <NetworkBuildReview />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/build">
                            <PrivateRoute>
                                <Build />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/playbooksbuild/:id">
                            <PrivateRoute>
                                <PlaybooksBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/track-build">
                            <PrivateRoute>
                                <TrackBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/newbuild">
                            <PrivateRoute>
                                <NewBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/newbuild/:id">
                            <PrivateRoute>
                                <NewBuild />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/appliancesettings">
                            <PrivateRoute>
                                <ApplianceSettings />
                            </PrivateRoute>
                        </Route>
                        <Route
                            exact
                            path="/uploadpage/:atype/:firstReview?/:secondReview?"
                        >
                            <PrivateRoute>
                                <UploadPage />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/newnetwork">
                            <PrivateRoute>
                                <NewNetwork />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/virtualnetwork">
                            <PrivateRoute>
                                <VirtualNetwork />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/virtualnetwork/:id">
                            <PrivateRoute>
                                <VirtualNetwork />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/existingvirtualnetwork">
                            <PrivateRoute>
                                <ExistingVirtualNetwork />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/cutsheet">
                            <PrivateRoute>
                                <UploadCSV />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/cutsheet-network">
                            <PrivateRoute>
                                <UploadCSVNetwork />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/uploadkeygen">
                            <PrivateRoute>
                                <UploadKeygen />
                            </PrivateRoute>
                        </Route>
                        <Route exact path="/writeerase">
                            <PrivateRoute>
                                <WriteErase />
                            </PrivateRoute>
                        </Route>
                    </Switch>
                ) : (
                    <Switch>
                        <div>
                            <h1
                                style={{
                                    color: "white",
                                    textAlign: "center",
                                    margin: "30px auto -30px",
                                }}
                            >
                                A valid software license is required to proceed
                            </h1>
                            <UploadKeygen />
                        </div>
                    </Switch>
                )}
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
