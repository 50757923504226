import {
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

export const PlaybookBuildReview = (props) => {
    const build = props.complete;
    const classes = props.classes;

    return (
        <Grid container>
            <Typography
                component="h5"
                variant="h6"
                style={{
                    margin: "15px",
                }}
            >
                {"Built from: "}
                <Link
                    to={{
                        pathname: `/review/build/${build?.built_from}`,
                    }}
                >
                    {build?.built_from}
                </Link>
            </Typography>
            {build?.config?.playbooks?.map((pb, i) => (
                <Grid key={pb.name + i} item md={12} sm={12} xs={12}>
                    <Box className={classes.accordionBox}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                id={pb.name}
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography component="h6" variant="h6">
                                    {`Playbook ${i + 1}: ${pb.name}`}
                                </Typography>
                            </AccordionSummary>
                            <Card>
                                <Box>
                                    <TableContainer
                                        component={Paper}
                                        className={classes.table}
                                    >
                                        {/* <Typography className={classes.tableHeader}>{serial_device.device.name}</Typography> */}
                                        <Table>
                                            <TableHead>
                                                <TableCell
                                                    className={
                                                        classes.tableCellHeader
                                                    }
                                                >
                                                    Name
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCellHeader
                                                    }
                                                >
                                                    Filename
                                                </TableCell>
                                            </TableHead>

                                            <TableBody>
                                                <TableRow key={i}>
                                                    <TableCell>
                                                        <Link
                                                            to={{
                                                                pathname: `/uploadpage/playbook/${pb.name}`,
                                                            }}
                                                        >
                                                            {pb.name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {pb.filename}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {pb?.variables && (
                                        <Box className={classes.table}>
                                            <Accordion>
                                                <AccordionSummary
                                                    id="config_variables"
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                >
                                                    <Typography>
                                                        Config Variables
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Table>
                                                        <TableHead>
                                                            <TableCell>
                                                                Name
                                                            </TableCell>
                                                            <TableCell>
                                                                Value
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableBody>
                                                            {pb?.variables?.map(
                                                                (
                                                                    config_var,
                                                                    j
                                                                ) => (
                                                                    <TableRow
                                                                        key={j}
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                config_var.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                config_var.value
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                    )}
                                </Box>
                            </Card>
                        </Accordion>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default PlaybookBuildReview;
