import React, { useState, useContext, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { Context } from "../../../context/Context";
import { Button, Container, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import VirtualNetworkDevice from "./VirtualNetworkDevice";
import useStyles from "./styles";
import { GetSerialDevices } from "../../../queries/SerialDevicesQuery";
import { GetTemplates } from "../../../queries/TemplatesQuery";
import { GetOVAs } from "../../../queries/OvasQuery";
import useGlobalStyle from "../../../themes/globalStyle";

const VirtualNetworkOVA = ({
    register,
    unregister,
    control,
    info,
    getValues,
    setValue,
    watch,
}) => {
    const token = useContext(Context);
    const {
        data: allDevices,
        isLoadingDevices,
        errorDevices,
    } = GetSerialDevices(token);
    const {
        data: allTemplates,
        isLoadingTemplates,
        errorTemplates,
    } = GetTemplates(token);
    const { data: ovas, isLoadingOVAs, errorOVAs } = GetOVAs(token);

    const classes = useStyles();
    const GlobalStyle = useGlobalStyle()

    const { fields, append, remove } = useFieldArray({
        control,
        name: "network_devices", //name of the field array in the form
    });

    if (isLoadingDevices || isLoadingTemplates || isLoadingOVAs)
        return <div>Loading...</div>;
    if (errorDevices || errorTemplates || errorOVAs)
        return (
            <div>
                Error loading data:{" "}
                {errorDevices?.message ||
                    errorTemplates?.message ||
                    errorOVAs?.message}
            </div>
        );


    const addDevice = () => {
        append({ name: "", config_variables: [{}] });
    };


    return (
        <>
            <Container
                className={classes.containerOVA}
                component="main"
                maxWidth="xl"
            >
                <Typography component="h1" variant="h5">
                    Build a New Virtual Network
                </Typography>

                {fields.map((field, index) => (
                    <div key={field.id} className={classes.deviceBox}>
                        <VirtualNetworkDevice
                            deviceIndex={index}
                            currentDevice={watch(`network_devices.${index}`)}
                            allDevices={allDevices}
                            allTemplates={allTemplates}
                            register={register}
                            unregister={unregister}
                            setValue={setValue}
                            watch={watch}
                            remove={remove}
                            control={control}
                            ovas={ovas}
                            fields={fields}
                            append={append}
                            getValues={getValues}
                            info={info}
                        />
                    </div>
                ))}
                <Button
                    variant="outlined"
                    onClick={addDevice}
                    tyle={{ margin: "20px" }}
                    className={classes.addDeviceButton}
                >
                    <AddIcon />
                    Device
                </Button>
            </Container>
            <DevTool control={control} />
        </>
    );
};

export default VirtualNetworkOVA;
