import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ContextProvider } from "./context/Context";
import { SystemSettingsProvider } from "./context/SystemSettings";
import { createGlobalTheme } from "./themes/globalTheme";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <MuiThemeProvider theme={createGlobalTheme()}>
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <SystemSettingsProvider>
          <App />
        </SystemSettingsProvider>
      </ContextProvider>
    </QueryClientProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
