import {
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";

const ReviewNetworking = (props) => {
    const complete = props.complete
    const classes = props.classes
    return (
        <>
            {complete?.post_build_scan?.networking
                ?.dswitches && (
                    <Card
                        className={classes.card}
                        class="card"
                    >
                        <Typography
                            component="h5"
                            variant="h5"
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            Networking
                        </Typography>

                        {complete?.post_build_scan?.networking?.dswitches?.map(
                            (port, index) => (
                                <Typography
                                    key={index}
                                    style={{
                                        marginBottom: "5px",
                                        width: "100%",
                                    }}
                                    component="div"
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display:
                                                    "inline-block",
                                            }}
                                            class="p-b-35"
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <TableContainer
                                                    style={{
                                                        backgroundColor:
                                                            "#EAEAEA",
                                                        borderRadius:
                                                            "0",
                                                        marginTop:
                                                            "30px",
                                                        paddingBottom:
                                                            "20px",
                                                    }}
                                                    component={
                                                        Paper
                                                    }
                                                >
                                                    <h2
                                                        style={{
                                                            marginBottom:
                                                                "-10px",
                                                        }}
                                                        class="p-t-0 p-l-20 p-r-20 p-b-10"
                                                    >
                                                        {
                                                            port.name
                                                        }
                                                    </h2>
                                                    <Table
                                                        style={{
                                                            padding:
                                                                "15px 30px",
                                                        }}
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            ".9rem",
                                                                        padding:
                                                                            "15px 30px 5px",
                                                                    }}
                                                                >
                                                                    Port
                                                                    Group
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            ".9rem",
                                                                        padding:
                                                                            "15px 15px 5px",
                                                                    }}
                                                                >
                                                                    VLAN
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                padding:
                                                                    "0 20px",
                                                            }}
                                                            key={
                                                                index
                                                            }
                                                        >
                                                            {port?.portgroups?.map(
                                                                (
                                                                    port,
                                                                    index
                                                                ) => (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                paddingLeft:
                                                                                    "30px",
                                                                                fontSize:
                                                                                    ".8rem",
                                                                                paddingTop:
                                                                                    "5px",
                                                                                paddingBottom:
                                                                                    "5px",
                                                                            }}
                                                                            scope="row"
                                                                        >
                                                                            {
                                                                                port.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                fontSize:
                                                                                    ".8rem",
                                                                                paddingTop:
                                                                                    "5px",
                                                                                paddingBottom:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                port.vlan
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </div>
                                </Typography>
                            )
                        )}

                        {complete?.post_build_scan?.networking?.vswitches?.map(
                            (port, index) => (
                                <Typography
                                    key={index}
                                    style={{
                                        marginBottom: "5px",
                                        width: "100%",
                                    }}
                                    component="div"
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display:
                                                    "inline-block",
                                            }}
                                            class="p-b-0"
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <TableContainer
                                                    style={{
                                                        backgroundColor:
                                                            "#EAEAEA",
                                                        borderRadius:
                                                            "0",
                                                        marginTop:
                                                            "10px",
                                                        paddingBottom:
                                                            "20px",
                                                    }}
                                                    component={
                                                        Paper
                                                    }
                                                >
                                                    <h2
                                                        style={{
                                                            marginBottom:
                                                                "-10px",
                                                        }}
                                                        class="p-t-0 p-l-20 p-r-20 p-b-10"
                                                    >
                                                        {
                                                            port.name
                                                        }{" "}
                                                        <span
                                                            style={{
                                                                fontStyle:
                                                                    "italic",
                                                            }}
                                                        >
                                                            (
                                                            {
                                                                port.host
                                                            }

                                                            )
                                                        </span>
                                                        &nbsp;
                                                        &nbsp;
                                                        <span>
                                                            PNIC:&nbsp;
                                                            {port?.pnic?.map(
                                                                (
                                                                    pnic,
                                                                    index
                                                                ) => (
                                                                    <span>
                                                                        {
                                                                            pnic
                                                                        }
                                                                    </span>
                                                                )
                                                            )}
                                                        </span>
                                                    </h2>
                                                    <Table
                                                        style={{
                                                            padding:
                                                                "15px 30px",
                                                        }}
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            ".9rem",
                                                                        padding:
                                                                            "15px 30px 5px",
                                                                    }}
                                                                >
                                                                    Name
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            ".9rem",
                                                                        padding:
                                                                            "15px 15px 5px",
                                                                    }}
                                                                >
                                                                    VLAN
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                padding:
                                                                    "0 20px",
                                                            }}
                                                            key={
                                                                index
                                                            }
                                                        >
                                                            {port?.portgroups?.map(
                                                                (
                                                                    port,
                                                                    index
                                                                ) => (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                paddingLeft:
                                                                                    "30px",
                                                                                fontSize:
                                                                                    ".8rem",
                                                                                paddingTop:
                                                                                    "5px",
                                                                                paddingBottom:
                                                                                    "5px",
                                                                            }}
                                                                            scope="row"
                                                                        >
                                                                            {
                                                                                port.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                fontSize:
                                                                                    ".8rem",
                                                                                paddingTop:
                                                                                    "5px",
                                                                                paddingBottom:
                                                                                    "5px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                port.vlan
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </div>
                                </Typography>
                            )
                        )}
                    </Card>
                )}
        </>

    );
}

export default ReviewNetworking