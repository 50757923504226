import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },

  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    backgroundColor: "#EAEAEA",
    flexDirection: "column",
    width: "50vw",
    alignItems: "center",
    padding: theme.spacing(2),
    "@media (max-width: 650px)": {
      width: "90%",
    },
  },

  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  submit: {
    backgroundColor: '#EA2331',
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: '#333333',
      color: '#EA2331',
    }
  },
  button: {
    marginBottom: 10,
    backgroundColor: '#EA2331',
    color: '#333333',
    width: '9rem',
    marginRight: '20px',
    "&:hover": {
      backgroundColor: '#333333',
      color: '#EA2331',
    }
  },


}));
