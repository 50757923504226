import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Typography, Paper } from "@material-ui/core";
import useStyles from "./style";
import { Context } from "../../context/Context";

const UploadFile = () => {
  const [files, setFiles] = useState("");
  const classes = useStyles();
  const token = useContext(Context);
  const history = useHistory();

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      var result = JSON.parse(e.target.result);
      setFiles(result);
    };
  };

  let baseURL = "";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://localhost:80/api/build/validate-workflow";
  } else if (process.env.NODE_ENV === "production") {
    baseURL = "/api/build/validate-workflow";
  }

  const saveData = async (data) => {
    await fetch(baseURL, {
      method: "POST",
      body: JSON.stringify(files),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((response) => response.json())
      .then((resdata) => {
        history.push({
          pathname: "/track-build",
          state: { files: files, resdata: resdata },
        });
      })
      .catch((error) => {
        alert("Error Deploying Build", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" style={{ fontFamily: "Montserrat" }}>
          Upload File
        </Typography>
        <div className={classes.fileInput}>
          <input type="file" required onChange={handleChange} />
        </div>
        <br />

        {files !== "" ? (
          <>
            <Link
              style={{
                textDecoration: "none",
              }}
              to={{
                pathname: "/newbuild",
                state: {
                  files: files,
                },
              }}
            >
              <Button
                className={classes.button}
                style={{
                  fontWeight: "bolder",
                  fontFamily: "Montserrat",
                  letterSpacing: "3px",
                }}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
              >
                Review
              </Button>
            </Link>
            <Button
              onClick={saveData}
              className={classes.button}
              style={{
                fontWeight: "bolder",
                fontFamily: "Montserrat",
                letterSpacing: "3px",
              }}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              Deploy
            </Button>
            
          </>
        ) : null}
      </Paper>
    </div>
  );
};

export default UploadFile;
