import React from "react";
import { AppBar } from "@material-ui/core";
import useStyles from "./styles";
import LastLogin from "../lastLogin/LastLogin";
import useGlobalStyle from "../../themes/globalStyle";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Navbar = () => {
    const classes = useStyles();
    const globalStyle = useGlobalStyle();
    const VERSION_TAG = process.env.REACT_APP_VERSION_TAG;
    return (
        <AppBar
            className={classes.appBar}
            position="static"
            color="inherit"
            id="footer"
            style={{
                padding: "0",
                zIndex: 1300,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 0px 5px 0px",
                    position: "relative",
                }}
            >
                <div className={globalStyle.mobileFooterText}>
                    <div>
                        © 2024
                        <a
                            href="https://nextechsol.com/"
                            target="_blank"
                            style={{
                                color: "white",
                                padding: "0px 5px 0px 5px",
                                textDecoration: "none",
                            }}
                        >
                            NexTech Solutions
                        </a>
                    </div>
                    <div className={globalStyle.mobileNoDisplay}>
                        | MANTLE v{VERSION_TAG}
                    </div>
                </div>
                <div
                    style={{
                        position: "absolute",
                        right: 20,
                    }}
                >
                    <LastLogin />
                </div>
            </div>
        </AppBar>
    );
};

export default Navbar;
