import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../context/Context";
import { Controller } from "react-hook-form";
import {
	Grid,
	MenuItem,
	Container,
	Paper,
	InputLabel,
	Select,
	Typography,
	TextField,
} from "@material-ui/core";
import useStyles from "./styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { GetLicenses } from "../../../queries/LicenseQuery";
import { GetISOs } from "../../../queries/IsosQuery";

const ESXIVersion = ({ control, info, register}) => {
	const token = useContext(Context);
	const classes = useStyles();

    
    const { data: isoVersion, error, isLoading } = GetISOs(token);
    const { data: licenseData, error: licenseError, isLoading: licenseIsLoading} = GetLicenses(token)

    if (isLoading || licenseIsLoading) return <div>Loading...</div>;
    if (error || licenseError) return <div>Error retrieving data...</div>;

    // Filter ESXi licenses from licenseData
    const esxiLicense = licenseData?.filter(license => license.license_type === 'esxi');


	isoVersion?.push({ version: info?.esxi_ver });
	const uniqesxi = [...new Set(isoVersion)];

	const license = [];
	esxiLicense?.map((l) => license.push(l.license_key));
	license.push(info?.esxi_license);

	const Alerttip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "black",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(14),
			border: "1px solid #dadde9",
		},
	}));
	return (
		<Container component="main" maxWidth="xl">
			<Paper className={classes.paper} elevation={6}>
				<Typography
					style={{ marginBottom: "20px" }}
					component="h1"
					variant="h5"
				>
					Build Settings
				</Typography>
				<Grid style={{ marginTop: "20px", display: "flex" }}>
					<InputLabel
						style={{
							color: "black",
							display: "flex",
							margin: "20px 20px",
						}}
					>
						ESXI Version
					</InputLabel>
					<Controller
						style={{ marginLeft: "45px" }}
						control={control}
						name="esxi_ver"
						defaultValue={info?.esxi_ver || ""}
						render={({ field }) => (
							<Select {...field}>
								{uniqesxi?.map((iso) => (
									<MenuItem
										onChange={field.onChange}
										key={iso.version}
										value={iso.version}
									>
										{iso.version}
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</Grid>

				<Grid style={{ marginTop: "20px", display: "flex" }}>
					<InputLabel
						style={{
							color: "black",
							display: "flex",
							margin: "20px 20px",
						}}
					>
						Select License
					</InputLabel>
					<Controller
						style={{ marginLeft: "45px" }}
						control={control}
						name="esxi_license"
						defaultValue={info?.esxi_license || ""}
						render={({ field }) => (
							<Select {...field}>
								{esxiLicense?.map((l) => (
									<MenuItem
										onChange={field.onChange}
										key={l.license_key}
										value={l.license_key}
									>
										{l.license_name} - ({l.license_key})
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</Grid>

				<Grid style={{ display: "flex", margin: "55px 0" }}>
					<Alerttip title="Subnet for PXE Server (example: 192.168.1.0)">
						<TextField
							style={{ marginRight: "40px" }}
							variant="outlined"
							label="Subnet"
							{...register("subnet")}
							defaultValue={info?.subnet || ""}
						/>
					</Alerttip>
					<Alerttip title="Domain name for operational network (example: nextechsol.com)">
						<TextField
							variant="outlined"
							label="Domain"
							{...register("domain")}
							defaultValue={info?.domain || ""}
						/>
					</Alerttip>

				</Grid>
				<Grid style={{ display: "flex", margin: "15px 0" }}>
					<Alerttip title="IP address for the PXE server. This will be an unused IP in the build network (example: 192.168.1.250)">
						<TextField
							style={{ marginRight: "40px" }}
							variant="outlined"
							label="PXE Server"
							{...register("pxe_server")}
							defaultValue={info?.pxe_server || ""}
						/>
					</Alerttip>
					<Alerttip title="Enter the Name of the Desired ESXi Datastore (example: nts-Datastore)">
						<TextField
							variant="outlined"
							label="Datastore Name"
							{...register("vcsa_datastore_name")}
							defaultValue={info?.vcsa_datastore_name || ""}
						/>
					</Alerttip>
				</Grid>
			</Paper>
		</Container>
	);
};

export default ESXIVersion;
