import HelpIcon from "@material-ui/icons/HelpOutline";
import Alerttip from "../alertTip/Alerttip";

const AuthFooter = () => {
    const VERSION_TAG = process.env.REACT_APP_VERSION_TAG;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
                fontSize: ".9rem",
                marginTop: "30px",
            }}
        >
            <p style={{ color: "white", alignSelf: "center" }}>
                &#xa9; NexTech Solutions. All Rights Reserved
            </p>
            <a
                style={{
                    textDecoration: "none",
                    marginLeft: "20px",
                    alignSelf: "center",
                    color: "red",
                }}
                href="/docs/index.html"
                target="_blank"
            >
                <Alerttip title="Help">
                    <HelpIcon style={{ fontSize: "2rem", color: "white" }} />
                </Alerttip>
            </a>
            <p style={{ color: "white", alignSelf: "center" }}>
                Version {VERSION_TAG}
            </p>
        </div>
    );
};

export default AuthFooter;
