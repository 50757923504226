import {
    Box,
    Card,
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import ConstructionIcon from "@mui/icons-material/Construction";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import ProgressBar from "../create/ProgressBar";
import NetworkBuildReview from "./NetworkBuildReview";
import NetworkUtilReview from "./NetworkUtilReview";
import PlaybookBuildReview from "./PlaybookBuildReview";
import VirtualNetworkBuildReview from "./VirtualNetworkReview";
import Item from "./reviewHelpers/Item";
import ReviewBuildInfo from "./reviewHelpers/ReviewBuildInfo";
import ReviewButtons from "./reviewHelpers/ReviewButtons";
import ReviewHosts from "./reviewHelpers/ReviewHosts";
import ReviewNetworking from "./reviewHelpers/ReviewNetworking";
import ReviewVCSA from "./reviewHelpers/ReviewVCSA";
import ReviewVMs from "./reviewHelpers/ReviewVMs";
import useStyles from "./styles";
import "./styles.scss";

const ReviewContent = ({ buildObj, getBuilds }) => {
    const complete = buildObj;
    const [isRunning, setIsRunning] = useState(false);
    const [res, setRes] = useState();
    const token = useContext(Context);
    const classes = useStyles();
    let { build_type, id } = useParams();

    let baseURL = "";
    let getKeysURL = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = `http://localhost:80/api/${build_type}`;
        getKeysURL = "http://localhost:80/api/keygen/user_keys";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = `/api/${build_type}`;
        getKeysURL = "/api/keygen/user_keys";
    }

    const getStatus = async () => {
        await fetch(baseURL + "/" + id, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                setRes(resdata);
                if (resdata?.vm_tasks_status !== "in_progress") {
                    setIsRunning(false);
                } else {
                    setIsRunning(true);
                }
            })
            .catch((error) => {
                alert("Error Providing Status, Please Try Again");
            });
    };

    useEffect(() => {
        if (isRunning) {
            const id = window.setInterval(() => {
                getStatus();
            }, 10000);
            return () => window.clearInterval(id);
        }
        return null;
    }, [isRunning]);

    const dataCenter = complete?.tasks?.datacenter;

    return (
        <Container id="review">
            {!id ? (
                <Paper id="reviewInner" className={classes.paper}>
                    <Typography component="h4" variant="h4">
                        Please select a build to review.
                    </Typography>
                </Paper>
            ) : complete?.Error ? (
                <Paper id="reviewInner" className={classes.paper}>
                    <Typography component="h4" variant="h4">
                        Build does not exist.
                    </Typography>
                </Paper>
            ) : (
                <Paper id="reviewInner" className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12} id="reviewTitle">
                            <Item
                                style={{
                                    width: "100%",
                                    display: "inline-block",
                                }}
                            >
                                <Typography component="h4" variant="h4">
                                    <ConstructionIcon />
                                    Build Review
                                </Typography>
                                <ReviewButtons
                                    res={res}
                                    classes={classes}
                                    complete={complete}
                                    dataCenter={dataCenter}
                                    baseURL={baseURL}
                                    id={id}
                                    build_type={build_type}
                                    setIsRunning={setIsRunning}
                                    getBuilds={getBuilds}
                                />
                            </Item>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            id="buildInfo"
                            className={classes.accordionBox}
                        >
                            <ReviewBuildInfo
                                complete={complete}
                                classes={classes}
                            />
                        </Grid>

                        {isRunning ? (
                            <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                id="buildInfo"
                                style={{ position: "relative" }}
                            >
                                <Item>
                                    <div class="vmPostInstallProgress">
                                        <Typography component="h6" variant="h6">
                                            <PendingActionsIcon /> VM Post
                                            Install:
                                            <span>In Progress...</span>
                                        </Typography>
                                        {/* VM Post install progress bar */}
                                        <ProgressBar />
                                    </div>
                                </Item>
                            </Grid>
                        ) : null}
                    </Grid>
                    {!isRunning && build_type !== "network" ? (
                        <Box sx={{ mt: 2, width: "100%" }}>
                            <Grid container spacing={2}>
                                {build_type === "build" ||
                                build_type === "virtual-network-builds" ? (
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Item>
                                            {!complete?.config?.hosts ? (
                                                <Card
                                                    className={classes.card}
                                                    class="card noData"
                                                >
                                                    <Typography
                                                        component="h5"
                                                        variant="h5"
                                                        style={{
                                                            marginBottom:
                                                                "20px",
                                                        }}
                                                    >
                                                        No data added for Hosts.
                                                    </Typography>
                                                </Card>
                                            ) : (
                                                <ReviewHosts
                                                    complete={complete}
                                                    classes={classes}
                                                />
                                            )}
                                        </Item>
                                    </Grid>
                                ) : null}
                                {build_type === "build" ||
                                build_type === "virtual-network-builds" ? (
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Item>
                                            {build_type === "build" ? (
                                                <>
                                                    <ReviewVCSA
                                                        complete={complete}
                                                        classes={classes}
                                                    />
                                                    <ReviewVMs
                                                        complete={complete}
                                                        classes={classes}
                                                    />
                                                    <ReviewNetworking
                                                        complete={complete}
                                                        classes={classes}
                                                    />
                                                    {!complete?.config
                                                        ?.vcsa_system_name &&
                                                        !complete
                                                            ?.post_build_scan
                                                            ?.vms &&
                                                        !complete
                                                            ?.post_build_scan
                                                            ?.networking
                                                            ?.dswitches && (
                                                            <Card
                                                                className={
                                                                    classes.card
                                                                }
                                                                class="card noData"
                                                            >
                                                                <Typography
                                                                    component="h5"
                                                                    variant="h5"
                                                                    style={{
                                                                        marginBottom:
                                                                            "20px",
                                                                    }}
                                                                >
                                                                    No data
                                                                    added for
                                                                    VCSA, VM's,
                                                                    or
                                                                    Networking.
                                                                </Typography>
                                                            </Card>
                                                        )}
                                                </>
                                            ) : (
                                                <VirtualNetworkBuildReview
                                                    complete={complete}
                                                />
                                            )}
                                        </Item>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Box>
                    ) : null}
                    {/* Render Physical Network */}
                    {build_type === "network-builds" && (
                        <NetworkBuildReview
                            complete={complete}
                            classes={classes}
                        />
                    )}
                    {build_type === "network-utils" && (
                        <NetworkUtilReview
                            complete={complete}
                            classes={classes}
                        />
                    )}
                    {build_type === "playbooks" && (
                        <PlaybookBuildReview
                            complete={complete}
                            classes={classes}
                        />
                    )}
                </Paper>
            )}
        </Container>
    );
};

export default ReviewContent;
