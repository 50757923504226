import bg from "../../images/mantleBG.jpg";
import UpdatePasswordForm from "./UpdatePasswordForm";
import AuthFooter from "./AuthFooter";


const UpdatePassword = () => {

    return (
        <div
            style={{
                backgroundImage: `url(${bg})`,
                width: "100vw",
                backgroundRepeat: "no-repeat",
                height: "100vh",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <UpdatePasswordForm displayTitle={true} />
            <AuthFooter />
        </div>
    );

}

export default UpdatePassword
