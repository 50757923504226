import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
		},
	},

	paper: {
		padding: theme.spacing(3),
		marginTop: "250px",
		width: "32vw",
		backgroundColor: "#EAEAEA",
		height: "30vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		"@media (max-width: 650px)": {
			width: "60vw",
		},
	},
	form: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
	},
	fileInput: {
		margin: "10px 0",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		minWidth: "600px",
	},
	button: {
		marginBottom: 10,
		backgroundColor: "#EA2331",
		color: "#333333",
		width: "9rem",
		marginRight: "20px",
		"&:hover": {
			backgroundColor: "#333333",
			color: "#EA2331",
		},
	},

	select: {
		maxWidth: "80px",
		display: "inline-block",
		overflow: "hidden",
		textOverflow: "ellipsis",
		marginBottom: "-10px",
		'&:before': {
			borderColor: "black",
		},
		'&:after': {
			borderColor: "black",
		}
	  },	

	typeSelect: {
		'&:before': {
			borderColor: "black",
		},
		'&:after': {
			borderColor: "black",
		}
	  },
	textField: {
		'& .MuiInput-underline:before': {
			borderColor: "black",
		},
		'& .MuiInput-underline:after': {
			borderColor: "black",
		}
	},	
	  
}));
