import {createContext, useReducer, useEffect} from "react";
import Reducer from "./Reducers";

const INITIAL_STATE = {
    token: sessionStorage.getItem("token"),
    isFetching:false,
    error: false,
};

export const Context = createContext(INITIAL_STATE);

export const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

    useEffect(()=>{
        sessionStorage.setItem("token",state.token)
    },[state.token])

    return(
        <Context.Provider value={{
            token:state.token,
            isFetching:state.isFetching,
            error:state.error,
            dispatch,
        }}>
            {children}
        </Context.Provider>
    )
}