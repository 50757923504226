import {
    Button,
    Container,
    Fab,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";


const Ovas = ({ info, classes, register, ovaFile, controls, getValues, unregister }) => {

    const { control, setValue, watch } = useForm({});

    const { fields, append, update, remove } = useFieldArray({
        control: controls,
        name: "ovas",
    });
    const handleAddOvaFields = () => {
        append({
            filename: "",
            port_mapping: []
        });
    };

    const handleUpdatePortFields = async (outerIndex, ova) => {
        update(outerIndex, {
            filename: ova.filename,
            port_mapping: ova.port_mapping
        });
        setValue(`ovas[${outerIndex}].port_mapping`, ova.port_mapping);
        setValue(`ovas[${outerIndex}].filename`, ova.filename);
        if (watch(`ovas[${outerIndex}].filename`) !== getValues(`ovas[${outerIndex}].filename`)) {
            unregister(`ovas[${outerIndex}].port_mapping`)
        }
    };

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));

    const handleDelete = (index) => {
        remove(index)
    }

    return (
        <Container component="main" maxWidth="xl">
            <Paper
                style={{ display: "flex", flexDirection: "column" }}
                className={classes.paper}
                elevation={6}
            >
                <Typography component="h1" variant="h5">
                    OVA
                </Typography>
                <Fab
                    variant="extended"
                    style={{ marginTop: "15px" }}
                    onClick={handleAddOvaFields}
                >
                    <AddIcon />
                    OVA
                </Fab>
                <form
                    autoComplete="off"
                    className={classes.form}
                >
                </form>
                {fields.map((item, outerIndex) => (
                    <div key={item.id}>
                        <div>
                            <div>
                                <div style={{ display: "flex" }}>
                                    <Alerttip title="Select the Path to the OVA.">
                                        <InputLabel
                                            style={{
                                                color: "black",
                                                display: "flex",
                                                marginTop: "40px",
                                                marginRight: "25px",
                                            }}
                                        >
                                            OVA Path:
                                        </InputLabel>
                                    </Alerttip>

                                    <Controller
                                        style={{ marginLeft: "45px" }}
                                        control={controls}
                                        name={`ovas[${outerIndex}].filename`}
                                        defaultValue={fields[outerIndex]?.filename || ""}
                                        render={({ field }) => (
                                            <Select
                                                className={classes.select}
                                                defaultValue={""}
                                                {...field}
                                            >
                                                {ovaFile?.map((ova, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={ova.filename}

                                                        onClick={() => {
                                                            handleUpdatePortFields(outerIndex, ova)
                                                        }}
                                                    >
                                                        {ova.filename}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    <Alerttip title="Remove OVA">
                                        <IconButton size="small" style={{ display: "flex", marginLeft: "40px" }}>
                                            <DeleteIcon
                                                sx={{ color: "#D3D3D3" }}
                                                onClick={() => {
                                                    handleDelete(outerIndex);
                                                }}
                                            >
                                                Delete
                                            </DeleteIcon>
                                        </IconButton>
                                    </Alerttip>
                                </div>
                            </div>
                        </div>

                        {fields[outerIndex]?.port_mapping?.length > 0 ?
                            < Container component="main" maxWidth="xl">
                                <Typography component="h4" variant="h6" style={{ marginTop: "20px" }}>
                                    Port Mapping:
                                </Typography>
                                <form autoComplete="off" className={classes.form}>
                                    {fields[outerIndex].port_mapping?.map((item, index) => (
                                        <div key={index}>
                                            <TextField
                                                variant="outlined"
                                                style={{ margin: "20px" }}
                                                {...register(`ovas[${outerIndex}].port_mapping.${index}.interface`,
                                                )
                                                }
                                                value={item.interface}
                                                autoComplete="false"
                                            />
                                            <TextField
                                                variant="outlined"
                                                style={{ margin: "20px" }}
                                                label="Port Group"
                                                {...register(`ovas[${outerIndex}].port_mapping.${index}.port_group`,
                                                )
                                                }
                                                defaultValue={item.port_group || ""}
                                                autoComplete="false"
                                            />

                                        </div>
                                    ))}
                                </form>
                            </Container>
                            : ""}
                    </div>
                ))}

            </Paper>
        </Container >
    )
};

export default Ovas;
