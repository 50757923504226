import {
	Box,
	useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/Context";
import BuildsDisplay from "./BuildsDisplay";

const PriorTable = () => {
	const [getBuilds, setGetBuilds] = useState([]);
	const token = useContext(Context);
	const isMobile = useMediaQuery('(max-width:620px)')

	let baseURL = "";
	if (process.env.NODE_ENV === "development") {
		baseURL = "http://localhost:80/api/build/builds";
	} else if (process.env.NODE_ENV === "production") {
		baseURL = "/api/build/builds";
	}

	const getAllBuilds = async () => {
		await fetch(baseURL, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token.token}`,
			},
		})
			.then((response) => response.json())
			.then((resdata) => {
				setGetBuilds(resdata.builds);
			})
			.catch((error) => {
				alert("Error Retrieving Builds", error);
			});
	};

	useEffect(() => {
		getAllBuilds();
	}, []);

	return (
		<div>
			<Box>
				<BuildsDisplay type="datacenter" title="Datacenter Builds" data={getBuilds} isMobile={isMobile} />
			</Box>
		</div>
	);
};

export default PriorTable;
