import React from 'react';

import { LinearProgress, Box } from '@material-ui/core';

const ProgressBar = ({style}) => {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress style={style} />
        </Box>
    )
}

export default ProgressBar
