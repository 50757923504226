import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function BuildProgressBar(props) {
    return (
        <div className={props.classes.pbDiv}>
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    size="7rem"
                    thickness={4}
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    variant="determinate"
                    value={100} />
                <CircularProgress
                    size="7rem"
                    thickness={4}
                    sx={{
                        color: (props.buildfailed ? '#b71c1c' : '#43a047'),
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    variant={props.value > 0 ? "determinate" : "indeterminate"}
                    value={props.value}
                />
                <Box className={props.classes.pbLabel}>
                    <Typography
                        component="h1"
                        variant="h5"
                        style={{
                            paddingTop: '0px',
                            paddingLeft: '7px'
                        }}
                        color="textSecondary"
                    >{`${Math.round(props.value)}`}</Typography>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="textSecondary"
                        style={{ paddingTop: "0px" }}
                    >%</Typography>
                </Box>
            </Box>
        </div>
    );
};

export default BuildProgressBar;