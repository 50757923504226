import {
    Button,
    Card,
    Container,
    Fab,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Alerttip from "../../alertTip/Alerttip";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Context } from "../../../context/Context";
import useGlobalStyle from "../../../themes/globalStyle";
import { GetPlaybooks } from "../../../queries/PlaybooksQuery";

const Playbooks = ({
    info,
    register,
    controls,
    unregister,
    setValue,
    watch,
}) => {
    const token = useContext(Context);
    const globalClasses = useGlobalStyle();
    const [showConfigVars, setShowConfigVars] = useState({});

    const { fields, append, remove } = useFieldArray({
        control: controls,
        name: "playbooks",
        variables: {},
    });

    const handleAddFields = () => {
        append({
            name: "",
        });
    };

    const { data: playbooks, error, isLoading } = GetPlaybooks(token);

    const handleUpdateVariables = async (index, i) => {
        setValue(`playbooks[${index}].name`, playbooks[i].name);
        setValue(`playbooks[${index}].filename`, playbooks[i].filename);
        unregister(`playbooks[${index}].variables`);
    };

    const handleDelete = (index) => {
        remove(index);
    };

    const handleShowConfigVars = (id) => {
        setShowConfigVars((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error occurred: {error.message}</div>;

    return (
        <Container maxWidth="xl" style={{ paddingBottom: "50px" }}>
            <Paper className={globalClasses.BuildFormPaper} elevation={6}>
                <Typography component="h1" variant="h5">
                    Playbooks
                </Typography>
                <Fab
                    variant="extended"
                    style={{ marginTop: "15px" }}
                    onClick={handleAddFields}
                >
                    <AddIcon />
                    Add a Playbook
                </Fab>
                <form autoComplete="off" className={globalClasses.BuildForm}>
                    {fields?.map((item, index) => (
                        <Card
                            key={item.id}
                            className={globalClasses.BuildFormCard}
                            spacing={2}
                            name="playbooks"
                            id={item.id}
                        >
                            <div className={globalClasses.divRow}>
                                <InputLabel
                                    className={globalClasses.customInputLabel}
                                >
                                    Playbook:
                                </InputLabel>

                                <Controller
                                    name={`playbooks.${index}.name`}
                                    control={controls}
                                    defaultValue={
                                        info?.playbooks?.[index]?.name || ""
                                    }
                                    render={({ field }) => (
                                        <Select {...field}>
                                            {playbooks?.map((playbook, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={playbook.name}
                                                    onClick={() => {
                                                        handleUpdateVariables(
                                                            index,
                                                            i
                                                        );
                                                    }}
                                                >
                                                    {playbook.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <Alerttip title="Remove Playbook">
                                    <IconButton
                                        size="small"
                                        style={{
                                            display: "flex",
                                            marginLeft: "20px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <DeleteIcon
                                            sx={{ color: "#D3D3D3" }}
                                            onClick={() => {
                                                handleDelete(index);
                                            }}
                                        >
                                            Delete
                                        </DeleteIcon>
                                    </IconButton>
                                </Alerttip>
                            </div>
                            <div className={globalClasses.divRow}>
                                <Button
                                    className={globalClasses.buildButtons}
                                    onClick={handleShowConfigVars}
                                >
                                    {showConfigVars
                                        ? "Hide Variables"
                                        : "Show Variables"}
                                </Button>
                            </div>
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {playbooks?.map((p) => {
                                    if (
                                        p.name ===
                                        watch(`playbooks[${index}].name`)
                                    ) {
                                        return p?.playbook_variables?.map(
                                            (v, i) => (
                                                <div
                                                    key={i}
                                                    style={{
                                                        display: showConfigVars
                                                            ? "inline-flex"
                                                            : "none",
                                                        margin: "20px 0",
                                                        width: "230px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            value={v}
                                                            {...register(
                                                                `playbooks[${index}].variables[${i}].name`
                                                            )}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "inline",
                                                        }}
                                                    >
                                                        <label>
                                                            {v.toUpperCase()}
                                                        </label>

                                                        <TextField
                                                            variant="outlined"
                                                            {...register(
                                                                `playbooks[${index}].variables[${i}].value`
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        );
                                    }
                                })}
                            </div>
                        </Card>
                    ))}
                </form>
            </Paper>
        </Container>
    );
};

export default Playbooks;
