import { AppBar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSystemSettingsContext } from "../../context/SystemSettings";
import { CLASSIFICATIONS } from "../applianceSettings/ClassificationSettings";
import useStyles from "./styles";

const ClassifiedBanner = () => {
    const classes = useStyles();
    const [classificationObj, setClassificationObj] = useState();
    const { systemSettings } = useSystemSettingsContext();

    let getApplianceSettings = "";
    if (process.env.NODE_ENV === "development") {
        getApplianceSettings = "http://localhost:80/api/appliance-settings/appliance_settings";
    } else if (process.env.NODE_ENV === "production") {
        getApplianceSettings = "/api/appliance-settings/appliance_settings";
    }

    const SetBannerInfo = () => {
        for (const cls of CLASSIFICATIONS) {
            if (cls.value === systemSettings.classification) {
                setClassificationObj(cls)
            }
        }
    }

    useEffect(() => {
        SetBannerInfo();
    }, [systemSettings.classification]);

    return (
        <AppBar style={{
            background: classificationObj?.color,
            color: classificationObj?.value === "top_secret_sci" ? "black" : "white",
            display: classificationObj?.value === "none" ? classificationObj?.value : ""
        }}
            className={classes.appBar}
            position="static">
            {classificationObj?.display_name?.toUpperCase()}
        </AppBar>
    );
};

export default ClassifiedBanner;