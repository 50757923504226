import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  
  paper: {
    padding: theme.spacing(3),
    marginTop:"250px",
    width: '32vw',
    backgroundColor:"#EAEAEA",
    height:"30vh",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    '@media (max-width: 650px)': {
      width: "60vw"
  }
   

  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    
  },
  fileInput: {
    margin: '10px 0',
  },
  button: {
    marginBottom: 10,
    backgroundColor: '#EA2331',
    color: '#333333',
    width: '9rem',
    marginRight: '20px',
    "&:hover":{
        backgroundColor: '#333333',
        color: '#EA2331',
      }
  },
}));
