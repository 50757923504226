import {
    Box,
    Button,
    Fab,
    FormControlLabel,
    FormGroup,
    Tab,
    Switch,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";
import { useSystemSettingsContext } from "../../context/SystemSettings";
import ESXIVersion from "./Advanced/ESXIVersion";
import NetworkSettings from "./Advanced/NetworkSettings";
import Bios from "./BIOS/Bios";
import AddedVSwitch from "./ISO/AddedVSwitch";
import AddedVmk from "./ISO/AddedVmk";
import AdvOptions from "./ISO/AdvOptions";
import Host from "./ISO/Host";
import Settings from "./ISO/Settings";
import VSwitchVmks from "./ISO/VSwitchVmks";
import PostInstall from "./Post Install/PostInstall";
import VMAdvOptions from "./Post Install/VmAdvOptions";
import VSANSettings from "./VCSA/VSANSettings";
import VSettings from "./VCSA/VSettings";
import Playbooks from "./Playbooks/Playbooks";
import useStyles from "./styles";

export default function NewBuild({ hostsLength }) {
    const location = useLocation();
    const info = location?.state?.files?.config || location.state?.files;
    const classes = useStyles();
    const token = useContext(Context);
    const history = useHistory();
    const handleChange = (e, newValue) => {
        setTabValue(newValue);
    };
    const [tabValue, setTabValue] = useState("0");
    const [showBios, setShowBios] = useState(
        info?.do_bios || info?.do_bios === undefined ? true : ""
    );
    const [show2, setShow2] = useState(
        info?.do_pxe || info?.do_pxe === undefined ? true : ""
    );
    const [show3, setShow3] = useState(true);
    const [showVSAN, setShowVSAN] = useState(
        info?.do_vsan || info?.do_vsan === undefined ? true : ""
    );
    const [show4, setShow4] = useState(
        info?.do_distributed_switch || info?.do_distributed_switch === undefined
            ? true
            : ""
    );
    const [show5, setShow5] = useState(
        info?.do_post_install || info?.do_post_install === undefined ? true : ""
    );
    const [showTaclan, setShowTaclan] = useState(
        info?.taclan ? info.taclan : false
    );
    const [showESXIOptions, setShowESXIOptions] = useState(
        info?.esxi_advanced_options === undefined ? false : true
    );
    const [showVMOptions, setShowVMOptions] = useState(
        info?.vm_advanced_options === undefined ? false : true
    );
    const [showAnsible, setShowAnsible] = useState(true);
    const handleShow4 = () => {
        setShow4(!show4);
    };
    const handleShow5 = () => {
        setShow5(!show5);
    };
    const handleShow2 = () => {
        setShow2(!show2);
    };
    const handleShowVSAN = () => {
        setShowVSAN(!showVSAN);
    };
    const handleTaclan = () => {
        setShowTaclan(!showTaclan);
    };
    const handleShowBios = () => setShowBios(!showBios);
    const handleShowAnsible = () => setShowAnsible(!showAnsible);
    const { systemState } = useSystemSettingsContext();

    let baseURL = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://localhost:80/api/build/validate-workflow";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "/api/build/validate-workflow";
    }
    const [showStandaloneHost, setShowStandaloneHost] = useState(
        info?.do_standalone ? true : false
    );

    const allBios = info?.bios_devices;
    const allHosts = info?.hosts;
    const allAdded = info?.added_vswitches;
    const allVMK = info?.added_vmks;
    const allVms = info?.vms;
    const allOvas = info?.ovas;
    const allEsxiAdvOpt = info?.esxi_advanced_options;
    const playbooks = info?.playbooks;

    const esxiLicense = [];
    const vsanLicense = [];
    const vcsaLicense = [];

    systemState.licenses?.map((l) =>
        l.license_type === "esxi"
            ? esxiLicense.push(l)
            : l.license_type === "vsan"
            ? vsanLicense.push(l)
            : vcsaLicense.push(l)
    );

    const {
        register,
        unregister,
        control,
        handleSubmit,
        getValues,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            bios_devices: allBios || [
                {
                    tag: [],
                    port: [],
                },
            ],

            hosts: allHosts || [
                {
                    ip: "",
                    hostname: "",
                    pw: "",
                    user: "",
                },
            ],
            //initializing all build elements in NewBuild will ensure they are registered before selecting each tab
            //ESXI/BUILD SETTINGS:
            esxi_ver: info?.esxi_ver || "",
            esxi_license: info?.esxi_license || "",
            subnet: info?.subnet || "",
            domain: info?.domain || "",
            pxe_server: info?.pxe_server || "",
            vcsa_datastore_name: info?.vcsa_datastore_name || "",
            //ESXI/NETWORK SETTINGS:
            netmask: info?.netmask || "",
            vcsa_gateway: info?.vcsa_gateway || "",
            vcsa_dns_0: info?.vcsa_dns_0 || "",
            vcsa_dns_1: info?.vcsa_dns_1 || "",
            vcsa_ntp_server_0: info?.vcsa_ntp_server_0 || "",
            vcsa_ntp_server_1: info?.vcsa_ntp_server_1 || "",
            vlanid: info?.vlanid || "",
            esxi_ssh_enable: info?.esxi_ssh_enable || false,
            //ESXI/vSwitch0 SETTINGS:
            vswitch0_standard_uplink: info?.vswitch0_standard_uplink || "",
            additional_vswitch0_uplinks:
                info?.additional_vswitch0_uplinks || [],
            vm_portgroup: info?.vm_portgroup || "",
            vm_portgroup_vlan: info?.vm_portgroup_vlan || "",
            extra_kickstart: info?.extra_kickstart || "",
            added_vswitches: allAdded ||
                [] || [
                    {
                        name: "",
                        port_groups: [{ name: "", vlan: "" }],
                        uplinks: [],
                        vlan: "",
                    },
                ],
            added_vmks: allVMK ||
                [] || [
                    {
                        name: "",
                        port_group: "",
                        network: "",
                        mask: "",
                        VMotion: "",
                    },
                ],
            esxi_advanced_options: allEsxiAdvOpt ||
                [] || [{ key: "", value: "" }],
            //VCSA & VSAN
            vcsa_system_name: info?.vcsa_system_name || "",
            vcsa_appliance_name: info?.vcsa_appliance_name || "",
            vcsa_datacenter_name: info?.vcsa_datacenter_name || "",
            vcsa_cluster_name: info?.vcsa_cluster_name || "",
            vcsa_ip: info?.vcsa_ip || "",
            vcsa_sso_user: info?.vcsa_sso_user || "",
            vcsa_sso_domain: info?.vcsa_sso_domain || "",
            vcsa_sso_pass: info?.vcsa_sso_pass || "",
            vcsa_os_password: info?.vcsa_os_password || "",
            vcsa_network_prefix: info?.vcsa_network_prefix || "",
            vcsa_ssh_enable: info?.vcsa_ssh_enable || false,
            vcsa_appliance_thin_disk_mode:
                info?.vcsa_appliance_thin_disk_mode || false,
            vcsa_license: info?.vcsa_license || "",
            vcsa_appliance_deployment_option:
                info?.vcsa_appliance_deployment_option || "",
            vcsa_ver: info?.vcsa_ver || "",
            vsan_vmknic: info?.vsan_vmknic || "",
            vsan_license: info?.vsan_license || "",
            //ADVANCED NETWORKING
            dvs_name: info?.dvs_name || "",
            dv_port_name: info?.dv_port_name || "",
            vmnic_distributed_switch: info?.vmnic_distributed_switch || "",
            //POST INSTALL
            vms: allVms ||
                [] || [
                    {
                        name: "",
                        ip: "",
                        ram_in_gb: "",
                        cpu_cores: "",
                        datastore_iso_path: "",
                        ovas: "",
                        hdds: [],
                        networks: [],
                    },
                ],
            ovas: allOvas || [],
            playbooks: playbooks || [
                {
                    name: "",
                    variables: {
                        name: "",
                        value: "",
                    },
                },
            ],
            do_bios: info?.do_bios,
            do_pxe: info?.do_pxe,
            do_distributed_switch: info?.do_distributed_switch,
            do_post_install: info?.do_post_install,
            do_vsan: info?.do_vsan,
            taclan: info?.taclan,
            do_standalone: info?.do_standalone,
            do_ansible: info?.do_ansible,
        },
        mode: "onBlur",
        reValidateMode: "onChange",
        shouldUnregister: false,
    });

    const saveData = async (data) => {
        for (const vm of data.vms) {
            let newNetworks = [];
            for (const net of vm.networks) {
                newNetworks.push(...Object.values(net));
            }
            vm.networks = newNetworks;
        }
        // console.log("data", data);
        await fetch(baseURL, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                history.push({
                    pathname: "/track-build",
                    state: {
                        data: data,
                        resdata: resdata,
                        kit_id: data.kit_id,
                    },
                });
            })
            .catch((error) => {
                alert("Error Creating Build. Please Try Again", error);
            });
    };

    const handleSingleEXSiHost = () => {
        // console.log(getValues(`hosts`));
        setShowStandaloneHost(!showStandaloneHost);
        if (showStandaloneHost) {
            document.getElementById("addEXSiHost").style.display = "none";
        } else {
            document.getElementById("addEXSiHost").style.display =
                "inline-flex";
        }
    };

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));
    return (
        <Box
            sx={{
                width: "100%",
                typography: "body1",
                backgroundColor: "#EAEAEA",
            }}
        >
            {info ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Alert
                        style={{ marginBottom: "10px", width: "30%" }}
                        severity="warning"
                    >
                        Please Review Each Tab
                    </Alert>
                </div>
            ) : (
                ""
            )}

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={handleSubmit(saveData)}
                    style={{ fontWeight: "bolder", letterSpacing: "3px" }}
                    className={classes.buildbtn}
                >
                    Build
                </Button>
                <input
                    className={classes.kitID}
                    placeholder="Kit ID"
                    {...register("kit_id")}
                    defaultValue=""
                    autoComplete="false"
                />
            </div>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} variant="fullWidth">
                        <Tab className={classes.text} label="BIOS" value="0" />

                        <Tab className={classes.text} label="ESXI" value="1" />

                        <Tab
                            label="VCSA & VSAN"
                            className={
                                showStandaloneHost ? "disabled" : classes.text
                            }
                            disabled={showStandaloneHost}
                            value="2"
                        />

                        <Tab
                            className={
                                showStandaloneHost ? "disabled" : classes.text
                            }
                            disabled={showStandaloneHost}
                            label="Advanced Networking"
                            value="3"
                        />
                        <Tab
                            className={classes.text}
                            label="Post Install"
                            value="4"
                        />
                        <Tab
                            className={classes.text}
                            label="Playbooks"
                            value="5"
                        />
                    </TabList>
                </Box>

                <TabPanel value="0">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormGroup
                            style={{ display: "block", marginLeft: "30px" }}
                        >
                            <FormControlLabel
                                style={{
                                    backgroundColor: "#cccccc",
                                    height: "50px",
                                    width: "100px",
                                    borderRadius: "5%",
                                }}
                                control={
                                    <input
                                        type="checkbox"
                                        style={{ opacity: 0 }}
                                        onClick={handleShowBios}
                                        checked={showBios}
                                        {...register("do_bios")}
                                    />
                                }
                                label={showBios ? "Disable" : " Enable"}
                            />
                        </FormGroup>
                    </div>
                    {showBios && (
                        <>
                            <Bios
                                info={info}
                                controls={control}
                                register={register}
                                unregister={unregister}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                            />
                        </>
                    )}
                </TabPanel>

                <TabPanel value="1">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormGroup
                            style={{ display: "block", marginLeft: "30px" }}
                        >
                            <FormControlLabel
                                style={{
                                    backgroundColor: "#cccccc",
                                    height: "50px",
                                    width: "100px",
                                    borderRadius: "5%",
                                }}
                                control={
                                    <input
                                        type="checkbox"
                                        style={{ opacity: 0 }}
                                        onClick={handleShow2}
                                        checked={show2}
                                        {...register("do_pxe")}
                                    />
                                }
                                label={show2 ? "Disable" : " Enable"}
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        color="success"
                                        onClick={handleSingleEXSiHost}
                                        checked={showStandaloneHost}
                                        {...register("do_standalone")}
                                    />
                                }
                                label="Standalone Host?"
                            />
                        </FormGroup>
                    </div>
                    {show2 && (
                        <>
                            <Host
                                info={info}
                                control={control}
                                controls={control}
                                register={register}
                                showEXSiAddButton={showStandaloneHost}
                                getValues={getValues}
                                showStandaloneHost={showStandaloneHost}
                            />
                            <ESXIVersion
                                info={info}
                                esxiLicense={esxiLicense}
                                control={control}
                                register={register}
                            />
                            <Settings
                                show2={show2}
                                info={info}
                                control={control}
                                register={register}
                            />
                            <VSwitchVmks
                                info={info}
                                control={control}
                                register={register}
                            />
                            <AddedVSwitch
                                info={info}
                                register={register}
                                controls={control}
                                control={control}
                                getValues={getValues}
                            />
                            <AddedVmk
                                info={info}
                                register={register}
                                controls={control}
                                getValues={getValues}
                            />
                            <Fab
                                variant="extended"
                                style={{
                                    margin: "40px 0 20px 30px",
                                }}
                                onClick={() =>
                                    setShowESXIOptions(!showESXIOptions)
                                }
                            >
                                {showESXIOptions ? "Disable" : "Enable"}{" "}
                                Advanced Options
                            </Fab>
                            {showESXIOptions && (
                                <AdvOptions
                                    info={info}
                                    register={register}
                                    controls={control}
                                />
                            )}
                        </>
                    )}
                </TabPanel>

                <TabPanel value="2">
                    {show3 && (
                        <>
                            <VSettings
                                info={info}
                                vcsaLicense={vcsaLicense}
                                control={control}
                                register={register}
                            />
                        </>
                    )}
                    {(getValues("hosts")?.length || info?.hosts?.length || 1) <
                    3 ? (
                        <p
                            style={{
                                textAlign: "center",
                                marginBottom: "100px",
                            }}
                        >
                            Three EXSi hosts are needed to enable VSAN
                        </p>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    margin: "15px 0",
                                    alignItems: "center",
                                }}
                            >
                                <Alerttip title="Toggle to enable or disable the vSAN installation from the build">
                                    <FormGroup
                                        style={{
                                            display: "block",
                                            marginTop: "20px",
                                            marginLeft: "30px",
                                        }}
                                    >
                                        <FormControlLabel
                                            style={{
                                                backgroundColor: "#cccccc",
                                                height: "50px",
                                                width: "100px",
                                                borderRadius: "5%",
                                            }}
                                            control={
                                                <input
                                                    type="checkbox"
                                                    style={{ opacity: 0 }}
                                                    onClick={handleShowVSAN}
                                                    checked={showVSAN}
                                                    {...register("do_vsan")}
                                                />
                                            }
                                            label={
                                                showVSAN ? "Disable" : " Enable"
                                            }
                                        />
                                    </FormGroup>
                                </Alerttip>
                            </div>
                            {showVSAN && (
                                <VSANSettings
                                    vsanLicense={vsanLicense}
                                    info={info}
                                    register={register}
                                    control={control}
                                />
                            )}
                        </>
                    )}
                </TabPanel>

                <TabPanel value="3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Alerttip title="Toggle to enable or disable the vSAN installation from the build">
                            <FormGroup
                                style={{ display: "block", marginLeft: "30px" }}
                            >
                                <FormControlLabel
                                    style={{
                                        backgroundColor: "#cccccc",
                                        height: "50px",
                                        width: "100px",
                                        borderRadius: "5%",
                                    }}
                                    control={
                                        <input
                                            type="checkbox"
                                            style={{ opacity: 0 }}
                                            onClick={handleShow4}
                                            defaultChecked={show4}
                                            {...register(
                                                "do_distributed_switch"
                                            )}
                                        />
                                    }
                                    label={show4 ? "Disable" : " Enable"}
                                />
                            </FormGroup>
                        </Alerttip>
                    </div>
                    {show4 && (
                        <>
                            <NetworkSettings
                                info={info}
                                show4={show4}
                                control={control}
                                register={register}
                            />
                        </>
                    )}
                </TabPanel>
                <TabPanel value="4">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Alerttip title="Toggle to enable or disable the post installation from the build">
                            <FormGroup
                                style={{ display: "block", marginLeft: "30px" }}
                            >
                                <FormControlLabel
                                    style={{
                                        backgroundColor: "#cccccc",
                                        height: "50px",
                                        width: "100px",
                                        borderRadius: "5%",
                                    }}
                                    control={
                                        <input
                                            type="checkbox"
                                            style={{ opacity: 0 }}
                                            onClick={handleShow5}
                                            checked={show5}
                                            {...register("do_post_install")}
                                        />
                                    }
                                    label={show5 ? "Disable" : " Enable"}
                                />

                                <FormControlLabel
                                    style={{
                                        backgroundColor: "#cccccc",
                                        height: "50px",
                                        width: "100px",
                                        borderRadius: "5%",
                                        display: "none",
                                    }}
                                    control={
                                        <input
                                            type="checkbox"
                                            style={{ opacity: 0 }}
                                            onClick={handleTaclan}
                                            checked={showTaclan}
                                            {...register("taclan")}
                                        />
                                    }
                                    label={showTaclan ? "Disable" : " Enable"}
                                />
                            </FormGroup>
                        </Alerttip>
                    </div>
                    {show5 && (
                        <>
                            <PostInstall
                                show5={show5}
                                info={info}
                                controls={control}
                                register={register}
                                getValues={getValues}
                                unregister={unregister}
                            />
                            <Fab
                                variant="extended"
                                style={{
                                    margin: "40px 0 20px 30px",
                                }}
                                onClick={() => setShowVMOptions(!showVMOptions)}
                            >
                                {showVMOptions ? "Disable" : "Enable"} Advanced
                                Options
                            </Fab>
                            {showVMOptions && (
                                <VMAdvOptions
                                    info={info}
                                    control={control}
                                    register={register}
                                    showVMOptions={showVMOptions}
                                />
                            )}
                        </>
                    )}
                </TabPanel>
                <TabPanel value="5">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Alerttip title="Toggle to enable or disable ansible playbooks from the build">
                            <FormGroup
                                style={{ display: "block", marginLeft: "30px" }}
                            >
                                <FormControlLabel
                                    style={{
                                        backgroundColor: "#cccccc",
                                        height: "50px",
                                        width: "100px",
                                        borderRadius: "5%",
                                    }}
                                    control={
                                        <input
                                            type="checkbox"
                                            style={{ opacity: 0 }}
                                            onClick={handleShowAnsible}
                                            checked={showAnsible}
                                            {...register("do_ansible")}
                                        />
                                    }
                                    label={showAnsible ? "Disable" : " Enable"}
                                />
                            </FormGroup>
                        </Alerttip>
                    </div>
                    {showAnsible && (
                        <Playbooks
                            info={info}
                            controls={control}
                            register={register}
                            unregister={unregister}
                            setValue={setValue}
                            watch={watch}
                        />
                    )}
                </TabPanel>
            </TabContext>
        </Box>
    );
}
