import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";

const NestedUplinks = ({
  nestedIndex,
  info,
  controls
}) => {
  const { fields, append } = useFieldArray({
    control: controls,
    name: `added_vswitches.${nestedIndex}.uplinks`,
  });

  const handleAddFields = (e) => {
    e.preventDefault();
    append({
      uplinks: "",
    });
  };

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  const vmnic = [
    "vmnic0",
    "vmnic1",
    "vmnic2",
    "vmnic3",
    "vmnic4",
    "vmnic5",
    "vmnic6",
    "vmnic7",
    "vmnic8",
    "vusb0",
    "vusb1"
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        style={{ backgroundColor: "lightgrey" }}
        onClick={handleAddFields}
      >
        + Uplinks
      </Button>
      {fields.map((item, k) => (
        <>
          <div
            style={{
              display: "flex",
            }}
          >
            <Alerttip
              key={k}
              title="What physical network uplink would you like to assign to the new vSwitch (example: vmnic1)"
            >
              <InputLabel
                style={{
                  position: "relative",
                  top: "10px",
                  color: "black",
                  display: "flex",
                  margin: "10px 20px",
                }}
              >
                vSwitch Uplink
              </InputLabel>
            </Alerttip>
            <Controller
              style={{ marginLeft: "45px" }}
              control={controls}
              name={`added_vswitches.${nestedIndex}.uplinks.${k}`}
              defaultValue={
                info?.added_vswitches?.[nestedIndex]?.uplinks?.[k] || ""
              }
              render={({ field }) => (
                <Select {...field}>
                  {vmnic?.map((v) => (
                    <MenuItem onChange={field.onChange} key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
        </>
      ))}
    </div>
  );
};

export default NestedUplinks;
