import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright } from "react-syntax-highlighter/dist/esm/styles/hljs";
const CodeBlock = ({ codestring }) => {
    return (
        <SyntaxHighlighter
            language="django"
            showLineNumbers
            wrapLines
            style={tomorrowNightBright}
        >
            {codestring}
        </SyntaxHighlighter>
    );
};

export default CodeBlock;
