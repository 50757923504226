import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import Item from "./Item"
import Alerttip from "./Alerttip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HostAccordion = (props) => {
    const index = props.index
    const host = props.host

    return (
        <Grid
            item
            xs={
                12
            }
            id="hostsTables"
        >
            <Item>
                <div>
                    <div style={{
                        width: "100%",
                    }}
                    >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    Disks
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer
                                    component={
                                        Paper
                                    }
                                >
                                    <Table
                                        style={{
                                            padding:
                                                "15px 30px",
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Vendor
                                                </TableCell>
                                                <TableCell>
                                                    Model
                                                </TableCell>
                                                <TableCell>
                                                    Capacity
                                                </TableCell>
                                                <TableCell>
                                                    Canonical
                                                    Name
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            style={{
                                                textDecoration:
                                                    "none",
                                                padding:
                                                    "0 20px",
                                            }}
                                            key={
                                                index
                                            }
                                        >
                                            {host?.disks?.map(
                                                (
                                                    disk,
                                                    index
                                                ) => (
                                                    <TableRow
                                                        key={
                                                            index
                                                        }
                                                    >
                                                        <TableCell scope="row">
                                                            {
                                                                disk.vendor
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                disk.model
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                disk.capacity
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Alerttip
                                                                title={
                                                                    disk.canonical_name
                                                                }
                                                            >
                                                                <span>
                                                                    {disk.canonical_name.slice(
                                                                        0,
                                                                        12
                                                                    )}
                                                                    ...
                                                                </span>
                                                            </Alerttip>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    Datastores
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table
                                        style={{
                                            padding:
                                                "15px 30px",
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Name
                                                </TableCell>
                                                <TableCell>
                                                    Type
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight:
                                                            "bold",
                                                        fontSize:
                                                            ".9rem",
                                                        padding:
                                                            "15px 15px 5px",
                                                    }}
                                                >
                                                    Capacity
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            style={{
                                                textDecoration:
                                                    "none",
                                                padding:
                                                    "0 20px",
                                            }}
                                            key={
                                                index
                                            }
                                        >
                                            {host?.datastores?.map(
                                                (
                                                    datastores,
                                                    index
                                                ) => (
                                                    <TableRow
                                                        key={
                                                            index
                                                        }
                                                    >
                                                        <TableCell scope="row">
                                                            {
                                                                datastores.name
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {datastores.type.toString()}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                datastores.capacity_h
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    VMKs
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer
                                    component={
                                        Paper
                                    }
                                >
                                    <Table
                                        style={{
                                            padding:
                                                "15px 30px",
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Device
                                                </TableCell>
                                                <TableCell>
                                                    IP
                                                </TableCell>
                                                <TableCell>
                                                    Netmask
                                                </TableCell>
                                                <TableCell>
                                                    Gateway
                                                </TableCell>
                                                <TableCell>
                                                    MAC
                                                    Address
                                                </TableCell>
                                                <TableCell>
                                                    Portgroup
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            style={{
                                                textDecoration:
                                                    "none",
                                                padding:
                                                    "0 20px",
                                            }}
                                            key={
                                                index
                                            }
                                        >
                                            {host?.vmks?.map(
                                                (
                                                    vm,
                                                    index
                                                ) => (
                                                    <TableRow
                                                        key={
                                                            index
                                                        }
                                                    >
                                                        <TableCell scope="row">
                                                            {
                                                                vm.device
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                vm.ip_address
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                vm.netmask
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                vm.gateway
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                vm.mac
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                vm.portgroup
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </Item>
        </Grid>
    );
};
export default HostAccordion