import React, { useState } from "react";
import { Typography, InputLabel, TextField, MenuItem } from "@material-ui/core";
import Alerttip from "../../alertTip/Alerttip";
import useGlobalStyle from "../../../themes/globalStyle";
import styles from "./styles";

const VirtualNetworkTemplate = ({
    deviceIndex,
    currentDevice,
    register,
    unregister,
    setValue,
    watch,
    getValues,
    info,
    allTemplates,
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const globalStyle = useGlobalStyle();
    const classes = styles();

    const deviceTemplates = allTemplates?.filter(
        (item) =>
            item.device_name ===
            getValues(`network_devices[${deviceIndex}].name`)
    );

    const handleUpdateTemplate = (template) => {
        setSelectedTemplate(template);
        setValue(
            `network_devices[${deviceIndex}].template_name`,
            template.template_name
        );
        unregister(`network_devices[${deviceIndex}].config_variables`);
        for (let [index, cv] of template.config_variables.entries()) {
            setValue(
                `network_devices[${deviceIndex}].config_variables.${index}.name`,
                cv.name
            );
            setValue(
                `network_devices[${deviceIndex}].config_variables.${index}.value`,
                ""
            );
        }
    };

    return (
        <>
            <Typography
                component="h1"
                variant="h6"
                className={globalStyle.customCardTitle}
            >
                Template
            </Typography>
            <div className={classes.centerContent}>
                <TextField
                    select
                    className={globalStyle.customTextField}
                    variant="outlined"
                    label="Template"
                    {...register(
                        `network_devices[${deviceIndex}.template_name]`
                    )}
                    // value={watch(`network_devices[${deviceIndex}.template_name]`)}
                    defaultValue={
                        getValues(
                            `network_devices[${deviceIndex}].template_name`
                        ) ||
                        info?.virtual_serial_devices[deviceIndex]
                            ?.template_name ||
                        ""
                    }
                    onChange={(e) => {
                        const selectedTemplate = deviceTemplates.find(
                            (template) =>
                                template.template_name === e.target.value
                        );
                        if (selectedTemplate) {
                            handleUpdateTemplate(selectedTemplate);
                        }
                    }}
                >
                    {deviceTemplates?.map((template, index) => (
                        <MenuItem
                            value={template?.template_name}
                            key={template?.template_name}
                        >
                            {getValues(
                                `network_devices[${deviceIndex}].template_name`
                            ) ||
                                template?.template_name ||
                                "Loading..."}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className={classes.centerContent}>
                <TextField
                    select
                    className={globalStyle.customTextField}
                    variant="outlined"
                    label="Port"
                    {...register(`network_devices[${deviceIndex}].port`)}
                    value={watch(`network_devices[${deviceIndex}].port`) || ""}
                    defaultValue={
                        getValues(`network_devices[${deviceIndex}].port`) ||
                        info?.virtual_serial_devices[deviceIndex]?.port ||
                        ""
                    }
                >
                    {[
                        "None",
                        "/dev/char/serial/uart0",
                        "/dev/char/serial/uart1",
                    ].map((port) => (
                        <MenuItem
                            value={port === "None" ? "" : port}
                            key={port}
                        >
                            {port}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div style={{ textAlign: "center" }}>
                {watch(`network_devices[${deviceIndex}].config_variables`)
                    ?.length > 0 && (
                    <>
                        <Typography
                            component="h4"
                            variant="h6"
                            className={globalStyle.customCardTitle}
                        >
                            Template Variables
                        </Typography>
                        {watch(
                            `network_devices[${deviceIndex}].config_variables`
                        )?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "inline-flex",
                                        margin: "5px 0",
                                        width: "230px",
                                    }}
                                >
                                    <div style={{ display: "none" }}>
                                        <TextField
                                            variant="outlined"
                                            value={item?.name}
                                            {...register(
                                                `network_devices[${deviceIndex}].name`
                                            )}
                                        />
                                    </div>
                                    {item?.name.length > 17 ? (
                                        <Alerttip
                                            title={item?.name.toUpperCase()}
                                        >
                                            <TextField
                                                variant="outlined"
                                                label={
                                                    item?.name
                                                        .toUpperCase()
                                                        .slice(0, 16) + "..."
                                                }
                                                {...register(
                                                    `network_devices[${deviceIndex}].config_variables[${index}]`
                                                )}
                                            />
                                        </Alerttip>
                                    ) : (
                                        <TextField
                                            variant="outlined"
                                            label={item?.name.toUpperCase()}
                                            {...register(
                                                `network_devices[${deviceIndex}].config_variables[${index}].value`
                                            )}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </>
    );
};

export default VirtualNetworkTemplate;
