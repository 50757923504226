import { Typography } from "@material-ui/core";

function BuildDuration(props) {
    var diff = new Date() - new Date(props.res?.date);
    var hours = Math.floor(diff / 3.6e6)
        .toString()
        .padStart(2, "0");
    var minutes = Math.floor((diff % 3.6e6) / 6e4)
        .toString()
        .padStart(2, "0");
    var seconds = Math.floor((diff % 6e4) / 1000)
        .toString()
        .padStart(2, "0");
    var duration = hours + ":" + minutes + ":" + seconds;

    const status = props?.res?.status;

    return status === "success" ? (
        <div className={props.classes.buildInfoDiv}>
            <Typography
                component="h1"
                variant="h5"
                className={props.classes.buildInfoLabels}
            >
                Build Duration:
            </Typography>
            <Typography
                component="h1"
                variant="h5"
                className={props.classes.buildInfo}
            >
                <div className={props.classes.buildDuration}>
                    {props?.res?.build_duration}
                </div>
            </Typography>
        </div>
    ) : duration === "NaN:NaN:NaN" ? (
        ""
    ) : status === "in_progress" ? (
        <div className={props.classes.buildInfoDiv}>
            <Typography
                component="h1"
                variant="h5"
                className={props.classes.buildInfoLabels}
            >
                Build Time:
            </Typography>
            <Typography
                component="h1"
                variant="h5"
                className={props.classes.buildInfo}
            >
                <div className={props.classes.buildDuration}>{duration}</div>
            </Typography>
        </div>
    ) : (
        ""
    );
}

export default BuildDuration;
