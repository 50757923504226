import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
root: {
    '& .MuiTextField-root': {
    margin: theme.spacing(2),
    },
    
},
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    '@media (max-width: 450px)' : {
      width: '100%'
    },
  },
  
  form: {
    width: '100%', 
    display: 'flex',
    justifyContent:'space-between',
    marginTop: theme.spacing(2),
    flexDirection: 'column'
  },
  button: {
    backgroundColor: '#EA2331',
    margin: theme.spacing(3, 0, 2),
    "&:hover":{
      backgroundColor: '#333333',
      color: '#EA2331',
    }
  },

  card:{
    cursor: 'pointer',
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems:'center',
    padding: "10px 0 0 0",
    "&:hover":{
      boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
    },
  }
  
  
}));
