import GridItems from "./GridItems";

function Stoplights(props) {
    const res = props.res
    const stoplight_type_obj = props.stoplight_type_obj || [];

    let gridItems = stoplight_type_obj.map(function (e, i) {
        if (Array.isArray(res?.tasks)) {
            return { "task_name": e, "status": res?.tasks?.[i]?.status }
        } else {
            return { "task_name": e, "status": res?.tasks?.[props.stoplight_type]?.[i]?.status };
        }

    });

    return (
        <GridItems
            gridItems={gridItems}
            classes={props.classes}
        />

    );
};

export default Stoplights;