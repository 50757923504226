import { Typography } from "@material-ui/core";

const BuildStatus = (props) => {
    const status = props?.res?.status;
    return (
        <div className={props.classes.buildInfoDiv}>
            <Typography
                component="h1"
                variant="h5"
                className={props.classes.buildInfoLabels}
            >
                Status:
            </Typography>
            <Typography
                component="h1"
                variant="h5"
                style={{
                    display: status === undefined ? "none" : "inline-block",
                }}
                className={props.classes.buildInfo}
            >
                {status?.replace(/[_-]/g, " ").toUpperCase()}
            </Typography>
        </div>
    );
};

export default BuildStatus;
