import {
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import htmr from 'htmr';
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Context } from "../../context/Context";
import useStyles from "./styles";

const PluginReview = () => {
    const [plugin, setPlugin] = useState();
    const token = useContext(Context);
    let { firstReview, secondReview } = useParams();
    const history = useHistory();
    const classes = useStyles();
    let getPluginByName = "";
    if (process.env.NODE_ENV === "development") {
        getPluginByName = "http://localhost:80/api/collections/collection";
    } else if (process.env.NODE_ENV === "production") {
        getPluginByName = "/api/collections/collection";
    }

    useEffect(() => {
        const getPlugin = () => {
            fetch(getPluginByName + "/" + firstReview + "/" + secondReview, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            })
                .then((response) => response.json())
                .then((done) => {
                    console.log(done);
                    setPlugin(done);
                })

                .catch((error) => {
                    alert("Error Retrieving Collection", error);
                });
        };
        getPlugin();
    }, [getPluginByName, firstReview, secondReview, token]);

    const PluginDocs = () => {
        return (
            <div>
                {plugin ? (
                    <div>{htmr(plugin)}</div>
                ) : (
                    <CircularProgress
                        size="3rem"
                        color="error"
                        sx={{
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                    />
                )}
            </div>
        )
    }

    return (
        <Container>
            <Grid container>
                <Paper className={classes.paper} style={{ marginBottom: "50px", }}>
                    <Grid container justifyContent="center" spacing={2}>
                        <PluginDocs />
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
};

export default PluginReview;
