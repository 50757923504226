import {
  Card,
  Container,
  Fab,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useStyles from "./styles";

const AdvOptions = ({ register, info, controls, getValues }) => {
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control: controls,
    name: "esxi_advanced_options",
  });

  const handleAddFields = () => {
    append({ key: "", value: "" });
  };

  const handleDelete = (index) => {
    remove(index)
  }

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Container component="main" maxWidth="xl">
      <Paper
        style={{ display: "flex", flexDirection: "column" }}
        className={classes.paper}
        elevation={6}
      >
        <Typography component="h1" variant="h5">
          Advanced Options
        </Typography>
        <Fab
          variant="extended"
          style={{ marginTop: "15px" }}
          onClick={handleAddFields}
        >
          <AddIcon />
          Options
        </Fab>
        <form autoComplete="off" className={classes.form}>
          {fields.map((item, index) => (
            <div key={item.id}>
              <Card name="esxi_advanced_options" className={classes.card}>
                <InputLabel
                  style={{
                    color: "black",
                    display: "flex",
                    margin: "10px 10px",
                  }}
                >
                  Type:
                </InputLabel>
                <Controller
                  style={{ marginLeft: "10px" }}
                  control={controls}
                  name={`esxi_advanced_options[${index}].type`}
                  defaultValue={info?.esxi_advanced_options[index]?.type || ""}
                  render={({ field }) => (
                    <Select {...field} onChange={field.onChange}>
                      <MenuItem value={"integer"}>Integer</MenuItem>
                      <MenuItem value={"string"}>String</MenuItem>
                    </Select>
                  )}
                />
                <div style={{ display: "flex" }}>
                  <Alerttip title="Do you need any Advanced Systems Settings? If so, enter the Advanced System Setting identifier (example: Config.Etc.motd).">
                    <TextField
                      variant="outlined"
                      style={{ margin: "20px" }}
                      label="Advanced Key"
                      {...register(`esxi_advanced_options[${index}].key`)}
                      defaultValue={
                        info?.esxi_advanced_options[index]?.key || ""
                      }
                      autoComplete="false"
                    />
                  </Alerttip>
                  <Alerttip title="Enter in the value for the Advanced System Settings. For example, for the MOTD, you could enter “Welcome to ESXi, Powered by Mantle!">
                    <TextField
                      variant="outlined"
                      style={{ margin: "20px" }}
                      label="Advanced Value"
                      {...register(`esxi_advanced_options[${index}].value`)}
                      defaultValue={
                        info?.esxi_advanced_options[index]?.value || ""
                      }
                      autoComplete="false"
                    />
                  </Alerttip>
                  <Alerttip title="Remove Option">
                    <IconButton size="small" style={{ display: "flex", marginLeft: "40px", marginTop: "10px" }}>
                      <DeleteIcon
                        sx={{ color: "#D3D3D3" }}
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        Delete
                      </DeleteIcon>
                    </IconButton>
                  </Alerttip>
                </div>
              </Card>
            </div>
          ))}
          <Alerttip title="Do you need to add additional commands to the ESXi Kickstart?">
            <TextField
              variant="outlined"
              style={{ margin: "20px" }}
              label="Extra Kickstart Commands"
              multiline
              {...register("extra_kickstart")}
              defaultValue={
                info?.extra_kickstart || ""
              }
              autoComplete="false"
            />
          </Alerttip>
        </form>
      </Paper>
    </Container>
  );
};

export default AdvOptions;
