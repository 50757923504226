import React, { useState, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import {
    Button,
    Grid,
    TextField,
    MenuItem,
    IconButton,
    Typography,
    Box,
} from "@material-ui/core";
import useStyles from "./styles";
import useGlobalStyle from "../../../themes/globalStyle";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Alerttip from "../../alertTip/Alerttip";

const VirtualNetworkPortMapping = ({
    deviceIndex,
    allDevices,
    register,
    unregister,
    setValue,
    getValues,
    watch,
    ovas,
    currentDevice,
    control,
    info,
}) => {
    const classes = useStyles();
    const globalStyle = useGlobalStyle();

    const { fields } = useFieldArray({
        control,
        name: `network_devices[${deviceIndex}].port_mapping`,
    });
    useEffect(() => {
        if (!(currentDevice.ova_filename && ovas.length > 0)) {
            return;
        }
        const ova = ovas.find((o) => o.filename === currentDevice.ova_filename);
        if ((ova?.deployment_options || []).length > 0) {
            setDeployOpts(ova.deployment_options);
        }
    }, [ovas]);
    // Values for port mapping
    const PA_VM_INTERFACES = allDevices
        ? allDevices.find((device) => device.name === "Palo Alto VM-ESX")
              ?.network_schema ?? []
        : [];
    const ASAV_INTERFACES = allDevices
        ? allDevices.find((device) => device.name === "Cisco ASAv")
              ?.network_schema ?? []
        : [];
    const CSR1000V_INTERFACES = allDevices
        ? allDevices.find((device) => device.name === "Cisco CSR 1000V")
              ?.network_schema ?? []
        : [];
    const ARUBAOS_VMC_INTERFACES = allDevices
        ? allDevices.find((device) => device.name === "Aruba VMC")
              ?.network_schema ?? []
        : [];
    const RIVERBED_INTERFACES = allDevices
        ? allDevices
              .find((device) => device.name === "Riverbed SteelHead")
              ?.network_schema.slice(0, -1) ?? []
        : [];
    const COMPASS_INTERFACES = allDevices
        ? allDevices
              .find((device) => device.name === "Vidterra Compass")
              ?.network_schema.slice(0, -1) ?? []
        : [];
    const DEFAULT_INTERFACES = ["Network Adapter {1}"];
    const interfaceMapping = {
        "Palo Alto VM-ESX": PA_VM_INTERFACES,
        "Cisco ASAv": ASAV_INTERFACES,
        "Cisco CSR 1000V": CSR1000V_INTERFACES,
        "Aruba VMC": ARUBAOS_VMC_INTERFACES,
        "Riverbed SteelHead": RIVERBED_INTERFACES,
        "Vidterra Compass": COMPASS_INTERFACES,
    };
    const setInterfaces = () => {
        let interfaceList = [];
        if (currentDevice.name in interfaceMapping) {
            interfaceList = interfaceMapping[currentDevice.name];
            console.log("interfaceList: ", interfaceList);
        } else {
            interfaceList = DEFAULT_INTERFACES;
        }
        const numAdjustmentMapping = {
            "Aruba VMC": 1,
            "Cisco ASAv": 1,
            "Cisco CSR 1000V": 0,
            "Palo Alto VM-ESX": 0,
            // "Riverbed SteelHead": 0, // not needed because we don't add/remove ports
            // "Vidterra Compass": 0, // same as riverbed
            "Basic OVA": 0,
        };
        const numAdjustment =
            currentDevice.name in numAdjustmentMapping
                ? numAdjustmentMapping[currentDevice.name]
                : 0;

        // Preparing the new interfaces based on the mapping or default
        const newPortMappings = interfaceList.map((template, index) => {
            const num = index - numAdjustment;
            return {
                interface: template.replace(/\{(\d+)\}/, num.toString()),
                port_group: "",
            };
        });
        // Update the specific device's port_mapping with the new interfaces
        setValue(
            `network_devices[${deviceIndex}].port_mapping`,
            newPortMappings
        );
    };
    const [deployOpts, setDeployOpts] = useState([]);
    const handleOVAChange = (ova) => {
        setDeployOpts(ova.deployment_options);
        setValue(`network_devices[${deviceIndex}].ova_filename`, ova.filename);
        unregister(`network_devices[${deviceIndex}].deployment_option`);
        setInterfaces();
    };
    const handleAddPort = () => {
        // Access interface list and the current ports
        const currentPorts =
            getValues(`network_devices[${deviceIndex}].port_mapping`) || [];
        // console.log('currentPorts: ',currentPorts)
        if (currentPorts.length === 0) {
            console.error(
                "No ports available to determine the next interface increment."
            );
            return; // Optionally handle the case where no ports are set yet
        }
        // get the last index(port) in the array of pcurrentPorts
        const lastInterface = currentPorts[currentPorts.length - 1].interface;

        // get the last numeric value from the last interface
        const lastNumberMatch = lastInterface.match(/(\d+)(?!.*\d)/);
        if (!lastNumberMatch) {
            console.error(
                "No numeric value found in the last interface:",
                lastInterface
            );
            // TODO ADD logic to handle if default number interface was delete, or prevent deletion..
            return;
        }

        // increment the last number
        const nextNumber = parseInt(lastNumberMatch[1], 10) + 1;

        // generate new interface string
        const newInterface = lastInterface.replace(
            /(\d+)(?!.*\d)/,
            nextNumber.toString()
        );

        // append the new port to the correct devices port_mapping
        const updatedPorts = [
            ...currentPorts,
            { interface: newInterface, port_group: "" },
        ];
        setValue(`network_devices[${deviceIndex}].port_mapping`, updatedPorts);
    };
    const handleDeletePort = (index) => {
        // first get the current ports
        const currentPorts = getValues(
            `network_devices[${deviceIndex}].port_mapping`
        );
        // find the port that needs to be removed, with filter
        const updatedPorts = currentPorts.filter((_, i) => i !== index);
        // update form state
        setValue(`network_devices[${deviceIndex}].port_mapping`, updatedPorts);
    };
    function extractInterfaceName(interfaceString) {
        return interfaceString.match(/[a-zA-Z]+/g)?.[0] || "";
    }
    const isPortDeletable = (index) => {
        if (
            index !== fields.length - 1 &&
            fields.length > 1 &&
            !DEFAULT_VALUES.includes(fields?.[index]?.interface) &&
            currentDevice.name !== "Riverbed SteelHead"
        ) {
            return false;
        }

        const currentInterface = getValues(
            `network_devices[${deviceIndex}].port_mapping[${index}].interface`
        );
        const previousInterface =
            index > 0
                ? getValues(
                      `network_devices[${deviceIndex}].port_mapping[${
                          index - 1
                      }].interface`
                  )
                : "";

        const currentAlpha = extractInterfaceName(currentInterface);
        const previousAlpha = extractInterfaceName(previousInterface);

        return currentAlpha === previousAlpha;
    };
    // Don't add port groups that aren't available
    let vmPortGroup = getValues("vm_portgroup") || "";
    let addedVSwitches = getValues("added_vswitches") || [];
    function getPortGroups() {
        let pgList = [vmPortGroup];
        for (const vs of addedVSwitches) {
            for (const pgs of vs.port_groups) {
                pgList.push(pgs.name);
            }
        }
        return pgList;
    }
    const portGroups = getPortGroups();
    const DEFAULT_VALUES = [
        "MGMT",
        "Management0-0",
        "Primary",
        "Aux",
        "LAN",
        "WAN",
    ];

    if (!allDevices) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Typography
                component="h1"
                variant="h6"
                className={globalStyle.customCardTitle}
            >
                OVA
            </Typography>
            <div className={classes.centerContent}>
                <TextField
                    select
                    className={classes.customTextField}
                    variant="outlined"
                    label="OVA"
                    {...register(
                        `network_devices[${deviceIndex}].ova_filename`
                    )}
                    value={
                        watch(`network_devices[${deviceIndex}].ova_filename`) ||
                        ""
                    }
                    defaultValue={
                        getValues(
                            `network_devices[${deviceIndex}].ova_filename`
                        ) ||
                        info?.virtual_serial_devices[deviceIndex]
                            ?.ova_filename ||
                        ""
                    }
                >
                    {ovas?.map((ova) => (
                        <MenuItem
                            value={ova.filename}
                            key={ova.filename}
                            onClick={() => {
                                handleOVAChange(ova);
                            }}
                        >
                            {ova.filename}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            {deployOpts?.length > 0 ||
            watch(`network_devices[${deviceIndex}].deployment_option`) ? (
                <div className={classes.centerContent}>
                    <TextField
                        select
                        className={classes.customTextField}
                        variant="outlined"
                        label="Deployment Option"
                        {...register(
                            `network_devices[${deviceIndex}].deployment_option`
                        )}
                        value={
                            watch(
                                `network_devices[${deviceIndex}].deployment_option`
                            ) || ""
                        }
                        defaultValue={
                            getValues(
                                `network_devices[${deviceIndex}].deployment_option`
                            ) ||
                            info?.virtual_serial_devices[deviceIndex]
                                ?.deployment_option ||
                            ""
                        }
                    >
                        {deployOpts?.map((item) => (
                            <MenuItem value={item} key={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            ) : null}
            {watch(`network_devices[${deviceIndex}].ova_filename`) && (
                <div className={classes.displayFlex}>
                    <Typography
                        component="h4"
                        variant="h6"
                        className={globalStyle.customCardTitle}
                    >
                        Port Mapping
                    </Typography>
                    {!["Riverbed SteelHead", "Vidterra Compass"].includes(
                        currentDevice.name
                    ) && (
                        <div className={classes.centerContent}>
                            <Button
                                variant="outlined"
                                onClick={handleAddPort}
                                style={{ margin: "0px 0px 20px 0px" }}
                                className={classes.addDeviceButton}
                            >
                                <AddIcon />
                                Port
                            </Button>
                        </div>
                    )}
                    {fields?.map((item, index) => (
                        <div className={classes.centerContent} key={item.id}>
                            <Grid
                                container
                                spacing={2}
                                className={globalStyle.customGridContainer}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                    sm={10}
                                    md={11}
                                    lg={11}
                                    xl={11}
                                    className={classes.gridRow}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        className={classes.customGridItem1}
                                    >
                                        <TextField
                                            variant="outlined"
                                            className={
                                                classes.customTextFieldFullWidth
                                            }
                                            label="Interface"
                                            {...register(
                                                `network_devices[${deviceIndex}].port_mapping[${index}].interface`
                                            )}
                                            autoComplete="false"
                                            disabled={true}
                                            defaultValue={
                                                getValues(
                                                    `network_devices[${deviceIndex}].port_mapping[${index}].interface`
                                                ) ||
                                                info?.virtual_serial_devices[
                                                    deviceIndex
                                                ].port_mapping[index]?.interface
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        className={classes.customGridItem2}
                                    >
                                        <TextField
                                            select
                                            variant="outlined"
                                            className={
                                                classes.customTextFieldFullWidth
                                            }
                                            label="Port Group"
                                            {...register(
                                                `network_devices[${deviceIndex}].port_mapping[${index}].port_group`
                                            )}
                                            value={
                                                watch(
                                                    `network_devices[${deviceIndex}].port_mapping[${index}].port_group`
                                                ) || ""
                                            }
                                            defaultValue={
                                                getValues(
                                                    `network_devices[${deviceIndex}].port_mapping[${index}].port_group`
                                                ) ||
                                                info?.virtual_serial_devices[
                                                    deviceIndex
                                                ]?.port_mapping[index]
                                                    .port_group
                                            }
                                        >
                                            {portGroups?.map((pg) => (
                                                <MenuItem key={pg} value={pg}>
                                                    {pg}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                    md={1}
                                    lg={1}
                                    xl={1}
                                    className={classes.endRowIcon}
                                >
                                    <Alerttip title="Remove Interface">
                                        <IconButton
                                            size="small"
                                            disabled={!isPortDeletable(index)}
                                            onClick={() => {
                                                handleDeletePort(index);
                                            }}
                                        >
                                            <DeleteOutlinedIcon
                                                sx={{
                                                    color: isPortDeletable(
                                                        index
                                                    )
                                                        ? "#FF0000"
                                                        : "#D3D3D3",
                                                }}
                                                style={{
                                                    marginTop: "15px",
                                                }}
                                            />
                                        </IconButton>
                                    </Alerttip>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default VirtualNetworkPortMapping;
