import { createContext, useContext, useEffect, useState, useMemo } from "react";
import LogoutManager from "../components/logoutManager/logoutManager";
import { Context } from "./Context";

const SystemSettingsContext = createContext();

export const SystemSettingsProvider = ({ children }) => {
    const [systemSettings, setSystemSettings] = useState({
        classification: null,
        passwordExpires: null,
    });
    const [licenses, setLicenses] = useState();
    const [isUploading, setIsUploading] = useState(false);

    const token = useContext(Context);

    let getApplianceSettings = "";
    let updateClassificationURL = "";
    let licenseURL = "";
    let passwordExpiresURL = "";

    if (process.env.NODE_ENV === "development") {
        getApplianceSettings = "http://localhost:80/api/appliance-settings/appliance_settings";
        updateClassificationURL = "http://localhost:80/api/appliance-settings/classification";
        licenseURL = "http://localhost:80/api/licenses/";
        passwordExpiresURL = "http://localhost:80/api/users/user/password-expires";
    } else if (process.env.NODE_ENV === "production") {
        getApplianceSettings = "/api/appliance-settings/appliance_settings";
        updateClassificationURL = "/api/appliance-settings/classification";
        licenseURL = "/api/licenses/";
        passwordExpiresURL = "/api/users/user/password-expires";
    }

    const getClassification = async () => {
        try {
            const response = await fetch(getApplianceSettings, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            });
            const data = await response.json();
            const classificationValue = data.classification ? data.classification : "none";

            setSystemSettings((prev) => ({
                ...prev,
                classification: classificationValue,
            }));
        } catch (error) {
            alert("Error Retrieving Application Info", error);
        }
    };

    useEffect(() => {
        getClassification();
    }, []);

    /*
            GET LICENSES  
    */
    const getLicense = async () => {
        try {
            const response = await fetch(licenseURL, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            });
            const licenseData = await response.json();
            setLicenses(licenseData.licenses);
            sessionStorage.setItem("licenses", JSON.stringify(licenseData.licenses));
        } catch (error) {
            alert("Error Retrieving Licenses", error);
        }
    };

    useEffect(() => {
        const storedLicenses = JSON.parse(sessionStorage.getItem("licenses"));
        if (storedLicenses) {
            setLicenses(storedLicenses);
        } else {
            getLicense(); // Fetch from server if not found in sessionStorage
        }
    }, []);

    /*
        GET PASSWORD EXPIRES T/F
    */
    const getPasswordExpires = async () => {
        try {
            const response = await fetch(`${passwordExpiresURL}/admin`, {
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setSystemSettings((prevSettings) => ({
                ...prevSettings,
                passwordExpires: data.passwordExpires,
            }));
            sessionStorage.setItem("passwordExpires", data.passwordExpires);
        } catch (error) {
            console.error("Error fetching password expires:", error);
        }
    };
    useEffect(() => {
        if (systemSettings.passwordExpires === null) {
            getPasswordExpires();
        }
    }, [systemSettings.passwordExpires]);

    // GROUPED STATE and STATE SETTERS and FUNCTIONS for Provider
    const systemState = {
        systemSettings: systemSettings,
        licenses: licenses,
        isUploading: isUploading,
    };

    const systemStateSetters = {
        setSystemSettings: setSystemSettings,
        setLicenses: setLicenses,
        setIsUploading: setIsUploading,
    };

    const systemFunctions = {
        getClassification: getClassification,
        getLicense: getLicense,
        getPasswordExpires: getPasswordExpires,
    };

    const SystemSettingsProviderValue = useMemo(
        () => ({
            systemState,
            systemStateSetters,
            systemFunctions,
            systemSettings,
            setSystemSettings,
        }),
        [systemState, systemStateSetters, systemFunctions, systemSettings, setSystemSettings]
    );

    return (
        <SystemSettingsContext.Provider value={SystemSettingsProviderValue}>
            {children}
            <LogoutManager token={token} isUploading={isUploading} />
        </SystemSettingsContext.Provider>
    );
};

export const useSystemSettingsContext = () => useContext(SystemSettingsContext);
