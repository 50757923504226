import { Card, Grid, Typography } from "@material-ui/core";
import Item from "./Item";
import HostAccordion from "./HostAccordion";
import NetworkAccordion from "./NetworkAccordion";

const ReviewHosts = (props) => {
    const complete = props.complete;
    return (
        <Card class="card hosts">
            <Typography component="h5" variant="h5">
                Hosts ({complete?.config?.hosts?.length})
            </Typography>
            {!complete?.post_build_scan && (
                <>
                    {complete?.config?.hosts && (
                        <Typography
                            style={{
                                width: "100%",
                            }}
                            component="div"
                            class="m-b-5"
                        >
                            {complete?.config?.hosts?.map((host, index) => (
                                <Grid
                                    container
                                    spacing={2}
                                    id="hostInfo"
                                    xs={12}
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "1.25rem",
                                            display: "block",
                                        }}
                                        class="p-b-0  m-l-5"
                                    >
                                        <span class="hostName m-r-5">
                                            #{index + 1}:
                                        </span>
                                        <a
                                            class="hostLink"
                                            href={`https://${host.ip}`}
                                        >
                                            {host.hostname}
                                        </a>
                                    </span>
                                </Grid>
                            ))}
                        </Typography>
                    )}
                </>
            )}
            {complete?.post_build_scan?.host_data?.map((host, index) => (
                <Typography
                    key={index}
                    style={{
                        width: "100%",
                    }}
                    component="div"
                    class="m-b-5"
                >
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%",
                            }}
                        >
                            <div>
                                <span
                                    style={{
                                        fontSize: ".9rem",
                                        display: "block",
                                    }}
                                >
                                    <Grid container spacing={2} id="hostInfo">
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                marginBottom: "15px",
                                            }}
                                        >
                                            <Item>
                                                <span
                                                    style={{
                                                        fontSize: "1.25rem",
                                                        display: "block",
                                                    }}
                                                    class="p-b-0  m-l-5"
                                                >
                                                    <span class="hostName m-r-5">
                                                        #{index + 1}:
                                                    </span>
                                                    <a
                                                        class="hostLink"
                                                        href={`https://${host.hostname}`}
                                                    >
                                                        {host.hostname}
                                                    </a>
                                                </span>
                                            </Item>
                                        </Grid>

                                        <Grid item md={6} sm={12}>
                                            <Item>
                                                <span
                                                    style={{
                                                        fontSize: "1rem",
                                                        display: "block",
                                                    }}
                                                    class="p-b-5 p-t-10 p-l-10"
                                                >
                                                    <b>Hardware Info:</b>
                                                </span>
                                                <span
                                                    style={{
                                                        display: "block",
                                                    }}
                                                    class="p-l-15"
                                                >
                                                    <b>Vendor :</b>{" "}
                                                    {host.vendor}
                                                    <br />
                                                    <b>Model :</b> {host.model}
                                                    <br />
                                                    <b>Serial Number :</b>{" "}
                                                    {host.serial_number}
                                                    <br />
                                                    <b>Memory Size :</b>{" "}
                                                    {host.memory_size_h}
                                                    <br />
                                                    <b>CPU Model:</b>{" "}
                                                    {host.cpu_model}
                                                    <br />
                                                    <b>CPU Count:</b>{" "}
                                                    {host.cpu_count}
                                                    <br />
                                                    <b>
                                                        CPU Cores / Threads:
                                                    </b>{" "}
                                                    {host.cpu_cores} /{" "}
                                                    {host.cpu_threads}
                                                    <br />
                                                    <b>NICS Count:</b>{" "}
                                                    {host.nics_count}
                                                    <br />
                                                    <br />
                                                </span>
                                            </Item>
                                        </Grid>

                                        <Grid item md={6} sm={12}>
                                            <Item>
                                                <span
                                                    style={{
                                                        fontSize: "1rem",
                                                        display: "block",
                                                    }}
                                                    class="p-b-5 p-t-10 p-l-10"
                                                >
                                                    <b>Software Info:</b>
                                                </span>
                                                <span
                                                    style={{
                                                        display: "block",
                                                    }}
                                                    class="p-l-15"
                                                >
                                                    <b>Full Name:</b>{" "}
                                                    {host.product_full_name}
                                                    <br />
                                                    <b>Product Name:</b>{" "}
                                                    {host.product_name}
                                                    <br />
                                                    <b>Product Version:</b>{" "}
                                                    {host.product_version}
                                                    <br />
                                                    <b>Product Build:</b>{" "}
                                                    {host.product_build}
                                                    <br />
                                                    <b>
                                                        Managment Server IP:
                                                    </b>{" "}
                                                    {host.management_server_ip}
                                                    <br />
                                                    <br />
                                                </span>
                                            </Item>
                                        </Grid>
                                        <HostAccordion
                                            host={host}
                                            index={index}
                                        />
                                    </Grid>
                                </span>
                            </div>
                        </div>
                    </div>
                </Typography>
            ))}
            {complete?.post_build_scan?.networking && (
                <NetworkAccordion
                    networking={complete?.post_build_scan?.networking}
                />
            )}
        </Card>
    );
};

export default ReviewHosts;
