import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Card,
    Grid,
    TextField,
    MenuItem,
} from "@material-ui/core";
import RemoveIcon from "@mui/icons-material/Remove";
import useStyles from "./styles";
import useGlobalStyle from "../../../themes/globalStyle";
import VirtualNetworkVariables from "./VirtualNetworkVariables";
import VirtualNetworkTemplate from "./VirtualNetworkTemplate";
import VirtualNetworkPortMapping from "./VirtualNetworkPortMapping";

const VirtualNetworkDevice = ({
    deviceIndex,
    allDevices,
    allTemplates,
    register,
    unregister,
    setValue,
    getValues,
    remove,
    watch,
    ovas,
    control,
    info,
}) => {
    const classes = useStyles();
    const globalStyle = useGlobalStyle();
    const [currentDevice, setCurrentDevice] = useState({});

    const removeDevice = (index) => remove(index);

    const handleDeviceChange = (index, device) => {
        unregister(`network_devices.${index}`);
        setValue(`network_devices.${index}`, device);
        if (Array.isArray(device.required_fields)) {
            const configVariables = device.required_fields.map((field) => ({
                name: field,
                value: "",
            }));
            setValue(
                `network_devices.${index}.config_variables`,
                configVariables
            );
        }
        setCurrentDevice(watch(`network_devices[${index}]`));
    };

    useEffect(() => {
        if (info?.virtual_serial_devices?.length > deviceIndex) {
            const device = info.virtual_serial_devices[deviceIndex] || "";
            setValue(`network_devices[${deviceIndex}]`, device, {
                shouldValidate: true,
            });
            setCurrentDevice(device);
        } else {
            if (watch("network_devices")) {
                setCurrentDevice(watch(`network_devices[${deviceIndex}]`));
            }
        }
    }, [info, deviceIndex, setValue]);

    return (
        <>
            <Box className={classes.parent}>
                <div className={classes.beforeAndAfter} />
                <Box className={classes.centerContent}>
                    <TextField
                        select
                        className={classes.customTextField}
                        variant="outlined"
                        label="Device"
                        fullWidth
                        margin="normal"
                        {...register(`network_devices[${deviceIndex}].name`)}
                        defaultValue={
                            getValues(`network_devices[${deviceIndex}].name`) ||
                            info?.virtual_serial_devices[deviceIndex]?.name ||
                            ""
                        }
                    >
                        {allDevices?.map(
                            (device) =>
                                [
                                    "Cisco CSR 1000V",
                                    "Cisco ASAv",
                                    "Palo Alto VM-ESX",
                                    "Aruba VMC",
                                    "Riverbed SteelHead",
                                    "Vidterra Compass",
                                    "Basic OVA",
                                ].includes(device.name) && (
                                    <MenuItem
                                        key={device.name}
                                        value={device.name}
                                        onClick={() =>
                                            handleDeviceChange(
                                                deviceIndex,
                                                device
                                            )
                                        }
                                    >
                                        {device.name}
                                    </MenuItem>
                                )
                        )}
                    </TextField>
                </Box>
                <div className={classes.rightAligned}>
                    <Button
                        variant="outlined"
                        onClick={() => removeDevice(deviceIndex)}
                        className={classes.removeDeviceButton}
                    >
                        <RemoveIcon />
                        Device
                    </Button>
                </div>
            </Box>

            {getValues(`network_devices[${deviceIndex}]`)?.name && (
                <form autoComplete="off" className={classes.form2}>
                    <Card className={classes.card}>
                        <Grid container spacing={2}>
                            {currentDevice?.name === "Basic OVA" ? null : [
                                  "Aruba VMC",
                                  "Riverbed SteelHead",
                                  "Palo Alto VM-ESX",
                                  "Vidterra Compass",
                              ].includes(currentDevice.name) ? (
                                <Grid item sm={12} md={12} lg={6}>
                                    <Card className={classes.card}>
                                        {/* Required Fields / Config Variables */}

                                        <VirtualNetworkVariables
                                            currentDevice={currentDevice}
                                            register={register}
                                            deviceIndex={deviceIndex}
                                            unregister={unregister}
                                            setValue={setValue}
                                            getValues={getValues}
                                            watch={watch}
                                            info={info}
                                        />
                                    </Card>
                                </Grid>
                            ) : (
                                <Grid item sm={12} md={12} lg={6}>
                                    <Card className={classes.card}>
                                        {/* Template Section only loads if there is not currentDevice.name*/}
                                        <VirtualNetworkTemplate
                                            deviceIndex={deviceIndex}
                                            register={register}
                                            unregister={unregister}
                                            watch={watch}
                                            setValue={setValue}
                                            allTemplates={allTemplates}
                                            currentDevice={currentDevice}
                                            getValues={getValues}
                                            info={info}
                                        />
                                    </Card>
                                </Grid>
                            )}
                            <Grid item sm={12} md={12} lg={6}>
                                <Card className={classes.card}>
                                    {/* OVA Section */}
                                    <VirtualNetworkPortMapping
                                        deviceIndex={deviceIndex}
                                        register={register}
                                        unregister={unregister}
                                        watch={watch}
                                        setValue={setValue}
                                        allTemplates={allTemplates}
                                        currentDevice={currentDevice}
                                        control={control}
                                        getValues={getValues}
                                        ovas={ovas}
                                        allDevices={allDevices}
                                        info={info}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            )}
        </>
    );
};

export default VirtualNetworkDevice;
