import { useQuery, useQueryClient, useMutation } from "react-query";
import { playbooksURL } from "./utilties/QueryURLs";
import {
  fetchData,
  uploadDataWPercent,
  deleteData,
} from "./utilties/QueryManager";

export const GetPlaybooks = (token) => {
  return useQuery(
    ["playbooks", playbooksURL, token],
    async () => {
      const data = await fetchData(playbooksURL, token);
      return data?.playbooks;
    },
    {
      refetchOnWindowFocus: true,
    }
  );
};

export const useUploadPlaybook = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ url, headers, data, onUploadProgress, token }) => {
      return await uploadDataWPercent(
        url,
        data,
        headers,
        onUploadProgress,
        token
      );
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(["playbooks", playbooksURL, token]);
      },
      onError: (error) => {
        console.error(error.message);
        alert(error.message);
      },
    }
  );
};

export const useDeletePlaybook = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ url, token }) => {
      return await deleteData(url, token);
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(["playbooks", playbooksURL, token]);
      },
      onError: (error) => {
        console.error(error.message);
        alert(error.message);
      },
    }
  );
};
