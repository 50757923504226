import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Alert from "@material-ui/lab/Alert";

function CheckActiveBuilds(props) {
    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));

    return (
        props.activeBuilds?.Result !== null ? (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100vw",
                }}
            >
                <Alerttip
                    title={
                        `Another build is active on this appliance ${props.activeBuilds?.Result}.`
                    }
                >
                    <Alert
                        style={{
                            marginBottom: "10px",
                            width: "30%",
                        }}
                        severity="warning"
                    >
                        Active Build Detected
                    </Alert>
                </Alerttip>
            </div>
        ) : (
            ""
        )
    );
};

export default CheckActiveBuilds;