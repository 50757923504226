
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorIcon from "@material-ui/icons/Error";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Box from '@material-ui/core/Box';
import CircularProgress from '@mui/material/CircularProgress';

function TaskIcon(props) {
    const task = props.task;
    let color = task.status
    return (
        <Box m={1}
            key={task?.task_name}
            aria-label="datacenter"
            style={{
                color: color,
                marginTop: "12.5px",
            }}
        >
            {task.status === "green" ? (
                <CheckCircleOutlineIcon />
            ) : task.status === "red" ? (
                <ErrorOutlineIcon />
            ) : task.status === "yellow" ? (
                <CircularProgress
                    size="1.35rem"
                    color="warning"
                />
            ) : ""}
        </Box>
    );
};

export default TaskIcon;