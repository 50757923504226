import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Grid,
    List,
    ListItemIcon,
    ListItemText,
    Box,
    CssBaseline,
    ListSubheader,
} from "@material-ui/core";
import UploadField from "./uploadField";
import UploadTable from "./uploadTable";
import ListItemButton from "@mui/material/ListItemButton";
import useGlobalStyle from "../../themes/globalStyle";

import AlbumIcon from "@mui/icons-material/Album";
import KeyIcon from "@mui/icons-material/Key";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import InventoryIcon from "@mui/icons-material/Inventory";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import TemplateReview from "../../components/review/TemplateReview";
import CollectionReview from "../../components/review/CollectionReview";
import PlaybookReview from "../../components/review/PlaybookReview";
import PluginReview from "../../components/review/PluginReview";

export const UploadPage = () => {
    const history = useHistory();
    const { atype, firstReview, secondReview } = useParams();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [activeType, setActiveType] = useState(atype || "iso");
    const [dataChanged, setDataChanged] = useState(0); // state for passing as prop to uploadTable to update the table

    const fileTypes = [
        {
            display_name: "ISO",
            icon: <AlbumIcon />,
        },
        {
            display_name: "Template",
            icon: <UploadFileIcon />,
        },
        {
            display_name: "OVA",
            icon: <ViewInArIcon />,
        },
        {
            display_name: "License",
            icon: <KeyIcon />,
        },
        {
            display_name: "Collection",
            icon: <InventoryIcon />,
        },
        {
            display_name: "Playbook",
            icon: <DocumentScannerIcon />,
        },
        {
            display_name: "Offline Packages",
            icon: <FolderZipIcon />,
        },
    ];

    const handleListItemClick = (type) => {
        setActiveType(type);
        history.push({
            pathname: `/uploadpage/${type}`,
        });
    };
    // Global Styles
    const globalClasses = useGlobalStyle();

    // function to pass to uploadfield as a prop to update dataChanged
    const handleUploadSuccess = () => {
        setDataChanged((prev) => prev + 1);
    };
    const sidebar = (
        <div
            className={globalClasses.customBox}
            style={{
                height: "85vh", // Fill the rest of the height
                overflow: "auto", // In case of scroll
            }}
        >
            <List
                subheader={
                    <ListSubheader
                        className={globalClasses.customMuiListSubheader}
                    >
                        Upload Management
                    </ListSubheader>
                }
            >
                {fileTypes.map((type) => (
                    <ListItemButton
                        className={globalClasses.customMuiListItemButton}
                        sx={{
                            "&.Mui-selected": {
                                backgroundColor: "#e0e0e0",
                                ":hover": {
                                    backgroundColor: "#e0e0e0",
                                },
                            },
                            ":hover": {
                                backgroundColor: "#eeeeee",
                            },
                        }}
                        key={type.display_name}
                        selected={
                            activeType ===
                            type.display_name.toLowerCase().replace(" ", "")
                        }
                        onClick={() =>
                            handleListItemClick(
                                type.display_name.toLowerCase().replace(" ", "")
                            )
                        }
                    >
                        <ListItemIcon>{type.icon}</ListItemIcon>
                        <ListItemText
                            className={globalClasses.customMuiListItemText}
                            primary={type.display_name}
                        />
                    </ListItemButton>
                ))}
            </List>
        </div>
    );

    const reviewComponent = () => {
        if (atype == "collection" && secondReview) {
            return <PluginReview />;
        }
        if (atype == "collection" && firstReview) {
            return <CollectionReview />;
        }
        if (atype == "playbook" && firstReview) {
            return <PlaybookReview />;
        }
        if (atype == "template" && firstReview) {
            return <TemplateReview />;
        }
    };
    const getBreadcrumbs = () => {
        let crumbs = [
            {
                name: atype,
                link: `/uploadpage/${atype}`,
            },
        ];
        if (firstReview) {
            crumbs.push({
                name: firstReview,
                link: `/uploadpage/${atype}/${firstReview}`,
            });
        }
        if (secondReview) {
            crumbs.push({
                name: secondReview,
                link: `/uploadpage/${atype}/${firstReview}/${secondReview}`,
            });
        }
        console.log(crumbs);
        return crumbs;
    };
    const renderBreadcrumbs = (crumbs) => {
        return (
            <div>
                <Breadcrumbs style={{ marginLeft: "30px" }} separator="›">
                    {crumbs.map((crumb, i) => {
                        return (
                            <Link
                                key={crumb.name}
                                className={globalClasses.linkPointer}
                                underline="hover"
                                color="inherit"
                                onClick={() => history.push(crumb.link)}
                            >
                                {crumb.name.toUpperCase()}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
                {reviewComponent()}
            </div>
        );
    };

    useEffect(() => {
        setBreadcrumbs(getBreadcrumbs());
        console.log(breadcrumbs);
    }, [atype, firstReview, secondReview]);

    return (
        <>
            <CssBaseline />
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        <Box>{sidebar}</Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={9}
                        lg={10}
                        xl={10}
                        className={globalClasses.mainContentGrid}
                    >
                        {secondReview || firstReview ? (
                            renderBreadcrumbs(breadcrumbs)
                        ) : (
                            <>
                                {/* Only render UploadField if activeType is true */}
                                <UploadField
                                    type={activeType}
                                    onUploadSuccess={handleUploadSuccess}
                                />
                                {/* Only render UploadTable if activeType is true */}
                                <UploadTable
                                    type={activeType}
                                    onUploadSuccess={handleUploadSuccess}
                                    dataChanged={dataChanged}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UploadPage;
