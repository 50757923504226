import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Paper } from "@material-ui/core";
import useStyles from "./style";

const UploadNetwork = () => {
  const [files, setFiles] = useState("");

  const classes = useStyles();

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      var result = JSON.parse(e.target.result);
      setFiles(result);
    };
  };

  return (
    <Paper className={classes.paper} elevation={6}>
      <Typography variant="h6" style={{ fontFamily: "Montserrat" }}>
        Upload File
      </Typography>
      <div className={classes.fileInput}>
        <input type="file" onChange={handleChange} />
      </div>
      <br />
      <Link
        style={{
          textDecoration: "none",
        }}
        to={{
          pathname: "/newnetwork",
          state: {
            files: files,
          },
        }}
      >
        <Button
          className={classes.button}
          style={{
            fontWeight: "bolder",
            fontFamily: "Montserrat",
            letterSpacing: "3px",
          }}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
        >
          Review
        </Button>
      </Link>
    </Paper>
  );
};

export default UploadNetwork;
