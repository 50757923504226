import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Context } from "../../context/Context";
import useStyles from "./styles";
import useGlobalStyle from "../../themes/globalStyle";

const TemplateReview = () => {
    const [template, setTemplate] = useState();
    const token = useContext(Context);
    let { firstReview } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const globalClasses = useGlobalStyle();
    const [show, setShow] = useState(false);
    const codeString = `${template?.config_template}`;
    let getTemplateByName = "";
    if (process.env.NODE_ENV === "development") {
        getTemplateByName = "http://localhost:80/api/serial-devices/template";
    } else if (process.env.NODE_ENV === "production") {
        getTemplateByName = "/api/serial-devices/template";
    }

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));

    const deleteTemplate = () => {
        fetch(getTemplateByName + "/" + firstReview, {
            method: "Delete",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Template Deleted");
        history.push("/uploadpage/template");
    };

    const CodeBlock = () => {
        return (
            <SyntaxHighlighter
                language="django"
                showLineNumbers
                wrapLines
                style={tomorrowNightBright}
            >
                {codeString}
            </SyntaxHighlighter>
        );
    };

    const TemplateVariables = () => {
        return (
            <div>
                {template?.config_variables.length > 0 ? (
                    <List dense={true}>
                        {template?.config_variables?.map((data) => (
                            <ListItem>
                                <ListItemText primary={data.name} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography>None</Typography>
                )}
            </div>
        );
    };

    const TemplateInterfaces = () => {
        return (
            <div>
                {template?.config_interfaces?.all?.length > 0 ? (
                    <List dense={true}>
                        {template?.config_interfaces?.all?.map((data) => (
                            <ListItem>
                                <ListItemText primary={data} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography>None</Typography>
                )}
            </div>
        );
    };

    useEffect(() => {
        const getTemplate = () => {
            fetch(getTemplateByName + "/" + firstReview, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token.token}`,
                },
            })
                .then((response) => response.json())
                .then((done) => {
                    setTemplate(done);
                })
                .catch((error) => {
                    alert("Error Retrieving Template", error);
                });
        };
        getTemplate();
    }, [getTemplateByName, firstReview, token]);

    return (
        <Container maxWidth={false}>
            <Grid container>
                <Paper
                    className={classes.paper}
                    style={{ backgroundColor: "#f5f5f5" }}
                >
                    <Typography
                        className={globalClasses.paperTitle}
                        variant="h5"
                    >
                        Template Review
                    </Typography>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            align="center"
                            className={classes.buttonGrid}
                        >
                            <Button
                                onClick={() => setShow(!show)}
                                className={globalClasses.buildButtons}
                            >
                                {show ? "Hide Contents" : "Show Contents"}
                            </Button>
                            <Button
                                href={`data:text/text;charset=utf-8,${encodeURIComponent(
                                    template?.config_template
                                )}`}
                                download={`${template?.template_name}.cfg.j2`}
                                className={globalClasses.buildButtons}
                            >
                                Download Template
                            </Button>
                            <Button
                                onClick={(e) => {
                                    if (
                                        window.confirm(
                                            "Are you sure you wish to delete this template?"
                                        )
                                    )
                                        deleteTemplate(e);
                                }}
                                className={globalClasses.buildButtons}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        {show && (
                            <Grid item xs={12}>
                                <Paper
                                    className={classes.paper}
                                    style={{ width: "95%" }}
                                >
                                    <Typography variant="h6">
                                        Template Contents
                                    </Typography>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        spacing={3}
                                    >
                                        <Grid item xs={11}>
                                            <CodeBlock
                                                codestring={
                                                    template?.config_template
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Alerttip title="Copy to clipboard">
                                                <ContentCopyIcon
                                                    className={classes.copybtn}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            template?.config_template
                                                        );
                                                    }}
                                                />
                                            </Alerttip>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6">
                                    Template Info
                                </Typography>
                                <List dense={true}>
                                    <ListItem>
                                        <ListItemText>
                                            Name: {template?.template_name}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Type: {template?.device_name}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Filename: {template?.file_name}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6">
                                    Template Variables
                                </Typography>
                                <TemplateVariables />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6">
                                    Template Interfaces
                                </Typography>
                                <TemplateInterfaces />
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
};

export default TemplateReview;
