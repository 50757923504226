import React from "react";
import { Typography, Card, CardContent, Container } from "@material-ui/core";
import useStyles from "./style";

const CounterMetricsNetwork = ({ netBuilds }) => {
  const classes = useStyles();
  const netData = netBuilds.builds;

  let count = [];
  let count2 = [];

  netData?.map((data) => {
    if (
      data.status.includes("success") ||
      data.status.includes("pass") ||
      data.status.includes("succeed") ||
      data.status.includes("succeeded") ||
      data.status.includes("succeessful") ||
      data.status.includes("succeessfully")
    ) {
      count.push("l");
    } else if (
      data.status.includes("failed") ||
      data.status.includes("fail") ||
      data.status.includes("failure") ||
      data.status.includes("fails")
    ) {
      count2.push("q");
    }
  });

  let duration = [];
  netData?.map((time) => duration.push(time.build_duration));
  const totalTime = duration.reduce((a, b) => a + b, 0);

  const metrics = [
    { Number: netData?.length, title: "Total" },
    { Number: count.length, title: "Successful" },
    { Number: count2.length, title: "Failed" },
    { Number: totalTime, title: "Time(m)" },
  ];

  return (
    <Container className={classes.metricsContainer} style={{ marginBottom: "-15px", flexWrap: "wrap", paddingBottom: "35px", paddingLeft: "0", paddingRight: "0" }}>
      {Object.entries(metrics).map((metric) => {
        return (
          <Card
            key={metric[1].title}
            className={classes.card}
            sx={{ minWidth: 275 }}
            style={{ marginRight: "10px", marginLeft: "10px", marginBottom: "20px", backgroundColor: "rgb(234 35 49 / 25%", textShadow: "rgb(0 0 0 / 75%) 2px 2px 2px" }}
          >
            <CardContent style={{ padding: "30px 20px 40px", minWidth: "130px" }}>
              <Typography
                style={{ textAlign: "center", lineHeight: "initial", paddingTop: "10px", fontWeight: "bold", color: "white", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "130px" }}
                variant="h5"
                component="div"
              >
                {metric[1].Number}
              </Typography>
              <Typography style={{ textAlign: "center", color: "white" }}>{metric[1].title}</Typography>
            </CardContent>
          </Card>
        );
      })}
    </Container>
  );
};

export default CounterMetricsNetwork;
