import React, { useContext, useState } from "react";
import { Context } from "../../context/Context";

import { Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useStyles from "./style";
import UpdatePorts from "./UpdatePorts";

const CreatePorts = () => {
	const [createPort, setCreatePort] = useState();
	const [enablePort, setEnablePort] = useState(false);
	const [wait, setWait] = useState(false);
	const [status, setStatus] = useState();
	const token = useContext(Context);
	const classes = useStyles();

	let postSerialPort = "";
	if (process.env.NODE_ENV === "development") {
		postSerialPort = "http://localhost:80/api/serial-ports/serial-port";
	} else if (process.env.NODE_ENV === "production") {
		postSerialPort = "/api/serial-ports/serial-port";
	}

	const submitNewPort = (e) => {
		e.preventDefault();
		fetch(
			postSerialPort + `?serial_port=${createPort}&enabled=${enablePort}`,
			{
				method: "POST",
				headers: {
					Authorization: `Bearer ${token.token}`,
					accept: "application/json",
				},
			}
		)
			.then((res) => {
				setStatus(res.status);
				setWait(false);
				setCreatePort("");
				setEnablePort(false);
			})

			.catch((error) => {
				alert("error", error);
			});
	};

	return (
		<Paper className={classes.paper} elevation={6}>
			<UpdatePorts />
		</Paper>
	);
};

export default CreatePorts;
