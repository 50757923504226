import {
    createTheme,
    responsiveFontSizes,
    makeStyles,
} from "@material-ui/core/styles";
import { red, grey } from "@material-ui/core/colors";

let theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#424242",
        },
        secondary: {
            main: "#b71c1c",
        },
    },
});

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
    root: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 700,
            marginLeft: "auto",
            marginRight: "auto",
        },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    buildButtonDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buildButtons: {
        fontWeight: "bolder",
        letterSpacing: "3px",
        backgroundColor: "#b71c1c",
        color: "white",
        width: "9rem",
        borderRadius: theme.shape.borderRadius * 1,
        "&:hover": {
            backgroundColor: "#424242",
            color: "white",
        },
    },
    buildInfoPaper: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
    },
    buildInfoDiv: {
        display: "inline-block",
        textAlign: "center",
        margin: "10px",
    },
    buildDuration: {
        width: "6rem",
    },
    buildInfoLabels: {
        textAlign: "left",
        fontFamily: "Montserrat",
        marginLeft: "10px",
        fontSize: "0.75rem",
        padding: "0px",
    },
    buildInfo: {
        textAlign: "left",
        fontFamily: "Montserrat",
        marginLeft: "10px",
        fontSize: "1.25rem",
        padding: "0px",
    },
    stepperPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(10),
        padding: theme.spacing(2),
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    stoplightGrid: {
        borderRadius: theme.shape.borderRadius * 1,
        marginBottom: theme.spacing(3),
    },
    stoplightGridItem: {
        backgroundColor: theme.palette.background.default,
        marginBottom: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius * 1,
        borderStyle: "solid",
        borderWidth: "0.25px",
        borderColor: "#eeeeee",
    },
    stoplightGridTaskName: {
        marginLeft: "50px",
    },
    stoplightP: {
        // fontWeight: "bolder",
        letterSpacing: "1px",
        marginBottom: "16px",
        marginLeft: "10px",
    },
    pbDiv: {
        paddingRight: theme.spacing(2),
    },
    pbLabel: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Montserrat",
    },
    logsDiv: {
        position: "fixed",
        bottom: 0,
    },
    logsAppBar: {
        width: "100%",
        backgroundColor: "white",
        color: "black",
        display: "flex",
        flexDirection: "row",
        padding: "2px 15px 2px 10px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
        },
        position: "fixed",
        bottom: "0",
        marginBottom: "30px",
        zIndex: 1399,
        "@media (min-width: 476px) and (max-width: 725px)": {
            marginBottom: "50px",
        },
    },
    logsDrawerPaper: {
        marginBottom: "62px",
        zIndex: -1,
        "@media (min-width: 476px) and (max-width: 725px)": {
            marginBottom: "82px",
        },
    },
    virtualTableBox: {
        marginTop: "20px",
        height: "100%",
        width: "100%",
    },
    dragger: {
        width: "100%",
        height: "20px",
        cursor: "ns-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        backgroundColor: "#f4f7f9",
    },
    logsDivScroll: {
        marginTop: "20px",
        overflowY: "scroll",
        maxHeight: "500px",
    },
    logsCell: {
        padding: "5px",
        paddingLeft: "15px",
        whiteSpace: "nowrap",
    },
    autoscrollLabel: {
        padding: "0px",
        fontFamily: "Montserrat",
        fontSize: "0.75rem",
    },
    autoScrollSwitch: {
        margin: "0px 0px 0px auto",
    },
    overflowScrollCell: {
        overflow: "auto",
        "&::-webkit-scrollbar": {
            backgroundColor: "#fff",
            height: "4px",
        },

        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#fff",
            borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#e0e0e0",
        },
    },
}));

export { theme, useStyle };
