import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../context/Context";
import {
  Paper,
  Grid,
  Typography,
  Container,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import Host from "../ISO/Host";
import { Controller } from "react-hook-form";

const ExistingVirtualNetwork = () => {
  const [formStep, setFormStep] = useState(0);
  const [ovas, setOVAS] = useState();
  const token = useContext(Context);
  const classes = useStyles();

  const { register, control, handleSubmit } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  let getAllOVAS = "";
  if (process.env.NODE_ENV === "development") {
    getAllOVAS = "http://localhost:80/api/ovas/ovas";
  } else if (process.env.NODE_ENV === "production") {
    getAllOVAS = "/api/ovas/ovas";
  }

  const getOVAS = async () => {
    await fetch(getAllOVAS, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((response) => response.json())
      .then((resdata) => {
        setOVAS(resdata.ovas);
      })
      .catch((error) => {
        alert("Error Retrieving ISOs", error);
      });
  };

  useEffect(() => {
    getOVAS();
  }, []);

  const nextStep = () => {
    setFormStep((cur) => cur + 1);
  };

  const prevStep = () => {
    setFormStep((cur) => cur - 1);
  };

  const renderButton = () => {
    if (formStep === 1) {
      return (
        <Button className={classes.button} onClick={prevStep}>
          Previous Step
        </Button>
      );
    } else {
      return (
        <Button className={classes.button} onClick={nextStep}>
          Next Step
        </Button>
      );
    }
  };

  const deployOVA = (data) => {
    console.log(data);
  };

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={6}>
        {renderButton()}
        {formStep === 0 && (
          <section>
            <Typography component="h1" style={{ margin: "40px" }} variant="h5">
              Host Configuration
            </Typography>
            <Host register={register} />
          </section>
        )}
        {formStep === 1 && (
          <section>
            <form autoComplete="off" className={classes.form}>
              <Typography
                component="h1"
                style={{ margin: "30px 0" }}
                variant="h5"
              >
                Select OVA
              </Typography>
              <Grid
                style={{
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                    }}
                  >
                    Name:
                  </InputLabel>
                  <input
                    style={{
                      margin: "30px 10px",
                      height: "30px",
                      width: "100%",
                    }}
                    {...register("name")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <InputLabel
                    style={{
                      color: "black",
                      display: "flex",
                      margin: "20px 20px",
                    }}
                  >
                    OVA Filename:
                  </InputLabel>
                  <Controller
                    style={{ marginLeft: "45px" }}
                    control={control}
                    name="ova_file"
                    defaultValue={""}
                    render={({ field }) => (
                      <Select {...field}>
                        {ovas?.map((ova) => (
                          <MenuItem value={ova.filename} key={ova.filename}>
                            {ova.filename}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
            </form>
          </section>
        )}
      </Paper>
    </Container>
  );
};

export default ExistingVirtualNetwork;
