import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../context/Context";
import { Controller } from "react-hook-form";
import { Grid, MenuItem, InputLabel, Select } from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const VCSAVersion = ({ info, control }) => {
  const [isoVersion, setIsoVersion] = useState(null);
  const token = useContext(Context);

  let baseURL = "";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://localhost:80/api/isos/isos";
  } else if (process.env.NODE_ENV === "production") {
    baseURL = "/api/isos/isos";
  }

  const getISOS = async () => {
    await fetch(baseURL + "/?iso_type=vcsa", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((response) => response.json())
      .then((resdata) => {
        setIsoVersion(resdata);
      })
      .catch((error) => {
        alert("Error Retrieving ISOs", error);
      });
  };

  useEffect(() => {
    getISOS();
  }, []);

  isoVersion?.isos.push({ version: info?.vcsa_ver });
  const uniqvcsa = [...new Set(isoVersion?.isos)];

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Grid style={{ marginTop: "20px", display: "flex" }}>
      <Alerttip title="Select the vCenter ISO that you would like to use for the deployment. If you do not see any listed, please upload in the “Uploads” page">
        <InputLabel
          style={{ color: "black", display: "flex", margin: "20px 8px" }}
        >
          VCSA Version
        </InputLabel>
      </Alerttip>
      <Controller
        control={control}
        name="vcsa_ver"
        defaultValue={info?.vcsa_ver || ""}
        render={({ field }) => (
          <Select {...field} onChange={field.onChange}>
            {uniqvcsa.map((iso) => (
              <MenuItem
                onChange={field.onChange}
                key={iso.version}
                value={iso.version}
              >
                {iso.version}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Grid>
  );
};

export default VCSAVersion;
