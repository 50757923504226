import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import Item from "./Item"
import Alerttip from "./Alerttip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NetworkAccordion = (props) => {
    const networking = props.networking
    return (
        <div
            style={{
                width: "100%",
                marginTop:
                    "15px",
            }}
        >
            <Accordion>
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        vSwitches
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer
                        component={
                            Paper
                        }
                    >
                        <Table
                            style={{
                                padding:
                                    "15px 30px",
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        vSwitch
                                    </TableCell>
                                    <TableCell>
                                        Host
                                    </TableCell>
                                    <TableCell>
                                        Portgroup(s)
                                    </TableCell>
                                    <TableCell>
                                        Pnic(s)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                                style={{
                                    textDecoration:
                                        "none",
                                    padding:
                                        "0 20px",
                                }}
                            >
                                {networking?.vswitches?.map(
                                    (
                                        vs,
                                        index
                                    ) => (
                                        <TableRow
                                            key={
                                                index
                                            }
                                        >
                                            <TableCell scope="row">
                                                {
                                                    vs.name
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    vs.host
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    vs?.portgroups?.map((p, i, vs) => {
                                                        if (i + 1 === vs.length) {
                                                            return p.name
                                                        } else {
                                                            return p.name + ", "
                                                        }
                                                    }
                                                    )}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    vs?.pnic?.map((p, i, vs) => {
                                                        if (i + 1 === vs.length) {
                                                            return p
                                                        } else {
                                                            return p + ", "
                                                        }
                                                    }
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default NetworkAccordion;