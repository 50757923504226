import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
        },
    },

    paper: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
    },

    form: {
        width: "100%",
        marginTop: theme.spacing(3),
    },

    textfield: {
        marginTop: theme.spacing(3),
        width: "100%",
    },
    button: {
        backgroundColor: "#EA2331",
        width: "9rem",
        marginRight: "20px",
        alignSelf: "center",
        marginBottom: "30px",
        "&:hover": {
            backgroundColor: "#333333",
            color: "#EA2331",
        },
    },
    buildbtn: {
        margin: '30px 0',
        padding: "10px",
        width: '10vw',
        backgroundColor: '#EA2331',
        color: '#333333',

        "&:hover": {
            backgroundColor: '#333333',
            color: '#EA2331',
        }
    },

    btnContainer: {
        display: "flex",
        justifyContent: "center",
        "@media (max-width: 650px)": {
            display: "flex",
            flexDirection: "column",
        },
    },

    box: {
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
    },
    kitID: {
        alignSelf: "center",
        width: "20%",
        padding: "1%",
        margin: "5px 0",
    },
}));
