import {
    TextField,
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
} from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/Context";
import { useSystemSettingsContext } from "../../../context/SystemSettings";
import Alerttip from "./Alerttip";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import useGlobalStyles from "../../../themes/globalStyle";

import { CLASSIFICATIONS } from "../../applianceSettings/ClassificationSettings";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="in" ref={ref} {...props} />;
});

const ReviewButtons = (props) => {
    const token = useContext(Context);
    const res = props.res;
    const classes = props.classes;
    const complete = props.complete;
    const dataCenter = props.dataCenter;
    const baseURL = props.baseURL;
    const id = props.id;
    const build_type = props.build_type;
    const setIsRunning = props.setIsRunning;
    const getBuilds = props.getBuilds;
    const inProgress = complete?.status === "in_progress" ? true : false;
    const history = useHistory();
    const [taclan, setTaclan] = useState(false);

    const [buildObj, setBuildObj] = useState(complete);
    const [configObj, setConfigObj] = useState(
        complete?.config || complete?.serial_devices
    );
    const { systemSettings } = useSystemSettingsContext();

    const globalStyle = useGlobalStyles();

    const [openLogs, setOpenLogs] = useState(false);
    const handleClickOpenLogs = () => {
        handleControlMenuClose();
        setOpenLogs(true);
    };
    const handleCloseLogs = () => {
        setOpenLogs(false);
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [anchorElDownload, setAnchorElDownload] = useState(null);
    const openDownloadMenu = Boolean(anchorElDownload);

    const handleDownloadMenuOpen = (event) => {
        setAnchorElDownload(event.currentTarget);
    };

    const handleDownloadMenuClose = () => {
        setAnchorElDownload(null);
    };

    const [anchorElControl, setAnchorElControl] = useState(null);
    const openControlMenu = Boolean(anchorElControl);

    const handleControlMenuOpen = (event) => {
        setAnchorElControl(event.currentTarget);
    };

    const handleControlMenuClose = () => {
        setAnchorElControl(null);
    };

    const [anchorElTasks, setAnchorElTasks] = useState(null);
    const openTasksMenu = Boolean(anchorElTasks);

    const handleTasksMenuOpen = (event) => {
        setAnchorElTasks(event.currentTarget);
    };

    const handleTasksMenuClose = () => {
        setAnchorElTasks(null);
    };

    let getKeysURL = "";
    let stopURL = "";
    let vmTasks = "";
    let cancelURL = "";
    if (process.env.NODE_ENV === "development") {
        getKeysURL = "http://localhost:80/api/keygen/user_keys";
        vmTasks = "http://localhost:80/api/vm-tasks";
        stopURL = "http://localhost:80/api/control";
        cancelURL = "http://localhost:80/api/control/cancel-build";
    } else if (process.env.NODE_ENV === "production") {
        getKeysURL = "/api/keygen/user_keys";
        vmTasks = "/api/vm-tasks";
        stopURL = "/api/control";
        cancelURL = "/api/control/cancel-build";
    }

    const downContainers = () => {
        fetch(stopURL + "/" + id + `?command=down&type=${build_type}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Container Stopped");
    };

    const deleteBuild = () => {
        fetch(baseURL + "/" + id, {
            method: "Delete",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Build Deleted");
        getBuilds();
        history.push("/review");
    };

    const cancelContainers = () => {
        fetch(cancelURL + "/" + id + `?type=${build_type}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert("Build Canceled");
        getBuilds();
        history.push(`/review/${build_type}/${id}`);
    };

    const vmTaskPostInstall = () => {
        fetch(vmTasks + "/complete-vm-install/" + id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => {
                alert("VM Post Install in Progress");
                setIsRunning(true);
            })
            .catch((error) => {
                alert("Error Initializing VM Post Install. Please Try Again");
                setIsRunning(false);
            });
    };

    let kitID = "";
    const addKitId = (e) => {
        e.preventDefault();
        const ID = document.getElementById("kitID").value;
        kitID = ID;
        const sendData = new FormData();
        sendData.append("kit_id", kitID);
        sendData.append("build_id", id);

        axios
            .put(
                baseURL + "/kit_id?" + `kit_id=${kitID}` + "&build_id=" + id,
                sendData,
                {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        accept: "application/json",
                    },
                    body: `kit_id=@${kitID}"`,
                }
            )
            .then((res) => {
                alert("Kit Id Added Successfully");
                handleClose();
                getBuilds();
                history.push(`/review/${build_type}/${id}`);
            })
            .catch((error) => {
                alert(
                    "Error.  Please check all data requested, and retry.",
                    error
                );
            });
    };

    const prependClassification = () => {
        if (systemSettings.classification && complete) {
            const config = complete?.config || complete?.serial_devices;
            for (const cls of CLASSIFICATIONS) {
                if (cls.value === systemSettings.classification) {
                    setBuildObj({
                        classification: cls.display_name,
                        ...complete,
                    });
                    setConfigObj({
                        classification: cls.display_name,
                        ...config,
                    });
                }
            }
        }
    };
    const getActiveKeys = () => {
        fetch(getKeysURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => response.json())
            .then((resdata) => {
                let userKeys = resdata;
                let taclanKey = userKeys.find(
                    (key) =>
                        key.feature_name === "taclan" && key.status != "expired"
                );
                if (taclanKey) {
                    setTaclan(true);
                }
            });
        // .catch((error) => {
        // 	alert("Error Retrieving Keys", error);
        // });
    };

    useEffect(() => {
        prependClassification();
        getActiveKeys();
    }, [complete]);

    return (
        <div id="reviewButtons" style={{ paddingTop: "10px" }}>
            <Dialog
                open={openLogs}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseLogs}
                aria-describedby="alert-dialog-slide-description"
                id="logs"
            >
                <DialogTitle style={{ paddingTop: "25px" }}>
                    {"Logs"}
                    <Alerttip title="Copy to clipboard">
                        <ContentCopyIcon
                            className={classes.copybtn}
                            style={{
                                width: "15px",
                                height: "15px",
                                position: "absolute",
                                top: "-5px",
                                right: "0",
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    "Build Logs:\n" +
                                        complete?.logs?.stdout +
                                        "VM Logs:\n" +
                                        complete?.vm_logs?.stdout
                                );
                            }}
                        />
                    </Alerttip>
                </DialogTitle>
                <DialogContent
                    style={{
                        marginTop: "-5px",
                        textAlign: "left",
                    }}
                >
                    <DialogContentText id="alert-dialog-slide-description">
                        <pre
                            style={{
                                whiteSpace: "pre-wrap",
                                fontSize: ".795rem",
                                marginLeft: "15px",
                            }}
                        >
                            <code>Build Logs:</code>
                            <code>{complete?.logs?.stdout}</code>
                            {complete?.vm_logs?.stdout ? (
                                <code>
                                    VM Logs: {complete?.vm_logs?.stdout}
                                </code>
                            ) : (
                                ""
                            )}
                        </pre>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: "space-around",
                    }}
                >
                    <Button onClick={handleCloseLogs}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                style={{ textAlign: "center" }}
            >
                <DialogTitle style={{ paddingTop: "25px" }}>
                    {"Add a Kit ID to this Build?"}
                </DialogTitle>
                <DialogContent style={{ marginTop: "-5px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField
                            id="kitID"
                            label="Enter Kit ID"
                            style={{
                                marginBottom: "15px",
                                width: "100%",
                            }}
                        ></TextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: "space-around",
                        marginTop: "-5px",
                        marginBottom: "15px",
                    }}
                >
                    <Button onClick={handleClose}>Close</Button>

                    <Button onClick={addKitId}>Add</Button>
                </DialogActions>
            </Dialog>
            <ButtonGroup variant="outlined">
                {res?.vm_tasks_status === "in_progress" ? (
                    <Button
                        variant="contained"
                        className={globalStyle.MenuButtons}
                    >
                        <Alerttip title="Logs available once VM Post Install completes...">
                            Logs
                        </Alerttip>
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        className={globalStyle.MenuButtons}
                        onClick={handleClickOpenLogs}
                    >
                        Logs
                    </Button>
                )}
                <Button
                    variant="contained"
                    className={globalStyle.MenuButtons}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleDownloadMenuOpen}
                >
                    Download
                </Button>
                <Menu
                    open={openDownloadMenu}
                    onClose={handleDownloadMenuClose}
                    anchorEl={anchorElDownload}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MenuItem
                        component={Button}
                        variant="text"
                        onClick={handleDownloadMenuClose}
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(configObj, null, 4)
                        )}`}
                        download={`${
                            complete?.build_id || complete?.util_id
                        }-config.json`}
                    >
                        Config
                    </MenuItem>
                    <MenuItem
                        component={Button}
                        variant="text"
                        onClick={handleDownloadMenuClose}
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(buildObj, null, 4)
                        )}`}
                        download={`${
                            complete?.build_id || complete?.util_id
                        }.json`}
                    >
                        Build Object
                    </MenuItem>
                </Menu>
                <Button
                    variant="contained"
                    className={globalStyle.MenuButtons}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleControlMenuOpen}
                >
                    Control
                </Button>
                <Menu
                    open={openControlMenu}
                    onClose={handleControlMenuClose}
                    anchorEl={anchorElControl}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ horizontal: "center" }}
                >
                    {inProgress && (
                        <MenuItem
                            component={Button}
                            variant="text"
                            onClick={cancelContainers}
                        >
                            Cancel Build
                        </MenuItem>
                    )}
                    {!inProgress && (
                        <MenuItem
                            component={Button}
                            variant="text"
                            onClick={(e) => {
                                if (
                                    window.confirm(
                                        "Are you sure you wish to delete this build?"
                                    )
                                )
                                    deleteBuild(e);
                                handleControlMenuClose();
                            }}
                        >
                            Delete Build
                        </MenuItem>
                    )}
                    {!inProgress && (
                        <MenuItem
                            component={Button}
                            variant="text"
                            onClick={downContainers}
                        >
                            Stop Containers
                        </MenuItem>
                    )}
                </Menu>

                {["build", "virtual-network-builds"].includes(build_type) && (
                    <Button
                        variant="contained"
                        className={globalStyle.MenuButtons}
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={handleTasksMenuOpen}
                    >
                        Build Tasks
                    </Button>
                )}
                <Menu
                    open={openTasksMenu}
                    onClose={handleTasksMenuClose}
                    anchorEl={anchorElTasks}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{ horizontal: "center" }}
                >
                    {taclan && dataCenter && (
                        <MenuItem
                            component={Button}
                            onClick={vmTaskPostInstall}
                        >
                            VM Post Install
                        </MenuItem>
                    )}
                    {!inProgress && (
                        <MenuItem component={Button}>
                            <Link
                                to={{
                                    pathname: `/playbooksbuild/${id}`,
                                }}
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                Run Playbook
                            </Link>
                        </MenuItem>
                    )}
                </Menu>

                {!inProgress && !buildObj?.util_id && (
                    <Button
                        variant="contained"
                        className={globalStyle.MenuButtons}
                        onClick={handleClickOpen}
                    >
                        Add Kit ID
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );
};

export default ReviewButtons;
