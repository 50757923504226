import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Context } from "../../context/Context";
import {
    Grid,
    List,
    ListItemIcon,
    ListItemText,
    Box,
    CssBaseline,
    ListSubheader,
    Paper,
} from "@material-ui/core";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import ReviewContent from "./ReviewContent";
import ListItemButton from "@mui/material/ListItemButton";
import useGlobalStyle from "../../themes/globalStyle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import StorageIcon from "@mui/icons-material/Storage";
import RouterIcon from "@mui/icons-material/Router";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import DevicesIcon from "@mui/icons-material/Devices";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

export const Review = () => {
    const token = useContext(Context);
    const history = useHistory();
    const [isFetching, setIsFetching] = useState(true);
    let { build_type, id } = useParams();
    const [buildObj, setBuildObj] = useState({});
    const [builds, setBuilds] = useState([]);
    const [virtualNetBuilds, setVirtualNetBuilds] = useState([]);
    const [netBuilds, setNetBuilds] = useState([]);
    const [netUtilites, setNetUtilities] = useState([]);
    const [playbookBuilds, setPlaybookBuilds] = useState([]);

    // Global Styles
    const globalClasses = useGlobalStyle();

    const allBuilds = [
        {
            buildType: "build",
            displayName: "Datacenter",
            icon: <StorageIcon />,
            builds: builds || [],
        },
        {
            buildType: "network-builds",
            displayName: "Network",
            icon: <RouterIcon />,
            builds: netBuilds || [],
        },
        {
            buildType: "virtual-network-builds",
            displayName: "Virtual Network",
            icon: <DevicesIcon />,
            builds: virtualNetBuilds || [],
        },
        {
            buildType: "network-utils",
            displayName: "Network Utilities",
            icon: <CloudSyncOutlinedIcon />,
            builds: netUtilites || [],
        },
        {
            buildType: "playbooks",
            displayName: "Playbook",
            icon: <SubscriptionsIcon />,
            builds: playbookBuilds || [],
        },
    ];

    const initialOpenState = [
        { buildType: "build", open: false },
        { buildType: "network-builds", open: false },
        { buildType: "virtual-network-builds", open: false },
        { buildType: "network-utils", open: false },
        { buildType: "playbooks", open: false },
    ];

    const [openBuilds, setOpenBuilds] = useState(initialOpenState);

    let baseURL = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://localhost:80/api/all_builds/all_builds";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "/api/all_builds/all_builds";
    }

    const handleListItemClick = (bt, bi) => {
        history.push({
            pathname: `/review/${bt}/${bi}`,
        });
    };

    const handleUpdateOpenState = (type, index, open = false) => {
        let newArr = [...openBuilds];
        newArr[index] = {
            buildType: type,
            open: open || !newArr?.[index]?.open,
        };
        setOpenBuilds(newArr);
    };

    const getBuilds = async () => {
        await fetch(baseURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token.token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((error) => {
                        throw new Error(error.detail);
                    });
                }
            })
            .then((resdata) => {
                setBuilds(sortData(resdata.builds));
                setNetBuilds(sortData(resdata.networkBuilds));
                setVirtualNetBuilds(sortData(resdata.virtualNetworkBuilds));
                setNetUtilities(sortData(resdata.networkUtilities));
                setPlaybookBuilds(sortData(resdata.playbookBuilds));
                setIsFetching(false);
            })
            .catch((error) => {
                console.log(error);
                alert("Error Retrieving Builds", error);
            });
    };

    const sortData = (data) => {
        return Array.isArray(data)
            ? [...data].sort((a, b) =>
                  b.date ? new Date(b.date) - new Date(a.date) : -1
              )
            : [];
    };

    const sidebar = (
        <div
            className={globalClasses.customBox}
            style={{
                height: "85vh", // Fill the rest of the height
                overflow: "auto", // In case of scroll
            }}
        >
            <List
                subheader={
                    <ListSubheader
                        className={globalClasses.customMuiListSubheader}
                    >
                        Builds
                    </ListSubheader>
                }
            >
                {allBuilds.map((buildType, index) => (
                    <>
                        <ListItemButton
                            className={globalClasses.customMuiListItemButton}
                            onClick={() =>
                                handleUpdateOpenState(
                                    buildType.buildType,
                                    index
                                )
                            }
                        >
                            <ListItemIcon>{buildType.icon}</ListItemIcon>
                            <ListItemText primary={buildType.displayName} />
                            {openBuilds[index].open ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openBuilds[index].open}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div">
                                {buildType?.builds?.map((build) => (
                                    <ListItemButton
                                        style={{
                                            padding: "0px 5px",
                                            width: "100%",
                                        }}
                                        sx={{
                                            "&.Mui-selected": {
                                                backgroundColor: "#e0e0e0",
                                                ":hover": {
                                                    backgroundColor: "#e0e0e0",
                                                },
                                            },
                                            ":hover": {
                                                backgroundColor: "#eeeeee",
                                            },
                                        }}
                                        onClick={() =>
                                            handleListItemClick(
                                                buildType.buildType,
                                                build.build_id || build.util_id
                                            )
                                        }
                                        selected={[
                                            build.build_id,
                                            build.util_id,
                                        ].includes(id || "not set")}
                                    >
                                        <HorizontalRuleIcon
                                            fontSize="large"
                                            style={{
                                                padding: "0px 5px",
                                                color:
                                                    build?.status === "success"
                                                        ? "#43a047"
                                                        : build?.status ===
                                                          "in_progress"
                                                        ? "#ffa000"
                                                        : build?.status ===
                                                          "canceled"
                                                        ? "#bdbdbd"
                                                        : "#b71c1c",
                                            }}
                                        />
                                        <ListItemText
                                            primary={
                                                build.kit_id ||
                                                (
                                                    build.build_id ||
                                                    build.util_id
                                                ).slice(0, 17)
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </>
                ))}
            </List>
        </div>
    );

    const getFinishedBuild = () => {
        const builds = allBuilds.find(
            (b) => b.buildType === build_type
        )?.builds;
        const build = builds?.find((b) => (b.build_id || b.util_id) === id);
        if (build) {
            setBuildObj(build);
        }
    };

    useEffect(() => {
        getBuilds();
        if (build_type) {
            const index = openBuilds.findIndex(
                (x) => x.buildType === build_type
            );
            handleUpdateOpenState(build_type, index);
        }
    }, []);
    useEffect(() => {
        getFinishedBuild();
    }, [id, allBuilds]);

    useEffect(() => {
        // Review Home
        if (!build_type) {
            setOpenBuilds(initialOpenState);
        }

        if (id && build_type) {
            const index = openBuilds.findIndex(
                (x) => x.buildType === build_type
            );
            handleUpdateOpenState(build_type, index, true);
        }
    }, [build_type]);

    return (
        <>
            <CssBaseline />
            <Box>
                {isFetching ? (
                    <Paper className={globalClasses.buildInfoPaper}>
                        <CircularProgress
                            size="3rem"
                            color="error"
                            sx={{
                                [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: "round",
                                },
                            }}
                        />
                    </Paper>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                            <Box>{sidebar}</Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={9}
                            lg={10}
                            xl={10}
                            className={globalClasses.mainContentGrid}
                        >
                            <ReviewContent
                                buildObj={buildObj}
                                getBuilds={getBuilds}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default Review;
