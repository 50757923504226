import { Button, TextField } from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { useFieldArray } from "react-hook-form";

const NestedNetworks = ({ nestedIndex, info, register, controls, getValues }) => {
  const { fields, append } = useFieldArray({
    control: controls,
    name: `vms.${nestedIndex}.networks`, // changing vms.${nestedIndex}.networks to networks here fixes object, Object render issue
  });

  const handleAddFields = (e) => {
    e.preventDefault();
    append(
      { name: "" } // if this is {networks: ""}, it creates an object within the networks object until you type in the field
    );
  };

  const Alerttip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        style={{ backgroundColor: "lightgrey" }}
        onClick={handleAddFields}
      >
        + Networks
      </Button>
      {fields.map((item, k) => (

        <Alerttip
          title="Enter the VM Port Group to be assigned to the Virtual Machine Template (example: VM Network)"
        >
          <TextField
            variant="outlined"
            style={{ margin: "20px" }}
            label="Networks"
            {...register(
              `vms.${nestedIndex}.networks.${k}.name`
            )}
            defaultValue={info?.vms?.[nestedIndex]?.networks?.[k]?.name || info?.vms?.[nestedIndex]?.networks?.[k] || ""}
            autoComplete="false"
          />
        </Alerttip>
      ))}
    </div>
  );
};

export default NestedNetworks;
