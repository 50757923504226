import {
    Button,
    Card,
    Container,
    Fab,
    FormControlLabel,
    FormGroup,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useStyles from "./styles";

const AddedVmk = ({ info, register, controls, getValues }) => {
    const classes = useStyles();

    const allVMK = info?.added_vmks;

    const { fields, append, remove } = useFieldArray({
        control: controls,
        name: "added_vmks",
    });

    const handleAddFields = () => {
        append({
            name: "",
            port_group: "",
            network: "",
            mask: "",
            gateway: "",
            VMotion: "",
        });
    };

    const handleDelete = (index) => {
        remove(index)
    }

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));

    return (
        <Container component="main" maxWidth="xl">
            <Paper
                style={{ display: "flex", flexDirection: "column" }}
                className={classes.paper}
                elevation={6}
            >
                <Typography component="h1" variant="h5">
                    Added VMKs
                </Typography>
                <Fab
                    variant="extended"
                    style={{ marginTop: "15px" }}
                    onClick={handleAddFields}
                >
                    <AddIcon />
                    VMK
                </Fab>
                <form autoComplete="off" className={classes.form}>
                    {fields.map((item, index) => (
                        <div key={item.id}>
                            <Card name="added_vmks" className={classes.card}>
                                <div style={{ display: "flex" }}>
                                    <Alerttip title="Would you like to add additional VMkernal adapters to the ESXi host? If so, what number do you want assigned to the VMK adapter (example: 1)?">
                                        <TextField
                                            variant="outlined"
                                            style={{ margin: "20px" }}
                                            label="Name"
                                            {...register(
                                                `added_vmks[${index}].name`
                                            )}
                                            defaultValue={
                                                info?.added_vmks
                                                    ? info?.added_vmks[index]
                                                        ?.name
                                                    : ""
                                            }
                                            autoComplete="false"
                                        />
                                    </Alerttip>
                                    <Alerttip title="What Port Group would you like to assign to the VMK Adapter (example: vSAN, vMotion)">
                                        <TextField
                                            variant="outlined"
                                            style={{ margin: "20px" }}
                                            label="Port Group"
                                            {...register(
                                                `added_vmks[${index}].port_group`
                                            )}
                                            defaultValue={
                                                info?.added_vmks
                                                    ? info?.added_vmks[index]
                                                        ?.port_group
                                                    : ""
                                            }
                                            autoComplete="false"
                                        />
                                    </Alerttip>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Alerttip title="What IP address would you like to assign to the VMK Adapter (example: 192.168.2.10)?">
                                        <TextField
                                            variant="outlined"
                                            style={{ margin: "20px" }}
                                            label="Network"
                                            {...register(
                                                `added_vmks[${index}].network`
                                            )}
                                            defaultValue={
                                                info?.added_vmks
                                                    ? info?.added_vmks[index]
                                                        ?.network
                                                    : ""
                                            }
                                            autoComplete="false"
                                        />
                                    </Alerttip>
                                    <Alerttip title="What Subnet Mask would you like to assign to the VMK Adapter (example: 255.255.255.0)?">
                                        <TextField
                                            variant="outlined"
                                            style={{ margin: "20px" }}
                                            label="Mask"
                                            {...register(
                                                `added_vmks[${index}].mask`
                                            )}
                                            defaultValue={
                                                info?.added_vmks
                                                    ? info?.added_vmks[index]
                                                        ?.mask
                                                    : ""
                                            }
                                            autoComplete="false"
                                        />
                                    </Alerttip>
                                    <Alerttip title="Default gateway for vmk  (example: 192.168.1.1)">
                                        <TextField
                                            variant="outlined"
                                            style={{ margin: "20px" }}
                                            label="Gateway"
                                            {...register(
                                                `added_vmks[${index}].gateway`
                                            )}
                                            defaultValue={
                                                info?.added_vmks
                                                    ? info?.added_vmks[index]
                                                        ?.gateway
                                                    : ""
                                            }
                                            autoComplete="false"
                                        />
                                    </Alerttip>
                                </div>
                                <Alerttip title="Would you like to enable vMotion on the VMK adapter?">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <input
                                                    type="checkbox"
                                                    defaultValue={
                                                        info?.added_vmks
                                                            ? info?.added_vmks[
                                                                index
                                                            ]?.VMotion === 1
                                                            : ""
                                                    }
                                                    {...register(
                                                        `added_vmks[${index}].VMotion`
                                                    )}
                                                />
                                            }
                                            label="vMotion"
                                        />
                                    </FormGroup>
                                </Alerttip>
                                <Alerttip title="Remove VMK">
                                    <IconButton size="small" style={{ display: "flex", marginLeft: "40px", marginTop: "10px" }}>
                                        <DeleteIcon
                                            sx={{ color: "#D3D3D3" }}
                                            onClick={() => {
                                                handleDelete(index);
                                            }}
                                        >
                                            Delete
                                        </DeleteIcon>
                                    </IconButton>
                                </Alerttip>
                            </Card>
                        </div>
                    ))}
                </form>
            </Paper>
        </Container>
    );
};

export default AddedVmk;
