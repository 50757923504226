import {
    AppBar,
    Box,
    Fab,
    Modal,
    Toolbar,
    Typography,
} from "@material-ui/core";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SchoolIcon from "@mui/icons-material/School";
import GradingIcon from "@mui/icons-material/Grading";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Context } from "../../context/Context";
import logo from "../../images/logo.png";
import useStyles from "./styles";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Navbar = () => {
    const [openUploads, setOpenUploads] = useState(false);
    const token = useContext(Context);
    const history = useHistory();
    const classes = useStyles();

    const handleOpenUploads = () => setOpenUploads(true);
    const handleCloseUploads = () => setOpenUploads(false);

    const handleLogout = () => {
        window.sessionStorage.clear();

        sessionStorage.removeItem(token);
        window.location.replace("/auth");
    };

    const Alerttip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "black",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    }));

    const handleClick = () => {
        history.push("/");
    };

    return (
        <AppBar className={classes.appBar} position="static" color="inherit">
            <Link style={{ textDecoration: "none" }} to="/">
                <img src={logo} alt="mantle icon" height="60" />
            </Link>
            <Toolbar>
                {token.token === null || token.token === "null" ? (
                    ""
                ) : (
                    <div className={classes.profile}>
                        <Alerttip title="Home">
                            <Link style={{ textDecoration: "none" }} to="/">
                                <HomeIcon className={classes.navbtn} />
                            </Link>
                        </Alerttip>
                        <Alerttip title="Uploads">
                            <Link to="/uploadpage/iso">
                                <FileUploadIcon
                                    className={classes.navbtn}
                                    style={{ fontSize: "2rem" }}
                                />
                            </Link>
                        </Alerttip>
                        <Alerttip title="Review">
                            <Link to="/review">
                                <GradingIcon
                                    className={classes.navbtn}
                                    style={{ fontSize: "2rem" }}
                                />
                            </Link>
                        </Alerttip>
                        <Alerttip title="Appliance Settings">
                            <Link
                                style={{ textDecoration: "none" }}
                                to="/appliancesettings"
                            >
                                <SettingsIcon className={classes.navbtn} />
                            </Link>
                        </Alerttip>
                        <Alerttip title="Documentation">
                            <Link
                                style={{ textDecoration: "none" }}
                                to="/docs/index.html"
                                target="_blank"
                            >
                                <SchoolIcon className={classes.navbtn} />
                            </Link>
                        </Alerttip>

                        <Alerttip title="Logout">
                            <LogoutIcon
                                className={classes.navbtn}
                                onClick={handleLogout}
                            />
                        </Alerttip>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
